import React, { useEffect, useState } from "react";
import { Autocomplete, Pagination, Snackbar, TextField } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import iconeAdd from "../../../assets/IconeSvg/boutonAdd.svg";
import iconeDelete from "../../../assets/IconeSvg/delete.svg";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { ajouterPresenceDunAnimateur, listeCompleteDesAnimateurs, recupereAnimateurParSonId, recupereListePresenceAnimateurParSonId, supprimerPresenceAnimateurDeLaClasse } from "../../../services/animateur/AnimateurRequest";
import { useParams } from "react-router-dom";

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const ListePresenceAnimateur = () => {
  registerLocale("fr", fr);
  let { animateurId } = useParams();
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 10,
    nombrePage: 1,
    pagePagination: 1,
  });
  const [loadingData, setLoadingData] = useState(true);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [animateurSelected, setAnimateurSelected] = useState(null);
  const [searchDate, setSearchDate] = useState(new Date());
  const [listePresence, setListePresence] = useState([]);
  const [listeCompleteAnimateur, setListeCompleteAnimateur] = useState([]);
  const [presenceInfo, setPresenceInfo] = useState(null);
  const [animateurSelectedEl, setAnimateurSelectedEl] = useState(null);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState(null);
  const [presenceData, setPresenceData] = useState({
    animateur: animateurId,
    dateAppel: new Date().toISOString(),
    present: true,
    enRetard: "NON",
    absent: false,
    classe: "",
    anneeScolaire: currentAnnee._id,
  });

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = snackbarPosition;

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const getListePresenceAnimateur = () => {
    recupereListePresenceAnimateurParSonId(0, pageQuery.size, animateurId, "")
      .then((res) => {
        setLoadingData(false);
        setListePresence(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("ERROR", error);
      });
  }

  const getListeCompleteDesAnimateur = () => {
    listeCompleteDesAnimateurs()
      .then((res) => {
        let liste = [];
        res.data.map(item => {
          if (item._id !== animateurId) {
            liste.push({
              label: `${item.nom} ${item.prenoms}`,
              value: item._id
            });
          }
        });
        setListeCompleteAnimateur(liste);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }

  const rechercherListePresenceAnimateurParDate = () => {
    setSearchLoading(true);
    recupereListePresenceAnimateurParSonId(0, pageQuery.size, animateurId, searchDate)
      .then((res) => {
        setSearchLoading(false);
        setListePresence(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setSearchLoading(false);
        console.log("ERROR", error);
      });
  }

  const clearFormEnregistrement = () => {
    document.getElementById("presence_animateur").showModal();
    setPresenceData({
      animateur: animateurId,
      dateAppel: new Date().toISOString(),
      present: true,
      enRetard: "NON",
      absent: false,
      classe: "",
      anneeScolaire: currentAnnee._id,
    })
  };

  const enregistrerLaPresence = () => {
    const data = {
      ...presenceData,
      classe: animateurSelected.classe ? animateurSelected.classe._id : "",
      commission: animateurSelected.commission ? animateurSelected.commission : "",
      niveau: animateurSelected.niveau ? animateurSelected.niveau : "",
      enRetard: presenceData.enRetard === "OUI" ? true : false,
      remplacant: presenceData.absent && animateurSelectedEl ? animateurSelectedEl.value : ""
    }
    if (presenceData.absent && !animateurSelectedEl) {
      setErrorMessage("Choisir un animateur remplaçant");
      setSnackbarOpenError(true);
    } else {
      setAddLoading(true);
      ajouterPresenceDunAnimateur(data)
        .then(res => {
          setAddLoading(false)
          document.getElementById("presence_animateur").close();
          setSuccessMessage('Présence marquée avec succès.')
          getListePresenceAnimateur()
        })
        .catch(err => {
          setAddLoading(false)
          if (err && err.response && err.response.data) {
            setErrorMessage(err.response.data.message);
            setSnackbarOpenError(true);
          } else {
            setErrorMessage("Une erreur s'est produite, rééssayer!");
            setSnackbarOpenError(true);
          }
          console.log('error')
        })
    }

  };

  const supprimerElementPresence = () => {
    setDeleteLoading(true)
    supprimerPresenceAnimateurDeLaClasse(presenceInfo._id)
      .then(res => {
        setDeleteLoading(false)
        document.getElementById("delete_presence").close();
        setSuccessMessage('Présence supprimée avec succès.')
        getListePresenceAnimateur()
      })
      .catch(err => {
        setDeleteLoading(false)
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
      })
  }

  const TableElement = ({ ponctualiterItem }) => {
    return (
      <tr className="">
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {new Date(ponctualiterItem.dateAppel).toLocaleDateString()}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {ponctualiterItem.present ? "Présent" : "Absent"}
        </td>

        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {ponctualiterItem.present && ponctualiterItem.enRetard ? "En retard" : ponctualiterItem.present && !ponctualiterItem.enRetard ? "A l'heure" : "-"}
        </td>
        
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {!ponctualiterItem.present && ponctualiterItem.remplacant ? `${ponctualiterItem.remplacant.nom} ${ponctualiterItem.remplacant.prenoms}` : "-"}
        </td>

        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {ponctualiterItem.classe ? ponctualiterItem.classe.commission.designation : "-"}
        </td>

        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {ponctualiterItem.classe ? ponctualiterItem.classe.niveau.designation : "-"}
        </td>

        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {ponctualiterItem.classe ? ponctualiterItem.classe.designation : "-"}
        </td>

        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          <div>
            <button
              onClick={() => {
                setPresenceInfo(ponctualiterItem);
                document.getElementById('delete_presence').showModal();
              }}
              className="w-9 h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
            >
              <img src={iconeDelete} alt="" className="w-5" />
            </button>
          </div>
        </td>

      </tr>
    );
  };

  useEffect(() => {
    getListePresenceAnimateur();
    getListeCompleteDesAnimateur();

    recupereAnimateurParSonId(animateurId)
      .then((res) => {
        setAnimateurSelected(res.data);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }, []);

  return (
    <>
      <div className="w-full h-full">
        <div className="">
          <div className="flex flex-col sm:flex-row justify-end items-end sm:items-start gap-2">
            <div className="w-full md:w-fit flex items-center gap-x-2 overflow-x-scroll sm:overflow-auto">
              <DatePicker
                locale="fr"
                dateFormat="dd/MM/yyyy"
                selected={searchDate}
                onChange={(date) => setSearchDate(date)}
                className="w-full h-10 font-medium text-sm border border-stone-300 rounded-lg px-4 py-2.5 focus:m-0"
              />
              <button
                disabled={searchLoading}
                className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
                onClick={() => rechercherListePresenceAnimateurParDate()}
              >
                {!searchLoading ? (
                  "Rechercher"
                ) : (
                  <ThreeDots
                    height="35"
                    width="35"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={searchLoading}
                  />
                )}
              </button>
            </div>
            <button
              onClick={() => {
                clearFormEnregistrement();
              }}
              className="bg-black w-full sm:w-fit h-10 px-4 flex items-center justify-center gap-x-2 rounded-xl"
            >
              <img src={iconeAdd} alt="" className="w-5 sm:w-auto" />
              <span className="text-white text-sm font-semibold truncate">
                Marquer la présence
              </span>
            </button>
          </div>

          <div className="flex w-full mt-2 h-full">
            <div className="overflow-x-auto bg-white w-full mt-2 ">
              <table className="custom-table table table-zebra w-full">
                <thead>
                  <tr className="bg-black h-12">
                    <th className="text-sm text-white">Date du cours</th>
                    <th className="text-sm text-white">Statut</th>
                    <th className="text-sm text-white">Arrivée</th>
                    <th className="text-sm text-white">Remplaçant</th>
                    <th className="text-sm text-white">Commission</th>
                    <th className="text-sm text-white">Niveau</th>
                    <th className="text-sm text-white">Classe</th>
                    <th className="text-sm text-white">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingData && listePresence.length !== 0 ? (
                    listePresence.map((item) => (
                      <TableElement key={item.id} ponctualiterItem={item} />
                    ))
                  ) : !loadingData && listePresence.length === 0 ? (
                    <tr>
                      <td colSpan="8">
                        <div className="w-full h-32 bg-white flex gap-x-2 text-red-500 items-center justify-center">
                          <p className="font-semibold text-lg text-red-600">
                            Aucun élément trouvé
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : loadingData && listePresence.length === 0 ? (
                    <tr>
                      <td colSpan="8">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="8">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <Pagination
              count={pageQuery.nombrePage}
              page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
            // onChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>


      {/* ENREGISTRER UNE PRESENCE */}
      <dialog id="presence_animateur" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-left text-primary">
            Marquer la présence de cet animateur
          </h3>
          <div className="py-6">
            <div className="flex items-center gap-x-4">
              <div className="form-control">
                <label className="label cursor-pointer gap-x-2">
                  <span className="label-text text-base text-black font-semibold">
                    Présent
                  </span>
                  <input
                    type="checkbox"
                    checked={presenceData.present}
                    onChange={(e) =>
                      setPresenceData((prev) => ({
                        ...prev,
                        present: true,
                        absent: false,
                      }))
                    }
                    className="checkbox rounded-md checkbox-sm [--chkbg:theme(colors.green.600)] [--chkfg:white]"
                  />
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer gap-x-2">
                  <span className="label-text text-base text-black font-semibold">
                    Absent
                  </span>
                  <input
                    type="checkbox"
                    checked={presenceData.absent}
                    onChange={(e) =>
                      setPresenceData((prev) => ({
                        ...prev,
                        present: false,
                        absent: true,
                      }))
                    }
                    className="checkbox rounded-md checkbox-sm [--chkbg:theme(colors.green.600)] [--chkfg:white]"
                  />
                </label>
              </div>
            </div>
            {presenceData.present ? (
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold">Arrivée</span>
                </div>
                <select
                  value={presenceData.enRetard}
                  onChange={(e) =>
                    setPresenceData((prev) => ({
                      ...prev,
                      enRetard: e.target.value,
                    }))
                  }
                  className="select select-bordered font-semibold"
                >
                  <option disabled value="">
                    Choisir une valeur
                  </option>
                  <option value="NON">A l'heure</option>
                  <option value="OUI">En retard</option>
                </select>
              </label>
            ) : null}
            {presenceData.absent ? (
              <div className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold">Choisir un animateur remplaçant</span>
                </div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={listeCompleteAnimateur}
                  value={animateurSelectedEl}
                  onChange={(event, newValue) => {
                    setAnimateurSelectedEl(newValue ? newValue : "");
                  }}
                  inputValue={autocompleteInputValue}
                  onInputChange={(event, newInputValue) => {
                    setAutocompleteInputValue(newInputValue);
                  }}
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} />}
                  disabled={searchLoading}
                />
              </div>
            ) : null}
          </div>
          <div className="modal-action flex justify-end">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-end gap-x-3"
            >
              {addLoading ? (
                <div
                  disabled={addLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={addLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={() => enregistrerLaPresence()}
                disabled={addLoading}
                className="mt-2 sm:mt-0 w-full sm:w-44 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!addLoading ? (
                  "Enregistrer"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={addLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>

        {/* SNACKBAR SUCCESS */}
        {successMessage ? (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackbarSuccessOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbarSuccess}
            key={vertical + horizontal}
          >
            <div className="bg-green-600 font-semibold z-[999999] text-white px-10 py-3 rounded-lg">
              {successMessage}
            </div>
          </Snackbar>
        ) : null}

        {/* SNACKBAR ERROR */}
        {errorMessage ? (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackbarErrorOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbarError}
            key={vertical + horizontal}
          >
            <div className="bg-red-600 font-semibold z-[999999] text-white px-10 py-3 rounded-lg">
              {errorMessage}
            </div>
          </Snackbar>
        ) : null}
      </dialog>

      {/* MODAL DELETE PRESENCE */}
      <dialog id="delete_presence" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-center text-primary">
            Supprimer cet élément
          </h3>
          <div className="pt-4 pb-2">
            <p className="text-center text-base font-medium text-gray-600">
              Attention action est irrversible et entrainera la suppression de
              cet élément
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form method="dialog" className="w-full flex flex-col sm:flex-row justify-center gap-x-3">
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? <div disabled={deleteLoading} className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold">
                Annuler
              </div> : <button disabled={deleteLoading} className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold">
                Annuler
              </button>}
              <div
                onClick={() => supprimerElementPresence()}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
};

export default ListePresenceAnimateur;
