import React, { useEffect, useState } from "react";
import iconeAdd from "../../../assets/IconeSvg/boutonAdd.svg";
import iconethreeDots from "../../../assets/IconeSvg/treeDotsGray.svg";
import iconeDelete from "../../../assets/IconeSvg/delete.svg";
import iconePencil from "../../../assets/IconeSvg/pencil.svg";
import iconeEye from "../../../assets/IconeSvg/eye.svg";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import { Drawer, Pagination, Snackbar } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import {
  ajouterPermissionParMenuAuRole,
  ajouterUneRole,
  listeDesRolePaginee,
  modifierUneRole,
  retirerMenuAssocieAuRole,
  supprimerUneRole
} from "../../../services/config-role/RoleRequest";
import { listeCompleteDesMenu } from "../../../services/config-role/MenuRequest";
import { listeCompleteDesPermission } from "../../../services/config-role/PermissionRequest";

import { MultiSelect } from "react-multi-select-component";
import { returnPermissionsParMenu, verificationPermissionsExistantes } from "../../../utils/ManageUserProfil";
const listePermissionAUTh = returnPermissionsParMenu("/config-role");

const connectedUser = localStorage.getItem("cc_pssm_admin")
  ? JSON.parse(localStorage.getItem("cc_pssm_admin"))
  : null;
const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const languageOption = {
  allItemsAreSelected: "Tous les items sont selectionnées.",
  clearSearch: "Vider la recherche",
  clearSelected: "Vider la selection",
  noOptions: "Pas d'options",
  search: "Rechercher",
  selectAll: "Tout choisir",
  selectAllFiltered: "Tout sélectionner (Filtrés)",
  selectSomeItems: "Selectionner...",
  create: "Créer"
};

const RolesComponent = () => {
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 10,
    nombrePage: 1,
    pagePagination: 1
  });
  const [addLoading, setAddLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [addPermissionLoading, setAddPermissionLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [listeMenus, setListeMenus] = useState([]);
  const [listePermissions, setListePermissions] = useState([]);
  const [listeRole, setListeRole] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [designation, setDesignation] = useState("");
  const [description, setDescription] = useState("");
  const [roleInfo, setRoleInfo] = useState(null);
  const [newRolePermission, setNewRolePermission] = useState({
    role: "",
    menu: "",
    permissions: []
  });

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;

  const TableElement = ({ role }) => {
    return (
      <tr className=" bg-white">
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {role.designation ? role.designation : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {role.description ? role.description : "-"}
        </td>

        <td className="px-4">
          <div className="dropdown dropdown-left dropdown-end">
            <div
              tabIndex={0}
              className="w-7 h-7 rounded-lg bg-gray-200 flex items-center justify-center cursor-pointer"
            >
              <img src={iconethreeDots} alt="" />
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-50 role p-1 shadow border bg-base-100 rounded-lg w-32 mr-2"
            >
              {verificationPermissionsExistantes(
                listePermissionAUTh,
                "VOIR_DETAIL"
              ) && (
                  <button
                    onClick={() => {
                      setRoleInfo(role);
                      openModalModif(role);
                    }}
                    className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                  >
                    <img src={iconeEye} alt="" className="w-5" />
                    <span className="font-semibold text-sm">Détail</span>
                  </button>
                )}

              {verificationPermissionsExistantes(
                listePermissionAUTh,
                "SUPPRIMER"
              ) && (
                  <button
                    onClick={() => {
                      setRoleInfo(role);
                      document.getElementById("delete_modePaiement").showModal();
                    }}
                    className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                  >
                    <img src={iconeDelete} alt="" className="w-5" />
                    <span className="font-semibold text-sm">Supprimer</span>
                  </button>
                )}
            </ul>
          </div>
        </td>
      </tr>
    );
  };

  const openModalModif = (role) => {
    setOpenModalDetail(true);
    setRoleInfo(role);
    setNewRolePermission((prev) => ({
      ...prev,
      role: role._id
    }));
    setDesignation(role.designation);
    setDescription(role.description);
  };

  const preselectMenuPermission = (menu, permission) => {
    let liste = [];
    permission.map((item) =>
      liste.push({ label: item.designation, value: item._id })
    );
    setNewRolePermission((prev) => ({
      ...prev,
      menu: menu._id,
      permissions: liste
    }));
  };

  // === VIDE FORMULAIRE ===
  const clearFormEnregistrement = () => {
    setRoleInfo(null);
    setAddLoading(false);
    setDesignation("");
    setDescription("");
  };

  // === RECUPERE LISTE DES MENU ===
  const getListeDesMenus = () => {
    listeCompleteDesMenu()
      .then((res) => {
        setListeMenus(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES PERMISSIONS ===
  const getListeDesPermissions = () => {
    listeCompleteDesPermission()
      .then((res) => {
        let liste = [];
        res.data.map((item) => {
          liste.push({ label: item.designation, value: item._id });
        });
        setListePermissions(liste);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesRole = () => {
    setLoadingData(true);
    listeDesRolePaginee(pageQuery.page, pageQuery.size, "")
      .then((res) => {
        setLoadingData(false);
        setListeRole(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === ENREGISTRE NOUVEAU PERMISSION ===
  const ajoutePermissionParMenu = () => {
    setAddPermissionLoading(true);
    let liste = [];
    newRolePermission.permissions.map((item) => liste.push(item.value));

    const permissionValues = {
      role: roleInfo._id,
      menu: newRolePermission.menu,
      permissions: liste
    };

    ajouterPermissionParMenuAuRole(permissionValues)
      .then((res) => {
        setRoleInfo(res.data);
        setNewRolePermission({
          role: res.data._id,
          menu: "",
          permissions: []
        });
        setAddPermissionLoading(false);
        document.getElementById("add-permission").close();
        setSuccessMessage("Permission enregistrées avec succès.");
        setSnackbarOpenSuccess(true);
      })
      .catch((err) => {
        setAddPermissionLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("ELEMENT", err);
      });
  };

  // === ENREGISTRE UNE COMMISSION ===
  const enregistrerRole = () => {
    setAddLoading(true);
    const modePaiementData = {
      designation: designation,
      description: description,
      anneeScolaire: currentAnnee ? currentAnnee._id : "",
      origine: connectedUser ? connectedUser._id : ""
    };

    ajouterUneRole(modePaiementData)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Role enregistréee avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesRole();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("ELEMENT", err);
      });
  };

  // === MODIFIER UNE COMMISSION ===
  const modifierRole = () => {
    setAddLoading(true);
    const modePaiementData = {
      designation: designation,
      description: description,
      anneeScolaire: currentAnnee ? currentAnnee._id : "",
      origine: connectedUser ? connectedUser._id : ""
    };

    modifierUneRole(modePaiementData, roleInfo._id)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Role modifié avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesRole();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("ELEMENT", err);
      });
  };

  // === SUPPRIMER UNE COMMISSION ===
  const retirerMenuAuRole = (menuSelected) => {
    setDeleteLoading(true);
    retirerMenuAssocieAuRole(roleInfo._id, menuSelected._id)
      .then((res) => {
        setRoleInfo(res.data)
        setSuccessMessage("Menu retiré de ce rôle avec succès");
        getListeDesRole();
        setSnackbarOpenSuccess(true);
        setDeleteLoading(false);
      })
      .catch((err) => {
        setDeleteLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("api error", err);
      });
  };

  // === SUPPRIMER UNE COMMISSION ===
  const supprimerRole = () => {
    setDeleteLoading(true);

    supprimerUneRole(roleInfo._id)
      .then((res) => {
        setSuccessMessage("Role supprimé avec succès");
        getListeDesRole();
        setSnackbarOpenSuccess(true);
        setDeleteLoading(false);
        document.getElementById("delete_modePaiement").close();
      })
      .catch((err) => {
        setDeleteLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("api error", err);
      });
  };

  // === FILTRER LES ELEMENTS ===
  const filterElement = () => {
    setSearchLoading(true);
    listeDesRolePaginee(pageQuery.page, pageQuery.size, searchParam)
      .then((res) => {
        setSearchLoading(false);
        setListeRole(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setSearchLoading(false);
        console.log("error", error);
      });
  };

  // === PAGINATION DES ELEMENTS ===
  const handlePaginationChange = (event, value) => {
    setLoadingData(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value
    }));
    listeDesRolePaginee(value, pageQuery.size, searchParam)
      .then((res) => {
        setLoadingData(false);
        setListeRole(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  useEffect(() => {
    getListeDesMenus();
    getListeDesPermissions();

    setLoadingData(true);
    listeDesRolePaginee(pageQuery.page, pageQuery.size, "")
      .then((res) => {
        setLoadingData(false);
        setListeRole(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  }, []);

  return (
    <div className="w-full h-full">
      <div className="flex flex-col sm:flex-row justify-end items-end sm:items-center gap-2">
        <div className="w-full sm:w-fit flex items-center gap-x-2">
          <input
            type="text"
            placeholder="Rechercher un élement..."
            value={searchParam}
            onChange={(e) => setSearchParam(e.target.value)}
            className="input input-bordered w-11/12 sm:w-56 h-10 text-sm font-medium"
          />
          <button
            disabled={searchLoading}
            className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
            onClick={filterElement}
          >
            {!searchLoading ? (
              "Rechercher"
            ) : (
              <ThreeDots
                height="35"
                width="35"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={searchLoading}
              />
            )}
          </button>
        </div>

        {verificationPermissionsExistantes(listePermissionAUTh, "AJOUTER") && (
          <button
            onClick={() => {
              clearFormEnregistrement();
              setOpenModal(true);
            }}
            className="bg-black w-full sm:w-fit h-10 px-4 flex items-center justify-center gap-x-2 rounded-xl"
          >
            <img src={iconeAdd} alt="" className="w-5 sm:w-auto" />
            <span className="text-white text-sm font-semibold truncate">
              Ajouter un role
            </span>
          </button>
        )}
      </div>

      <div className="mt-2 w-full h-full">
        <div className="overflow-x-auto">
          <table className="custom-table table table-zebra w-full">
            <thead>
              <tr className="bg-black h-12">
                <th className="text-sm text-white">Désignation</th>
                <th className="text-sm text-white">Description</th>
                <th className="text-sm text-white">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loadingData && listeRole.length > 0 ? (
                listeRole.map((item) => (
                  <TableElement key={item._id} role={item} />
                ))
              ) : !loadingData && listeRole.length === 0 ? (
                <tr>
                  <td colSpan="3">
                    <div className="w-full h-32 bg-white flex gap-x-2 text-red-600 items-center justify-center">
                      <p className="font-semibold text-lg text-red-600">
                        Aucun élément trouvé
                      </p>
                    </div>
                  </td>
                </tr>
              ) : loadingData && listeRole.length === 0 ? (
                <tr>
                  <td colSpan="3">
                    <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-600 items-center justify-center">
                      <TailSpin
                        height="30"
                        width="30"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={loadingData}
                      />
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="3">
                    <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-600 items-center justify-center">
                      <TailSpin
                        height="30"
                        width="30"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={loadingData}
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-2">
          <Pagination
            count={pageQuery.nombrePage}
            page={pageQuery.pagePagination}
            variant="outlined"
            shape="rounded"
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="w-screen sm:w-[430px] p-4 flex flex-col bg-white h-full">
          <button onClick={() => setOpenModal(false)}>
            <img src={iconeCloseWindow} alt="" />
          </button>

          <h2 className="mt-3 text-xl font-extrabold">
            {roleInfo === null
              ? " Ajouter un mode paiement"
              : "Modifier ce mode paiement"}
          </h2>

          <div className="mt-6">
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Désignation
                  </span>
                </div>
                <input
                  type="text"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  disabled={addLoading}
                  placeholder="Entrer une designation"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>
            <div className="mt-2">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Description
                  </span>
                </div>
                <input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={addLoading}
                  placeholder="Entrer une description"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>

            <button
              onClick={
                roleInfo === null
                  ? () => enregistrerRole()
                  : () => modifierRole()
              }
              disabled={addLoading}
              className="mt-8 bg-primary flex items-center justify-center w-full h-11 font-bold text-white rounded-lg hover:drop-shadow-md"
            >
              {!addLoading ? (
                <span>{roleInfo === null ? "Enregistrer" : "Modifier"}</span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={addLoading}
                />
              )}
            </button>
          </div>
        </div>
      </Drawer>

      {/* VOIR LISTE DES PERMISSIONS */}
      <Drawer
        anchor={"right"}
        open={openModalDetail}
        onClose={() => setOpenModalDetail(false)}
      >
        <div className="w-screen sm:w-[460px] p-4 flex flex-col bg-white h-full">
          <button onClick={() => setOpenModalDetail(false)}>
            <img src={iconeCloseWindow} alt="" />
          </button>

          <h2 className="mt-3 text-xl font-extrabold">
            Liste des permissions rôle:{" "}
            <span className="text-primary">
              {roleInfo ? roleInfo.designation : ""}
            </span>
          </h2>

          <div className="mt-8 flex justify-end">
            <button
              className="w-fit px-4 h-9 rounded-lg flex items-center justify-center text-sm text-white font-bold bg-black"
              onClick={() => {
                document.getElementById("add-permission").showModal();
                setNewRolePermission({
                  role: roleInfo._id,
                  menu: "",
                  permissions: []
                });
              }}
            >
              Ajouter un menu
            </button>
          </div>

          <div className="mt-4 space-y-3 pb-16">
            {roleInfo &&
              roleInfo.menusPermissions.length > 0 &&
              roleInfo.menusPermissions.map((item, index) => (
                <div
                  key={index}
                  className="border rounded-lg px-2 pt-2 pb-3 bg-gray-50"
                >
                  <div className="flex items-center justify-between gap-5 pb-2">
                    <p className="font-bold">
                      Menu:{" "}
                      <span className="text-primary font-extrabold">
                        {item.menu.designation}
                      </span>
                    </p>
                    <div className="flex flex-wrap items-center justify-end gap-2">
                      <button
                        onClick={() => {
                          document.getElementById("add-permission").showModal();
                          preselectMenuPermission(item.menu, item.permissions);
                        }}
                        className="w-fit px-2 h-6 rounded bg-primary text-xs text-white font-medium"
                      >
                        Ajout permission
                      </button>
                      <button
                        onClick={() => {
                          retirerMenuAuRole(item.menu);
                        }}
                        className="w-fit px-2 h-6 rounded bg-black text-xs text-white font-medium"
                      >
                        Sup. menu
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center gap-3 border-t pt-3">
                    {item.permissions.map((el, key) => (
                      <div
                        key={key}
                        className="w-fit px-3 h-8 rounded-lg bg-gray-200 border flex items-center justify-center gap-2 text-xs font-semibold"
                      >
                        {el.designation}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            {roleInfo && roleInfo.menusPermissions.length == 0 && (
              <div className="py-10 flex items-center justify-center border rounded-lg bg-gray-100">
                <p className="text-base font-medium text-gray-500">
                  Aucune permissions associées à ce rôle
                </p>
              </div>
            )}
          </div>
        </div>
      </Drawer>

      {/* MODAL AJOUTER PERMISSIONS PAR MENU */}
      <dialog id="add-permission" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-black">
            Ajouter des permissions
          </h3>
          <div className="py-4">
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Menu visible
                  </span>
                </div>
                <select
                  value={newRolePermission.menu}
                  onChange={(e) =>
                    setNewRolePermission((prev) => ({
                      ...prev,
                      menu: e.target.value
                    }))
                  }
                  className="select select-bordered"
                >
                  <option value="" disabled selected>
                    Choisir un menu
                  </option>
                  {listeMenus.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.designation}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div>
              <div className="label">
                <span className="label-text font-bold text-gray-500 text-sm">
                  Permissions
                </span>
              </div>
              <MultiSelect
                overrideStrings={languageOption}
                options={listePermissions}
                value={newRolePermission.permissions || []}
                onChange={(selected) => {
                  setNewRolePermission((prev) => ({
                    ...prev,
                    permissions: selected
                  }));
                }}
                labelledBy="Select"
              />
            </div>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {addPermissionLoading ? (
                <div
                  disabled={addPermissionLoading}
                  className="w-full h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={addPermissionLoading}
                  className="w-full h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={ajoutePermissionParMenu}
                disabled={addPermissionLoading}
                className="mt-2 sm:mt-0 w-full px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!addPermissionLoading ? (
                  "Enregistrer"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={addPermissionLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>

        {/* SNACKBAR SUCCESS */}
        {successMessage ? (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackbarSuccessOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbarSuccess}
            key={vertical + horizontal}
            className="z-[999999]"
          >
            <div className="bg-green-600 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
              {successMessage}
            </div>
          </Snackbar>
        ) : null}

        {/* SNACKBAR ERROR */}
        {errorMessage ? (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackbarErrorOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbarError}
            key={vertical + horizontal}
            className="z-[999999]"
          >
            <div className="bg-red-600 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
              {errorMessage}
            </div>
          </Snackbar>
        ) : null}
      </dialog>

      {/* MODAL DELETE ENTREPRISE */}
      <dialog id="delete_modePaiement" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-center text-primary">
            Supprimer ce mode
          </h3>
          <div className="py-4">
            <p className="text-center text-base font-medium text-gray-600">
              Attention action est irrversible et entrainera la suppression de
              ce mode
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? (
                <div
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={supprimerRole}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
        {/* SNACKBAR SUCCESS */}
        {successMessage ? (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackbarSuccessOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbarSuccess}
            key={vertical + horizontal}
            className="z-[999999]"
          >
            <div className="bg-green-600 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
              {successMessage}
            </div>
          </Snackbar>
        ) : null}

        {/* SNACKBAR ERROR */}
        {errorMessage ? (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackbarErrorOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbarError}
            key={vertical + horizontal}
            className="z-[999999]"
          >
            <div className="bg-red-600 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
              {errorMessage}
            </div>
          </Snackbar>
        ) : null}
      </dialog>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
          className="z-[999999]"
        >
          <div className="bg-green-600 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
          className="z-[999999]"
        >
          <div className="bg-red-600 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default RolesComponent;
