import React, { useEffect, useState } from "react";
import BaseLayout from "../../layout/BaseLayout";
import { Tab, Tabs } from "@mui/material";
import AnneeCatechetique from "./annee-scolaire/AnneeCatechetique";
import Commissions from "./commissions/Commissions";
import Niveaux from "./niveaux/Niveaux";
import Classes from "./classes/Classes";
import ModePaiement from "./mode-paiement/ModePaiement";
import MotifPaiement from "./motif-paiement/MotifPaiement";
import Tarification from "./tarification/Tarification";
import MotifRejetInscription from "./motif-rejet-inscription/MotifRejetInscription";
import NiveauScolaire from "./niveau-scolaire/NiveauScolaire";
import Notations from "./notations/Notations";
import { jwtDecode } from "jwt-decode";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const Parametrages = () => {
  const [valueTab, setValueTab] = useState(0);
  const [connectedUser, setConnectedUser] = useState(null);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    if (localStorage.getItem("cc_pssm_api")) {
      const token = localStorage.getItem("cc_pssm_api");
      const decoded = jwtDecode(token);
      setConnectedUser(decoded ? decoded.connected : null);
    }
  }, [])
  

  return (
    <BaseLayout>
      <div className="w-full h-full">

      
        <div className="w-full ">
          <h1 className="text-2xl md:text-3xl font-extrabold">Paramètres</h1>
          <p className="mt-2 text-gray-500 font-medium text-sm sm:text-base md:text-lg">
            Paramétrer les éléments nécessaires au fonctionnement de
            l’application
          </p>
        </div>

        <div className="mt-5 border-b">
          {connectedUser && connectedUser.profil === 'SUPER_ADMIN' && <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            variant="scrollable"
            textColor="black"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            <Tab
              label="Années catéchétique"
              {...a11yProps(0)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Niveaux scolaire"
              {...a11yProps(1)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Commissions"
              {...a11yProps(2)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Niveaux"
              {...a11yProps(3)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Classes"
              {...a11yProps(4)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Notations"
              {...a11yProps(5)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Modes paiement"
              {...a11yProps(6)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Motifs paiement"
              {...a11yProps(7)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Motifs rejet inscription"
              {...a11yProps(8)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Tarifications"
              {...a11yProps(9)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
          </Tabs>}

          {connectedUser && connectedUser.profil !== 'SUPER_ADMIN' && <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            variant="scrollable"
            textColor="black"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            <Tab
              label="Années catéchétique"
              {...a11yProps(0)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Classes"
              {...a11yProps(1)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Notations"
              {...a11yProps(2)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Motifs paiement"
              {...a11yProps(3)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Motifs rejet inscription"
              {...a11yProps(4)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Tarifications"
              {...a11yProps(5)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
          </Tabs>}
        </div>

        {/* VUE SUPER ADMIN */}
        {connectedUser && connectedUser.profil === 'SUPER_ADMIN' &&<div className="mt-8">
          <CustomTabPanel value={valueTab} index={0}>
            <AnneeCatechetique />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={1}>
            <NiveauScolaire />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={2}>
            <Commissions />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={3}>
            <Niveaux />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={4}>
            <Classes />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={5}>
            <Notations />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={6}>
            <ModePaiement />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={7}>
            <MotifPaiement />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={8}>
            <MotifRejetInscription />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={9}>
            <Tarification />
          </CustomTabPanel>
        </div>}


        {/* VUE ADMIN PAROISSE */}
        {connectedUser && connectedUser.profil !== 'SUPER_ADMIN' && <div className="mt-8">
          <CustomTabPanel value={valueTab} index={0}>
            <AnneeCatechetique />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={1}>
            <Classes />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={2}>
            <Notations />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={3}>
            <MotifPaiement />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={4}>
            <MotifRejetInscription />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={5}>
            <Tarification />
          </CustomTabPanel>
        </div>}
      </div>
    </BaseLayout>
  );
};

export default Parametrages;
