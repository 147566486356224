import { BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
}, function (error) {
  console.log('AN ERROR REQUEST', error)
});

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const listeDesAnimateurPaginee = async (page = 1, size = 10, param =" ", commission =" ", niveau =" ", classe =" ") => {  
  return await axios.get(
    BASE_URL(`animateur/query?page=${page}&size=${size}&param=${param}&commission=${commission}&niveau=${niveau}&classe=${classe}&annee=${currentAnnee ? currentAnnee._id : ""}`),
    {}
  );
};

export const listeCompleteDesAnimateurs = async (commission = "", niveau = "", classe = "") => {
  return await axios.get(
    BASE_URL(`animateur/complete?commission=${commission}&niveau=${niveau}&classe=${classe}`),
    {}
  );
};

export const recupereListeCompleteAnimateurParClasse = async (classeId) => {
  return await axios.get(
    BASE_URL(`animateur/classe/${classeId}/all`),
    {}
  );
};

export const recupereAnimateurParSonId = async (animateurId) => {
  return await axios.get(
    BASE_URL(`animateur/${animateurId}`),
    {}
  );
};

export const recupereListePresenceAnimateurParSonId = async (page, size, animateur, date) => {
  return await axios.get(
    BASE_URL(`presence-animateur/query?page=${page}&size=${size}&animateur=${animateur}&date=${date}`),
    {}
  );
};

export const ajouterPresenceDunAnimateur = async (values) => {
  return await axios.post(
    BASE_URL(`presence-animateur`),
    values
  );
};

export const ajouterUneAnimateur = async (values) => {
  return await axios.post(
    BASE_URL(`animateur`),
    values
  );
};

export const modifierUneAnimateur = async (values, animateurId) => {
  return await axios.put(
    BASE_URL(`animateur/${animateurId}`),
    values
  );
};

export const genererMotDePasseAnimateur = async (animateurId) => {
  return await axios.post(
    BASE_URL(`animateur/generate-password/${animateurId}`),
    {}
  );
};

export const supprimerUneAnimateur = async (animateurId) => {
  return await axios.delete(
    BASE_URL(`animateur/${animateurId}`),
    {}
  );
};

export const supprimerAnimateurDeLaClasse = async (animateurId, type) => {
  return await axios.delete(
    BASE_URL(`animateur/${animateurId}/delete-classe?type=${type}`),
    {}
  );
};

export const supprimerPresenceAnimateurDeLaClasse = async (presenceId) => {
  return await axios.delete(
    BASE_URL(`presence-animateur/${presenceId}`),
    {}
  );
};
