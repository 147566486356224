import { BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
}, function (error) {
  console.log('AN ERROR REQUEST', error)
});

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const listeDesMotifRejetInscriptionPaginee = async (page = 1, size = 10, param = "") => {  
  return await axios.get(
    BASE_URL(`motif-rejet-inscription/query?page=${page}&size=${size}&param=${param}`),
    {}
  );
};

export const listeCompleteDesMotifRejetInscriptions = async () => {
  return await axios.get(
    BASE_URL(`motif-rejet-inscription`),
    {}
  );
};

export const listeCompleteDesMotifRejetInscriptionsParAnnee = async (anneeId) => {
  return await axios.get(
    BASE_URL(`motif-rejet-inscription`),
    {}
  );
};

export const recupereMotifRejetInscriptionParSonId = async (commissionId) => {
  return await axios.get(
    BASE_URL(`motif-rejet-inscription/${commissionId}`),
    {}
  );
};

export const ajouterUneMotifRejetInscription = async (values) => {
  return await axios.post(
    BASE_URL(`motif-rejet-inscription`),
    values
  );
};

export const modifierUneMotifRejetInscription = async (values, commissionId) => {
  return await axios.put(
    BASE_URL(`motif-rejet-inscription/${commissionId}`),
    values
  );
};

export const supprimerUneMotifRejetInscription = async (commissionId) => {
  return await axios.delete(
    BASE_URL(`motif-rejet-inscription/${commissionId}`),
    {}
  );
};
