import React, { useEffect, useState } from "react";
import SnackbarComponent from "../../../components/SnackbarComponent";
import { Drawer, Pagination } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import iconeDefaultUser from "../../../assets/IconeSvg/user-rounded.svg";
import iconethreeDots from "../../../assets/IconeSvg/treeDotsGray.svg";
import iconeDelete from "../../../assets/IconeSvg/delete.svg";
import iconeBadge from "../../../assets/IconeSvg/badge.svg";
import iconeSquareShare from "../../../assets/IconeSvg/square-share.svg";
import iconeAddNote from "../../../assets/IconeSvg/document-add.svg";
import iconeDownload from "../../../assets/IconeSvg/download-minimalistic.svg";
import {
  exportListeCatechumene,
  listeDesCatechumenePaginee,
  supprimerCatechumeneDeLaClasse,
} from "../../../services/catechumene/CatechumeneRequest";
import { NavLink, useParams } from "react-router-dom";
import {
  listeCompleteDesCatechumenesParClasse,
  listeDesCatechumenesParFilterParam,
  transfertCatechumeneVersAutresClasse,
} from "../../../services/historique-catechumene/HistoriqueCatechumeneRequest";
import { listeCompleteDesCommissions } from "../../../services/parametrage/CommissionRequest";
import { listeCompleteDesNiveauParCommission } from "../../../services/parametrage/NiveauxRequest";
import { listeCompleteDesClasses } from "../../../services/parametrage/ClasseRequest";
import { listeCompleteDesNotations } from "../../../services/parametrage/NotationRequest";
import {
  enregistrerUneNoteCatechumene,
  recupererLesNotesParCatechumene,
  supprimerUneNoteCatechumene,
} from "../../../services/notation/NotationRequest";
import { recupereListeCompleteAnimateurParClasse } from "../../../services/animateur/AnimateurRequest";
import { jwtDecode } from "jwt-decode";
import {
  returnPermissionsParMenu,
  verificationPermissionsExistantes,
} from "../../../utils/ManageUserProfil";

const connectedUser = localStorage.getItem("cc_pssm_api")
  ? jwtDecode(localStorage.getItem("cc_pssm_api"))
  : null;
const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const listePermissions = returnPermissionsParMenu("/classes");

const ClasseCatechumene = ({ classe }) => {
  let { classeId } = useParams();
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 20,
    nombrePage: 1,
    pagePagination: 1,
  });
  const [notePageQuery, setNotePageQuery] = useState({
    page: 0,
    size: 10,
    nombrePage: 1,
    pagePagination: 1,
  });
  const [addLoading, setAddLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [transfertLoading, setTransfertLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openModalNotation, setOpenModalNotation] = useState(false);
  const [modalShowDetailCatechumene, setModalShowDetailCatechumene] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [listeCatechumene, setListeCatechumene] = useState([]);
  const [listeCatechumeneCompleteDeLaClasse, setListeCatechumeneCompleteDeLaClasse] = useState([]);
  const [listeCommission, setListeCommission] = useState([]);
  const [listeNiveaux, setListeNiveaux] = useState([]);
  const [listeClasses, setListeClasses] = useState([]);
  const [listeDesMatieresNotation, setListeDesMatieresNotation] = useState([]);
  const [listeNoteCatechumene, setListeNoteCatechumene] = useState([]);
  const [animateurClasse, setAnimateurClasse] = useState(null);
  const [catechumeneInfo, setCatechumeneInfo] = useState(null);
  const [historiqueInfo, setHistoriqueInfo] = useState(null);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [catechumeneNoteData, setCatechumeneNoteData] = useState({
    trimestre: "",
    matiere: "",
    coefficientMatiere: "",
    animateur: "",
    anneeScolaire: currentAnnee ? currentAnnee._id : "",
  });
  const [noteParCatechumene, setNoteParCatechumene] = useState([]);
  const [transfertData, setTransfertData] = useState({
    commission: "",
    niveau: "",
    classe: "",
  });

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = snackbarPosition;

  const CatechumeneCard = ({ element }) => {
    return (
      <div key={element._id} className="w-full h-full relative">
        <div className="absolute right-2 top-2 z-50">
          <div className="dropdown dropdown-left dropdown-end">
            <div
              tabIndex={0}
              className="w-7 h-7 rounded-lg bg-gray-200 flex items-center justify-center cursor-pointer"
            >
              <img src={iconethreeDots} alt="" />
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-50 menu p-1 shadow border bg-base-100 rounded-lg w-32 mr-2"
            >
              {verificationPermissionsExistantes(
                listePermissions,
                "TRANSFERER"
              ) && (
                <button
                  onClick={() => {
                    setHistoriqueInfo(element);
                    document
                      .getElementById("transferer_catechumene")
                      .showModal();
                  }}
                  className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                >
                  <img src={iconeSquareShare} alt="" className="w-5" />
                  <span className="font-semibold text-sm">Transférer</span>
                </button>
              )}
              {verificationPermissionsExistantes(
                listePermissions,
                "SUPPRIMER"
              ) && (
                <button
                  onClick={() => {
                    setCatechumeneInfo(element.catechumene);
                    document
                      .getElementById("delete_catechumene_clase")
                      .showModal();
                  }}
                  className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                >
                  <img src={iconeDelete} alt="" className="w-5" />
                  <span className="font-semibold text-sm">Retirer</span>
                </button>
              )}
            </ul>
          </div>
        </div>
        <div
          className="cursor-pointer w-full h-full bg-white rounded-xl p-4 border drop-shadow-sm"
          onClick={() => {
            document.getElementById("liste-note").showModal();
            getListeDesNotesDuCatechumene(element.catechumene._id);
            setCatechumeneInfo(element.catechumene);
          }}
        >
          <div className="h-16 w-16 rounded-full mx-auto bg-gray-100 flex items-center justify-center">
            {element.catechumene && element.catechumene.photo ? (
              <img
                src={element.catechumene.photo}
                className="w-full h-full rounded-full"
              />
            ) : (
              <img src={iconeDefaultUser} className="w-8" />
            )}
          </div>
          <div className="mt-4">
            <p className="text-md lg:text-lg text-primary text-center font-extrabold">
              {element.catechumene.nom} {element.catechumene.prenoms}
            </p>
          </div>
          <div className="mt-1">
            <p className="text-sm text-gray-600 text-center font-semibold">
              {element.catechumene.contact}
            </p>
          </div>
          <div className="mt-1">
            <p className="text-sm text-gray-500 text-center font-medium">
              Date création:{" "}
              {new Date(element.catechumene.createdAt).toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const TableElement = ({ element }) => {
    return (
      <tr className="">
        <td className="px-4 text-sm text-stone-500 font-medium">
          {element.trimestre && element.trimestre === "PREMIER"
            ? "Premier trimestre"
            : element.trimestre && element.trimestre === "DEUXIEME"
            ? "Deuxième trimestre"
            : "Troisième trimestre"}
        </td>

        <td className="px-4 text-sm text-stone-500 font-medium">
          {element.matiere ? element.matiere.designation : "-"}
        </td>

        <td className="px-4 text-xs text-stone-500 font-medium">
          {element.matiere ? element.matiere.coefficient : "-"}
        </td>

        <td className="px-4 text-xs text-stone-500 font-medium">
          {element.note ? `${element.note}/${element.matiere.pointMax}` : "-"}
        </td>

        <td className="px-4 text-sm text-stone-500 font-medium">
          {element.appreciation ? element.appreciation : "-"}
        </td>

        <td className="px-4">
          <div>
            <button
              className="w-8 h-8 rounded-md bg-gray-200 flex items-center justify-center"
              onClick={() => {
                document.getElementById("delete-note-catechumene").showModal();
                setNoteToDelete(element);
              }}
            >
              <img src={iconeDelete} alt="" className="w-5" />
            </button>
          </div>
        </td>
      </tr>
    );
  };

  const getListeDesCatechumene = () => {
    setLoadingData(true);
    listeDesCatechumenePaginee(
      0,
      pageQuery.size,
      searchParam,
      classe.commission._id,
      classe.niveau._id,
      classe._id,
      "VALIDER"
    )
      .then((res) => {
        setLoadingData(false);
        setListeCatechumene(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const getListeDesNotesDuCatechumene = (catechumeneId) => {
    setLoadingData(true);
    recupererLesNotesParCatechumene(
      "PREMIER",
      "",
      catechumeneId,
      "",
      "",
      "",
      "",
      "",
      ""
    )
      .then((res) => {
        setLoadingData(false);
        setListeNoteCatechumene(res.data.results);
        setNotePageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const enregistrerNoteCatechumene = () => {
    if (!catechumeneNoteData.trimestre || !catechumeneNoteData.matiere) {
      setErrorMessage("Choisir le trimestre et la matière svp!");
      setSnackbarOpenError(true);
    } else {
      setAddLoading(true);

      const { coefficient } = listeDesMatieresNotation.find(
        (item) => item._id === catechumeneNoteData.matiere
      );

      let listeNote = [];
      noteParCatechumene.forEach((element) => {
        listeNote.push({
          ...element,
          total: parseInt(coefficient) * parseInt(element.note),
        });
      });

      const values = {
        ...catechumeneNoteData,
        animateur: connectedUser ? connectedUser.id : "",
        listeNotesCatechumenes: listeNote,
        commission: classe ? classe.classe.commission._id : "",
        niveau: classe ? classe.classe.niveau._id : "",
        classe: classe ? classe.classe._id : "",
        anneeScolaire: currentAnnee ? currentAnnee._id : "",
        coefficientMatiere: coefficient,
      };

      enregistrerUneNoteCatechumene(values)
        .then((res) => {
          setAddLoading(false);
          setSuccessMessage("Note enregistrée avec succès.");
          setOpenModalNotation(false);
          setSnackbarOpenSuccess(true);
          setCatechumeneNoteData({
            trimestre: "",
            note: "",
            appreciation: "",
            matiere: "",
            coefficientMatiere: "",
            catechumene: "",
            animateur: "",
            commission: classe ? classe.classe.commission._id : "",
            niveau: classe ? classe.classe.niveau._id : "",
            classe: classe ? classe.classe._id : "",
            anneeScolaire: currentAnnee ? currentAnnee._id : "",
          });
        })
        .catch((err) => {
          setAddLoading(false);
          if (err && err.response && err.response.data) {
            setErrorMessage(err.response.data.message);
            setSnackbarOpenError(true);
          } else {
            setErrorMessage("Erreur d'enregistrement, rééssayer!");
            setSnackbarOpenError(true);
          }
          console.log("error", err);
        });
    }
  };

  const supprimerNoteCatechumique = () => {
    setDeleteLoading(true);
    supprimerUneNoteCatechumene(noteToDelete._id)
      .then((res) => {
        setSuccessMessage("Note supprimé avec succès");
        getListeDesNotesDuCatechumene(catechumeneInfo._id);
        setSnackbarOpenSuccess(true);
        setDeleteLoading(false);
        document.getElementById("delete-note-catechumene").close();
        setNoteToDelete(null);
      })
      .catch((err) => {
        setDeleteLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("api error", err);
      });
  };

  const transfertDeCatechumene = () => {
    setTransfertLoading(true);
    transfertCatechumeneVersAutresClasse(historiqueInfo._id, transfertData)
      .then((res) => {
        setTransfertLoading(false);
        setSuccessMessage("Catéchumène transféré avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesCatechumene();
        document.getElementById("transferer_catechumene").close();
      })
      .catch((err) => {
        setTransfertLoading(false);
        if (err && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
      });
  };

  const supprimerCatechumiqueDeClasse = () => {
    setDeleteLoading(true);
    supprimerCatechumeneDeLaClasse(catechumeneInfo._id)
      .then((res) => {
        setSuccessMessage("Catechumene retiré de cette classe avec succès");
        getListeDesCatechumene();
        setSnackbarOpenSuccess(true);
        setDeleteLoading(false);
        document.getElementById("delete_catechumene_clase").close();
        setModalShowDetailCatechumene(false);
        setCatechumeneInfo(null);
      })
      .catch((err) => {
        setDeleteLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("api error", err);
      });
  };

  const filterElement = () => {
    setSearchLoading(true);
    listeDesCatechumenePaginee(
      pageQuery.page,
      pageQuery.size,
      searchParam,
      classe.commission._id,
      classe.niveau._id,
      classe._id,
      "VALIDER"
    )
      .then((res) => {
        setSearchLoading(false);
        setListeCatechumene(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setSearchLoading(false);
        console.log("error", error);
      });
  };

  const handlePaginationChange = (event, value) => {
    setLoadingData(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value,
    }));
    listeDesCatechumenesParFilterParam(
      value,
      pageQuery.size,
      searchParam,
      "",
      "",
      classeId,
      "VALIDER"
    )
      .then((res) => {
        setLoadingData(false);
        setListeCatechumene(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  // === RECUPERE LISTE DES ANIMATEURS ===
  const getAnimateurDeLaClasse = () => {
    recupereListeCompleteAnimateurParClasse(classeId)
      .then((res) => {
        setAnimateurClasse(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES MATIERES ===
  const getListeDesMatieresNotation = () => {
    listeCompleteDesNotations()
      .then((res) => {
        setListeDesMatieresNotation(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesCommission = () => {
    listeCompleteDesCommissions()
      .then((res) => {
        setListeCommission(res.data);
        setListeNiveaux([]);
        setListeClasses([]);
        setTransfertData({
          commission: "",
          niveau: "",
          classe: "",
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES NIVEAUX PAR COMISSION ===
  const getListeDesNiveauxParCommission = (commission = "", niveau = "") => {
    setListeNiveaux([]);
    setTransfertData((prev) => ({
      ...prev,
      niveau: "",
      classe: "",
    }));
    listeCompleteDesNiveauParCommission(commission)
      .then((res) => {
        getListeDesClassesParNiveau(commission, niveau, true);
        setListeNiveaux(res.data);
        setListeClasses([]);
      })
      .catch((error) => {
        console.log("error", error);
        setListeNiveaux([]);
      });
  };

  // === RECUPERE LISTE DES CLASSES PAR NIVEAU ===
  const getListeDesClassesParNiveau = (commission = "", niveau = "") => {
    setListeClasses([]);
    setTransfertData((prev) => ({
      ...prev,
      classe: "",
    }));
    listeCompleteDesClasses(commission, niveau)
      .then((res) => {
        setListeClasses(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // EXPORT LISTE CATECHUMENE PAR CLASSE
  const exporterListeCatechumeneInscrit = () => {
    setLoadingExport(true);
    const mainClasse = classe ? classe.classe : null
    exportListeCatechumene(
      mainClasse && mainClasse.commission ? mainClasse.commission._id : "",
      mainClasse && mainClasse.niveau ? mainClasse.niveau._id : "",
      mainClasse && mainClasse._id ? mainClasse._id : "",
      "VALIDER"
    )
      .then((res) => {
        setLoadingExport(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Recap-liste-catechumene-${
            classe ? classe.classe.designation : "classe"
          }.xlsx`
        );
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        setLoadingExport(false);
        console.log("api error", err);
      });
  };

  const updateNoteEtAppreciationCatechumene = (catechumeneId, key, value) => {
    setNoteParCatechumene((prevState) =>
      prevState.map((item) =>
        item.catechumene === catechumeneId ? { ...item, [key]: value } : item
      )
    );
  };

  const listeComppleteDesCatechumeneDeLaClasse = () => {
    listeCompleteDesCatechumenesParClasse(classeId)
      .then((res) => {
        let liste = [];
        res.data.forEach((element) => {
          liste.push({
            catechumene: element.catechumene._id,
            note: "",
            appreciation: "",
          });
        });
        setListeCatechumeneCompleteDeLaClasse(res.data)
        setNoteParCatechumene(liste);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getListeDesCommission();
    getAnimateurDeLaClasse();
    getListeDesMatieresNotation();
    listeComppleteDesCatechumeneDeLaClasse()

    listeDesCatechumenesParFilterParam(
      pageQuery.page,
      pageQuery.size,
      searchParam,
      "",
      "",
      classeId,
      "VALIDER"
    )
      .then((res) => {
        setLoadingData(false);
        setListeCatechumene(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  }, []);

  return (
    <div>
      <div className="flex flex-col sm:flex-row justify-end items-end sm:items-start gap-2">
        <div className="w-full md:w-fit flex items-center gap-x-2 overflow-x-scroll sm:overflow-auto">
          <input
            type="text"
            placeholder="Rechercher un élement..."
            value={searchParam}
            onChange={(e) => setSearchParam(e.target.value)}
            className="input input-bordered w-64 sm:w-56 h-10 text-sm font-medium"
          />
          <button
            disabled={searchLoading}
            className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
            onClick={filterElement}
          >
            {!searchLoading ? (
              "Rechercher"
            ) : (
              <ThreeDots
                height="35"
                width="35"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={searchLoading}
              />
            )}
          </button>
          <NavLink
            disabled={searchLoading}
            to={`/classes/${classeId}/print-badge`}
          >
            <button className="bg-black text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center gap-x-2">
              <img src={iconeBadge} className="w-5" />
              <p className="truncate">Générer les badges</p>
            </button>
          </NavLink>
          {verificationPermissionsExistantes(
            listePermissions,
            "AJOUTER_NOTE"
          ) && (
            <button
              className="bg-primary text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center gap-2"
              onClick={() => {
                setOpenModalNotation(true);
              }}
            >
              <img src={iconeAddNote} className="w-5" /> Noter
            </button>
          )}
          {verificationPermissionsExistantes(
            listePermissions,
            "VOIR_RECAP"
          ) && (
            <button
              disabled={loadingExport}
              className="bg-primary text-sm text-white h-10 min-w-32 px-4 font-semibold rounded-xl flex items-center justify-center gap-2"
              onClick={exporterListeCatechumeneInscrit}
            >
              {!loadingExport ? (
                <>
                  <img src={iconeDownload} className="w-5" />{" "}
                  <p className="truncate">Voir recap</p>
                </>
              ) : (
                <ThreeDots
                  height="35"
                  width="35"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={loadingExport}
                />
              )}
            </button>
          )}
        </div>
      </div>

      {!loadingData && listeCatechumene.length !== 0 ? (
        <div className="mt-5 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 items-stretch gap-2 sm:gap-4 w-full">
          {listeCatechumene.map((info, index) => (
            <CatechumeneCard element={info} key={index} />
          ))}
        </div>
      ) : null}

      {!loadingData && listeCatechumene.length === 0 ? (
        <div className="mt-5 h-56 w-full flex items-center justify-center">
          <p className="text-red-600 font-semibold">Aucun élément trouvé.</p>
        </div>
      ) : null}

      {loadingData && listeCatechumene.length === 0 ? (
        <div className="mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-3 w-full">
          {[0, 1, 2, 3, 4].map((item) => (
            <div
              key={item}
              className="w-full bg-white rounded-xl p-4 border drop-shadow-sm"
            >
              <div className="skeleton h-16 w-16 rounded-full mx-auto"></div>
              <div className="skeleton h-5 w-11/12 mx-auto rounded mt-4"></div>
              <div className="skeleton h-5 w-9/12 mx-auto rounded mt-2"></div>
            </div>
          ))}
        </div>
      ) : null}

      {loadingData && listeCatechumene.length !== 0 ? (
        <div className="mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-3 w-full">
          {[0, 1, 2, 3, 4, 5, 6].map((item) => (
            <div
              key={item}
              className="w-full bg-white rounded-xl p-4 border drop-shadow-sm"
            >
              <div className="skeleton h-16 w-16 rounded-full mx-auto"></div>
              <div className="skeleton h-5 w-11/12 mx-auto rounded mt-4"></div>
              <div className="skeleton h-5 w-9/12 mx-auto rounded mt-2"></div>
            </div>
          ))}
        </div>
      ) : null}

      <div className="flex justify-end mt-2">
        <Pagination
          count={pageQuery.nombrePage}
          page={pageQuery.pagePagination}
          variant="outlined"
          shape="rounded"
          onChange={handlePaginationChange}
        />
      </div>

      {/* NOTER LES CATECHUMENES */}
      <Drawer
        anchor={"right"}
        open={openModalNotation}
        onClose={() => {
          setCatechumeneInfo(null);
          setOpenModalNotation(false);
        }}
      >
        <div className="w-screen sm:w-[480px] min-h-screen p-4 bg-white">
          <button
            onClick={() => {
              setCatechumeneInfo(null);
              setOpenModalNotation(false);
            }}
          >
            <img src={iconeCloseWindow} alt="" />
          </button>

          <h2 className="mt-3 text-2xl text-black font-extrabold">
            Noter ces catéchumènes
          </h2>

          <div className="mt-6 pb-16">
            <div className="flex flex-col">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Trimestre
                  </span>
                </div>
                <select
                  value={catechumeneNoteData.trimestre}
                  onChange={(e) =>
                    setCatechumeneNoteData((prev) => ({
                      ...prev,
                      trimestre: e.target.value,
                    }))
                  }
                  className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                >
                  <option value="" disabled selected>
                    Choisir un élément
                  </option>
                  <option value="PREMIER">Premier trimestre</option>
                  <option value="DEUXIEME">Deuxième trimestre</option>
                  <option value="TROISIEME">Troisième trimestre</option>
                </select>
              </label>
              <label className="form-control w-full mt-1">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Evaluation
                  </span>
                </div>
                <select
                  value={catechumeneNoteData.matiere}
                  onChange={(e) =>
                    setCatechumeneNoteData((prev) => ({
                      ...prev,
                      matiere: e.target.value,
                    }))
                  }
                  className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                >
                  <option value="" disabled selected>
                    Choisir un élément
                  </option>
                  {listeDesMatieresNotation.map((item, index) => (
                    <option
                      value={item._id}
                    >{`${item.designation} [/${item.pointMax}]`}</option>
                  ))}
                </select>
              </label>
              <div className="mt-3">
                {listeCatechumeneCompleteDeLaClasse.length > 0 &&
                  listeCatechumeneCompleteDeLaClasse.map((item, index) => (
                    <div
                      key={index}
                      className="bg-gray-100 border rounded-xl w-full h-fit p-2 mt-3"
                    >
                      <div className="flex items-center gap-2">
                        <div className="w-9 h-9 rounded-lg">
                          {item.catechumene && item.catechumene.photo ? (
                            <img
                              src={item.catechumene.photo}
                              className="w-full h-full rounded-full object-cover"
                            />
                          ) : (
                            <img src={iconeDefaultUser} className="w-8" />
                          )}
                        </div>
                        {item.catechumene.prenoms && (
                          <p className="text-sm font-bold">{`${item.catechumene.nom} ${item.catechumene.prenoms}`}</p>
                        )}
                      </div>
                      {noteParCatechumene.map(
                        (noteField, i) =>
                          noteField.catechumene == item.catechumene._id && (
                            <div key={i} className="flex items-end gap-3">
                              <label className="form-control w-full">
                                <div className="label">
                                  <span className="label-text font-bold text-gray-500 text-sm">
                                    Nombre point obtenu
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  value={noteField.note}
                                  onChange={(e) =>
                                    updateNoteEtAppreciationCatechumene(
                                      item.catechumene._id,
                                      "note",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Entrer la note obtenu"
                                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                                />
                              </label>
                              <label className="form-control w-full">
                                <div className="label">
                                  <span className="label-text font-bold text-gray-500 text-sm">
                                    Appréciation (facultatif)
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  value={noteField.appreciation}
                                  onChange={(e) =>
                                    updateNoteEtAppreciationCatechumene(
                                      item.catechumene._id,
                                      "appreciation",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Entrer une appréciation"
                                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                                />
                              </label>
                            </div>
                          )
                      )}
                    </div>
                  ))}

                {listeCatechumene.length === 0 && (
                  <div className="mt-10 flex items-center justify-center">
                    <p className="font-semibold text-primary">
                      Aucun catéchumène trouvé
                    </p>
                  </div>
                )}
              </div>
            </div>
            {listeCatechumene.length > 0 && (
              <div className="mt-8">
                <button
                  disabled={addLoading}
                  className="w-full h-11 rounded-lg bg-primary text-white text-sm font-semibold flex items-center justify-center"
                  onClick={() => enregistrerNoteCatechumene()}
                >
                  {!addLoading ? (
                    "Enregistrer les notes"
                  ) : (
                    <ThreeDots
                      height="40"
                      width="40"
                      radius="9"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={addLoading}
                    />
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </Drawer>

      {/* MODAL DELETE */}
      <dialog id="delete_catechumene_clase" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-center text-primary">
            Supprimer de cette classe
          </h3>
          <div className="pt-4 pb-2">
            <p className="text-center text-base font-medium text-gray-600">
              Attention action est irrversible et entrainera la suppression du
              catéchumène de cette classe.
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? (
                <div
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={supprimerCatechumiqueDeClasse}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      {/* MODAL BULLETIN DE NOTE CATECHUMENE */}
      <dialog id="liste-note" className="modal">
        <div className="modal-box rounded-lg p-3 max-w-3xl">
          <form method="dialog">
            <button
              className="w-7 h-7 absolute right-2 top-2"
              onClick={() => {
                setCatechumeneInfo(null);
                setListeNoteCatechumene([]);
              }}
            >
              <img
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-md md:text-xl text-left text-black">
            Notes du catéchumène{" "}
            <span className="text-primary">
              {catechumeneInfo
                ? `[${catechumeneInfo.nom} ${catechumeneInfo.prenoms}]`
                : null}
            </span>
          </h3>
          <div className="pt-4 pb-2">
            <div className="mt-2 w-full h-full">
              <div className="overflow-x-auto">
                <table className="custom-table table table-zebra w-full border">
                  <thead>
                    <tr className="bg-gray-100 h-12">
                      <th className="text-sm text-gray-600">Trimestre</th>
                      <th className="text-sm text-gray-600">Evaluation</th>
                      <th className="text-sm text-gray-600">Coefficient</th>
                      <th className="text-sm text-gray-600">Note obtenue</th>
                      <th className="text-sm text-gray-600">Appréciation</th>
                      <th className="text-sm text-gray-600">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loadingData && listeNoteCatechumene.length > 0 ? (
                      listeNoteCatechumene.map((item) => (
                        <TableElement key={item._id} element={item} />
                      ))
                    ) : !loadingData && listeNoteCatechumene.length === 0 ? (
                      <tr>
                        <td colSpan="6">
                          <div className="w-full h-32 bg-white flex gap-x-2 text-red-500 items-center justify-center">
                            <p className="font-semibold text-sm text-red-600">
                              Aucun élément trouvé
                            </p>
                          </div>
                        </td>
                      </tr>
                    ) : loadingData && listeNoteCatechumene.length === 0 ? (
                      <tr>
                        <td colSpan="6">
                          <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                            <TailSpin
                              height="40"
                              width="40"
                              color="#000"
                              ariaLabel="tail-spin-loading"
                              radius="1"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={loadingData}
                            />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                            <TailSpin
                              height="40"
                              width="40"
                              color="#000"
                              ariaLabel="tail-spin-loading"
                              radius="1"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={loadingData}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-end mt-2">
                <Pagination
                  count={notePageQuery.nombrePage}
                  // page={notePageQuery.pagePagination}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            </div>
          </div>
        </div>
      </dialog>

      {/* MODAL SUPPRIMER NOTE CATECHUMENE */}
      <dialog id="delete-note-catechumene" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-center text-primary">
            Supprimer cette note
          </h3>
          <div className="pt-4 pb-2">
            <p className="text-center text-base font-medium text-gray-600">
              Attention action est irrversible et entrainera la suppression de
              la note de ce catéchumène.
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? (
                <div
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={supprimerNoteCatechumique}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      {/* MODAL TRANSFERT CATECHUMENE */}
      <dialog id="transferer_catechumene" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-left text-black">
            Transférer dans une autre classe
          </h3>
          <div className="pt-4 pb-2">
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Commission
                  </span>
                </div>
                <select
                  disabled={transfertLoading}
                  value={transfertData.commission}
                  onChange={(e) => {
                    setTransfertData((prev) => ({
                      ...prev,
                      commission: e.target.value,
                    }));
                    getListeDesNiveauxParCommission(e.target.value);
                  }}
                  className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                >
                  <option disabled selected value="">
                    Choisir un élément
                  </option>
                  {listeCommission.map((item, index) => (
                    <option
                      key={index}
                      value={item._id}
                      className="font-medium"
                    >
                      {item.designation}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Niveau
                  </span>
                </div>
                <select
                  disabled={transfertLoading}
                  value={transfertData.niveau}
                  onChange={(e) => {
                    getListeDesClassesParNiveau(
                      transfertData.commission,
                      e.target.value
                    );
                    setTransfertData((prev) => ({
                      ...prev,
                      niveau: e.target.value,
                    }));
                  }}
                  className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                >
                  <option disabled selected value="">
                    Choisir un élément
                  </option>
                  {listeNiveaux.map((item, index) => (
                    <option
                      key={index}
                      value={item._id}
                      className="font-medium"
                    >
                      {item.designation}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Classe
                  </span>
                </div>
                <select
                  disabled={transfertLoading}
                  value={transfertData.classe}
                  onChange={(e) => {
                    setTransfertData((prev) => ({
                      ...prev,
                      classe: e.target.value,
                    }));
                  }}
                  className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                >
                  <option disabled selected value="">
                    Choisir un élément
                  </option>
                  {listeClasses.map((item, index) => (
                    <option
                      key={index}
                      value={item._id}
                      className="font-medium"
                    >
                      {item.designation}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
          <div className="modal-action flex justify-end">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-end gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {transfertLoading ? (
                <div
                  disabled={transfertLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={transfertLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={() => transfertDeCatechumene()}
                disabled={transfertLoading}
                className="mt-2 sm:mt-0 w-full sm:w-32 px-2 h-10 bg-emerald-600 text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!transfertLoading ? (
                  "Valider"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={transfertLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      <SnackbarComponent
        vertical={vertical}
        horizontal={horizontal}
        snackbarSuccessOpen={snackbarSuccessOpen}
        handleCloseSnackbarSuccess={handleCloseSnackbarSuccess}
        successMessage={successMessage}
        snackbarErrorOpen={snackbarErrorOpen}
        handleCloseSnackbarError={handleCloseSnackbarError}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default ClasseCatechumene;
