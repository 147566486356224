import React, { useEffect, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import PRINT_FILE from "./../../../assets/IconeSvg/print-file.svg";
import LOGO from "./../../../assets/images/pssm_logo.jpg";
import SEIGNEUR from "./../../../assets/images/seigneur.jpg";
import { listeCompleteDesCatechumenesParClasse } from "../../../services/historique-catechumene/HistoriqueCatechumeneRequest";
import html2canvas from "html2canvas-pro";
import { Grid, Oval } from "react-loader-spinner";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const PrintBadge = () => {
  const { classeId } = useParams();
  const [loadingData, setLoadingData] = useState(true);
  const [listeCatechumene, setListeCatechumene] = useState([]);

  const componentRef = useRef(null);

  const printFn = useReactToPrint({
    contentRef: componentRef,
  });

  const handleOnClick = () => {
    window.print();
  };

  // === CARTE À IMPRIMER ===
  const PrintedCard = ({ content }) => {
    return (
      <div className="relative card-box w-full h-full border bg-white p-1">
        <div className="backgroung-cover absolute inset-0 w-full h-full"></div>
        <div className="z-50">
          {/* INFORMATION DE LA PAROISSE */}
          <div className="flex items-center justify-between gap-x-2">
            <div className="w-12 h-12 print:w-9 print:h-9 rounded-full bg-gray-100 border-2 border-black">
              <img
                src={LOGO}
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <div>
              <p className="text-center text-xs print:text-[10px] text-black uppercase font-bold">
                Diocèse de Yopougon
              </p>
              <p className="text-center text-xs print:text-[8px]">
                Paroisse Saint Sauveur Miséricordieux
              </p>
              <p className="text-center text-xs print:text-[10px] text-black uppercase font-bold mt-px">
                Commission catéchèse
              </p>
            </div>
            <div className="w-12 h-16 print:w-9 print:h-12 rounded-full bg-gray-100">
              <img src={SEIGNEUR} className="w-full h-full object-cover" />
            </div>
          </div>
          <div className="-mt-1">
            <p className="text-center text-[10px] print:text-[8px] text-black">
              « Servire dans l'humilité »
            </p>
            <p className="text-center text-[10px] print:text-[8px]">
              23 BP 3330 Abidjan 23 - Tél: 07 07 66 23 05
            </p>
            <p className="text-center text-[10px] print:text-[8px] text-black mt-px">
              Email: catepssm@gmail.com - Tél: 0748070023/ 0505830856
            </p>
          </div>
          {/* INFORMATION DU CATÉCHUMENE */}
          <div className="mt-3 print:mt-1 divider divider-error">
            <p className="text-primary text-lg print:text-xs font-extrabold uppercase text-center">
              Catéchumène
            </p>
          </div>
          <p className="-mt-2 print:-mt-4 text-black text-sm print:text-[9px] font-extrabold text-center">
            Année paroissiale: {currentAnnee ? `${currentAnnee.anneeDebut}-${currentAnnee.anneeFin}` : null}
          </p>
          <div className="mt-3 print:mt-1 pb-3 relative px-4 print:px-1">
            <div className="w-32 mx-auto h-28 print:w-16 print:h-16">
              <img
                src={content.catechumene.photo}
                className="w-full h-full object-contain"
              />
            </div>
            <div className="pt-1 flex items-start gap-x-1 mt-1">
              <p className="text-xs font-medium underline">
                Niveau catéchétique:
              </p>
              <p className="text-sm print:text-xs text-black font-bold">
                {content.niveau.designation}
              </p>
            </div>
            <div className="flex items-start gap-x-1 mt-1">
              <p className="text-xs font-medium underline">Sous Commission:</p>
              <p className="text-sm print:text-xs text-black font-bold">
                {content.commission.designation}
              </p>
            </div>
            <div className="flex items-start gap-x-1 mt-1">
              <p className="text-xs font-medium underline">Nom:</p>
              <p className="text-sm print:text-xs text-black font-bold uppercase">
                {content.catechumene.nom}
              </p>
            </div>
            <div className="flex items-start gap-x-1 mt-1">
              <p className="text-xs font-medium underline">Prénoms:</p>
              <p className="text-sm print:text-xs text-black font-bold uppercase truncate">
                {content.catechumene.prenoms}
              </p>
            </div>
            <div className="flex items-start gap-x-1 mt-1">
              <p className="text-xs font-medium underline">Contacts:</p>
              <p className="text-sm print:text-xs text-black font-bold">
                {content.catechumene.contact} /{" "}
                {content.catechumene.secondContact}
              </p>
            </div>
            <div className="flex items-start gap-x-1 mt-1">
              <p className="text-xs font-medium underline">Quartier:</p>
              <p className="text-sm print:text-xs text-black font-bold">
                {content.catechumene.quartierResidence}
              </p>
            </div>
            <div className="flex items-start gap-x-1 mt-1">
              <p className="text-xs font-medium underline">Matricule:</p>
              <p className="text-sm text-red-600 font-bold">
                {content.catechumene.matricule}
              </p>
            </div>
            <div className="flex items-start gap-x-1 mt-1">
              <p className="text-xs font-medium underline">CEB:</p>
              <p className="text-sm print:text-xs text-black font-bold">
                {content.catechumene.ceb}
              </p>
            </div>
            <div className="flex items-start gap-x-1 mt-1">
              <p className="text-xs font-medium underline">Contact cas d'urgence:</p>
              <p className="text-sm print:text-xs text-black font-bold">
                {content.catechumene.personneAContacter.contact}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handlePrint = () => {
    window.print();
  };

  const createPDF = async () => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const element = document.querySelector("#pdf");

    const data = await html2canvas(element, {
      scrollX: 0,
      scrollY: 0,
      useCORS: true,
      scale: 2,
      windowWidth: element.scrollWidth,
      windowHeight: element.scrollHeight,
    });

    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Get the width and height of the generated image
    const imgWidth = pdfWidth;
    const imgHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    let position = 0;
    let heightLeft = imgHeight;

    // Add the first page
    pdf.addImage(img, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pdfHeight;

    // Continue to add pages until all content fits
    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();

      // Avoid clipping by recalculating the image part for each new page
      pdf.addImage(img, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
      heightLeft -= pdfHeight;
    }

    pdf.save("badge_catechumene.pdf");
  };

  useEffect(() => {
    listeCompleteDesCatechumenesParClasse(classeId)
      .then((res) => {
        setLoadingData(false);
        setListeCatechumene(res.data);
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  }, []);

  return (
    <div className="w-full min-h-screen bg-zinc-100">
      {/* HEADER */}
      <div className="sticky top-0 print:hidden w-full px-4 h-16 bg-black drop-shadow-lg z-50">
        <div className="w-full h-full flex items-center justify-between">
          <NavLink end to={`/classes/${classeId}`}>
            <button className="w-fit px-4 h-fit py-1 bg-[#343434] rounded-md flex items-center justify-center text-white text-sm font-semibold">
              Retour
            </button>
          </NavLink>
          <button
            className="w-fit px-6 h-9 bg-sky-600 rounded-full flex items-center justify-center gap-x-1 text-white text-xs font-semibold"
            onClick={() => handleOnClick()}
          >
            <img src={PRINT_FILE} className="w-5" />
            Imprimer les badges
          </button>
        </div>
      </div>
      {/* LISTE BADGE */}
      {!loadingData && listeCatechumene.length !== 0 ? (
        <div className="card-container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 print:grid-cols-3 print:gap-3 print:w-[210mm] print:mx-auto [@media_print]:{break-inside-avoid}">
          {listeCatechumene.map((item, index) => (
            <div
              key={index}
              className="print:break-inside-avoid print:w-[65mm] print:h-[108mm] print:transform print:origin-top-left"
            >
              <PrintedCard content={item} />
            </div>
          ))}
        </div>
      ) : null}
      {loadingData && listeCatechumene.length === 0 ? (
        <div className="w-full pt-16">
          <div className="w-fit mx-auto h-40 flex flex-col items-center justify-center">
            <Oval
              visible={true}
              height="30"
              width="30"
              strokeWidth="4"
              color="#000"
              secondaryColor="#9ca3af"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
            <p className="mt-3 text-sm text-black font-medium">
              Chargement des badges...
            </p>
          </div>
        </div>
      ) : null}
      {!loadingData && listeCatechumene.length === 0 ? (
        <div className="pt-16">
          <p className="text-lg text-red-600 text-center font-semibold">
            Aucun catéchumène trouvé pour cette classe.
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default PrintBadge;
