import React, { useEffect, useState } from "react";
import BaseLayout from "../../layout/BaseLayout";
import iconeAdd from "../../assets/IconeSvg/boutonAdd.svg";
import iconethreeDots from "../../assets/IconeSvg/treeDotsGray.svg";
import iconeDelete from "../../assets/IconeSvg/delete.svg";
import iconeDefaultUser from "../../assets/IconeSvg/user-rounded.svg";
import iconePencil from "../../assets/IconeSvg/pencil.svg";
import iconeCloseWindow from "../../assets/IconeSvg/close_window.svg";
import iconeAppareilPhoto from "../../assets/IconeSvg/appareil_photo.svg";
import { Drawer, Pagination, Snackbar } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import {
  listeCompleteDesCommissions,
  listeCompleteDesCommissionsParAnnee
} from "../../services/parametrage/CommissionRequest";
import {
  listeCompleteDesNiveauParCommission,
  listeDesNiveauPaginee
} from "../../services/parametrage/NiveauxRequest";
import {
  ajouterUneAnimateur,
  listeDesAnimateurPaginee,
  modifierUneAnimateur,
  supprimerUneAnimateur
} from "../../services/animateur/AnimateurRequest";
import {
  listeCompleteDesClasses,
  listeDesClassePaginee
} from "../../services/parametrage/ClasseRequest";
import {
  authentificationAPISms,
  authentificationPourApiSms,
  getAPISmsConsommee,
  getAPISmsRestant
} from "../../services/notification-sms/NotificationSmsRequest";
import isSmsTokenValid from "../../utils/SmsTokenValidity";
import { formatDate } from "../../utils/Utils";
import DatePicker from "react-datepicker";
import {
  ajouterSMSPersonnalise,
  listeDesSmsEnvoye,
  supprimerUnSMS
} from "../../services/envoi-sms/EnvoiSmsRequest";
import { returnPermissionsParMenu, verificationPermissionsExistantes } from "../../utils/ManageUserProfil";
const listePermissions = returnPermissionsParMenu("/notification-sms")

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const SMS_API_TOKEN = localStorage.getItem("notificationID")
  ? localStorage.getItem("notificationID")
  : null;

const NotificationSms = () => {
  const navigate = useNavigate();
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 10,
    nombrePage: 1,
    pagePagination: 1
  });

  const [dateDebut, setDateDebut] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [listeSMS, setListeSMS] = useState([]);
  const [listeCommission, setListeCommission] = useState([]);
  const [listeNiveaux, setListeNiveaux] = useState([]);
  const [listeClasses, setListeClasses] = useState([]);
  const [ListeCommissionAll, setListeCommissionAll] = useState([]);
  const [ListeNiveauAll, setListeNiveauAll] = useState([]);
  const [ListeClasseAll, setListeClasseAll] = useState([]);
  const [commissionSelected, setCommissionSelected] = useState("");
  const [niveauSelected, setNiveauSelected] = useState("");
  const [classeSelected, setClasseSelected] = useState("");
  const [commissionSelectedSMS, setCommissionSelectedSMS] = useState("");
  const [niveauSelectedSMS, setNiveauSelectedSMS] = useState("");
  const [classeSelectedSMS, setClasseSelectedSMS] = useState("");
  const [smsInfo, setSmsInfo] = useState(null);
  const [searchParam, setSearchParam] = useState("");
  const [messageSMS, setMessageSMS] = useState("");

  const [soldeRestant, setSoldeRestant] = useState(null);
  const [soldeConsomme, setSoldeConsomme] = useState(null);

  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;

  const TableElement = ({ smsItem }) => {
    return (
      <tr className="">
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {smsItem && smsItem.niveau
            ? ListeNiveauAll.find(
                (niveauItem) => niveauItem._id === smsItem.niveau._id
              )?.designation || "--"
            : "--"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {smsItem && smsItem.commission
            ? ListeCommissionAll.find(
                (commissionItem) =>
                  commissionItem._id === smsItem.commission._id
              )?.designation || "--"
            : "--"}
        </td>

        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {smsItem && smsItem.classe
            ? ListeClasseAll.find(
                (classeItem) => classeItem._id === smsItem.classe._id
              )?.designation || "--"
            : "--"}
        </td>
        <td className="px-4  text-sm text-stone-500 font-medium w-[10px] truncate">
          <div className="w-64">
            <p className="truncate">{smsItem ? smsItem.content : "--"}</p>
          </div>
        </td>

        <td className="px-4">
          <div className="dropdown dropdown-left dropdown-end">
            <div
              tabIndex={0}
              className="w-7 h-7 rounded-lg bg-gray-200 flex items-center justify-center cursor-pointer"
            >
              <img src={iconethreeDots} alt="" />
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-50 menu p-1 shadow border bg-white rounded-lg w-32 mr-2"
            >
              {
                verificationPermissionsExistantes(listePermissions, "VOIR_DETAIL") && <button
                onClick={() => {
                  openModalDetails(smsItem);
                  setSmsInfo(smsItem);
                }}
                className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
              >
                <img src={iconePencil} alt="" className="w-5" />
                <span className="font-semibold text-sm">Détails</span>
              </button>
              }
              
              {
                verificationPermissionsExistantes(listePermissions, "SUPPRIMER") &&  <button
                onClick={() => {
                  setSmsInfo(smsItem);
                  document.getElementById("delete_sms").showModal();
                }}
                className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
              >
                <img src={iconeDelete} alt="" className="w-5" />
                <span className="font-semibold text-sm">Supprimer</span>
              </button>
              }
              

             
            </ul>
          </div>
        </td>
      </tr>
    );
  };

  const openModalDetails = (smsItem) => {
    setOpenModal(true);
    setSmsInfo(smsItem);
    setCommissionSelectedSMS(smsItem.commission._id);
    setNiveauSelectedSMS(smsItem.niveau._id);
    setClasseSelectedSMS(smsItem.classe._id);
    setMessageSMS(smsItem.content);
  };

  const getListeDesSms = () => {
    setLoadingData(true);
    listeDesSmsEnvoye("", "", "", pageQuery.page, pageQuery.size, searchParam)
      .then((res) => {
        setLoadingData(false);
        setListeSMS(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesCommission = () => {
    listeCompleteDesCommissions()
      .then((res) => {
        setListeCommission(res.data);
        setListeNiveaux([]);
        setListeClasses([]);
        setNiveauSelected("");
        setClasseSelected("");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES NIVEAUX PAR COMISSION ===
  const getListeDesNiveauxParCommission = (
    commission = "",
    update = false,
    niveau = ""
  ) => {
    setListeNiveaux([]);

    listeCompleteDesNiveauParCommission(commission)
      .then((res) => {
        getListeDesClassesParNiveau(commission, niveau, true);
        setListeNiveaux(res.data);
        setListeClasses([]);
        setNiveauSelected("");
        setClasseSelected("");
      })
      .catch((error) => {
        console.log("error", error);
        setListeNiveaux([]);
      });
  };

  // === RECUPERE LISTE DES CLASSES PAR NIVEAU ===
  const getListeDesClassesParNiveau = (
    commission = "",
    niveau = "",
    update = false
  ) => {
    setListeClasses([]);

    listeCompleteDesClasses(commission, niveau)
      .then((res) => {
        setListeClasses(res.data);
        setClasseSelected("");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES NIVEAUX ===
  const getListeDesNiveauALL = () => {
    setLoadingData(true);

    listeDesNiveauPaginee(pageQuery.page, 50, "", "")
      .then((res) => {
        setLoadingData(false);
        setListeNiveauAll(res.data.results);
        // setPageQuery((prev) => ({
        //   ...prev,
        //   nombrePage: res.data.totalPages,
        //   totalElements: res.data.totalElements
        // }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES CLASSES ===
  const getListeDesClasseAll = () => {
    setLoadingData(true);
    listeDesClassePaginee(pageQuery.page, 50, "", "", "")
      .then((res) => {
        setLoadingData(false);
        setListeClasseAll(res.data.results);
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesCommissionAll = () => {
    listeCompleteDesCommissionsParAnnee(currentAnnee._id)
      .then((res) => {
        setListeCommissionAll(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const currentAnnee = localStorage.getItem("currentAnnee")
    ? JSON.parse(localStorage.getItem("currentAnnee"))
    : null;

  const clearFormEnregistrement = () => {
    setCommissionSelected("");
    setNiveauSelected("");
    setClasseSelected("");
    setMessageSMS("");
  };

  const enregistrementSMS = () => {
    setAddLoading(true);
    const data = {
      commission: commissionSelectedSMS,
      niveau: niveauSelectedSMS,
      classe: classeSelectedSMS,
      content: messageSMS,
      anneeScolaire: currentAnnee ? currentAnnee._id : ""
    };
    ajouterSMSPersonnalise(data)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Messages envoyés avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesSms();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur lors l'envoi, rééssayer!");
          setSnackbarOpenError(true);
        }
      });
  };

  const deleteSMS = () => {
    setDeleteLoading(true);
    supprimerUnSMS(smsInfo._id)
      .then((res) => {
        setSuccessMessage("SMS supprimé avec succès");
        getListeDesSms();
        setSnackbarOpenSuccess(true);
        setDeleteLoading(false);
        document.getElementById("delete_sms").close();
      })
      .catch((err) => {
        setDeleteLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
      });
  };

  // === FILTRER LES ELEMENTS ===
  const filterElement = () => {
    setSearchLoading(true);
    listeDesSmsEnvoye(
      commissionSelected,
      niveauSelected,
      classeSelected,
      1,
      pageQuery.size,
      searchParam,
    )
      .then((res) => {
        setSearchLoading(false);
        setListeSMS(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setSearchLoading(false);
        console.log("error", error);
      });
  };

  // === PAGINATION DES ELEMENTS ===
  const handlePaginationChange = (event, value) => {
    setLoadingData(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value
    }));
    listeDesSmsEnvoye(
      commissionSelected,
      niveauSelected,
      classeSelected,
      value,
      pageQuery.size,
      searchParam,
    )
      .then((res) => {
        setLoadingData(false);
        setListeSMS(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const recupereSoldeConsomme = (token) => {
    getAPISmsConsommee(token)
      .then((res) => {
        setSoldeConsomme(
          res.data.partnerStatistics
            ? res.data.partnerStatistics.statistics[0]
            : null
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const recupereSoldeRestant = (token) => {
    getAPISmsRestant(token)
      .then((res) => {
        setSoldeRestant(res.data.length > 0 ? res.data[0] : null);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    setLoadingData(true);
    getListeDesCommission();

    // VERIFY TOKEN SMS BUNDLE ORANGE
    recupereSoldeConsomme();
    recupereSoldeRestant();
    // GET ALL LISTE DES COMMISSIONS, NIVEAUX, CLASSES, ET SMS
    getListeDesCommissionAll();
    getListeDesNiveauALL();
    getListeDesClasseAll();
    getListeDesSms();
  }, []);

  return (
    <BaseLayout>

{verificationPermissionsExistantes(listePermissions, "VOIR_LISTE") ? (
        <div className="w-full h-full pb-16">
        <div className="w-full">
          <h1 className="text-2xl md:text-3xl font-extrabold">
            SMS & Notifications
          </h1>
          <p className="mt-2 text-gray-500 font-medium text-sm sm:text-base md:text-lg">
            Envoyez des SMS personnalisés aux parents et aux catéchumènes
          </p>
        </div>

        <div className="mt-8 flex flex-wrap items-center gap-3">
          <div className="w-56 h-full rounded-lg bg-white border shadow-sm p-3">
            <p className="text-sm text-gray-500 font-medium uppercase">
              Total SMS disponible
            </p>
            <p className="mt-2 text-xl text-black font-extrabold">
              {soldeRestant ? soldeRestant.availableUnits : "0"}
            </p>
          </div>
          <div className="w-56 h-full rounded-lg bg-white border shadow-sm p-3">
            <p className="text-sm text-gray-500 font-medium uppercase">
              Total SMS envoyés
            </p>
            <p className="mt-2 text-xl text-black font-extrabold">
              {soldeConsomme
                ? soldeConsomme.serviceStatistics[0].countryStatistics[0].usage
                : "0"}
            </p>
          </div>
          <div className="w-fit h-full rounded-lg bg-white border shadow-sm py-3 px-5">
            <p className="text-sm text-gray-500 font-medium uppercase">
              Date expiration SMS
            </p>
            <p className="mt-2 text-xl text-black font-extrabold">
              {soldeRestant
                ? formatDate(new Date(soldeRestant.expirationDate))
                : "0"}
            </p>
          </div>
        </div>

        <div>
          <div className="mt-8 flex flex-col lg:flex-row justify-end items-end lg:items-start gap-2">
            <div className="w-full md:w-fit flex items-center gap-x-2 overflow-x-scroll sm:overflow-auto">
              <input
                type="text"
                placeholder="Rechercher un élement..."
                value={searchParam}
                onChange={(e) => setSearchParam(e.target.value)}
                className="input input-bordered w-64 sm:w-56 h-10 text-sm font-medium"
              />
              <select
                value={commissionSelected}
                onChange={(e) => {
                  setCommissionSelected(e.target.value);
                  getListeDesNiveauxParCommission(e.target.value);
                }}
                className="select select-bordered custom-select w-32 h-10 font-medium"
              >
                <option disabled selected value="">
                  Commission
                </option>
                <option value="" className="font-medium">
                  Tout
                </option>
                {listeCommission.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
              <select
                value={niveauSelected}
                onChange={(e) => {
                  setNiveauSelected(e.target.value);
                  getListeDesClassesParNiveau(
                    commissionSelected,
                    e.target.value
                  );
                }}
                className="select select-bordered custom-select w-36 h-10 font-medium"
              >
                <option disabled selected value="">
                  Niveau
                </option>
                <option value="" className="font-medium">
                  Tout
                </option>
                {listeNiveaux.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
              <select
                value={classeSelected}
                onChange={(e) => {
                  setClasseSelected(e.target.value);
                }}
                className="select select-bordered custom-select w-40 h-10 font-medium"
              >
                <option disabled selected value="">
                  Classe
                </option>
                <option value="" className="font-medium">
                  Tout
                </option>
                {listeClasses.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
              <label className="form-control  w-fit">
                <DatePicker
                  locale="fr"
                  dateFormat="dd/MM/yyyy"
                  disabled={searchLoading}
                  selected={dateDebut}
                  placeholderText="date"
                  onChange={(date) => setDateDebut(date)}
                  className="font-medium w-32 h-9 text-sm rounded-lg px-3"
                />
              </label>

              <button
                disabled={searchLoading}
                className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
                onClick={filterElement}
              >
                {!searchLoading ? (
                  "Rechercher"
                ) : (
                  <ThreeDots
                    height="35"
                    width="35"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={searchLoading}
                  />
                )}
              </button>
            </div>
            {
              verificationPermissionsExistantes(listePermissions, "AJOUTER") && (<button
                onClick={() => {
                  clearFormEnregistrement();
                  setOpenModal(true);
                }}
                className="bg-black w-full sm:w-fit h-10 px-4 flex items-center justify-center gap-x-2 rounded-xl"
              >
                <img src={iconeAdd} alt="" className="w-5 sm:w-auto" />
                <span className="text-white text-sm font-semibold truncate">
                  Envoyer des SMS
                </span>
              </button>)
            }
            
          </div>
          <div className="flex w-full mt-2 h-full">
            <div className="overflow-x-auto bg-white w-full mt-2 ">
              <table className="custom-table table table-zebra w-full">
                <thead>
                  <tr className="bg-black h-12">
                    <th className="text-sm text-white">Niveau</th>
                    <th className="text-sm text-white">Commission</th>
                    <th className="text-sm text-white">Classe</th>
                    <th className="text-sm text-white">Message</th>
                    <th className="text-sm text-white">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingData && listeSMS.length !== 0 ? (
                    listeSMS.map((item) => (
                      <TableElement key={item._id} smsItem={item} />
                    ))
                  ) : !loadingData && listeSMS.length === 0 ? (
                    <tr>
                      <td colSpan="5">
                        <div className="w-full h-32 bg-white flex gap-x-2 text-red-500 items-center justify-center">
                          <p className="font-semibold text-lg text-red-600">
                            Aucun sms trouvé
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : loadingData && listeSMS.length === 0 ? (
                    <tr>
                      <td colSpan="5">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="5">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-end mt-5">
            <Pagination
              count={pageQuery.nombrePage}
              page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>
      ) : (
        <div className="h-56 flex items-center justify-center">
          <p className="text-base text-primary font-extrabold">
            Vous n'avez pas accès à ce menu.
          </p>
        </div>
      )}
     

      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setSmsInfo(null);
        }}
      >
        <div className="w-screen sm:w-[430px] pt-2 px-4 flex flex-col bg-white ">
          <button
            onClick={() => {
              setOpenModal(false);
              setSmsInfo(null);
            }}
          >
            <img src={iconeCloseWindow} alt="" />
          </button>

          <h2 className="mt-3 text-xl text-black font-extrabold">
            {smsInfo === null
              ? "Envoyer des SMS personnalisés"
              : "Details SMS envoyé"}
          </h2>

          <div className="mt-6">
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Commission
                  </span>
                </div>
                <select
                  disabled={addLoading}
                  value={commissionSelectedSMS}
                  onChange={(e) => {
                    setCommissionSelectedSMS(e.target.value);
                    getListeDesNiveauxParCommission(e.target.value);
                  }}
                  className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                >
                  <option disabled selected value="">
                    Choisir un élément
                  </option>
                  {listeCommission.map((item, index) => (
                    <option
                      key={index}
                      value={item._id}
                      className="font-medium"
                    >
                      {item.designation}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Niveau
                  </span>
                </div>
                <select
                  disabled={addLoading}
                  value={niveauSelectedSMS}
                  onChange={(e) => {
                    setNiveauSelectedSMS(e.target.value);
                    getListeDesClassesParNiveau(
                      commissionSelected,
                      e.target.value
                    );
                  }}
                  className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                >
                  <option disabled selected value="">
                    Choisir un élément
                  </option>
                  {listeNiveaux.map((item, index) => (
                    <option
                      key={index}
                      value={item._id}
                      className="font-medium"
                    >
                      {item.designation}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Classe
                  </span>
                </div>
                <select
                  disabled={addLoading}
                  value={classeSelectedSMS}
                  onChange={(e) => {
                    setClasseSelectedSMS(e.target.value);
                  }}
                  className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                >
                  <option disabled selected value="">
                    Choisir un élément
                  </option>
                  {listeClasses.map((item, index) => (
                    <option
                      key={index}
                      value={item._id}
                      className="font-medium"
                    >
                      {item.designation}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Message à envoyer
                  </span>
                </div>
                <textarea
                  value={messageSMS}
                  onChange={(e) => setMessageSMS(e.target.value)}
                  placeholder="Entrer le message du sms à envoyer"
                  className="input input-bordered w-full h-44 pt-2 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>

            {smsInfo !== null ? null : (
              <button
                disabled={addLoading}
                onClick={() => enregistrementSMS()}
                className="mt-10 bg-primary flex items-center justify-center w-full h-11 font-semibold text-sm text-white rounded-lg hover:drop-shadow-md"
              >
                {!addLoading ? (
                  <span>Envoyer</span>
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={addLoading}
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </Drawer>

      {/* MODAL DELETE SMS */}
      <dialog id="delete_sms" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-center text-primary">
            Supprimer cet SMS
          </h3>
          <div className="py-4">
            <p className="text-center text-base font-medium text-gray-600">
              Attention action est irrversible et entrainera la suppression de
              cet sms de la liste des sms envoyés
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? (
                <div
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={deleteSMS}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="bg-green-500 text-white px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="bg-red-500 text-white px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </BaseLayout>
  );
};

export default NotificationSms;
