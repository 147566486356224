import { jwtDecode } from "jwt-decode";
import { BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("cc_pssm_api");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers.Authorization = null;
    }

    return config;
  },
  function (error) {
    console.log("AN ERROR REQUEST", error);
  }
);

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const userToken = localStorage.getItem("cc_pssm_api");
const decoded = userToken
  ? jwtDecode(localStorage.getItem("cc_pssm_api"))
  : null;

export const listeDesClassePaginee = async (
  page = 1,
  size = 10,
  param = "",
  commission = "",
  niveau = "",
  classe = ""
) => {
  return await axios.get(
    BASE_URL(
      `classe/query?page=${page}&size=${size}&param=${param}&commission=${commission}&niveau=${niveau}&classe=${classe}`
    ),
    {}
  );
};

export const listeCompleteDesClasses = async (commission = "", niveau = "") => {
  return await axios.get(
    BASE_URL(
      `classe/complete?commission=${commission}&niveau=${niveau}&annee=${
        currentAnnee ? currentAnnee._id : ""
      }`
    ),
    {}
  );
};

export const recupereClasseParSonId = async (classeId) => {
  return await axios.get(BASE_URL(`classe/${classeId}`), {});
};

export const ajouterUneClasse = async (values) => {
  return await axios.post(BASE_URL(`classe`), values);
};

export const modifierUneClasse = async (values, niveauId) => {
  return await axios.put(BASE_URL(`classe/${niveauId}`), values);
};

export const supprimerUneClasse = async (niveauId) => {
  return await axios.delete(BASE_URL(`classe/${niveauId}`), {});
};
