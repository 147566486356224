import React, { useEffect, useState } from "react";
import LOGO from "./../assets/images/logo-preview.png";
import iconeDashboard from "./../assets/IconeSvg/dashboard.svg";
import iconeClasse from "./../assets/IconeSvg/Classe.svg";
import iconeCatechumene from "./../assets/IconeSvg/catechumene.svg";
import iconeAnimateur from "./../assets/IconeSvg/animateur.svg";
import iconeAdmission from "./../assets/IconeSvg/admission.svg";
import iconeEvenement from "./../assets/IconeSvg/calendrierRed.svg";
import iconeFinance from "./../assets/IconeSvg/finances.svg";
import iconeParametrage from "./../assets/IconeSvg/parametrage.svg";
import iconeAdministrateur from "./../assets/IconeSvg/administrateur.svg";
import iconeDeconnexion from "./../assets/IconeSvg/logout.svg";
import messageIcon from "./../assets/IconeSvg/message-icon.svg";
import roleIcon from "./../assets/IconeSvg/shield-keyhole.svg";
import keyIcon from "./../assets/IconeSvg/key-square.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const NewSidebar = ({ listeMenus }) => {
  const navigate = useNavigate();
  const [connectedUser, setConnectedUser] = useState(null);

  const deconnectAdmin = () => {
    localStorage.removeItem("cc_pssm_api");
    navigate("/login");
  };

  const displayMenuBasedOnRole = (role) => {
    let display = false;
    if (role == "ADMIN" || role == "SUPER ADMIN") {
      display = true;
    }

    return display;
  };

  const returnCorrectIconPerMenu = (route) => {
    if (route === "/dashboard") {
      return iconeDashboard;
    }
    if (route === "/classes") {
      return iconeClasse;
    }
    if (route === "/catechumenes") {
      return iconeCatechumene;
    }
    if (route === "/animateurs") {
      return iconeAnimateur;
    }
    if (route === "/admissions") {
      return iconeAdmission;
    }
    if (route === "/evenements") {
      return iconeEvenement;
    }
    if (route === "/finances") {
      return iconeFinance;
    }
    if (route === "/parametrages") {
      return iconeParametrage;
    }
    if (route === "/administrateurs") {
      return iconeAdministrateur;
    }
    if (route === "/notification-sms") {
      return messageIcon;
    }
    if (route === "/config-role") {
      return roleIcon;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("cc_pssm_api")) {
      const token = localStorage.getItem("cc_pssm_api");
      const decoded = jwtDecode(token);
      setConnectedUser(decoded ? decoded.connected : null);
    }
  }, []);

  return (
    <div className="hidden lg:block sticky top-0 left-0 min-w-[290px] max-w-[290px] border-r drop-shadow h-screen min-h-[400px] bg-white p-3 z-50">
      <div className="relative w-full h-full overflow-y-auto">
        <NavLink to="/classes">
          <div className="flex items-center gap-x-2">
            <img src={LOGO} className="w-14 mx-auto" />
            <p className="text-base text-primary font-extrabold leading-snug">
              Eglise Saint Sauveur Misericordieux
            </p>
          </div>
        </NavLink>

        <div className="mt-16 pb-20">
          {listeMenus && listeMenus.length > 0 ? (
            <>
              <NavLink to="/dashboard">
                <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl">
                  <img src={iconeDashboard} className="w-5" />
                  <span>Dashboard</span>
                </button>
              </NavLink>
              {listeMenus.map((item, index) => (
                <NavLink to={item.menu.route} key={index}>
                  <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl">
                    <img
                      src={returnCorrectIconPerMenu(item.menu.route)}
                      className="w-5"
                    />
                    <span>{item.menu.designation}</span>
                  </button>
                </NavLink>
              ))}
            </>
          ) : (
            <div>
              <NavLink to="/dashboard">
                <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl">
                  <img src={iconeDashboard} className="w-5" />
                  <span>Dashboard</span>
                </button>
              </NavLink>
              <NavLink to="/classes">
                <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl">
                  <img src={iconeClasse} className="w-5" />
                  <span>Classes</span>
                </button>
              </NavLink>
              <NavLink to="/catechumenes">
                <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl">
                  <img src={iconeCatechumene} className="w-5" />
                  <span>Catéchumènes</span>
                </button>
              </NavLink>
              <NavLink to="/animateurs">
                <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl">
                  <img src={iconeAnimateur} className="w-5" />
                  <span>Animateurs</span>
                </button>
              </NavLink>
              <NavLink to="/admissions">
                <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl">
                  <img src={iconeAdmission} className="w-5" />
                  <span>Admissions</span>
                </button>
              </NavLink>
              <NavLink to="/evenements">
                <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl truncate">
                  <img src={iconeEvenement} className="w-4" />
                  <span>Cérémonies & Evenements</span>
                </button>
              </NavLink>
              <NavLink to="/finances">
                <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl">
                  <img src={iconeFinance} className="w-5" />
                  <span>Finances</span>
                </button>
              </NavLink>
              <NavLink to="/parametrages">
                <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl">
                  <img src={iconeParametrage} className="w-5" />
                  <span>Paramétrages</span>
                </button>
              </NavLink>
              <NavLink to="/administrateurs">
                <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl">
                  <img src={iconeAdministrateur} className="w-5" />
                  <span>Administrateurs</span>
                </button>
              </NavLink>
              <NavLink to="/notification-sms">
                <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl">
                  <img src={messageIcon} className="w-5" />
                  <span>Envoie SMS</span>
                </button>
              </NavLink>
              <NavLink to="/config-role">
                <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl">
                  <img src={roleIcon} className="w-5" />
                  <span>Rôles</span>
                </button>
              </NavLink>
            </div>
          )}
        </div>
        <div className="absolute w-full h-20 left-0 bottom-0">
          <div className="w-full dropdown dropdown-top dropdown-start">
            <div tabIndex={0} role="button" className="mt-1">
              <div className="border border-gray-100 bg-primary rounded-xl h-fit p-2 cursor-pointer">
                {connectedUser ? (
                  <div className="flex items-center gap-2">
                    <div className="w-12 bg-white rounded-full border-2 border-black">
                      <img
                        src={
                          connectedUser && connectedUser.photo
                            ? connectedUser.photo
                            : LOGO
                        }
                        className="w-full h-full rounded-full border shadow"
                      />
                    </div>
                    <div className="w-9/12">
                      <p className="text-sm text-white font-extrabold truncate">
                        {connectedUser ? connectedUser.nom : "-"}{" "}
                        {connectedUser ? connectedUser.prenoms : "-"}
                      </p>
                      <p className="mt-px text-xs text-gray-200 font-medium">
                        {connectedUser ? connectedUser.contact : "-"}
                      </p>
                    </div>
                  </div>
                ) : null}
                {!connectedUser ? (
                  <div>
                    <div className="skeleton h-12 w-12 mx-auto rounded-full"></div>
                    <div className="skeleton h-4 w-48 mx-auto rounded-md mt-2"></div>
                    <div className="skeleton h-4 w-32 mx-auto rounded-md mt-1"></div>
                  </div>
                ) : null}
              </div>
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content menu bg-base-100 rounded-xl z-[1] w-full mx-auto p-2 shadow border"
            >
              <button
                className="w-full mx-auto h-11 bg-gray-100 text-sm text-black font-semibold flex items-center gap-x-2 px-4 rounded-xl"
                onClick={() => {
                  document.getElementById("update-password").showModal();
                }}
              >
                <img src={keyIcon} className="w-6" />
                <span>Modifier mot de passe</span>
              </button>
              <button
                className="mt-2 w-full mx-auto h-11 bg-black text-sm text-white font-semibold flex items-center gap-x-2 px-4 rounded-xl"
                onClick={() => deconnectAdmin()}
              >
                <img src={iconeDeconnexion} className="w-6" />
                <span>Déconnexion</span>
              </button>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSidebar;
