import { BASE_URL, FREE_BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
}, function (error) {
  console.log('AN ERROR REQUEST', error)
});

export const listeDesAnneesCatechumiquePaginee = async (page = 1, size = 10, param = "") => {
  const token = localStorage.getItem("cc_pssm_api");
  
  return await axios.get(
    BASE_URL(`annee-catechumique/query?page=${page}&size=${size}&param=${param}`),
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      }
    }
  );
};

export const listeCompleteDesAnneesCatechumique = async () => {
  return await axios.get(
    BASE_URL(`annee-catechumique`),
    {}
  );
};

export const recupererationDeAnneeScolaireActive = async () => {
  return await axios.get(
    BASE_URL(`annee-catechumique/active`),
    {}
  );
};

export const recupereAnneeCatechumiqueParSonId = async (anneeId) => {
  return await axios.get(
    BASE_URL(`annee-catechumique/${anneeId}`),
    {}
  );
};

export const recupereAnneeCatechumiqueEnCours = async () => {
  return await axios.get(
    FREE_BASE_URL(`annee-catechumique/en-cours`),
    {}
  );
};

export const ajouterUneAnneeCatechumique = async (values) => {
  return await axios.post(
    BASE_URL(`annee-catechumique`),
    values
  );
};

export const rendreAnneeActive = async (anneeId) => {
  return await axios.post(
    BASE_URL(`annee-catechumique/${anneeId}/active`),
    {}
  );
};

export const modifierUneAnneeCatechumique = async (values, anneeId) => {
  return await axios.put(
    BASE_URL(`annee-catechumique/${anneeId}`),
    values
  );
};

export const supprimerUneAnneeCatechumique = async (anneeId) => {
  return await axios.delete(
    BASE_URL(`annee-catechumique/${anneeId}`),
    {}
  );
};
