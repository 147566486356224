import { BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
}, function (error) {
  console.log('AN ERROR REQUEST', error)
});

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const listeDesNotationPaginee = async (page = 1, size = 10, param = "") => {  
  return await axios.get(
    BASE_URL(`notation/query?page=${page}&size=${size}&param=${param}&annee=${currentAnnee ? currentAnnee._id : ""}`),
    {}
  );
};

export const listeCompleteDesNotations = async () => {
  return await axios.get(
    BASE_URL(`notation`),
    {}
  );
};

export const listeCompleteDesNotationsParAnnee = async (anneeId) => {
  return await axios.get(
    BASE_URL(`notation`),
    {}
  );
};

export const recupereNotationParSonId = async (notationId) => {
  return await axios.get(
    BASE_URL(`notation/${notationId}`),
    {}
  );
};

export const ajouterUneNotation = async (values) => {
  return await axios.post(
    BASE_URL(`notation`),
    values
  );
};

export const modifierUneNotation = async (values, notationId) => {
  return await axios.put(
    BASE_URL(`notation/${notationId}`), 
    values
  );
};

export const supprimerUneNotation = async (notationId) => {
  return await axios.delete(
    BASE_URL(`notation/${notationId}`),
    {}
  );
};
