import React, { useEffect, useState } from "react";
import iconeAdd from "../../../assets/IconeSvg/boutonAdd.svg";
import iconethreeDots from "../../../assets/IconeSvg/treeDotsGray.svg";
import iconeDelete from "../../../assets/IconeSvg/delete.svg";
import iconeDesactiver from "../../../assets/IconeSvg/desactiver.svg";
import iconePencil from "../../../assets/IconeSvg/pencil.svg";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import {
  Autocomplete,
  Chip,
  Drawer,
  Pagination,
  Snackbar,
  Stack,
  TextField
} from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import {
  addParticipantToEvent,
  ListeCompleteParticipantEvent,
  ListParticipantsEvent,
  supprminerParticipantEvent
} from "../../../services/evenements/EvenementRequests";
import { ListeCompleteCatechumene } from "../../../services/catechumene/CatechumeneRequest";
import { listeCompleteDesCommissionsParAnnee } from "../../../services/parametrage/CommissionRequest";
import { listeDesAnimateurPaginee } from "../../../services/animateur/AnimateurRequest";

const EvenementParticipants = (idEvent) => {
  const currentAnnee = localStorage.getItem("currentAnnee")
    ? JSON.parse(localStorage.getItem("currentAnnee"))
    : null;
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 1,
    nombrePage: 1
  });

  const [selectedCatechumens, setSelectedCatechumens] = useState([]);

  const [infoParticipants, setInfoParticipants] = useState(null);
  const [searchParam, setSearchParam] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [listeAnimateur, setListeAnimateur] = useState([]);
  const [listeParticipants, setListeParticipants] = useState([]);
  const [listeCommission, setListeCommission] = useState([]);

  console.log(idEvent);

  console.log(selectedCatechumens);

  const [infosAdministrateur, setInfosAdministrateur] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const openModalModif = (adminItem) => {
    setOpenModal(true);
    setInfosAdministrateur(adminItem);
  };

  const deleteEvenement = () => {
    setDeleteLoading(true);

    setTimeout(() => {
      setDeleteLoading(false);
      setSuccessMessage("Administrateur supprimée ");
      setSnackbarOpenSuccess(true);
    }, 3000);
    document.getElementById("delete_participant").click();
  };

  const handleDelete = (chipToDelete) => () => {
    setSelectedCatechumens((chips) =>
      chips.filter((chip) => chip !== chipToDelete)
    );
  };

  const EnregistrerParticipantEvent = () => {
    setIsLoading(true);
    // Extraction des _id des catéchumènes
    const participantIds = selectedCatechumens.map(
      (catechumene) => catechumene._id
    );
    const dataEvent = {
      eventId: idEvent.idEvent,
      participantIds: participantIds,
      
    };

    addParticipantToEvent(dataEvent)
      .then((res) => {
        setIsLoading(false);
        setSuccessMessage("Participant ajouté avec succès");
        setSnackbarOpenSuccess(true);
        setOpenModal(false);
        // clearInputEnregistrement();
        ListeDesParticipants();
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage("Erreur lors de l'enregistrement du participant");
        setSnackbarOpenError(true);
      });
  };

  const ListeDesAnimateur = () => {
    setIsLoading(true);

    listeDesAnimateurPaginee(
      pageQuery.page,
      100,
      searchParam,
      "",
      "",
      ""
    )
      .then((res) => {
        setIsLoading(false);
        console.log("liste des animateurs : " + res.data);
        setListeAnimateur(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorMessage("Erreur lors de la récupération des administrateurs");
      });
  };

  const deleteParticipant = () => {
    setDeleteLoading(true);
    console.log(infoParticipants);
    const dataEvent = {
      eventId: idEvent.idEvent,
      participantIds: infoParticipants._id,
      administrateurIds: []
    };
    supprminerParticipantEvent(dataEvent)
      .then((res) => {
        setDeleteLoading(false);
        console.log(res);
        setSuccessMessage("Ce participant est retiré avec succès");
        setSnackbarOpenSuccess(true);
        ListeDesParticipants();
        document.getElementById("delete_participant").click();
      })
      .catch((err) => {
        setDeleteLoading(false);
        console.log("api error", err);
        setErrorMessage("Erreur du retrait du participant, réessayez!");
        setSnackbarOpenError(true);
      });
  };

  const ListeDesParticipants = () => {
    setIsLoading(true);
    ListeCompleteParticipantEvent(
      idEvent.idEvent,
      pageQuery.page,
      pageQuery.size
    )
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        setListeParticipants(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // === FILTRER LES ELEMENTS ===
  // const searchParticipant = () => {
  //   setSearchLoading(true);
  //   ListeCompleteParticipantEvent(
  //     idEvent.idEvent,
  //     pageQuery.page,
  //     pageQuery.pageSize
  //   )
  //     .then((res) => {
  //       setSearchLoading(false);
  //       setListeParticipants(res.data.results);
  //       setPageQuery((prev) => ({
  //         ...prev,
  //         nombrePage: res.data.totalPages,
  //         totalElements: res.data.totalElements
  //       }));
  //     })
  //     .catch((error) => {
  //       setSearchLoading(false);
  //       console.log("error", error);
  //     });
  // };

  // === PAGINATION DES ELEMENTS ===
  const handlePaginationChange = (event, value) => {
    setIsLoading(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value
    }));
    ListeCompleteParticipantEvent(idEvent.idEvent, value, pageQuery.size)
      .then((res) => {
        setIsLoading(false);
        setListeParticipants(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesCommission = () => {
    listeCompleteDesCommissionsParAnnee(currentAnnee._id)
      .then((res) => {
        setListeCommission(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    
  }, []);
  useEffect(() => {
    ListeDesParticipants();
    ListeDesAnimateur();
    getListeDesCommission();
  }, []);
  return (
    <>
      <div className="w-full h-full flex flex-col">
        <div className="flex flex-col w-full h-full">
          <div className="flex xl:flex-row  sm:flex-row md:flex-row  lg:flex-row flex-col gap-y-2  justify-end items-center gap-x-4 mt-3">
            {/* <input
              type="text"
              placeholder="Rechercher un élement ..."
              className="input input-bordered xl:w-fit  md:w-fit sm:w-fit  lg:w-fit w-full"
            />
            <button
              disabled={searchLoading}
              className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
              onClick={searchParticipant}
            >
              {!searchLoading ? (
                "Rechercher"
              ) : (
                <ThreeDots
                  height="35"
                  width="35"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={searchLoading}
                />
              )}
            </button> */}

            <button
              onClick={() => setOpenModal(true)}
              className="flex flex-row bg-black py-3 px-2 gap-x-2 rounded-lg justify-center xl:w-fit  md:w-fit sm:w-fit  lg:w-fit w-full items-center"
            >
              <img src={iconeAdd} alt="" />
              <span className="text-white">Ajouter un participant </span>
            </button>
          </div>

          {/* Liste des participants d events */}

          <div className="mt-[3rem] grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5">
            {!isLoading && listeParticipants.length > 0 ? (
              listeParticipants.map((item, index) => {
                const commission = listeCommission.find(
                  (commissionItem) => commissionItem._id === item.commission
                );
                return (
                  <div
                    key={index}
                    className="relative w-full h-fit p-2 flex flex-col items-center bg-[#fdfdfd] hover:shadow-md rounded-lg border-[1px]  "
                  >
                    <div className=" flex items-center justify-end w-full gap-x-2">
                      <div className="dropdown dropdown-bottom dropdown-end">
                        <button className="w-7 h-7 rounded-lg bg-gray-200 flex items-center justify-center">
                          <img src={iconethreeDots} alt="" />
                        </button>
                        <ul
                          tabIndex={0}
                          className="dropdown-content flex flex-col gap-2 z-[1]  menu p-1 px-2 shadow bg-base-100 rounded-box w-32"
                        >
                          {/* <button
                            onClick={() => openModalModif(item)}
                            className="  rounded-lg  flex flex-row gap-x-2 items-center justify-center pr-4"
                          >
                            <img src={iconePencil} alt="" />
                            <span className="font-medium text-[16px]">
                              Modifier
                            </span>
                          </button> */}

                          <button
                            onClick={() => {
                              window.delete_participant.showModal();
                              setInfoParticipants(item);
                            }}
                            className="rounded-lg  flex flex-row gap-x-2 items-center justify-center"
                          >
                            <img src={iconeDelete} alt="" />
                            <span className="font-medium text-[16px]">
                              Retirer
                            </span>
                          </button>
                        </ul>
                      </div>
                    </div>
                    <img
                      src={item.photo}
                      alt=""
                      className="rounded-full w-12 h-12"
                    />

                    <div className="flex flex-col justify-center items-center w-full  gap-2">
                      <h2 className="text-primary text-[20px] font-semibold truncate">
                        {`${item.nom} ${item.prenoms}`}
                      </h2>
                      <span className="xl:text-[15px] md:text-[15px] text-[12px] lg:text-[15px] font-semibold text-[#5F5F5F]">
                        {item.contact ? item.contact : "--"}
                      </span>
                      <span className="xl:text-[15px] md:text-[15px] text-[12px] lg:text-[15px] font-semibold text-[#5F5F5F]">
                        Commission :{" "}
                        {commission ? commission.designation : "N/A"}
                      </span>
                      <span className="xl:text-[15px] md:text-[15px] text-[12px] lg:text-[15px] font-semibold text-[#5F5F5F]">
                        Classe : {item.classe ? item.classe.designation : "--"}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : !isLoading && listeParticipants.length === 0 ? (
              <div className="flex col-span-full w-full items-center justify-center  h-[55vh]">
                <div className="flex items-center justify-center text-red-500">
                  {/* <ErrorOutlineIcon className="w-6 h-6 mr-2" /> */}
                  <p className="font-medium">Aucun participant trouvé</p>
                </div>
              </div>
            ) : (
              <div className="flex col-span-full w-full items-center justify-center  h-[55vh]">
                <div className="w-full  h-32 rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                  <TailSpin
                    height="50"
                    width="50"
                    color="#000"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={isLoading}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-end mt-4">
            <Pagination
              count={pageQuery.nombrePage}
              // page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="w-screen sm:w-[430px] h-full px-4 pt-2  flex flex-col bg-white ">
          <div className="flex justify-start ">
            <img
              onClick={() => setOpenModal(false)}
              src={iconeCloseWindow}
              className="cursor-pointer"
              alt=""
            />
          </div>

          <h2 className="mt-3 ml-2 text-lg font-semibold">
            Ajouter un participant
          </h2>

          <div className="flex flex-col w-full pb-4 pl-2">
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Catéchumènes
                  </span>
                </div>
                {/* <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={top100Films}
                  getOptionLabel={(option) => option.title}
                  defaultValue={[top100Films[13]]}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Catéchumènes selectionnés"
                      placeholder=""
                    />
                  )}
                /> */}
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={listeAnimateur.filter(
                    (catechumene) => !selectedCatechumens.includes(catechumene)
                  )}
                  getOptionLabel={(option) => `${option.nom} ${option.prenoms}`}
                  value={selectedCatechumens}
                  onChange={(event, newValue) => {
                    setSelectedCatechumens(newValue);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Catéchumènes sélectionnés"
                      placeholder="Catéchumènes sélectionnés"
                    />
                  )}
                  renderTags={() => null}
                />
              </label>
            </div>

            <div className="mt-4">
              <span className="label-text font-semibold text-md">
                Éléments sélectionnés :
              </span>
              <div className="grid grid-cols-2 gap-2" spacing={1}>
                {selectedCatechumens.map((catechumen, index) => (
                  <Chip
                    key={index}
                    label={`${catechumen.nom} ${catechumen.prenoms} `}
                    onDelete={handleDelete(catechumen)}
                  />
                ))}
              </div>
            </div>

            <button
              onClick={EnregistrerParticipantEvent}
              className="mt-5 bg-primary flex items-center justify-center w-full h-10 font-medium text-white rounded-lg hover:drop-shadow-md"
            >
              {!isLoading ? (
                <span>
                  {infosAdministrateur === null
                    ? " Enregistrer cet admin"
                    : "Modifier cet  administrateur"}
                </span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={isLoading}
                />
              )}
            </button>
          </div>
        </div>
      </Drawer>

      {/* MODAL DELETE ENTREPRISE */}
      <dialog id="delete_participant" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog" className="flex flex-row justify-end">
            <button className="w-7 h-7 flex mb-[-2rem] items-center  rounded-lg hover:border-red-500 hover:border-2  justify-center text-red-600 font-semibold">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-7 h-7"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-bold text-xl text-center text-red-600">
            Retirer ce participant
          </h3>
          <div className="py-4">
            <p className="text-center text-lg text-black">
              Attention action est irrversible et entrainera le retrait de ce
              participant
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form method="dialog" className="flex gap-x-3">
              {/* if there is a button in form, it will close the modal */}
              <button className="w-32 h-10 bg-gray-300 text-gray-700 rounded-lg">
                Annuler
              </button>
              <button
                onClick={deleteParticipant}
                className="w-32 h-10 bg-red-600 text-white rounded-lg flex items-center justify-center cursor-pointer"
              >
                Retirer
              </button>
            </form>
          </div>
        </div>
      </dialog>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="bg-green-500 text-white px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="bg-red-500 text-white px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </>
  );
};

export default EvenementParticipants;

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980
  },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  {
    title: "The Lord of the Rings: The Two Towers",
    year: 2002
  }
];
