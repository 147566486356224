import { BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("cc_pssm_api");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers.Authorization = null;
    }

    return config;
  },
  function (error) {
    console.log("AN ERROR REQUEST", error);
  }
);

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const recupererLesNotesParCatechumene = async (
  semestre = "PREMIER",
  matiereId = "",
  catechumeneId = "",
  animateurId = "",
  commissionId = "",
  niveauId = "",
  classeId = ""
) => {
  return await axios.get(
    BASE_URL(
      `notation-semestrielle/query?semestre=${semestre}&matiere=${matiereId}&catechumene=${catechumeneId}&animateur=${animateurId}&commission=${commissionId}&niveau=${niveauId}&classe=${classeId}&anneeScolaire=${
        currentAnnee ? currentAnnee._id : ""
      }`
    ),
    {}
  );
};

export const recupererLesNotesParTrimestre = async (
  page = 1,
  size = 20,
  trimestre = "PREMIER",
  matiereId = "",
  catechumeneId = "",
  commissionId = "",
  niveauId = "",
  classeId = ""
) => {
  return await axios.get(
    BASE_URL(
      `notation-semestrielle/query?page=${page}&size=${size}&trimestre=${trimestre}&catechumene=${catechumeneId}&matiere=${matiereId}&commission=${commissionId}&niveau=${niveauId}&classe=${classeId}&anneeScolaire=${
        currentAnnee ? currentAnnee._id : ""
      }`
    ),
    {}
  );
};

export const recupererLesResultatsAnnuels = async (
  page = 1,
  size = 20,
  catechumeneId = "",
  commissionId = "",
  niveauId = "",
  classeId = ""
) => {
  return await axios.get(
    BASE_URL(
      `notation-annuelle/query?page=${page}&size=${size}&catechumene=${catechumeneId}&commission=${commissionId}&niveau=${niveauId}&classe=${classeId}&anneeScolaire=${
        currentAnnee ? currentAnnee._id : ""
      }`
    ),
    {}
  );
};

export const enregistrerDecisionFinal = async (values) => {
  return await axios.post(
    BASE_URL(`notation-annuelle/decision-final`),
    values
  );
};

export const enregistrerUneNoteCatechumene = async (values) => {
  return await axios.post(
    BASE_URL(`notation-semestrielle/ajouter-note`),
    values
  );
};

export const supprimerUneNoteCatechumene = async (noteId) => {
  return await axios.delete(
    BASE_URL(`notation-semestrielle/delete-note/${noteId}`),
    {}
  );
};
