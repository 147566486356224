import QueryString from "qs";
import { BASE_URL } from "../BaseUrl.js";
import axios from "axios";

// axios.interceptors.request.use(
//   function (config) {
//     const token = localStorage.getItem("cc_pssm_api");
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     } else {
//       config.headers.Authorization = null;
//     }

//     return config;
//   },
//   function (error) {
//     console.log("AN ERROR REQUEST", error);
//   }
// );

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const listeDesNotificationSmsPaginee = async (
  page = 1,
  size = 10,
  param = " ",
  commission = " ",
  niveau = " ",
  classe = " "
) => {
  return await axios.get(
    BASE_URL(
      `animateur/query?page=${page}&size=${size}&param=${param}&commission=${commission}&niveau=${niveau}&classe=${classe}&annee=${
        currentAnnee ? currentAnnee._id : ""
      }`
    ),
    {}
  );
};

export const listeCompleteDesNotificationSmss = async (
  commission = "",
  niveau = "",
  classe = ""
) => {
  return await axios.get(
    BASE_URL(
      `animateur/complete?commission=${commission}&niveau=${niveau}&classe=${classe}`
    ),
    {}
  );
};

export const recupereNotificationSmsParSonId = async (animateurId) => {
  return await axios.get(BASE_URL(`animateur/${animateurId}`), {});
};

export const ajouterUneNotificationSms = async (values) => {
  return await axios.post(BASE_URL(`animateur`), values);
};

export const getAPISmsRestant = async () => {
  return await axios.get(BASE_URL(`sms-api/balance/restant`), {});
};

export const getAPISmsConsommee = async () => {
  return await axios.get(BASE_URL(`sms-api/balance/consomme`), {});
};
