import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Drawer,
  Pagination,
  Snackbar,
  TextField,
} from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import iconeEye from "../../../assets/IconeSvg/eye.svg";
import { listeCompleteDesNiveauParCommission } from "../../../services/parametrage/NiveauxRequest";
import { listeCompleteDesCommissions } from "../../../services/parametrage/CommissionRequest";
import { listeCompleteDesCatechumenesEnregistre } from "../../../services/catechumene/CatechumeneRequest";
import { listeCompleteDesClasses } from "../../../services/parametrage/ClasseRequest";
import { formatDate } from "../../../utils/Utils";
import { recupererLesNotesParTrimestre } from "../../../services/notation/NotationRequest";
import { listeCompleteDesNotations } from "../../../services/parametrage/NotationRequest";

const PremierTrismestre = () => {
  const bulletinRef = useRef();
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 20,
    nombrePage: 1,
    pagePagination: 1,
  });
  const [notePageQuery, setNotePageQuery] = useState({
    page: 0,
    size: 20,
    nombrePage: 1,
    pagePagination: 1,
  });
  const [loadingData, setLoadingData] = useState(true);
  const [loadingDataCatechumene, setLoadingDataCatechumene] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [listeNiveaux, setListeNiveaux] = useState([]);
  const [listeCommission, setListeCommission] = useState([]);
  const [listeMatieres, setListeMatieres] = useState([]);
  const [listeCatechumene, setListeCatechumene] = useState([]);
  const [listeClasses, setListeClasses] = useState([]);
  const [classeSelected, setClasseSelected] = useState("");
  const [niveauSelected, setNiveauSelected] = useState("");
  const [commissionSelected, setCommissionSelected] = useState("");
  const [matiereSelected, setMatiereSelected] = useState("");
  const [catechumeneSelectedEl, setCatechumeneSelectedEl] = useState(null);
  const [catechumeneSelected, setCatechumeneSelected] = useState("");
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const [catechumeneInfo, setCatechumeneInfo] = useState("");
  const [listeNote, setlisteNote] = useState([]);
  const [listeNoteCatechumene, setListeNoteCatechumene] = useState([]);

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = snackbarPosition;

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const TableElement = ({ notation }) => {
    return (
      <tr className="">
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.catechumene
            ? `${notation.catechumene.nom} ${notation.catechumene.prenoms}`
            : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.matiere ? notation.matiere.designation : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.matiere ? notation.matiere.coefficient : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.note
            ? `${notation.note}/${notation.matiere.pointMax}`
            : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.note
            ? `${notation.total}/${notation.matiere.pointMax * notation.matiere.coefficient
            }`
            : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.appreciation ? notation.appreciation : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.commission ? notation.commission.designation : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.niveau ? notation.niveau.designation : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.classe ? notation.classe.designation : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.updatedAt ? formatDate(new Date(notation.updatedAt)) : "-"}
        </td>
        {!catechumeneInfo && (
          <td>
            <div>
              <button
                className="w-8 h-8 rounded-md bg-gray-200 flex items-center justify-center"
                onClick={() => {
                  document.getElementById("liste-note-catechumene").showModal();
                  setCatechumeneInfo(notation.catechumene);
                  getListeDesNotesParCatechumene(notation.catechumene._id);
                }}
              >
                <img src={iconeEye} alt="" className="w-5" />
              </button>
            </div>
          </td>
        )}
      </tr>
    );
  };

  const calculTotalNoteParCoefficient = (listeNote) => {
    let total = 0;
    listeNote.forEach(element => {
      total += Number(element.total)
    });
    return total
  }

  const handlePaginationChange = (event, value) => {
    setSearchLoading(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value,
    }));
    recupererLesNotesParTrimestre(
      value,
      pageQuery.size,
      "PREMIER",
      matiereSelected,
      catechumeneSelected,
      commissionSelected,
      niveauSelected,
      classeSelected
    )
      .then((res) => {
        setlisteNote(res.data.results);
        setSearchLoading(false);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        console.log("UNE ERREUR S'EST PRODUITE", error);
        setSearchLoading(false);
      });
  };

  const handlePaginationChangeNoteCatechumene = (event, value) => {
    setLoadingDataCatechumene(true);
    setNotePageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value,
    }));
    recupererLesNotesParTrimestre(
      value,
      notePageQuery.size,
      "PREMIER",
      "",
      catechumeneInfo._id,
      "",
      "",
      ""
    )
      .then((res) => {
        setListeNoteCatechumene(res.data.results);
        setLoadingDataCatechumene(false);
        setNotePageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        console.log("UNE ERREUR S'EST PRODUITE", error);
        setLoadingDataCatechumene(false);
      });
  };

  const filtrerLesNotesParParametres = () => {
    setSearchLoading(true);
    recupererLesNotesParTrimestre(
      pageQuery.page,
      pageQuery.size,
      "PREMIER",
      matiereSelected,
      catechumeneSelected,
      commissionSelected,
      niveauSelected,
      classeSelected
    )
      .then((res) => {
        setlisteNote(res.data.results);
        setSearchLoading(false);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        console.log("UNE ERREUR S'EST PRODUITE", error);
        setSearchLoading(false);
      });
  };

  // === RECUPERER LISTE NOTE PAR CATECHUMENE ===
  const getListeDesNotesParCatechumene = (catechumeneID) => {
    setLoadingDataCatechumene(true);
    recupererLesNotesParTrimestre(
      1,
      notePageQuery.size,
      "PREMIER",
      "",
      catechumeneID,
      "",
      "",
      ""
    )
      .then((res) => {
        setListeNoteCatechumene(res.data.results);
        setLoadingDataCatechumene(false);
        setNotePageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        console.log("UNE ERREUR S'EST PRODUITE", error);
        setLoadingDataCatechumene(false);
      });
  };

  // === RECUPERE LISTE DES MATIERES ===
  const getListeDesMatieres = () => {
    listeCompleteDesNotations()
      .then((res) => {
        setListeMatieres(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesCommission = () => {
    listeCompleteDesCommissions()
      .then((res) => {
        setListeCommission(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES NIVEAUX PAR COMMISSION ===
  const getListeDesNiveauxParCommission = (commission = "") => {
    setListeNiveaux([]);
    setNiveauSelected("");
    setListeClasses([]);
    setClasseSelected("");
    listeCompleteDesNiveauParCommission(commission)
      .then((res) => {
        setListeNiveaux(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // LISTE CATECHUMENE PAR COMMISSION NIVEAU CLASSE
  const getListeCompleteDesCatechumenes = (commission, niveau, classe) => {
    const query = {
      commission: commission ? commission : commissionSelected,
      niveau: niveau ? niveau : niveauSelected,
      classe: classe ? classe : classeSelected,
    };
    listeCompleteDesCatechumenesEnregistre(query)
      .then((res) => {
        let liste = [];
        res.data.results.forEach((element) => {
          const el = {
            label: `${element.catechumene.nom} ${element.catechumene.prenoms}`,
            value: element.catechumene._id,
          };
          liste.push(el);
        });

        setListeCatechumene(liste);
      })
      .catch((error) => {
        console.log("Impossible de récupérer les catéchumènes", error);
      });
  };

  // === RECUPERE LISTE DES CLASSES PAR NIVEAU ===
  const getListeDesClassesParNiveau = (commission = "", niveau = "") => {
    setListeClasses([]);
    setClasseSelected("");
    listeCompleteDesClasses(commission, niveau)
      .then((res) => {
        setListeClasses(res.data);
        setClasseSelected("");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getListeDesCommission();
    getListeDesMatieres();
    getListeCompleteDesCatechumenes();

    recupererLesNotesParTrimestre(
      1,
      pageQuery.size,
      "PREMIER",
      "",
      "",
      "",
      "",
      ""
    )
      .then((res) => {
        setlisteNote(res.data.results);
        setLoadingData(false);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        console.log("UNE ERREUR S'EST PRODUITE", error);
        setLoadingData(false);
      });
  }, []);

  return (
    <>
      <div className="w-full h-full">
        <div className="">
          {/* FILTRES POSSIBLE */}
          <div className="w-full flex items-end gap-x-2 overflow-x-scroll sm:overflow-auto">
            <label className="form-control min-w-40">
              <div className="label -mb-1">
                <span className="label-text">Matière</span>
              </div>
              <select
                value={matiereSelected}
                onChange={(e) => {
                  setMatiereSelected(e.target.value);
                }}
                className="select select-bordered custom-select w-full h-10 font-medium"
              >
                <option value="" className="font-medium">
                  Tout
                </option>
                {listeMatieres.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
            </label>
            <label className="form-control min-w-40">
              <div className="label -mb-1">
                <span className="label-text">Commission</span>
              </div>
              <select
                value={commissionSelected}
                onChange={(e) => {
                  setCommissionSelected(e.target.value);
                  getListeDesNiveauxParCommission(e.target.value);
                  getListeCompleteDesCatechumenes(e.target.value);
                }}
                className="select select-bordered custom-select w-full h-10 font-medium"
              >
                <option value="" className="font-medium">
                  Tout
                </option>
                {listeCommission.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
            </label>
            <label className="form-control w-40">
              <div className="label -mb-1">
                <span className="label-text">Niveau</span>
              </div>
              <select
                value={niveauSelected}
                onChange={(e) => {
                  setNiveauSelected(e.target.value);
                  getListeCompleteDesCatechumenes("", e.target.value, "");
                  getListeDesClassesParNiveau(
                    commissionSelected,
                    e.target.value
                  );
                }}
                className="select select-bordered custom-select w-40 h-10 font-medium"
              >
                <option value="" className="font-medium">
                  Tout
                </option>
                {listeNiveaux.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
            </label>
            <label className="form-control w-40">
              <div className="label -mb-1">
                <span className="label-text">Classe</span>
              </div>
              <select
                value={classeSelected}
                onChange={(e) => {
                  setClasseSelected(e.target.value);
                  getListeCompleteDesCatechumenes("", "", e.target.value);
                }}
                className="select select-bordered custom-select w-40 h-10 font-medium"
              >
                <option value="" className="font-medium">
                  Tout
                </option>
                {listeClasses.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
            </label>
            <label className="form-control w-40">
              <div className="label -mb-1">
                <span className="label-text">Catéchumène</span>
              </div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={listeCatechumene}
                value={catechumeneSelectedEl}
                onChange={(event, newValue) => {
                  setCatechumeneSelectedEl(newValue ? newValue : "");
                  setCatechumeneSelected(newValue ? newValue.value : "");
                }}
                inputValue={autocompleteInputValue}
                onInputChange={(event, newInputValue) => {
                  setAutocompleteInputValue(newInputValue);
                }}
                sx={{ width: 160 }}
                renderInput={(params) => <TextField {...params} />}
                disabled={searchLoading}
              />
            </label>
            <button
              disabled={searchLoading}
              className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-lg flex items-center justify-center"
              onClick={filtrerLesNotesParParametres}
            >
              {!searchLoading ? (
                "Rechercher"
              ) : (
                <ThreeDots
                  height="35"
                  width="35"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={searchLoading}
                />
              )}
            </button>
          </div>

          <div className="w-full mt-2 h-full">
            <div className="overflow-x-auto bg-white w-full mt-2 ">
              <table className="custom-table table table-zebra w-full">
                <thead>
                  <tr className="bg-black h-12">
                    <th className="text-sm text-white">Catechumène</th>
                    <th className="text-sm text-white">Matière</th>
                    <th className="text-sm text-white">Coefficient</th>
                    <th className="text-sm text-white">Note</th>
                    <th className="text-sm text-white">Note coefficientée</th>
                    <th className="text-sm text-white">Appréciation</th>
                    <th className="text-sm text-white">Commission</th>
                    <th className="text-sm text-white">Niveau</th>
                    <th className="text-sm text-white">Classe</th>
                    <th className="text-sm text-white">Date création</th>
                    <th className="text-sm text-white">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingData && listeNote.length !== 0 ? (
                    listeNote.map((item) => (
                      <TableElement key={item.id} notation={item} />
                    ))
                  ) : !loadingData && listeNote.length === 0 ? (
                    <tr>
                      <td colSpan="11">
                        <div className="w-full h-32 bg-white flex gap-x-2 text-red-500 items-center justify-center">
                          <p className="font-semibold text-sm text-red-600">
                            Aucun élément trouvé
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : loadingData && listeNote.length === 0 ? (
                    <tr>
                      <td colSpan="11">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="11">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <Pagination
              count={pageQuery.nombrePage}
              page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      {/* MODAL BULLETIN DE NOTE CATECHUMENE */}
      <dialog id="liste-note-catechumene" className="modal">
        <div className="modal-box rounded-lg p-3 max-w-2xl">
          <form method="dialog">
            <button
              className="w-7 h-7 absolute right-2 top-2"
              onClick={() => {
                setCatechumeneInfo(null);
                setListeNoteCatechumene([]);
              }}
            >
              <img
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-md md:text-xl text-left text-black">
            Notes premier trimestre du catéchumène{" "}
            <span className="text-primary">
              {catechumeneInfo
                ? `[${catechumeneInfo.nom} ${catechumeneInfo.prenoms}]`
                : null}
            </span>
          </h3>
          <div className="pt-4 pb-2">
            <div className="mt-2 w-full h-full">
              <div className="overflow-x-auto">
                {!loadingDataCatechumene && listeNoteCatechumene.length > 0 ? (
                  <table className="w-full border">
                    <thead>
                      <tr className="bg-gray-50 h-11 border">
                        <th className="text-sm text-black text-left px-4 border">
                          Désignation
                        </th>
                        <th className="bg-gray-100 text-sm text-black text-left px-4 border">
                          Notes
                        </th>
                        <th className="bg-gray-100 text-sm text-black text-left px-4 border">
                          Coeff.
                        </th>
                        <th className="bg-gray-100 text-sm text-black text-left px-4 border">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {listeNoteCatechumene.map((item) => (
                        <tr>
                          <td className="px-4 py-2 truncate text-base text-stone-500 font-semibold border">
                            {item.matiere ? item.matiere.designation : "-"}
                          </td>
                          <td className="px-4 py-2 truncate text-base text-stone-500 font-semibold border">
                            {item.note
                              ? `${item.note}/${item.matiere.pointMax}`
                              : "-"}
                          </td>
                          <td className="px-4 py-2 truncate text-base text-stone-500 font-semibold border">
                            {item.matiere ? item.matiere.coefficient : "-"}
                          </td>
                          <td className="px-4 py-2 truncate text-base text-stone-500 font-semibold border-b">
                            {item.note
                              ? `${item.total}`
                              : "-"}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td className="bg-gray-100 py-2 px-4 text-sm text-black font-semibold">
                          TOTAUX
                        </td>
                        <td className="bg-gray-100 py-2 px-4 font-medium border-r"></td>
                        <td className="py-2 px-4 text-sm text-black font-semibold border">06</td>
                        <td className="py-2 px-4 font-medium border">{calculTotalNoteParCoefficient(listeNoteCatechumene)}</td>
                      </tr>
                    </tbody>
                  </table>
                ) : !loadingDataCatechumene &&
                  listeNoteCatechumene.length === 0 ? (
                  <div className="w-full h-32 bg-white flex gap-x-2 text-red-500 items-center justify-center">
                    <p className="font-semibold text-sm text-red-600">
                      Aucun élément trouvé
                    </p>
                  </div>
                ) : loadingDataCatechumene &&
                  listeNoteCatechumene.length === 0 ? (
                  <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                    <TailSpin
                      height="40"
                      width="40"
                      color="#000"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={loadingDataCatechumene}
                    />
                  </div>
                ) : (
                  <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                    <TailSpin
                      height="40"
                      width="40"
                      color="#000"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={loadingDataCatechumene}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </dialog>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="bg-green-500 text-white px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="bg-red-500 text-white px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </>
  );
};

export default PremierTrismestre;
