import { BASE_URL, FREE_BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("cc_pssm_api");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers.Authorization = null;
    }

    return config;
  },
  function (error) {
    console.log("AN ERROR REQUEST", error);
  }
);

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const listeDesCatechumenePaginee = async (
  page = 1,
  size = 10,
  param = "",
  commission = "",
  niveau = "",
  classe = "",
  statut = "VALIDER"
) => {
  return await axios.get(
    BASE_URL(
      `metier/liste-catechumene-paginee?param=${param}&commission=${commission}&niveau=${niveau}&classe=${classe}&page=${page}&size=${size}&statut=${statut}&anneeScolaire=${
        currentAnnee ? currentAnnee._id : ""
      }`
    ),
    {}
  );
};

export const listeDesCatechumeneNonPaginee = async (
  page = 1,
  size = 10,
  param = "",
  commission = "",
  niveau = "",
  classe = ""
) => {
  return await axios.get(
    BASE_URL(
      `metier/liste-catechumene-non-paginee?param=${param}&commission=${commission}&niveau=${niveau}&classe=${classe}&page=${page}&size=${size}&anneeScolaire=${
        currentAnnee ? currentAnnee._id : ""
      }`
    ),
    {}
  );
};

export const ListeCompleteCatechumene = async (page, size, param) => {
  return await axios.get(
    BASE_URL(`catechumene/query?page=${page}&size=${size}&param=${param}`),
    {}
  );
};

export const ListPresenseCatechu = async (
  catechumeneID,
  page = 1,
  size = 10
) => {
  return await axios.get(
    BASE_URL(
      `metier/presence-catechumene/query?catechumeneId=${catechumeneID}&page=${page}&size=${size}`
    ),
    {}
  );
};

// {{baseUrl}}/notation-semestrielle/query?catechumene&animateur&commission&niveau&classe&page=1&size=10&matiere

export const listeNotationSemestrielle = async (
  semestre,
  idCatechu,
  animateur,
  commission,
  niveau,
  classe,
  page,
  size,
  matiere
) => {
  return await axios.get(
    BASE_URL(
      `notation-semestrielle/query?catechumene=${idCatechu}&semestre=${semestre}&animateur=${animateur}&commission=${commission}&niveau=${niveau}&classe=${classe}&page=${page}&size=${size}&matiere=${matiere}`
    )
  );
};

export const listeHistoriqueCatechumeneAnalyse = async (
  param = "",
  commission = "",
  niveau = "",
  classe = "",
  page = 1,
  size = 10,
  statut = "VALIDER"
) => {
  return await axios.get(
    BASE_URL(
      `historique-catechumene/query?param=${param}&commission=${commission}&niveau=${niveau}&classe=${classe}&anneeScolaire=${
        currentAnnee ? currentAnnee._id : ""
      }&page=${page}&size=${size}&statut=${statut}`
    )
  );
};

export const listeCompleteDesCatechumenesEnregistre = async (query = {}) => {
  return await axios.get(
    BASE_URL(
      `metier/liste-catechumene-non-paginee?commission=${
        query.commission ? query.commission : ""
      }&niveau=${query.niveau ? query.niveau : ""}&classe=${
        query.classe ? query.classe : ""
      }`
    ),
    {}
  );
};

export const exportListeCatechumene = async (
  commission = "",
  niveau = "",
  classe = "",
  statut = "VALIDER"
) => {
  return await axios.post(
    BASE_URL(
      `historique-catechumene/export?commission=${commission}&niveau=${niveau}&classe=${classe}&anneeScolaire=${
        currentAnnee ? currentAnnee._id : ""
      }&statut=${statut}`
    ),
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("cc_pssm_api")}`,
      },
      responseType: "blob",
    }
  );
};

export const listeCompleteDesCatechumenes = async (classe = "") => {
  return await axios.get(BASE_URL(`catechumene/classe/${classe}/all`), {});
};

export const listeCompleteDesCatechumenesSansClasse = async () => {
  return await axios.get(BASE_URL(`catechumene/no-classe`), {});
};

export const listeCompleteDesCatechumeneAvecClasse = async (classeId) => {
  return await axios.get(BASE_URL(`catechumene/with-classe/${classeId}`), {});
};

export const recupereCatechumeneParSonId = async (catechumeneId) => {
  return await axios.get(BASE_URL(`catechumene/${catechumeneId}`), {});
};

export const ajouterUneCatechumene = async (values) => {
  return await axios.post(BASE_URL(`catechumene`), values);
};

// {{BASE_URL}}/catechumene/update/{catechumeneID}
export const modifierUneCatechumene = async (values, catechumeneId) => {
  return await axios.put(BASE_URL(`catechumene/${catechumeneId}`), values);
};

export const updateCatechumene = async (values, catechumeneId) => {
  return await axios.put(
    BASE_URL(`catechumene/update/${catechumeneId}`),
    values
  );
};

export const supprimerUneCatechumene = async (catechumeneId) => {
  return await axios.delete(
    BASE_URL(`metier/remove-catechumene/${catechumeneId}`),
    {}
  );
};

export const supprimerCatechumeneDeLaClasse = async (catechumeneId) => {
  return await axios.delete(
    BASE_URL(`catechumene/${catechumeneId}/delete-classe`),
    {}
  );
};

export const ListeCompletePaiementCatechumene = async (
  catechumeneID = "",
  param = "",
  dateDebut = "",
  dateFin = "",
  mode = "",
  motif = "",
  page = 1,
  size = 10
) => {
  return await axios.get(
    BASE_URL(
      `metier/paiement-catechumene/${catechumeneID}?param=${param}&dateDebut=${dateDebut}&dateFin=${dateFin}&mode=${mode}&motif=${motif}&annee=${
        currentAnnee ? currentAnnee._id : ""
      }&page=${page}&size=${size}`
    ),
    {}
  );
};

export const InfosCatechumene = (id) => {
  return axios.get(BASE_URL(`metier/detail-catechumene/${id}`), {});
};

export const recupererInfosCatechumeneParMatricule = (matricule) => {
  return axios.get(BASE_URL(`catechumene/matricule/${matricule}`), {});
};

export const recupererInfosCatechumeneParMatriculeEtCeremonie = (matricule, ceremonieID) => {
  return axios.get(FREE_BASE_URL(`metier/rechercher-catechumene-ceremonie/${matricule}/${ceremonieID}`), {});
};

export const inscrireNouveauCatechumene = async (dataInscription) => {
  return await axios.post(
    FREE_BASE_URL(`catechumene/inscription`),
    dataInscription
  );
};

export const inscrireAncienCatechumene = async (dataInscription) => {
  return await axios.post(
    FREE_BASE_URL(`catechumene/inscription/ancien`),
    dataInscription
  );
};

export const creatCatechumene = async (data) => {
  return await axios.post(BASE_URL(`catechumene`), data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const creatCatechumeneOnline = async (data) => {
  return await axios.post(BASE_URL(`catechumene/inscription`), data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const rejeterInscription = async (IdPostulant, data) => {
  return await axios.post(
    BASE_URL(`historique-catechumene/rejeter-inscription/${IdPostulant}`),
    data
  );
};

export const validerInscription = async (IdPostulant, data) => {
  return await axios.post(
    BASE_URL(`historique-catechumene/valider-inscription/${IdPostulant}`),
    data
  );
};

export const RevaliderInscription = async (IdPostulantRevalider, data) => {
  return await axios.post(
    BASE_URL(
      `historique-catechumene/revalider-inscription/${IdPostulantRevalider}`
    ),
    data
  );
};
