import { BASE_URL, FREE_BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
}, function (error) {
  console.log('AN ERROR REQUEST', error)
});

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const listeDesSmsEnvoye = async (commission,niveau,classe,page,size,param ) => {  
  return await axios.get(
    BASE_URL(`messagePersonnalise/query?commission=${commission}&niveau=${niveau}&classe=${classe}&annee=${currentAnnee ? currentAnnee._id : ""}&page=${page}&size=${size}&param=${param}`),
    {}
  );
};


export const recupereInfoSMS = async (smsId) => {
  return await axios.get(
    BASE_URL(`messagePersonnalise/${smsId}`),
    {}
  );
};

export const ajouterSMSPersonnalise = async (data) => {
  return await axios.post(
    BASE_URL(`metier/envoie-sms/personnalise`),
    data
  );
};

export const supprimerUnSMS = async (smsId) => {
  return await axios.delete(
    BASE_URL(`messagePersonnalise/${smsId}`),
    {}
  );
};
