import React from "react";
import imageCongra from "../../assets/images/congra3.png";
import { useNavigate } from "react-router-dom";

const FelicitationInscription = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center h-screen w-full">
      <div className="w-10/12 sm:w-[500px] mx-auto ">
        <img src={imageCongra} alt="" className="w-64 mx-auto" />

        <h1 className="text-2xl text-primary sm:text-3xl text-center uppercase font-extrabold">
          Félicitation pour votre inscription !
        </h1>

        <p className="text-lg font-medium text-center">
          Un SMS vous sera envoyé après vérification et validation de votre
          inscription, par l'équipe de la commission catéchèse de la paroisse
          Saint Sauveur Miséricordieux.
        </p>

        <div className="mx-auto w-fit">
          <button
            onClick={() => {
              navigate("/inscription-catechumene");
            }}
            className="w-40 h-10 bg-black font-medium text-sm mt-6 text-white rounded-lg"
          >
            Compris
          </button>
        </div>
      </div>
    </div>
  );
};

export default FelicitationInscription;
