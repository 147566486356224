import React, { useEffect, useRef, useState } from "react";

import { Drawer, Pagination, Snackbar } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import {
  listeNotationSemestrielle,
  ListPresenseCatechu
} from "../../../services/catechumene/CatechumeneRequest";
import { listeDesAnimateurPaginee } from "../../../services/animateur/AnimateurRequest";
import { listeDesClassePaginee } from "../../../services/parametrage/ClasseRequest";

const NotesCatechu = (idCatechu) => {
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 10,
    nombrePage: 1
  });

  const [searchParam, setSearchParam] = useState("");
  const [listeAnimateurs, setListeAnimateurs] = useState([]);
  const [listeClasse, setListeClasse] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [TableauPontialite, setTableauPontialite] = useState([]);
  const [TypeSemestre, setTypeSemestre] = useState("PREMIER");

  const [searchLoading, setSearchLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loadingChauffeurNonAssocier, setLoadingChauffeurNonAssocier] =
    useState(false);

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };
  const formatDate = (date) => {
    // Vérifier si la date est valide
    if (!date) {
      return ""; // Retourner une chaîne vide ou un message par défaut si la date est null/undefined
    }

    // Supprimer les guillemets échappés
    date = date.replace(/\"/g, "");

    // Convertir en objet Date
    date = new Date(date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const TableElement = ({ noteBySemestreItem }) => {
    return (
      <tr className="">
        <td className="px-4 text-sm text-black font-semibold ">
          {noteBySemestreItem ? noteBySemestreItem.semestre : "--"}
        </td>
        <td className="px-4 text-sm text-black font-semibold ">
        {noteBySemestreItem && noteBySemestreItem.matiere ? noteBySemestreItem.matiere.designation : "--"}
        </td>

        <td className="px-4 text-sm text-black font-semibold ">
        {noteBySemestreItem && noteBySemestreItem.matiere ? noteBySemestreItem.matiere.coefficient : "--"}
        </td>

        <td className="px-4 text-sm text-black font-semibold ">
        {noteBySemestreItem ? noteBySemestreItem.note : "--"}
        </td>

        <td className="px-4 text-sm text-black font-semibold ">
        {noteBySemestreItem ? noteBySemestreItem.appreciation : "--"}
        </td>
      </tr>
    );
  };

  const getListeNoteBySemestreOfCatechu = () => {
    setLoadingData(true);
    listeNotationSemestrielle(
      TypeSemestre,
      idCatechu.idCatechu,
      "",
      "",
      "",
      "",
      1,
      pageQuery.size,
      ""
    )
      .then((res) => {
        setLoadingData(false);
        setTableauPontialite(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  console.log(TableauPontialite);

  // === RECUPERE LISTE DES ANIMATEURS ===
  const getListeDesAnimateurs = () => {
    setLoadingData(true);
    listeDesAnimateurPaginee(1, pageQuery.size, searchParam, "", "", "")
      .then((res) => {
        setLoadingData(false);
        setListeAnimateurs(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES CLASSES ===
  const getListeDesClasse = () => {
    setLoadingData(true);
    listeDesClassePaginee(pageQuery.page, 100, searchParam, "", "")
      .then((res) => {
        setLoadingData(false);
        setListeClasse(res.data.results);
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const returnStatutBarNavig = (statut) => {
    return statut === "DEUXIEME" ? (
      <div
        className={
          TypeSemestre === "DEUXIEME"
            ? "truncate px-1.5 lg:px-3 md:px-3 xl:px-3 lg:h-9 xl:h-9 md:h-9 h-7 flex items-center justify-center gap-x-1 rounded-md text-[13px] font-semibold text-white bg-primary"
            : "truncate px-1.5 lg:px-3 md:px-3 xl:px-3 lg:h-9 xl:h-9 md:h-9 h-7 flex items-center justify-center gap-x-1 rounded-md text-[13px] font-semibold text-primary bg-stone-100"
        }
      >
        {/* <div className="bg-green-500 rounded-full w-2 h-2"></div> */}
        DEUXIEME
      </div>
    ) : statut === "PREMIER" ? (
      <div
        className={
          TypeSemestre === "PREMIER"
            ? "truncate px-1.5 lg:px-3 md:px-3 xl:px-3 lg:h-9 xl:h-9 md:h-9 h-7 flex items-center justify-center gap-x-1 rounded-md text-[13px] font-semibold text-white bg-primary"
            : "truncate px-1.5 lg:px-3 md:px-3 xl:px-3 lg:h-9 xl:h-9 md:h-9 h-7 flex items-center justify-center gap-x-1 rounded-md text-[13px] font-semibold text-primary bg-stone-100"
        }
      >
        {/* <div className="bg-[#fb872f] rounded-full w-2 h-2"></div> */}
        PREMIER
      </div>
    ) : statut === " " ? (
      <div
        className={
          TypeSemestre === " "
            ? "truncate px-1.5 lg:px-3 md:px-3 xl:px-3 lg:h-9 xl:h-9 md:h-9 h-7 flex items-center justify-center gap-x-1 rounded-md text-[13px] font-semibold text-white bg-primary"
            : "truncate px-1.5 lg:px-3 md:px-3 xl:px-3 lg:h-9 xl:h-9 md:h-9 h-7 flex items-center justify-center gap-x-1 rounded-md text-[13px] font-semibold text-primary bg-stone-100"
        }
      >
        {/* <div className="bg-white rounded-full w-2 h-2"></div> */}
        TOUS
      </div>
    ) : null;
  };

  useEffect(() => {
    // getListeNoteBySemestreOfCatechu();
    getListeDesAnimateurs();
    getListeDesClasse();
  }, []);

  useEffect(() => {
    getListeNoteBySemestreOfCatechu();
  }, [TypeSemestre]);

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <div className="flex flex-col w-full h-full">
          <div className="flex xl:flex-row  sm:flex-row md:flex-row  lg:flex-row flex-col gap-y-2  justify-between items-center">
            {/* TAB BAR MENU */}
            <div className="flex xl:flex-row lg:flex-row md:flex-row flex-col gap-2 justify-between w-full">
              <div className="w-fit lg:h-11 md:h-11 xl:h-11 h-9 rounded-lg drop-shadow gap-1 px-1 bg-white flex items-center">
                {/* <button
                  className={
                    TypeSemestre === " "
                      ? "h-9 flex items-center justify-center  gap-x-2 rounded-md text-[13px] font-semibold  text-white   "
                      : "h-9 flex items-center justify-center gap-x-2 rounded-md text-[13px] font-semibold  text-white  "
                  }
                  onClick={() => setTypeSemestre(" ")}
                >
                  {returnStatutBarNavig(" ")}
                </button> */}
                <button
                  className={
                    TypeSemestre === "PREMIER"
                      ? "h-9 flex items-center justify-center  gap-x-2 rounded-md text-[13px] font-medium  text-white   "
                      : "h-9 flex items-center justify-center gap-x-2 rounded-md text-[13px] font-medium  text-white  "
                  }
                  onClick={() => setTypeSemestre("PREMIER")}
                >
                  {returnStatutBarNavig("PREMIER")}
                </button>

                <button
                  className={
                    TypeSemestre === "DEUXIEME"
                      ? "h-9 flex items-center justify-center  gap-x-2 rounded-md text-[13px] font-medium  text-white   "
                      : " h-9 flex items-center justify-center  gap-x-2 rounded-md text-[13px] font-medium  text-white   "
                  }
                  onClick={() => setTypeSemestre("DEUXIEME")}
                >
                  {returnStatutBarNavig("DEUXIEME")}
                </button>
                <button
                  className={
                    TypeSemestre === "ANNULE"
                      ? "h-9 flex items-center justify-center  gap-x-2 rounded-md text-[13px] font-medium  text-white   "
                      : "h-9 flex items-center justify-center  gap-x-2 rounded-md text-[13px] font-medium  text-white  "
                  }
                  onClick={() => setTypeSemestre("ANNULE")}
                >
                  {returnStatutBarNavig("ANNULE")}
                </button>
              </div>
            </div>
            {/* <label className="form-control lg:w-fit xl:w-fit md:w-fit w-full ">
              <div className="label">
                <span className="label-text font-semibold text-md">
                  Date du cours
                </span>
              </div>
              <input
                type="date"
                className="input input-bordered lg:w-fit xl:w-fit md:w-fit w-full bg-[#F9F9F9]"
              />
            </label> */}
          </div>

          {/* Liste des evenements   */}

          <div className="flex w-full mt-2 h-full">
            <div className="overflow-x-auto bg-white w-full mt-2 ">
              <table className="custom-table table  table-zebra w-full">
                <thead>
                  <tr className="bg-gray-100 h-12">
                    <th className="text-sm text-gray-600">Semestre</th>
                    <th className="text-sm text-gray-600">Evaluation</th>
                    <th className="text-sm text-gray-600">Coefficient</th>
                    <th className="text-sm text-gray-600">Note obtenue</th>
                    <th className="text-sm text-gray-600">Appréciation</th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingData &&
                  TableauPontialite &&
                  TableauPontialite.length > 0 ? (
                    TableauPontialite.map((item) => (
                      <TableElement key={item.id} noteBySemestreItem={item} />
                    ))
                  ) : !loadingData && TableauPontialite.length === 0 ? (
                    <tr>
                      <td colSpan="6">
                        <div className="w-full h-32 bg-white flex gap-x-2 text-red-500 items-center justify-center">
                          {/* <AlertCircle size={18} /> */}
                          <p className="font-medium text-red-500">
                            Aucun élément trouvé
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : loadingData && TableauPontialite.length === 0 ? (
                    <tr>
                      <td colSpan="6">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <Pagination
              count={pageQuery.nombrePage}
              // page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
              // onChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="bg-green-500 text-white px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="bg-red-500 text-white px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </>
  );
};

export default NotesCatechu;
