import React, { useEffect, useState } from "react";
import iconeAdd from "../../../assets/IconeSvg/boutonAdd.svg";
import iconethreeDots from "../../../assets/IconeSvg/treeDotsGray.svg";
import iconeDelete from "../../../assets/IconeSvg/delete.svg";
import iconePencil from "../../../assets/IconeSvg/pencil.svg";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import { Drawer, Pagination, Snackbar } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import {
  ajouterUneMenu,
  listeDesMenuPaginee,
  modifierUneMenu,
  supprimerUneMenu
} from "../../../services/config-role/MenuRequest";
import {
  returnPermissionsParMenu,
  verificationPermissionsExistantes
} from "../../../utils/ManageUserProfil";
const listePermissions = returnPermissionsParMenu("/config-role");

const connectedUser = localStorage.getItem("cc_pssm_admin")
  ? JSON.parse(localStorage.getItem("cc_pssm_admin"))
  : null;
const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const MenusComponent = () => {
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 20,
    nombrePage: 1,
    pagePagination: 1
  });
  const [addLoading, setAddLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [listeMenu, setListeMenu] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [designation, setDesignation] = useState("");
  const [description, setDescription] = useState("");
  const [anneeScolaire, setAnneeScolaire] = useState("");
  const [route, setRoute] = useState("");
  const [menuInfo, setMenuInfo] = useState(null);

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;

  const TableElement = ({ menu }) => {
    return (
      <tr className=" bg-white">
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {menu.designation ? menu.designation : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {menu.route ? menu.route : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {menu.description ? menu.description : "-"}
        </td>

        <td className="px-4">
          <div className="dropdown dropdown-left dropdown-end">
            <div
              tabIndex={0}
              className="w-7 h-7 rounded-lg bg-gray-200 flex items-center justify-center cursor-pointer"
            >
              <img src={iconethreeDots} alt="" />
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-50 menu p-1 shadow border bg-base-100 rounded-lg w-32 mr-2"
            >
              {verificationPermissionsExistantes(
                listePermissions,
                "MODIFIER"
              ) && (
                  <button
                    onClick={() => {
                      setMenuInfo(menu);
                      openModalModif(menu);
                    }}
                    className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                  >
                    <img src={iconePencil} alt="" className="w-5" />
                    <span className="font-semibold text-sm">Modifier</span>
                  </button>
                )}


              {verificationPermissionsExistantes(
                listePermissions,
                "SUPPRIMER"
              ) && (
                  <button
                    onClick={() => {
                      setMenuInfo(menu);
                      document.getElementById("delete_modePaiement").showModal();
                    }}
                    className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                  >
                    <img src={iconeDelete} alt="" className="w-5" />
                    <span className="font-semibold text-sm">Supprimer</span>
                  </button>
                )}
            </ul>
          </div>
        </td>
      </tr>
    );
  };

  const openModalModif = (menu) => {
    setOpenModal(true);
    setMenuInfo(menu);
    setDesignation(menu.designation);
    setRoute(menu.route);
    setDescription(menu.description);
  };

  // === VIDE FORMULAIRE ===
  const clearFormEnregistrement = () => {
    setMenuInfo(null);
    setAddLoading(false);
    setDesignation("");
    setRoute("");
    setDescription("");
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesMenu = () => {
    setLoadingData(true);
    listeDesMenuPaginee(pageQuery.page, pageQuery.size, "")
      .then((res) => {
        setLoadingData(false);
        setListeMenu(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === ENREGISTRE UNE COMMISSION ===
  const enregistrerMenu = () => {
    setAddLoading(true);
    const modePaiementData = {
      designation: designation,
      route: route,
      description: description,
      anneeScolaire: currentAnnee ? currentAnnee._id : "",
      origine: connectedUser ? connectedUser._id : ""
    };

    ajouterUneMenu(modePaiementData)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Menu enregistréee avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesMenu();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("ELEMENT", err);
      });
  };

  // === MODIFIER UNE COMMISSION ===
  const modifierMenu = () => {
    setAddLoading(true);
    const modePaiementData = {
      designation: designation,
      route: route,
      description: description,
      anneeScolaire: currentAnnee ? currentAnnee._id : "",
      origine: connectedUser ? connectedUser._id : ""
    };

    modifierUneMenu(modePaiementData, menuInfo._id)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Menu modifié avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesMenu();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("ELEMENT", err);
      });
  };

  // === SUPPRIMER UNE COMMISSION ===
  const supprimerMenu = () => {
    setDeleteLoading(true);

    supprimerUneMenu(menuInfo._id)
      .then((res) => {
        setSuccessMessage("Menu supprimé avec succès");
        getListeDesMenu();
        setSnackbarOpenSuccess(true);
        setDeleteLoading(false);
        document.getElementById("delete_modePaiement").close();
      })
      .catch((err) => {
        setDeleteLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("api error", err);
      });
  };

  // === FILTRER LES ELEMENTS ===
  const filterElement = () => {
    setSearchLoading(true);
    listeDesMenuPaginee(pageQuery.page, pageQuery.size, searchParam)
      .then((res) => {
        setSearchLoading(false);
        setListeMenu(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setSearchLoading(false);
        console.log("error", error);
      });
  };

  // === PAGINATION DES ELEMENTS ===
  const handlePaginationChange = (event, value) => {
    setLoadingData(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value
    }));
    listeDesMenuPaginee(value, pageQuery.size, searchParam)
      .then((res) => {
        setLoadingData(false);
        setListeMenu(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  useEffect(() => {
    setLoadingData(true);
    listeDesMenuPaginee(pageQuery.page, pageQuery.size, "")
      .then((res) => {
        setLoadingData(false);
        setListeMenu(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  }, []);

  return (
    <div className="w-full h-full">
      <div className="flex flex-col sm:flex-row justify-end items-end sm:items-center gap-2">
        <div className="w-full sm:w-fit flex items-center gap-x-2">
          <input
            type="text"
            placeholder="Rechercher un élement..."
            value={searchParam}
            onChange={(e) => setSearchParam(e.target.value)}
            className="input input-bordered w-11/12 sm:w-56 h-10 text-sm font-medium"
          />
          <button
            disabled={searchLoading}
            className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
            onClick={filterElement}
          >
            {!searchLoading ? (
              "Rechercher"
            ) : (
              <ThreeDots
                height="35"
                width="35"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={searchLoading}
              />
            )}
          </button>
        </div>
        {verificationPermissionsExistantes(
          listePermissions,
          "AJOUTER"
        ) && (
            <button
              onClick={() => {
                clearFormEnregistrement();
                setOpenModal(true);
              }}
              className="bg-black w-full sm:w-fit h-10 px-4 flex items-center justify-center gap-x-2 rounded-xl"
            >
              <img src={iconeAdd} alt="" className="w-5 sm:w-auto" />
              <span className="text-white text-sm font-semibold truncate">
                Ajouter un menu
              </span>
            </button>
          )}

      </div>

      <div className="mt-2 w-full h-full">
        <div className="overflow-x-auto">
          <table className="custom-table table table-zebra w-full">
            <thead>
              <tr className="bg-black h-12">
                <th className="text-sm text-white">Désignation</th>
                <th className="text-sm text-white">Route</th>
                <th className="text-sm text-white">Description</th>
                <th className="text-sm text-white">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loadingData && listeMenu.length > 0 ? (
                listeMenu.map((item) => (
                  <TableElement key={item._id} menu={item} />
                ))
              ) : !loadingData && listeMenu.length === 0 ? (
                <tr>
                  <td colSpan="4">
                    <div className="w-full h-32 bg-white flex gap-x-2 text-red-500 items-center justify-center">
                      <p className="font-semibold text-lg text-red-600">
                        Aucun élément trouvé
                      </p>
                    </div>
                  </td>
                </tr>
              ) : loadingData && listeMenu.length === 0 ? (
                <tr>
                  <td colSpan="4">
                    <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                      <TailSpin
                        height="30"
                        width="30"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={loadingData}
                      />
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="4">
                    <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                      <TailSpin
                        height="30"
                        width="30"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={loadingData}
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-2">
          <Pagination
            count={pageQuery.nombrePage}
            page={pageQuery.pagePagination}
            variant="outlined"
            shape="rounded"
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="w-screen sm:w-[430px] p-4 flex flex-col bg-white h-full">
          <button onClick={() => setOpenModal(false)}>
            <img src={iconeCloseWindow} alt="" />
          </button>

          <h2 className="mt-3 text-xl font-extrabold">
            {menuInfo === null
              ? " Ajouter un mode paiement"
              : "Modifier ce mode paiement"}
          </h2>

          <div className="mt-6">
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Désignation
                  </span>
                </div>
                <input
                  type="text"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  disabled={addLoading}
                  placeholder="Entrer une designation"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>
            <div className="mt-2">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Route
                  </span>
                </div>
                <input
                  type="text"
                  value={route}
                  onChange={(e) => setRoute(e.target.value)}
                  disabled={addLoading}
                  placeholder="Entrer le nom d'une route"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>
            <div className="mt-2">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Description
                  </span>
                </div>
                <input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={addLoading}
                  placeholder="Entrer une description"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>

            <button
              onClick={
                menuInfo === null
                  ? () => enregistrerMenu()
                  : () => modifierMenu()
              }
              disabled={addLoading}
              className="mt-8 bg-primary flex items-center justify-center w-full h-11 font-bold text-white rounded-lg hover:drop-shadow-md"
            >
              {!addLoading ? (
                <span>{menuInfo === null ? "Enregistrer" : "Modifier"}</span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={addLoading}
                />
              )}
            </button>
          </div>
        </div>
      </Drawer>

      {/* MODAL DELETE ENTREPRISE */}
      <dialog id="delete_modePaiement" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-center text-primary">
            Supprimer ce mode
          </h3>
          <div className="py-4">
            <p className="text-center text-base font-medium text-gray-600">
              Attention action est irrversible et entrainera la suppression de
              ce mode
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? (
                <div
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={supprimerMenu}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>

        {/* SNACKBAR SUCCESS */}
        {successMessage ? (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackbarSuccessOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbarSuccess}
            key={vertical + horizontal}
            className="z-[999999]"
          >
            <div className="bg-green-600 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
              {successMessage}
            </div>
          </Snackbar>
        ) : null}

        {/* SNACKBAR ERROR */}
        {errorMessage ? (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackbarErrorOpen}
            autoHideDuration={2000}
            onClose={handleCloseSnackbarError}
            key={vertical + horizontal}
            className="z-[999999]"
          >
            <div className="bg-red-600 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
              {errorMessage}
            </div>
          </Snackbar>
        ) : null}
      </dialog>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
          className="z-[999999]"
        >
          <div className="bg-green-600 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
          className="z-[999999]"
        >
          <div className="bg-red-600 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default MenusComponent;
