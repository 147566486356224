import { jwtDecode } from "jwt-decode";
import isTokenExpire from "./TokenValidity";

export const isAuthenticated = () => {
  let tokenValid = true;

  try {
    if (localStorage.getItem("cc_pssm_api").replace(/[\""]+/g, "")) {
      if (
        jwtDecode(localStorage.getItem("cc_pssm_api").replace(/[\""]+/g, ""))
          .exp &&
        isTokenExpire(localStorage.getItem("cc_pssm_api"))
      ) {
        tokenValid = true;
      } else {
        tokenValid = false;
      }
    }
  } catch (error) {
    tokenValid = false;
  }
  return tokenValid;
};
