import { BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
}, function (error) {
  console.log('AN ERROR REQUEST', error)
});

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const listeDesCommissionPaginee = async (page = 1, size = 10, param = "") => {  
  return await axios.get(
    BASE_URL(`commission/query?page=${page}&size=${size}&param=${param}&annee=${currentAnnee ? currentAnnee._id : ""}`),
    {}
  );
};

export const listeCompleteDesCommissions = async () => {
  return await axios.get(
    BASE_URL(`commission`),
    {}
  );
};

export const listeCompleteDesCommissionsParAnnee = async (anneeId) => {
  return await axios.get(
    BASE_URL(`commission`),
    {}
  );
};

export const recupereCommissionParSonId = async (commissionId) => {
  return await axios.get(
    BASE_URL(`commission/${commissionId}`),
    {}
  );
};

export const ajouterUneCommission = async (values) => {
  return await axios.post(
    BASE_URL(`commission`),
    values
  );
};

export const modifierUneCommission = async (values, commissionId) => {
  return await axios.put(
    BASE_URL(`commission/${commissionId}`),
    values
  );
};

export const supprimerUneCommission = async (commissionId) => {
  return await axios.delete(
    BASE_URL(`commission/${commissionId}`),
    {}
  );
};
