import React, { useEffect, useRef, useState } from "react";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import iconeAdd from "../../../assets/IconeSvg/boutonAdd.svg";
import { Drawer, Pagination, Snackbar } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { listeDesMotifPaiementPaginee } from "../../../services/parametrage/MotifPaiementRequest";
import { listeDesModePaiementPaginee } from "../../../services/parametrage/ModePaiementRequest";
import { ListeCompletePaiementCatechumene } from "../../../services/catechumene/CatechumeneRequest";

const PaiementCatechu = (idCatechu) => {
  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 10,
    nombrePage: 1
  });

  const [ListeTransaction, setListeTransaction] = useState([
    
  ]);
  const [selectModePaiement, setSelectModePaiement] = useState("");
  const [selectMotif, setSelectMotif] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingPaiement, setLoadingPaiement] = useState(false)

  const [ListeMotifPaiement, setListeMotifPaiement] = useState([
    {
      id: 1,
      designation: "Bapteme"
    },
    {
      id: 2,
      designation: "Confirmation"
    },
    {
      id: 3,
      designation: "Sortie detente"
    }
  ]);

  const [ListeModePaiement, setListeModePaiement] = useState([
    {
      id: 1,
      designation: "Orange money"
    },
    {
      id: 2,
      designation: "MTN money"
    },
    {
      id: 3,
      designation: "Moov money"
    }
  ]);
 const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };


  const formatDate = (date) => {
    // Vérifier si la date est valide
    if (!date) {
      return ""; // Retourner une chaîne vide ou un message par défaut si la date est null/undefined
    }

    // Supprimer les guillemets échappés
    date = date.replace(/\"/g, "");

    // Convertir en objet Date
    date = new Date(date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const TableElement = ({ financeItem }) => {
    return (
      <tr className="">
        <td className="px-4 text-sm text-black font-semibold">
          {financeItem.datePaiement ? formatDate(financeItem.datePaiement) : "--"}
        </td>
        <td className="px-4 text-sm text-black font-semibold">
          {ListeModePaiement.find(
            (modeItem) => modeItem._id === financeItem.modePaiement._id
          )?.designation || "--"}
        </td>

        <td className="px-4 text-sm text-black font-semibold">
        {financeItem.montant ? financeItem.montant : "--"}
          
        </td>

       

        <td className="px-4 text-sm text-black font-semibold">
        {ListeMotifPaiement.find(
  (motif) => motif._id === financeItem.motifPaiement?._id
)?.designation || "--"}

        </td>
      </tr>
    );
  };

  // === RECUPERE LISTE DES MODE DE PAIEMENT ===
  const getListeDesModePaiement = () => {
    setLoadingData(true);
    listeDesModePaiementPaginee(pageQuery.page, pageQuery.size, "")
      .then((res) => {
        setLoadingData(false);
        setListeModePaiement(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES MOTIFS DE PAIEMENT ===
  const getListeDesMotifPaiement = () => {
    setLoadingData(true);
    listeDesMotifPaiementPaginee(pageQuery.page, pageQuery.size, "")
      .then((res) => {
        setLoadingData(false);
        setListeMotifPaiement(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const getPaiementCatechu = () => {
    setLoadingData(true);

    ListeCompletePaiementCatechumene(
      idCatechu.idCatechu,
      "",
      "",
      "",
      "",
      "",
      pageQuery.page,
      pageQuery.size
    )
      .then((res) => {
        setLoadingData(false);
        console.log(res);
        setListeTransaction(res.data.results);
      })
      .catch((err) => {
        setLoadingData(false);
        console.log(err);
      });
  };

  // const effectuePaiementCatechu = () =>{
  //   setLoadingPaiement(true);

  //   const dataCatechumene = new FormData();
  //   dataCatechumene.append("photo", photoRecuPaiement );
  //   dataCatechumene.append("motifPaiement", selectMotif);
  //   dataCatechumene.append("modePaiement", selectModePaiement);
  //   dataCatechumene.append("montant", selectModePaiement);
    
  //   paiementCatechumene(idCatechu.idCatechu,dataCatechumene)
  //   .then((res)=>{
  //     setLoadingPaiement(false);
  //     setOpenModal(false)
  //     setSuccessMessage("Paiement enregistré avec succès");
  //     setSnackbarOpenSuccess(true);
  
  //   })

    

  // }

  useEffect(() => {
    getPaiementCatechu();
    getListeDesMotifPaiement();
    getListeDesModePaiement();
  }, []);

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <div className="flex flex-col w-full h-full ">
          <div className="flex xl:flex-row  sm:flex-row md:flex-row  lg:flex-row flex-col gap-y-2  justify-end items-center gap-x-4 mt-3">
            {/* <label className="form-control  lg:w-[16%] xl:w-[16%] md:w-[16%] w-full lg:mt-[-1.5rem] xl:mt-[-1.5rem] md:mt-[-1.5rem]">
              <select
                value={selectMotif}
                onChange={(e) => setSelectMotif(e.target.value)}
                className="py-3 px-2 rounded-lg border-[1.5px]  w-full bg-[#F5F5F5]  xl:mb-[-1.5rem] lg:mb-[-1.5rem] md:mb-[-1.5rem]"
              >
                <option disabled selected>
                  Motif de paiment
                </option>
                {ListeMotifPaiement.map((item) => (
                  <option value={item.id}>{item.designation}</option>
                ))}
              </select>
            </label>

            <label className="form-control  lg:w-[16%] xl:w-[16%] md:w-[16%] w-full lg:mt-[-1.5rem] xl:mt-[-1.5rem] md:mt-[-1.5rem]">
              <select
                value={selectModePaiement}
                onChange={(e) => setSelectModePaiement(e.target.value)}
                className="py-3 px-2 rounded-lg border-[1.5px]  w-full bg-[#F5F5F5]  xl:mb-[-1.5rem] lg:mb-[-1.5rem] md:mb-[-1.5rem]"
              >
                <option disabled selected>
                  Mode de paiement
                </option>
                {ListeModePaiement.map((item) => (
                  <option value={item.id}>{item.designation}</option>
                ))}
              </select>
            </label> */}
            {/* <button
              onClick={() => setOpenModal(true)}
              className="flex flex-row bg-black py-3 px-2 gap-x-2 rounded-lg justify-center xl:w-fit  md:w-fit sm:w-fit  lg:w-fit w-full items-center"
            >
              <img src={iconeAdd} alt="" />
              <span className="text-white">Ajouter un paiement </span>
            </button> */}
          </div>
          {/* Liste des paiements  */}
          <div className="flex w-full mt-2 h-full">
            <div className="overflow-x-auto bg-white w-full mt-2 ">
              <table className="custom-table table  table-zebra w-full">
                <thead>
                  <tr className="bg-black h-12">
                    <th className="text-sm text-white">Date de transaction</th>
                    <th className="text-sm text-white">Mode paiement</th>
                    <th className="text-sm text-white">Montant</th>
                    <th className="text-sm text-white">Evènement</th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingData &&
                  ListeTransaction &&
                  ListeTransaction.length > 0 ? (
                    ListeTransaction.map((item) => (
                      <TableElement key={item.id} financeItem={item} />
                    ))
                  ) : !loadingData && ListeTransaction.length === 0 ? (
                    <tr>
                      <td colSpan="6">
                        <div className="w-full h-32 bg-white flex gap-x-2 text-red-500 items-center justify-center">
                          {/* <AlertCircle size={18} /> */}
                          <p className="font-medium text-red-500">
                            Aucun élément trouvé
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : loadingData && ListeTransaction.length === 0 ? (
                    <tr>
                      <td colSpan="6">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <Pagination
              count={pageQuery.nombrePage}
              // page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
              // onChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="w-[400px] h-full px-4 pt-2 pl-2 flex flex-col bg-white ">
          <div className="flex justify-start ">
            <img
              onClick={() => setOpenModal(false)}
              src={iconeCloseWindow}
              className="cursor-pointer"
              alt=""
            />
          </div>

          <h2 className="mt-3 ml-2 text-lg font-semibold">
            Ajouter un nouveau paiement
          </h2>

          <div className="flex flex-col mt-6 w-full pb-4">
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Catéchumène
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Kouassi kouassi Luc"
                  disabled
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>
            </div>

            <div className="flex flex-row justify-center items-center gap-x-1">
              <label className="form-control w-full ">
                <div className="label">
                  <span className="label-text font-semibold">
                    Mode de paiement
                  </span>
                </div>
                <select
                  value={selectModePaiement}
                  onChange={(e) => setSelectModePaiement(e.target.value)}
                  className="select select-bordered"
                >
                  <option disabled selected>
                    selectionner un mode
                  </option>
                  ListeDesEvenements
                  {ListeModePaiement.map((mode) => (
                    <option
                      className="bg-[#F9F9F9] text-black"
                      key={mode.id}
                      value={mode.id}
                    >
                      {mode.designation}
                    </option>
                  ))}
                </select>
              </label>

              <label className="form-control w-full ">
                <div className="label">
                  <span className="label-text font-semibold">Evènements</span>
                </div>
                <select
                  value={selectModePaiement}
                  onChange={(e) => setSelectModePaiement(e.target.value)}
                  className="select select-bordered"
                >
                  <option disabled selected>
                    choisir un Evènement
                  </option>

                  {ListeMotifPaiement.map((evenement) => (
                    <option
                      className="bg-[#F9F9F9] text-black"
                      key={evenement.id}
                      value={evenement.id}
                    >
                      {evenement.designation}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Montant
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Entrer le montant ici"
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>
            </div>

            <button
              // onClick={enregistrementPaiment}
              className="mt-5 bg-primary flex items-center justify-center w-full h-10 font-medium text-white rounded-lg hover:drop-shadow-md"
            >
              {!isLoading ? (
                <span>Enregistrer</span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={isLoading}
                />
              )}
            </button>
          </div>
        </div>
      </Drawer>

       {/* SNACKBAR SUCCESS */}
       {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="bg-green-500 text-white px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="bg-red-500 text-white px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </>
  );
};

export default PaiementCatechu;
