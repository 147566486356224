import React, { useEffect, useState } from "react";
import BaseLayout from "../../layout/BaseLayout";
import { Tab, Tabs } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import MenusComponent from "./menus/Menus";
import PermissionsComponent from "./permissions/Permissions";
import RolesComponent from "./roles/Roles";
import { returnPermissionsParMenu, verificationPermissionsExistantes } from "../../utils/ManageUserProfil";
const listePermissions =returnPermissionsParMenu("/config-role")

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const GestionsRoles = () => {
  const [valueTab, setValueTab] = useState(0);
  const [connectedUser, setConnectedUser] = useState(null);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    if (localStorage.getItem("cc_pssm_api")) {
      const token = localStorage.getItem("cc_pssm_api");
      const decoded = jwtDecode(token);
      setConnectedUser(decoded ? decoded.connected : null);
    }
  }, [])


  return (
    <BaseLayout>
    {verificationPermissionsExistantes(listePermissions, "VOIR_LISTE") ? (
        <div className="w-full h-full">
        <div className="w-full ">
          <h1 className="text-2xl md:text-3xl font-extrabold">Rôles et permissions</h1>
          <p className="mt-2 text-gray-500 font-medium text-sm sm:text-base md:text-lg">
            Paramétrer les permissions et les rôles dans l'application.
          </p>
        </div>

        <div className="mt-5 border-b">
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            variant="scrollable"
            textColor="black"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            <Tab
              label="Menus"
              {...a11yProps(0)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Permissions"
              {...a11yProps(1)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
            <Tab
              label="Rôles"
              {...a11yProps(2)}
              sx={{ textTransform: "capitalize", fontSize: 14, fontWeight: 700 }}
            />
          </Tabs>
        </div>

        <div className="mt-8">
          <CustomTabPanel value={valueTab} index={0}>
            <MenusComponent />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={1}>
            <PermissionsComponent />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={2}>
            <RolesComponent />
          </CustomTabPanel>
        </div>
      </div>
      ) : (
        <div className="h-56 flex items-center justify-center">
          <p className="text-base text-primary font-extrabold">
            Vous n'avez pas accès à ce menu.
          </p>
        </div>
      )}


      
    </BaseLayout>
  );
};

export default GestionsRoles;
