import { BASE_URL, FREE_BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
});

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const recupererListeCeremoniesPaginee = async (
  page = 1,
  size = 10,
  param = "",
  commission = "",
  niveau = ""
) => {
  return await axios.get(
    BASE_URL(
      `ceremonies/query?page=${page}&size=${size}&param=${param}&commission=${commission}&niveau=${niveau}&anneeScolaire=${
        currentAnnee ? currentAnnee._id : ""
      }`
    ),
    {}
  );
};

export const recupererDetailCeremonieById = async (ceremonieID) => {
  return await axios.get(BASE_URL(`ceremonies/${ceremonieID}`), {});
};

export const enregistrerCeremonie = async (values) => {
  return await axios.post(BASE_URL("ceremonies"), values);
};

export const recupererDetailCeremonieByID = async (ceremonieID) => {
  return await axios.get(FREE_BASE_URL(`ceremonies/detail/${ceremonieID}`), {});
};

export const supprimerCeremonie = async (ceremonieID) => {
  return await axios.delete(BASE_URL(`ceremonies/${ceremonieID}`), {});
};

export const inscriptionPariticipantCeremonie = async (values) => {
  return await axios.post(FREE_BASE_URL(`ceremonies/inscription`), values);
};

