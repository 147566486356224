import React, { useEffect, useState } from "react";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import iconeAdd from "../../../assets/IconeSvg/boutonAdd.svg";
import iconeDelete from "../../../assets/IconeSvg/delete.svg";
import iconeAppareilPhoto from "../../../assets/IconeSvg/appareil_photo.svg";
import { Drawer, Pagination, Snackbar } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import {
  EnregistrerDepensesEvent,
  ListeCompleteDepensesEvenement,
  ListeCompletePaiementEvenement
} from "../../../services/evenements/EvenementRequests";
import { listeDesMotifPaiementPaginee } from "../../../services/parametrage/MotifPaiementRequest";
import { listeDesModePaiementPaginee } from "../../../services/parametrage/ModePaiementRequest";

const EvenementsDepenses = (idEvent) => {
  const currentAnnee = localStorage.getItem("currentAnnee")
    ? JSON.parse(localStorage.getItem("currentAnnee"))
    : null;

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 10,
    nombrePage: 1
  });

  const [ListeTransaction, setListeTransaction] = useState([
    {
      id: 1,
      dateTransaction: "02-08-2024",
      modePaiement: "Orange money",
      montant: "5000",
      catechumene: "John jo",
      evenement: "Inscription"
    },
    {
      id: 2,
      dateTransaction: "02-08-2024",
      modePaiement: "Orange money",
      montant: "3000",
      catechumene: "John jo",
      evenement: "Bapteme"
    },
    {
      id: 1,
      dateTransaction: "02-08-2024",
      modePaiement: "Orange money",
      montant: "5000",
      catechumene: "John jo",
      evenement: "Inscription"
    }
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [ListeDepensesEvent, setListeDepensesEvent] = useState([]);
  const [listeDesMotifs, setListeDesMotifs] = useState([]);
  const [selectModePaiement, setSelectModePaiement] = useState("");
  const [selectMotifPaiement, setSelectMotifPaiement] = useState();
  const [photoRecuPaiement, setPhotoRecuPaiement] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [preview, setPreview] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [depenseData, setDepenseData] = useState({
    dateDepense: "",
    modePaiement: "",
    motifPaiement: "",
    commentaire: "",
    photoDuRecu: "",
    montant: ""
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const [ListeModePaiement, setListeModePaiement] = useState([
    {
      id: 1,
      designation: "enfant"
    },
    {
      id: 2,
      designation: "Adulte"
    },
    {
      id: 3,
      designation: "jeune"
    }
  ]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;
  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };
  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhotoRecuPaiement(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formatDate = (date) => {
    // Vérifier si la date est valide
    if (!date) {
      return ""; // Retourner une chaîne vide ou un message par défaut si la date est null/undefined
    }

    // Supprimer les guillemets échappés
    date = date.replace(/\"/g, "");

    // Convertir en objet Date
    date = new Date(date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const TableElement = ({ financeItem }) => {
    return (
      <tr className="">
        <td className="px-4 text-sm text-stone-500 font-normal ">
          {financeItem.datePaiement
            ? formatDate(financeItem.datePaiement)
            : "--"}
        </td>
        <td className="px-4 text-sm text-stone-500 font-normal ">
          {/* {ListeModePaiement.find(
            (modeItem) => modeItem._id === financeItem.modePaiement._id
          )?.designation || "--"} */}
          {(() => {
            const modePaiement = ListeModePaiement.find(
              (itemmodePaiement) =>
                itemmodePaiement._id === financeItem.modePaiement._id
            );
            return modePaiement
              ? `${modePaiement.designation} (${modePaiement.numeroPaiement})`
              : "--";
          })()} 
        </td>
        <td className="px-4 text-sm text-stone-500 font-normal ">
          {financeItem.montant ? financeItem.montant : "--"} FCFA
        </td>
        <td className="px-4 text-sm text-stone-500 font-normal truncate ">
          {financeItem.commentaire ? financeItem.commentaire : "--"}
        </td>
        <td className="px-4 text-sm text-stone-500 font-normal truncate">
        {financeItem.photoRecuPaiement && (
          <img
            src={financeItem.photoRecuPaiement}
            alt=""
            onClick={() => handleImageClick(financeItem.photoRecuPaiement)}
            className="cursor-pointer"
          />
        )}
      </td>

      </tr>
    );
  };

  // const listeDepenseEvent = () => {
  //   setIsLoading(true);
  //   ListeCompletePaiementEvenement(
  //     idEvent.idEvent,
  //     searchTerm,
  //     "",
  //     "",
  //     "",
  //     "",
  //     pageQuery.page,
  //     pageQuery.size
  //   )
  //     .then((res) => {
  //       setIsLoading(false);
  //       console.log(res);
  //       setListeDepensesEvent(res.data.results);
  //       setPageQuery((prev) => ({
  //         ...prev,
  //         nombrePage: res.data.totalPages
  //       }));
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);

  //       console.log(err);
  //     });
  // };

  // const handlePaginationChange = (event, value) => {
  //   setIsLoading(true);
  //   setPageQuery((prev) => ({
  //     ...prev,
  //     page: value,
  //     pagePagination: value
  //   }));
  //   if (searchTerm.length !== 0) {
  //     ListeCompleteDepensesEvenement(
  //       idEvent.idEvent,
  //       searchTerm,
  //       "",
  //       "",
  //       "",
  //       "",
  //       value,
  //       pageQuery.size
  //     )
  //       .then((res) => {
  //         setIsLoading(false);
  //         ListeDepensesEvent(res.data.results);
  //         setPageQuery((prev) => ({
  //           ...prev,
  //           nombrePage: res.data.totalPages,
  //           totalElements: res.data.totalElements
  //         }));
  //       })
  //       .catch((err) => {
  //         setIsLoading(false);
  //         console.log("api error", err);
  //       });
  //   } else {
  //     ListeCompleteDepensesEvenement(
  //       idEvent.idEvent,
  //       searchTerm,
  //       "",
  //       "",
  //       "",
  //       "",
  //       value,
  //       pageQuery.size
  //     )
  //       .then((res) => {
  //         setIsLoading(false);
  //         ListeDepensesEvent(res.data.results);
  //         setPageQuery((prev) => ({
  //           ...prev,
  //           nombrePage: res.data.totalPages,
  //           totalElements: res.data.totalElements
  //         }));
  //       })
  //       .catch((err) => {
  //         setIsLoading(false);
  //         console.log("api error", err);
  //       });
  //   }
  // };

  // const searchEvementPaiement = () => {
  //   setSearchLoading(true);
  //   ListeCompleteDepensesEvenement(
  //     idEvent.idEvent,
  //     searchTerm,
  //     "",
  //     "",
  //     "",
  //     "",
  //     pageQuery.page,
  //     pageQuery.size
  //   )
  //     .then((res) => {
  //       setSearchLoading(false);
  //       ListeDepensesEvent(res.data.results);
  //       setPageQuery((prev) => ({
  //         ...prev,
  //         nombrePage: res.data.totalPages,
  //         totalElements: res.data.totalElements
  //       }));
  //     })
  //     .catch((err) => {
  //       setSearchLoading(false);
  //       console.log("api error", err);
  //     });
  // };

  const clearFormEnregistrement = () => {
    setDepenseData({
      nom: "",
      prenoms: "",
      contact: "",
      secondContact: "",
      email: "",
      commission: "",
      niveau: "",
      photo: ""
    });
  };

  // const enregistrementAnimateur = () => {
  //   setAddLoading(true);
  //   const formData = new FormData();
  //   formData.append("photoDuRecu", photoRecuPaiement);
  //   formData.append("dateDepense", depenseData.dateDepense);
  //   formData.append("montant", depenseData.montant);
  //   formData.append("commentaire", depenseData.commentaire);
  //   formData.append("modePaiement", selectModePaiement);
  //   formData.append("motifPaiement", idEvent.idEvent);
  //   formData.append("anneeScolaire", currentAnnee && currentAnnee._id);
  //   EnregistrerDepensesEvent(idEvent.idEvent, formData)
  //     .then((res) => {
  //       clearFormEnregistrement();
  //       setAddLoading(false);
  //       setOpenModal(false);
  //       setSuccessMessage("Dépense enregistré avec succès.");
  //       setSnackbarOpenSuccess(true);
  //       // listeDepenseEvent();
  //     })
  //     .catch((err) => {
  //       setAddLoading(false);
  //       if (err && err.response.data) {
  //         setErrorMessage(err.response.data.message);
  //         setSnackbarOpenError(true);
  //       } else {
  //         setErrorMessage("Erreur d'enregistrement, rééssayer!");
  //         setSnackbarOpenError(true);
  //       }
  //     });
  // };

  // === RECUPERE LISTE DES MODE DE PAIEMENT ===
  const getListeDesModePaiement = () => {
    setLoadingData(true);
    listeDesModePaiementPaginee(pageQuery.page, pageQuery.size, "")
      .then((res) => {
        setLoadingData(false);
        setListeModePaiement(res.data.results);
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES MOTIFS DE PAIEMENT ===
  const getListeDesMotifPaiement = () => {
    setLoadingData(true);
    listeDesMotifPaiementPaginee(pageQuery.page, pageQuery.size, "")
      .then((res) => {
        setLoadingData(false);
        setListeDesMotifs(res.data.results);
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    getListeDesModePaiement();
    getListeDesMotifPaiement();
    // listeDepenseEvent();
  }, []);

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <div className="flex flex-col w-full h-full ">
          <div className="flex xl:flex-row  sm:flex-row md:flex-row  lg:flex-row flex-col gap-y-2  justify-end items-center gap-x-4 mt-3">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Rechercher un élement ..."
              className="input input-bordered xl:w-fit h-10  md:w-fit sm:w-fit  lg:w-fit w-full"
            />
            <button
              disabled={searchLoading}
              className="bg-gray-600 text-sm text-white h-10 xl:w-fit lg:w-fit md:w-fit w-full px-4 font-semibold rounded-xl flex items-center justify-center"
              // onClick={searchEvementPaiement}
            >
              {!searchLoading ? (
                "Rechercher"
              ) : (
                <ThreeDots
                  height="35"
                  width="35"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={searchLoading}
                />
              )}
            </button>
            <button
              onClick={() => setOpenModal(true)}
              className="flex flex-row bg-black py-3 px-2 gap-x-2 rounded-lg justify-center xl:w-fit  md:w-fit sm:w-fit  lg:w-fit w-full items-center"
            >
              <img src={iconeAdd} alt="" />
              <span className="text-white">Ajouter une dépense </span>
            </button>
          </div>
          {/* Liste des Administrateur   */}
          <div className="flex w-full mt-2 h-full">
            <div className="overflow-x-auto bg-white w-full mt-2 ">
              <table className="custom-table table  table-zebra w-full">
                <thead>
                  <tr className="bg-primary h-12">
                    <th className="text-sm text-white">Date de transaction</th>
                    <th className="text-sm text-white">Mode paiement</th>
                    <th className="text-sm text-white">Montant</th>
                    <th className="text-sm text-white">Commentaire</th>
                    <th className="text-sm text-white">Photo du reçu</th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingData &&
                  ListeDepensesEvent &&
                  ListeDepensesEvent.length > 0 ? (
                    ListeDepensesEvent.map((item) => (
                      <TableElement key={item.id} financeItem={item} />
                    ))
                  ) : !loadingData && ListeDepensesEvent.length === 0 ? (
                    <tr>
                      <td colSpan="6">
                        <div className="w-full h-32 bg-white flex gap-x-2 text-red-500 items-center justify-center">
                          {/* <AlertCircle size={18} /> */}
                          <p className="font-medium text-red-500">
                            Aucun élément trouvé
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : loadingData && ListeDepensesEvent.length === 0 ? (
                    <tr>
                      <td colSpan="6">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <Pagination
              count={pageQuery.nombrePage}
              // page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
              // onChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="w-screen sm:w-[430px] h-full px-4 pt-2 pl-2 flex flex-col bg-white ">
          <div className="flex justify-start ">
            <img
              onClick={() => setOpenModal(false)}
              src={iconeCloseWindow}
              className="cursor-pointer"
              alt=""
            />
          </div>

          <h2 className="mt-3 ml-2 text-lg font-semibold">
            Ajouter une dépense
          </h2>

          <div className="pt-2 flex flex-col bg-white ">
            <div className="mt-6">
              <div className="flex xl:flex-row lg:flex-row md:flex-row flex-col justify-center items-center gap-2 w-full">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Date de la dépense
                    </span>
                  </div>
                  <input
                    type="date"
                    disabled={addLoading}
                    value={depenseData.dateDepense}
                    onChange={(e) => {
                      setDepenseData((prev) => ({
                        ...prev,
                        dateDepense: e.target.value
                      }));
                    }}
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Montant débité
                    </span>
                  </div>
                  <input
                    type="text"
                    disabled={addLoading}
                    value={depenseData.montant}
                    onChange={(e) => {
                      setDepenseData((prev) => ({
                        ...prev,
                        montant: e.target.value
                      }));
                    }}
                    placeholder="Entrer une designation"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
                <label className="form-control w-full ">
                  <div className="label">
                    <span className="label-text font-semibold">
                      Compte débité
                    </span>
                  </div>
                  <select
                    value={selectModePaiement}
                    onChange={(e) => setSelectModePaiement(e.target.value)}
                    className="select select-bordered bg-[#F9F9F9]"
                  >
                    <option disabled selected>
                      selectionner un compte
                    </option>

                    {ListeModePaiement.map((mode) => (
                      <option
                        className="bg-[#F9F9F9] text-black"
                        key={mode.id}
                        value={mode._id}
                      >
                        {`${mode.numeroPaiement} (${mode.designation})  `} {}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      description de la dépense
                    </span>
                  </div>
                  <textarea
                    className="textarea textarea-bordered w-full  font-medium bg-[#F9F9F9]"
                    placeholder="Entrer une commentaire"
                    disabled={addLoading}
                    value={depenseData.commentaire}
                    onChange={(e) => {
                      setDepenseData((prev) => ({
                        ...prev,
                        commentaire: e.target.value
                      }));
                    }}
                  ></textarea>
                </label>

                <div className="label mt-4">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Reçu de la dépense
                  </span>
                </div>

                {/* UPLOAD PHOTO ANIMATEUR */}
                <div className="flex justify-center items-center  mt-6 h-24 w-full ">
                  <div
                    onClick={() => {
                      document.getElementById("photo-profil").click();
                    }}
                    className="relative flex justify-center items-center w-full mx-auto h-40 bg-gray-200 rounded-lg cursor-pointer border border-black"
                  >
                    <button
                      className="absolute -top-3 -right-3 w-9 h-9 rounded-full flex items-center justify-center bg-white border drop-shadow z-50"
                      onClick={() => {
                        setPreview("");
                        setPhotoRecuPaiement("");
                      }}
                    >
                      <img src={iconeDelete} className="w-5" />
                    </button>

                    {photoRecuPaiement ? (
                      <div className="relative w-full h-full rounded-lg">
                        <img
                          src={preview}
                          alt="Preview"
                          className="w-full h-full  rounded-lg object-contain"
                        />
                      </div>
                    ) : null}

                    {!photoRecuPaiement ? (
                      <div className="w-20 h-20 relative flex items-center justify-center rounded-full">
                        <img
                          src={iconeAppareilPhoto}
                          alt="photo_animateur"
                          className="w-8 h-8"
                        />
                      </div>
                    ) : null}
                  </div>
                  <input
                    type="file"
                    id="photo-profil"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
              </div>

              <button
                disabled={addLoading}
                // onClick={() => enregistrementAnimateur()}
                className="mt-10 bg-primary flex items-center justify-center w-full h-11 font-medium text-sm text-white rounded-lg hover:drop-shadow-md"
              >
                {!addLoading ? (
                  <span>Enregistrer</span>
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={addLoading}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </Drawer>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="relative bg-white p-4 rounded shadow-lg">
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-black bg-gray-300 rounded-full p-1 px-2"
            >
              &times;
            </button>
            <img src={selectedImage} alt="Receipt" className="max-w-full max-h-screen" />
          </div>
        </div>
      )}

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="bg-green-500 text-white px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="bg-red-500 text-white px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </>
  );
};

export default EvenementsDepenses;
