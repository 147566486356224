import React, { useEffect, useState } from "react";
import SnackbarComponent from "../../../components/SnackbarComponent";
import { Drawer, Pagination } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import iconeAdd from "../../../assets/IconeSvg/boutonAdd.svg";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import iconeDefaultUser from "../../../assets/IconeSvg/user-rounded.svg";
import iconeDelete from "../../../assets/IconeSvg/delete-white.svg";
import { modifierUneCatechumene } from "../../../services/catechumene/CatechumeneRequest";
import { useParams } from "react-router-dom";
import {
  ajouterUnePresenceCatechumene,
  listeDesPresenceCatechumenePaginee,
  supprimerUnePresenceCatechumene,
} from "../../../services/presence-catechumene/PresenceCatechumeneRequest";
import { formatDate } from "../../../utils/Utils";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { listeCompleteDesCatechumenesParClasse } from "../../../services/historique-catechumene/HistoriqueCatechumeneRequest";
import { jwtDecode } from "jwt-decode";
import {
  returnPermissionsParMenu,
  verificationPermissionsExistantes,
} from "../../../utils/ManageUserProfil";

const connectedUser = localStorage.getItem("cc_pssm_api")
  ? jwtDecode(localStorage.getItem("cc_pssm_api"))
  : null;
const listePermissions = returnPermissionsParMenu("/classes");

const ClasseListePresence = ({ classe }) => {
  registerLocale("fr", fr);
  let { classeId } = useParams();
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 10,
    nombrePage: 1,
    pagePagination: 1,
  });
  const [addLoading, setAddLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalShowDetailCatechumene, setModalShowDetailCatechumene] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchDate, setSearchDate] = useState(new Date());
  const [searchDateFin, setSearchDateFin] = useState(new Date());
  const [listePresenceEnregistre, setListePresenceEnregistre] = useState([]);
  const [listeCatechumene, setListeCatechumene] = useState([]);
  const [listePresenceInfo, setListePresenceInfo] = useState(null);
  const [presenceSelected, setPresenceSelected] = useState(null);
  const [listePresenceData, setListePresenceData] = useState({
    presents: [],
    absents: [],
  });

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = snackbarPosition;

  const clearFormEnregistrement = () => {
    setListePresenceInfo(null);
    setListePresenceData({
      presents: initializePresents(listeCatechumene),
      absents: [],
    });
  };

  const initializePresents = (liste) => {
    return liste.map((item) => ({ catechumene: item._id, enRetard: false }));
  };

  const getListeDesCatechumene = () => {
    listeCompleteDesCatechumenesParClasse(classeId)
      .then((res) => {
        let totalCatechumene = [];
        res.data.map((item) => {
          totalCatechumene.push(item.catechumene);
        });
        setListePresenceData({
          presents: initializePresents(totalCatechumene),
          absents: [],
        });
        setListeCatechumene(totalCatechumene);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getListeDesPresencesDesCatechumene = () => {
    setLoadingData(true);
    listeDesPresenceCatechumenePaginee(1, pageQuery.size, "", "", classeId)
      .then((res) => {
        setLoadingData(false);
        setListePresenceEnregistre(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === ENREGISTRER LA LISTE D'APPEL ===
  const enregistrerLaListeDappel = () => {
    setAddLoading(true);
    const submitData = {
      dateAppel: new Date().toISOString(),
      ...listePresenceData,
      animateur: connectedUser.id,
      classe: classeId,
    };

    ajouterUnePresenceCatechumene(submitData)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Liste de présence enregistrée avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesPresencesDesCatechumene();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("ELEMENT", err);
      });
  };

  // === MODIFIER LA LISTE D'APPEL ===
  const modifierLaListeDappel = () => {
    setAddLoading(true);
    modifierUneCatechumene(listePresenceData, listePresenceInfo._id)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Liste de présence modifiée avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesPresencesDesCatechumene();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("ELEMENT", err);
      });
  };

  // === SUPPRIMER LA LISTE D'APPEL ===
  const supprimerListeDePresenceEnregistre = () => {
    setDeleteLoading(true);
    supprimerUnePresenceCatechumene(presenceSelected._id)
      .then((res) => {
        setSuccessMessage("Liste de présence supprimée avec succès");
        getListeDesPresencesDesCatechumene();
        setSnackbarOpenSuccess(true);
        setDeleteLoading(false);
        document.getElementById("delete_liste_presence").close();
        setPresenceSelected(null);
      })
      .catch((err) => {
        setDeleteLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("api error", err);
      });
  };

  const filterElement = () => {
    setSearchLoading(true);
    listeDesPresenceCatechumenePaginee(
      pageQuery.page,
      pageQuery.size,
      searchDate.toISOString(),
      searchDateFin.toISOString(),
      classeId
    )
      .then((res) => {
        setSearchLoading(false);
        setListePresenceEnregistre(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setSearchLoading(false);
        console.log("error", error);
      });
  };

  const handleRadioChange = (id, type) => {
    setListePresenceData((prevState) => {
      let newPresents = prevState.presents.filter((p) => p.catechumene !== id);
      let newAbsents = prevState.absents.filter((a) => a !== id);

      if (type === "retard") {
        newPresents.push({ catechumene: id, enRetard: true });
      } else if (type === "present") {
        newPresents.push({ catechumene: id, enRetard: false });
      } else if (type === "absent") {
        newAbsents.push(id);
      }

      return { presents: newPresents, absents: newAbsents };
    });
  };

  const handlePaginationChange = (event, value) => {
    setLoadingData(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value,
    }));
    listeDesPresenceCatechumenePaginee(
      value,
      pageQuery.size,
      searchDate,
      searchDateFin,
      classe._id
    )
      .then((res) => {
        setLoadingData(false);
        setListePresenceEnregistre(res.data);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  useEffect(() => {
    setLoadingData(true);
    getListeDesCatechumene();

    listeDesPresenceCatechumenePaginee(
      pageQuery.page,
      pageQuery.size,
      "",
      "",
      classeId
    )
      .then((res) => {
        setLoadingData(false);
        setListePresenceEnregistre(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  }, []);

  return (
    <div>
      {verificationPermissionsExistantes(listePermissions, "VOIR_LISTE") ? (
        <div>
          <div className="flex flex-col sm:flex-row justify-end items-end sm:items-end gap-2">
            <div className="w-fit flex items-end gap-x-2 overflow-x-scroll sm:overflow-auto">
              <div>
                <div className="label -mb-1">
                  <span className="label-text text-xs font-semibold">Date début</span>
                </div>
                <DatePicker
                  locale="fr"
                  dateFormat="dd/MM/yyyy"
                  selected={searchDate}
                  onChange={(date) => setSearchDate(date)}
                  className="input input-bordered w-32 h-10 text-sm font-medium"
                />
              </div>
              <div>
                <div className="label -mb-1">
                  <span className="label-text text-xs font-semibold">Date fin</span>
                </div>
                <DatePicker
                  locale="fr"
                  dateFormat="dd/MM/yyyy"
                  selected={searchDateFin}
                  onChange={(date) => setSearchDateFin(date)}
                  className="input input-bordered w-32 h-10 text-sm font-medium"
                />
              </div>
              <button
                disabled={searchLoading}
                className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
                onClick={filterElement}
              >
                {!searchLoading ? (
                  "Rechercher"
                ) : (
                  <ThreeDots
                    height="35"
                    width="35"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={searchLoading}
                  />
                )}
              </button>
            </div>
            {verificationPermissionsExistantes(
              listePermissions,
              "FAIRE_APPEL"
            ) && (
                <button
                  onClick={() => {
                    clearFormEnregistrement();
                    setOpenModal(true);
                  }}
                  className="bg-black w-full sm:w-fit h-10 px-4 flex items-center justify-center gap-x-2 rounded-xl"
                >
                  <img src={iconeAdd} alt="" className="w-5 sm:w-auto" />
                  <span className="text-white text-sm font-semibold truncate">
                    Faire l'appel
                  </span>
                </button>
              )}
          </div>

          <div className="mt-8 space-y-10">
            {!loadingData && listePresenceEnregistre.length !== 0
              ? listePresenceEnregistre.map((item, index) => {
                return (
                  <div key={index} className="rounded-xl drop-shadow- border bg-white p-3">
                    <div className="flex justify-end">
                      <button className="w-fit px-3 h-9 rounded-lg drop-shadow bg-primary text-xs text-white font-semibold flex items-center justify-center gap-1" onClick={() => {
                        document.getElementById('delete_liste_presence').showModal();
                        setPresenceSelected(item);
                      }}>
                        <img src={iconeDelete} alt="icone delete" className="w-4" />
                        Supprimer cet élément
                      </button>
                    </div>
                    <div className="mt-5 flex items-start justify-between gap-x-10">
                      <div className="">
                        <p className="text-base sm:text-lg text-primary font-extrabold">
                          {formatDate(new Date(item.dateAppel))} [{" "}
                          {item.presents.length} présents -{" "}
                          {item.absents.length} absents ]
                        </p>
                      </div>
                      <div className="">
                        <p className="text-base sm:text-lg text-black text-right font-extrabold">
                          Animateur:{" "}
                          {item.animateur
                            ? `${item.animateur.nom} ${item.animateur.prenoms}`
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2">
                      <p className="text-base sm:text-lg text-gray-500 font-bold">
                        Présents [{item.presents.length}]
                      </p>
                      <div className="mt-2 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4">
                        {item.presents.map((item, index) => (
                          <div
                            key={index}
                            className="bg-white drop-shadow-sm border w-fill h-fit rounded-xl p-1 flex items-center gap-x-2"
                          >
                            {item.catechumene ? (
                              <div className="w-10 h-10 bg-gray-100 rounded-lg">
                                <img
                                  src={item.catechumene.photo}
                                  className="w-full h-full object-cover rounded-lg"
                                />
                              </div>
                            ) : (
                              <div className="w-10 h-10 bg-gray-100 rounded-lg"></div>
                            )}
                            {item.catechumene ? (
                              <div className="w-9/12">
                                <p className="text-sm font-medium truncate">
                                  {item.catechumene.nom}{" "}
                                  {item.catechumene.prenoms}
                                </p>
                                {item.enRetard ? (
                                  <p className="text-xs text-orange-500 font-medium truncate">
                                    En retard
                                  </p>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mt-2">
                      <p className="text-base sm:text-lg text-gray-500 font-bold">
                        Absents [{item.absents.length}]
                      </p>
                      {item.absents.length > 0 ? (
                        <div className="mt-2 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4">
                          {item.absents.map((item, index) => (
                            <div
                              key={index}
                              className="bg-white drop-shadow-sm border w-fill h-fit rounded-xl p-1 flex items-center gap-x-2"
                            >
                              {item && item.photo ? (
                                <div className="w-10 h-10 bg-gray-100 rounded-lg">
                                  <img
                                    src={item.photo}
                                    className="w-full h-full object-cover rounded-lg"
                                  />
                                </div>
                              ) : (
                                <div className="w-10 h-10 bg-gray-100 rounded-lg"></div>
                              )}
                              {item ? (
                                <p className="w-9/12 text-sm font-medium truncate">
                                  {item.nom} {item.prenoms}
                                </p>
                              ) : null}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="mt-2 w-full h-28 flex items-center justify-center bg-neutral-50 rounded-lg">
                          <p className="font-semibold text-sm text-black">
                            Aucun catéchumène absent
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
              : null}
          </div>

          {!loadingData && listePresenceEnregistre.length === 0 ? (
            <div className="mt-5 h-56 w-full flex items-center justify-center">
              <p className="text-primary font-semibold">
                Aucune liste d'appel élément trouvé.
              </p>
            </div>
          ) : null}

          {loadingData && listePresenceEnregistre.length === 0 ? (
            <div className="space-y-14">
              {[1, 2, 3].map((item) => {
                return (
                  <div key={item}>
                    <div className="flex items-center justify-between gap-x-10">
                      <div className="skeleton h-6 w-8/12 md:w-72 rounded-md"></div>
                      <div className="skeleton h-6 w-2/5 md:w-44 rounded-md"></div>
                    </div>
                    <div className="mt-2">
                      <p className="text-base sm:text-lg text-gray-500 font-bold">
                        Présents
                      </p>
                      <div className="mt-2 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4">
                        {[0, 1, 2, 3, 4, 5].map((item) => (
                          <div
                            key={item}
                            className="bg-white drop-shadow-sm border w-fill h-fit rounded-xl p-1 flex items-center gap-x-2"
                          >
                            <div className="skeleton h-10 w-10 rounded-lg"></div>
                            <div className="skeleton h-6 w-36 rounded-md"></div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mt-2">
                      <p className="text-base sm:text-lg text-gray-500 font-bold">
                        Absents
                      </p>
                      <div className="mt-2 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4">
                        {[0, 1, 2, 3, 4].map((item) => (
                          <div
                            key={item}
                            className="bg-white drop-shadow-sm border w-fill h-fit rounded-xl p-1 flex items-center gap-x-2"
                          >
                            <div className="skeleton h-10 w-10 rounded-lg"></div>
                            <div className="skeleton h-6 w-36 rounded-md"></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          {loadingData && listePresenceEnregistre.length !== 0 ? (
            <div className="mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4 w-full">
              {[0, 1, 2, 3, 4, 5, 6].map((item) => (
                <div
                  key={item}
                  className="w-full bg-white rounded-xl p-4 border drop-shadow-sm"
                >
                  <div className="skeleton h-16 w-16 rounded-full mx-auto"></div>
                  <div className="skeleton h-5 w-11/12 mx-auto rounded mt-4"></div>
                  <div className="skeleton h-5 w-9/12 mx-auto rounded mt-2"></div>
                </div>
              ))}
            </div>
          ) : null}

          <div className="flex justify-end mt-5">
            <Pagination
              count={pageQuery.nombrePage}
              page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginationChange}
            />
          </div>
        </div>
      ) : (
        <div className="h-56 flex items-center justify-center">
          <p className="text-base text-primary font-extrabold">
            Vous n'avez pas accès à ce menu.
          </p>
        </div>
      )}

      {/* CREATION - MODIFICATION */}
      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => {
          clearFormEnregistrement();
          setOpenModal(false);
        }}
      >
        <div className="w-screen sm:w-[520px] p-4 bg-white">
          <button
            onClick={() => {
              clearFormEnregistrement();
              setOpenModal(false);
            }}
          >
            <img src={iconeCloseWindow} alt="" />
          </button>

          <h2 className="mt-3 text-xl font-extrabold">
            {listePresenceInfo === null
              ? "Faire un nouvel appel"
              : "Modifier cette liste"}
          </h2>

          <div className="mt-6 pb-16">
            <div className="flex items-center justify-between mb-3">
              <p className="text-lg text-gray-400 font-medium">Date du jour</p>
              <p className="text-lg text-black text-primary font-extrabold">
                {new Date().toLocaleDateString()}
              </p>
            </div>
            <div className="space-y-3">
              {listeCatechumene.length !== 0
                ? listeCatechumene.map((item, index) => (
                  <div
                    key={index}
                    className="bg-gray-100 w-full h-fit p-2 rounded-lg flex items-center justify-between"
                  >
                    <p className="w-7/12 text-sm font-medium truncate">
                      {item.nom} {item.prenoms}
                    </p>
                    <div className="flex items-center gap-x-4">
                      <div className="flex flex-col items-center">
                        <p className="text-xs font-medium">A l'heure</p>
                        <input
                          type="radio"
                          name={`radio-${item._id}`}
                          className="mt-1 radio radio-xs"
                          onChange={() =>
                            handleRadioChange(item._id, "present")
                          }
                        />
                      </div>
                      <div className="flex flex-col items-center">
                        <p className="text-xs font-medium">En retard</p>
                        <input
                          type="radio"
                          name={`radio-${item._id}`}
                          className="mt-1 radio radio-xs"
                          onChange={() =>
                            handleRadioChange(item._id, "retard")
                          }
                        />
                      </div>
                      <div className="flex flex-col items-center">
                        <p className="text-xs font-medium">Absent</p>
                        <input
                          type="radio"
                          name={`radio-${item._id}`}
                          className="mt-1 radio radio-xs"
                          onChange={() =>
                            handleRadioChange(item._id, "absent")
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))
                : null}
            </div>

            <button
              onClick={
                listePresenceInfo === null
                  ? () => enregistrerLaListeDappel()
                  : () => modifierLaListeDappel()
              }
              // disabled={addLoading}
              className="mt-8 bg-primary flex items-center justify-center w-full h-11 font-bold text-white rounded-lg hover:drop-shadow-md"
            >
              {!addLoading ? (
                <span>
                  {listePresenceInfo === null
                    ? "Enregistrer la liste d'appel"
                    : "Modifier la liste d'appel"}
                </span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={addLoading}
                />
              )}
            </button>
          </div>
        </div>
      </Drawer>

      {/* DETAIL */}
      <Drawer
        anchor={"right"}
        open={modalShowDetailCatechumene}
        onClose={() => {
          setListePresenceInfo(null);
          setModalShowDetailCatechumene(false);
        }}
      >
        <div className="w-screen sm:w-[450px] min-h-screen p-4 bg-white">
          <button
            onClick={() => {
              setListePresenceInfo(null);
              setModalShowDetailCatechumene(false);
            }}
          >
            <img src={iconeCloseWindow} alt="" />
          </button>

          <h2 className="mt-3 text-xl font-extrabold">
            A propos du catéchumène
          </h2>

          {listePresenceInfo ? (
            <div className="mt-6 pb-16">
              <div className="h-24 w-24 rounded-xl mx-auto bg-gray-100 flex items-center justify-center border-4 border-gray-200 shadow p-1">
                {listePresenceInfo.photo ? (
                  <img
                    src={listePresenceInfo.photo}
                    className="w-full h-full rounded-lg"
                  />
                ) : (
                  <img src={iconeDefaultUser} className="w-8" />
                )}
              </div>
              <div className="w-44 mx-auto mt-4">
                <button className="w-full h-10 rounded-lg bg-black text-white text-sm font-semibold">
                  Générer le badge
                </button>
              </div>
              <div className="mt-5">
                <p className="text-base text-primary font-extrabold">
                  Informations
                </p>
                <div className="mt-2 w-full h-fit bg-gray-100 rounded-xl shadow-sm p-3">
                  <div className="text-black text-base font-bold">
                    <span className="text-gray-500">Nom prénoms: </span>
                    {listePresenceInfo.nom} {listePresenceInfo.prenoms}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Contact: </span>
                    {listePresenceInfo.contact}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Second contact: </span>
                    {listePresenceInfo.secondContact}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Email: </span>
                    {listePresenceInfo.email}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Nom prénoms parent: </span>
                    {listePresenceInfo.nomParent}{" "}
                    {listePresenceInfo.prenomsParent}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Contact parent: </span>
                    {listePresenceInfo.contactParent}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">
                      Second contact parent:{" "}
                    </span>
                    {listePresenceInfo.secondContactParent}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Email parent: </span>
                    {listePresenceInfo.emailParent}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Date d'inscription: </span>
                    {new Date(listePresenceInfo.createdAt).toLocaleDateString()}
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <button
                  className="w-full h-11 rounded-lg bg-primary text-white text-sm font-semibold flex items-center justify-center"
                  onClick={() => {
                    document
                      .getElementById("delete_catechumene_clase")
                      .showModal();
                  }}
                >
                  Supprimer de la classe
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </Drawer>

      {/* MODAL DELETE */}
      <dialog id="delete_liste_presence" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-center text-primary">
            Supprimer de cette liste de présence
          </h3>
          <div className="pt-4 pb-2">
            <p className="text-center text-base font-medium text-gray-600">
              Attention action est irrversible et entrainera la suppression de cette liste de présence. La suppression d'une liste de présente peut avoir un impact négatif sur les informations liées aux catéchumènes.
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? (
                <div
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={supprimerListeDePresenceEnregistre}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      <SnackbarComponent
        vertical={vertical}
        horizontal={horizontal}
        snackbarSuccessOpen={snackbarSuccessOpen}
        handleCloseSnackbarSuccess={handleCloseSnackbarSuccess}
        successMessage={successMessage}
        snackbarErrorOpen={snackbarErrorOpen}
        handleCloseSnackbarError={handleCloseSnackbarError}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default ClasseListePresence;
