import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Pagination, Snackbar, TextField } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import iconeEye from "../../../assets/IconeSvg/eye.svg";
import iconePencil from "../../../assets/IconeSvg/pencil.svg";
import { listeCompleteDesNiveauParCommission } from "../../../services/parametrage/NiveauxRequest";
import { listeCompleteDesCommissions } from "../../../services/parametrage/CommissionRequest";
import { listeCompleteDesCatechumenesEnregistre } from "../../../services/catechumene/CatechumeneRequest";
import { listeCompleteDesClasses } from "../../../services/parametrage/ClasseRequest";
import {
  enregistrerDecisionFinal,
  recupererLesResultatsAnnuels,
} from "../../../services/notation/NotationRequest";
import { listeCompleteDesNotations } from "../../../services/parametrage/NotationRequest";

const BilanAnnuel = () => {
  const bulletinRef = useRef();
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 20,
    nombrePage: 1,
    pagePagination: 1,
  });
  const [notePageQuery, setNotePageQuery] = useState({
    page: 0,
    size: 20,
    nombrePage: 1,
    pagePagination: 1,
  });
  const [loadingData, setLoadingData] = useState(true);
  const [inputDecisionFinal, showInputDecisionFinal] = useState(false);
  const [loadingDataCatechumene, setLoadingDataCatechumene] = useState(true);
  const [loadingDecision, setLoadingDecision] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [listeNiveaux, setListeNiveaux] = useState([]);
  const [listeCommission, setListeCommission] = useState([]);
  const [listeMatieres, setListeMatieres] = useState([]);
  const [listeCatechumene, setListeCatechumene] = useState([]);
  const [listeClasses, setListeClasses] = useState([]);
  const [classeSelected, setClasseSelected] = useState("");
  const [niveauSelected, setNiveauSelected] = useState("");
  const [commissionSelected, setCommissionSelected] = useState("");
  const [decisionField, setDecisionField] = useState({
    decision: "ADMIS",
    commentaire: "",
  });
  const [catechumeneSelectedEl, setCatechumeneSelectedEl] = useState(null);
  const [catechumeneSelected, setCatechumeneSelected] = useState("");
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const [catechumeneInfo, setCatechumeneInfo] = useState("");
  const [bilanInfo, setBilanInfo] = useState("");
  const [listeNote, setlisteNote] = useState([]);
  const [resultatMoyenne, setResultatMoyenne] = useState([]);

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = snackbarPosition;

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const TableElement = ({ notation }) => {
    return (
      <tr className="">
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.bilan.catechumene
            ? `${notation.bilan.catechumene.nom} ${notation.bilan.catechumene.prenoms}`
            : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.moyenneTrimestreUn
            ? `${parseFloat(Number(notation.moyenneTrimestreUn).toFixed(2))}/20`
            : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.moyenneTrimestreDeux
            ? `${parseFloat(
              Number(notation.moyenneTrimestreDeux).toFixed(2)
            )}/20`
            : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.moyenneTrimestreTrois
            ? `${parseFloat(
              Number(notation.moyenneTrimestreTrois).toFixed(2)
            )}/20`
            : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.moyenneGenerale
            ? `${parseFloat(Number(notation.moyenneGenerale).toFixed(2))}/20`
            : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.moyenneGenerale
            ? afficherAppreciationParMoyenne(
              parseFloat(Number(notation.moyenneGenerale).toFixed(2))
            )
            : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {notation.bilan
            ? notation.bilan.decisionConseil
            : "-"}
        </td>
        <td>
          <div>
            <button
              className="w-8 h-8 rounded-md bg-gray-200 flex items-center justify-center"
              onClick={() => {
                document.getElementById("liste-note-catechumene").showModal();
                setCatechumeneInfo(notation.bilan.catechumene);
                setBilanInfo(notation.bilan);
                getListeDesNotesParCatechumene(notation.bilan.catechumene._id);
              }}
            >
              <img src={iconeEye} alt="" className="w-5" />
            </button>
          </div>
        </td>
      </tr>
    );
  };

  const afficherAppreciationParMoyenne = (moyenne) => {
    let appreciation = "";
    if (moyenne <= 10) {
      appreciation = "Passsable";
    } else if (moyenne > 10 && moyenne <= 12) {
      appreciation = "Assez bien";
    } else if (moyenne > 12 && moyenne <= 14) {
      appreciation = "Bien";
    } else if (moyenne > 14 && moyenne <= 16) {
      appreciation = "Très bien";
    } else if (moyenne > 16 && moyenne <= 18) {
      appreciation = "Excellent";
    } else if (moyenne > 18) {
      appreciation = "Honorable";
    }

    return appreciation;
  };

  const filtrerLesNotesParParametres = () => {
    setSearchLoading(true);
    recupererLesResultatsAnnuels(
      pageQuery.page,
      pageQuery.size,
      catechumeneSelected,
      commissionSelected,
      niveauSelected,
      classeSelected
    )
      .then((res) => {
        setlisteNote(res.data.results);
        setSearchLoading(false);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        console.log("UNE ERREUR S'EST PRODUITE", error);
        setSearchLoading(false);
      });
  };

  // === RECUPERER LISTE NOTE PAR CATECHUMENE ===
  const getListeDesNotesParCatechumene = (catechumeneID) => {
    setLoadingDataCatechumene(true);
    recupererLesResultatsAnnuels(
      1,
      notePageQuery.size,
      catechumeneID,
      "",
      "",
      ""
    )
      .then((res) => {
        setResultatMoyenne(res.data.results[0]);
        setLoadingDataCatechumene(false);
        setNotePageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        console.log("UNE ERREUR S'EST PRODUITE", error);
        setLoadingDataCatechumene(false);
      });
  };

  // === RECUPERE LISTE DES MATIERES ===
  const getListeDesMatieres = () => {
    listeCompleteDesNotations()
      .then((res) => {
        setListeMatieres(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesCommission = () => {
    listeCompleteDesCommissions()
      .then((res) => {
        setListeCommission(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES NIVEAUX PAR COMMISSION ===
  const getListeDesNiveauxParCommission = (commission = "") => {
    setListeNiveaux([]);
    setNiveauSelected("");
    setListeClasses([]);
    setClasseSelected("");
    listeCompleteDesNiveauParCommission(commission)
      .then((res) => {
        setListeNiveaux(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // LISTE CATECHUMENE PAR COMMISSION NIVEAU CLASSE
  const getListeCompleteDesCatechumenes = (commission, niveau, classe) => {
    const query = {
      commission: commission ? commission : commissionSelected,
      niveau: niveau ? niveau : niveauSelected,
      classe: classe ? classe : classeSelected,
    };
    listeCompleteDesCatechumenesEnregistre(query)
      .then((res) => {
        let liste = [];
        res.data.results.forEach((element) => {
          const el = {
            label: `${element.catechumene.nom} ${element.catechumene.prenoms}`,
            value: element.catechumene._id,
          };
          liste.push(el);
        });

        setListeCatechumene(liste);
      })
      .catch((error) => {
        console.log("Impossible de récupérer les catéchumènes", error);
      });
  };

  // === RECUPERE LISTE DES CLASSES PAR NIVEAU ===
  const getListeDesClassesParNiveau = (commission = "", niveau = "") => {
    setListeClasses([]);
    setClasseSelected("");
    listeCompleteDesClasses(commission, niveau)
      .then((res) => {
        setListeClasses(res.data);
        setClasseSelected("");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const enregistrerDecisionConseil = () => {
    const values = {
      bilanId: bilanInfo._id,
      decisionConseil: decisionField.decision,
      commentaire: decisionField.commentaire,
    };
    setLoadingDecision(true)
    enregistrerDecisionFinal(values)
      .then((res) => {
        setLoadingDecision(false)
        showInputDecisionFinal(false)
        getListeDesNotesParCatechumene(catechumeneInfo._id);
      })
      .catch((err) => {
        console.log("UNE ERREUR S'EST PRODUITE", err);
        setLoadingDecision(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
      });
  };

  useEffect(() => {
    getListeDesCommission();
    getListeDesMatieres();
    getListeCompleteDesCatechumenes();

    recupererLesResultatsAnnuels(1, pageQuery.size, "", "", "", "")
      .then((res) => {
        setlisteNote(res.data.results);
        setLoadingData(false);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        console.log("UNE ERREUR S'EST PRODUITE", error);
        setLoadingData(false);
      });
  }, []);

  return (
    <>
      <div className="w-full h-full">
        <div className="">
          {/* FILTRES POSSIBLE */}
          <div className="w-full flex items-end gap-x-2 overflow-x-scroll sm:overflow-auto">
            <label className="form-control min-w-40">
              <div className="label -mb-1">
                <span className="label-text">Commission</span>
              </div>
              <select
                value={commissionSelected}
                onChange={(e) => {
                  setCommissionSelected(e.target.value);
                  getListeDesNiveauxParCommission(e.target.value);
                  getListeCompleteDesCatechumenes(e.target.value);
                }}
                className="select select-bordered custom-select w-full h-10 font-medium"
              >
                <option value="" className="font-medium">
                  Tout
                </option>
                {listeCommission.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
            </label>
            <label className="form-control w-40">
              <div className="label -mb-1">
                <span className="label-text">Niveau</span>
              </div>
              <select
                value={niveauSelected}
                onChange={(e) => {
                  setNiveauSelected(e.target.value);
                  getListeCompleteDesCatechumenes("", e.target.value, "");
                  getListeDesClassesParNiveau(
                    commissionSelected,
                    e.target.value
                  );
                }}
                className="select select-bordered custom-select w-40 h-10 font-medium"
              >
                <option value="" className="font-medium">
                  Tout
                </option>
                {listeNiveaux.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
            </label>
            <label className="form-control w-40">
              <div className="label -mb-1">
                <span className="label-text">Classe</span>
              </div>
              <select
                value={classeSelected}
                onChange={(e) => {
                  setClasseSelected(e.target.value);
                  getListeCompleteDesCatechumenes("", "", e.target.value);
                }}
                className="select select-bordered custom-select w-40 h-10 font-medium"
              >
                <option value="" className="font-medium">
                  Tout
                </option>
                {listeClasses.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
            </label>
            <label className="form-control w-40">
              <div className="label -mb-1">
                <span className="label-text">Catéchumène</span>
              </div>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={listeCatechumene}
                value={catechumeneSelectedEl}
                onChange={(event, newValue) => {
                  setCatechumeneSelectedEl(newValue ? newValue : "");
                  setCatechumeneSelected(newValue ? newValue.value : "");
                }}
                inputValue={autocompleteInputValue}
                onInputChange={(event, newInputValue) => {
                  setAutocompleteInputValue(newInputValue);
                }}
                sx={{ width: 160 }}
                renderInput={(params) => <TextField {...params} />}
                disabled={searchLoading}
              />
            </label>
            <button
              disabled={searchLoading}
              className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-lg flex items-center justify-center"
              onClick={filtrerLesNotesParParametres}
            >
              {!searchLoading ? (
                "Rechercher"
              ) : (
                <ThreeDots
                  height="35"
                  width="35"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={searchLoading}
                />
              )}
            </button>
          </div>

          <div className="w-full mt-2 h-full">
            <div className="overflow-x-auto bg-white w-full mt-2 ">
              <table className="custom-table table table-zebra w-full">
                <thead>
                  <tr className="bg-black h-12">
                    <th className="text-sm text-white">Catechumène</th>
                    <th className="text-sm text-white">
                      Moyenne 1er trimestre
                    </th>
                    <th className="text-sm text-white">
                      Moyenne 2ème trimestre
                    </th>
                    <th className="text-sm text-white">
                      Moyenne 3ème trimestre
                    </th>
                    <th className="text-sm text-white">Moyenne générale</th>
                    <th className="text-sm text-white">Appréciation</th>
                    <th className="text-sm text-white">Décision du conseil</th>
                    <th className="text-sm text-white">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingData && listeNote.length !== 0 ? (
                    listeNote.map((item) => (
                      <TableElement key={item.id} notation={item} />
                    ))
                  ) : !loadingData && listeNote.length === 0 ? (
                    <tr>
                      <td colSpan="8">
                        <div className="w-full h-32 bg-white flex gap-x-2 text-red-500 items-center justify-center">
                          <p className="font-semibold text-sm text-red-600">
                            Aucun élément trouvé
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : loadingData && listeNote.length === 0 ? (
                    <tr>
                      <td colSpan="8">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="8">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <Pagination
              count={pageQuery.nombrePage}
              page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
            // onChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      {/* MODAL BULLETIN DE NOTE CATECHUMENE */}
      <dialog id="liste-note-catechumene" className="modal">
        <div className="modal-box rounded-lg p-4 max-w-2xl">
          <form method="dialog">
            <button
              className="w-7 h-7 absolute right-2 top-2"
              onClick={() => {
                setCatechumeneInfo(null);
                setResultatMoyenne([]);
              }}
            >
              <img
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-md md:text-xl text-left text-black">
            Moyennes du catéchumène{" "}
            <span className="text-primary">
              {catechumeneInfo
                ? `[${catechumeneInfo.nom} ${catechumeneInfo.prenoms}]`
                : null}
            </span>
          </h3>
          <div className="pt-4 pb-2">
            <table className="w-full border">
              <thead>
                <tr className="bg-gray-100 h-11 border">
                  <th className="text-sm text-black text-left px-4 border">
                    Désignation
                  </th>
                  <th className="bg-gray-100 text-sm text-black text-left px-4 border">
                    Moyenne
                  </th>
                  <th className="bg-gray-100 text-sm text-black text-left px-4 border">
                    Appréciation
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-2 truncate text-lg text-stone-500 font-semibold border">
                    1er trimestre
                  </td>
                  <td className="px-4 py-2 truncate text-lg text-stone-500 font-semibold border">
                    {resultatMoyenne.moyenneTrimestreUn
                      ? `${parseFloat(
                        Number(resultatMoyenne.moyenneTrimestreUn).toFixed(2)
                      )}/20`
                      : "-"}
                  </td>
                  <td className="px-4 py-2 truncate text-lg text-stone-500 font-semibold border">
                    {resultatMoyenne.moyenneTrimestreUn
                      ? afficherAppreciationParMoyenne(
                        parseFloat(
                          Number(resultatMoyenne.moyenneTrimestreUn).toFixed(
                            2
                          )
                        )
                      )
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 truncate text-lg text-stone-500 font-semibold border">
                    2ème trimestre
                  </td>
                  <td className="px-4 py-2 truncate text-lg text-stone-500 font-semibold border">
                    {resultatMoyenne.moyenneTrimestreDeux
                      ? `${parseFloat(
                        Number(resultatMoyenne.moyenneTrimestreDeux).toFixed(
                          2
                        )
                      )}/20`
                      : "-"}
                  </td>
                  <td className="px-4 py-2 truncate text-lg text-stone-500 font-semibold border">
                    {resultatMoyenne.moyenneTrimestreDeux
                      ? afficherAppreciationParMoyenne(
                        parseFloat(
                          Number(
                            resultatMoyenne.moyenneTrimestreDeux
                          ).toFixed(2)
                        )
                      )
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2 truncate text-lg text-stone-500 font-semibold border">
                    3ème trimestre
                  </td>
                  <td className="px-4 py-2 truncate text-lg text-stone-500 font-semibold border">
                    {resultatMoyenne.moyenneTrimestreTrois
                      ? `${parseFloat(
                        Number(resultatMoyenne.moyenneTrimestreTrois).toFixed(
                          2
                        )
                      )}/20`
                      : "-"}
                  </td>
                  <td className="px-4 py-2 truncate text-lg text-stone-500 font-semibold border">
                    {resultatMoyenne.moyenneTrimestreTrois
                      ? afficherAppreciationParMoyenne(
                        parseFloat(
                          Number(
                            resultatMoyenne.moyenneTrimestreTrois
                          ).toFixed(2)
                        )
                      )
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="bg-gray-100 py-2 px-4 text-sm text-black font-semibold border">
                    MOYENNE GENERALE
                  </td>
                  <td className="bg-gray-100 py-2 px-4 font-semibold border">
                    {resultatMoyenne.moyenneGenerale
                      ? `${parseFloat(
                        Number(resultatMoyenne.moyenneGenerale).toFixed(2)
                      )}/20`
                      : "-"}
                  </td>
                  <td className="bg-gray-100 py-2 px-4 font-semibold border">
                    {resultatMoyenne.moyenneGenerale
                      ? afficherAppreciationParMoyenne(
                        parseFloat(
                          Number(resultatMoyenne.moyenneGenerale).toFixed(2)
                        )
                      )
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td className="bg-white py-2 px-4 text-sm text-white font-black border"></td>
                  <td className="bg-white text-white py-2 px-4 font-semibold border"></td>
                  <td className="bg-white py-2 px-4 font-semibold border"></td>
                </tr>
                <tr>
                  <td className="bg-primary py-2 px-4 text-sm text-white font-black">
                    DECISION DU CONSEIL
                  </td>
                  <td className="bg-primary text-white py-2 px-4 font-semibold">
                    {resultatMoyenne.bilan && resultatMoyenne.bilan.decisionConseil ? resultatMoyenne.bilan.decisionConseil : ""}
                  </td>
                  <td className="bg-primary py-2 px-4 font-semibold"></td>
                </tr>
                <tr>
                  <td className="bg-gray-100 py-2 px-4 text-sm text-black font-semibold border">
                    COMMENTAIRE
                  </td>
                  <td className="bg-gray-100 py-2 px-4 font-semibold">
                    {resultatMoyenne.bilan && resultatMoyenne.bilan.commentaire ? resultatMoyenne.bilan.commentaire : ""}
                  </td>
                  <td className="bg-gray-100 py-2 px-4 font-semibold"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-3 pb-3">
            <div className="flex items-center gap-2">
              <p className="text-base text-primary font-semibold underline">
                Décision du conseil de classe :
              </p>
              <span
                className="p-1 bg-gray-200 rounded cursor-pointer"
                onClick={() => {
                  showInputDecisionFinal(true);
                  setDecisionField({
                    decision: "ADMIS",
                    commentaire: "",
                  });
                }}
              >
                <img src={iconePencil} alt="edit icon" className="w-4" />
              </span>
            </div>
            {inputDecisionFinal && (
              <div>
                <div className="w-full mt-3">
                  <label className="form-control w-full mb-3">
                    <div className="label">
                      <span className="label-text font-medium">Décision du conseil</span>
                    </div>
                    <select
                      disabled={loadingDecision}
                      value={decisionField.decision}
                      onChange={(e) =>
                        setDecisionField((prev) => ({
                          ...prev,
                          decision: e.target.value,
                        }))
                      }
                      className="select select-bordered font-semibold"
                    >
                      <option value="ADMIS">Passe en classe supérieure</option>
                      <option value="RECALE">Reprends la classe</option>
                      <option value="RETROGRADE">
                        Retrogradé d'une classe
                      </option>
                    </select>
                  </label>
                  <textarea
                    disabled={loadingDecision}
                    className="textarea textarea-bordered w-full h-24 p-2 font-semibold"
                    placeholder="Commentaire"
                    value={decisionField.commentaire}
                    onChange={(e) =>
                      setDecisionField((prev) => ({
                        ...prev,
                        commentaire: e.target.value,
                      }))
                    }
                  ></textarea>
                </div>
                <div className="mt-3 w-1/2 flex items-center gap-3">
                  <button
                    disabled={loadingDecision}
                    className="bg-black flex items-center justify-center w-full h-9 font-semibold text-sm text-white rounded-lg hover:drop-shadow-md"
                    onClick={() => {
                      showInputDecisionFinal(false);
                      setDecisionField({
                        decision: "ADMIS",
                        commentaire: "",
                      });
                    }}
                  >
                    Annuler
                  </button>
                  <button
                    disabled={loadingDecision}
                    className="bg-primary flex items-center justify-center w-full h-9 font-semibold text-sm text-white rounded-lg hover:drop-shadow-md"
                    onClick={enregistrerDecisionConseil}
                  >
                    {!loadingDecision ? (
                      <span>
                        Enregistrer
                      </span>
                    ) : (
                      <ThreeDots
                        height="40"
                        width="40"
                        radius="9"
                        color="#fff"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </dialog>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="bg-green-500 text-white px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="bg-red-500 text-white px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </>
  );
};

export default BilanAnnuel;
