import React, { useEffect, useRef, useState } from "react";
import BaseLayout from "../../../layout/BaseLayout";
import iconeLien from "../../../assets/IconeSvg/ArrowRight.svg";
import IconeBilletArgent from "../../../assets/IconeSvg/money-bag.svg";
import IconeGirlie from "../../../assets/IconeSvg/hand-money.svg";
import IconeGroupUser from "../../../assets/IconeSvg/users-group-two-rounded.svg";
import { useNavigate, useParams } from "react-router-dom";
import { InfosEvenements } from "../../../services/evenements/EvenementRequests";
import { Tab, Tabs } from "@mui/material";
import EvenementParticipants from "../participant/EvenementParticipants";
import EvenementsDepenses from "../depense/EvenementsDepenses";
import EvenementPaiements from "../paiement/EvenementsPaiments";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const EvenementDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  console.log(id);

  const [loadingData, setLoadingData] = useState(false);
  const [infoEvent, setInfoEvent] = useState([]);

  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue); 
  };

  const EventDetails = () => {
    setLoadingData(true);
    InfosEvenements(id)
      .then((res) => {
        setLoadingData(false);
        console.log(res);
        setInfoEvent(res.data);
      })
      .catch((err) => {
        setLoadingData(false);
        console.log(err);
      });
  };

  console.log(infoEvent)
  useEffect(() => {
    // handleOpenMenu();
    EventDetails();
  }, []);

  return (
    <BaseLayout>
      <div className="w-full h-full flex flex-col">
        <div className="flex flex-col w-full h-full p-4">
          <div className="w-full flex flex-row items-center gap-2 ">
            <h1
              onClick={() => {
                navigate("/evenements");
              }}
              className="xl:text-[28px] lg:text-[28px] md:text-[28px] text-[16px] font-bold cursor-pointer"
            >
              Evènements
            </h1>
            <img src={iconeLien} className="w-4 xl:w-6 lg:w-6 md:w-6" alt="" />
            <h2 className="xl:text-[28px] lg:text-[28px] md:text-[28px] text-[16px] text-primary font-bold">
              {infoEvent.designation}
            </h2>
          </div>

          <div className="flex mt-4 md:flex-row xl:flex-row lg:flex-row flex-col justify-start items-center w-full gap-3">
            <div className="bg-slate-50 border-[1.5px] md:w-fit xl:w-fit lg:w-fit w-full rounded-lg flex flex-row gap-3 py-1 px-3">
              <div className="rounded-full h-8 w-8">
                <img src={IconeGroupUser} className="h-8 w-8" alt="" />
              </div>
              <div className="flex flex-col">
                <h3 className="text-[#9D9D9D]">Nombre de participant</h3>
                <h2 className="font-bold text-lg">
                  {infoEvent.participants && infoEvent.participants.length > 0
                    ? infoEvent.participants.length
                    : "--"}
                </h2>
              </div>
            </div>

            {/* <div className="bg-slate-50 border-[1.5px] md:w-fit xl:w-fit lg:w-fit w-full rounded-lg flex flex-row gap-3 py-1 px-3">
              <div className="rounded-full h-8 w-8">
                <img src={IconeBilletArgent} className="h-8 w-8" alt="" />
              </div>
              <div className="flex flex-col">
                <h3 className="text-[#9D9D9D]">Revenu générée</h3>
                <span className="font-bold text-lg">
                  {infoEvent.paiements && infoEvent.paiements.length > 0
                    ? infoEvent.paiements.length
                    : "--"}{" "}
                  FCFA
                </span>
              </div>
            </div> */}

            <div className="bg-slate-50 border-[1.5px] md:w-fit xl:w-fit lg:w-fit w-full rounded-lg flex flex-row gap-3  py-1 px-3">
              <div className="rounded-full h-8 w-8">
                <img src={IconeGirlie} className="h-8 w-8" alt="" />
              </div>
              <div className="flex flex-col">
                <h3 className="text-[#9D9D9D]">Sortie de caisse</h3>
                <span className="font-bold text-lg">
                  {infoEvent && infoEvent.budget
                    ? infoEvent.budget
                    : "--"} {" "}
                  FCFA
                </span>
              </div>
            </div>
          </div>

          <div className="mt-5 border-b">
            <Tabs
              value={valueTab}
              onChange={handleChangeTab}
              variant="scrollable"
              textColor="black"
              scrollButtons="auto"
              aria-label="basic tabs example"
            >
              <Tab
                label="Participants"
                {...a11yProps(0)}
                sx={{
                  textTransform: "capitalize",
                  fontSize: 14,
                  fontWeight: 700,
                }}
              />
              {/* <Tab
                label="Paiements"
                {...a11yProps(1)}
                sx={{
                  textTransform: "capitalize",
                  fontSize: 14,
                  fontWeight: 700,
                }}
              /> */}
              {/* <Tab
                label="Dépenses"
                {...a11yProps(2)}
                sx={{
                  textTransform: "capitalize",
                  fontSize: 14,
                  fontWeight: 700,
                }}
              /> */}
            </Tabs>
          </div>

          <div className="mt-8">
            <CustomTabPanel value={valueTab} index={0}>
              <EvenementParticipants idEvent={id} />
            </CustomTabPanel>
            {/* <CustomTabPanel value={valueTab} index={1}>
              <EvenementPaiements
                montantParticipant={infoEvent.montant_participation}
                idEvent={id}
              />
            </CustomTabPanel> */}
            <CustomTabPanel value={valueTab} index={1}>
              <EvenementsDepenses idEvent={id} />
            </CustomTabPanel>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default EvenementDetails;
