import { BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
});

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const ListeCompleteParticipantEvent = async (
  evenementID,
  page,
  size
) => {
  return await axios.get(
    BASE_URL(
      `evenements-animateur/${evenementID}/listeparticipant?page=${page}&size=${size}`
    ),
    {}
  );
};

// http://192.168.1.24:7871/api/v1/evenements-animateur/query?annneeScolaire=&param=

// (GET)

export const ListeCompleteEvent = async (
  evenementID = "",
  dateDebut = "",
  dateFin = "",
  mode = "",
  motif = "",
  page = 1,
  size = 10,
  param = " "
) => {
  return await axios.get(
    BASE_URL(
      `evenements-animateur/query?anneeScolaire=${
        currentAnnee ? currentAnnee._id : ""
      }&page=${page}&size=${size}&param=${param}`
    ),
    {}
  );
};

// http://192.168.1.24:7871/api/v1/evenements-animateur/{eventId}

// (GET) InfosEvenements

export const InfosEvenements = async (eventId) => {
  return await axios.get(BASE_URL(`evenements-animateur/${eventId}`), {});
};

// https://paroisseapi.aimedesire.com/api/v1/utilisateur/query?page=0&size=10&param=

export const enregistrerEvenementAnimateur = async (data) => {
  return await axios.post(BASE_URL("evenements-animateur"), data);
};

export const addParticipantToEvent = async (dataParticipant) => {
  return await axios.post(
    BASE_URL(`evenements-animateur/ajout-participant`),
    dataParticipant
  );
};

export const supprminerEvent = async (eventId) => {
  return await axios.delete(BASE_URL(`evenements-animateur/${eventId}`), {});
};

// http://192.168.1.24:7871/api/v1/evenements-animateur/retirer-participant (POST)
export const supprminerParticipantEvent = async (participantDeletes) => {
  return await axios.delete(
    BASE_URL(`evenements-animateur/retirer-participant`),
    participantDeletes
  );
};
