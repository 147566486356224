import React, { useEffect, useRef, useState } from "react";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import DatePicker, { registerLocale } from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import fr from "date-fns/locale/fr";
import iconeAppareilPhoto from "../../assets/IconeSvg/appareil_photo.svg";
import imageCongra from "../../assets/images/congra3.png";
import iconeRetour from "../../assets/IconeSvg/IconeRetour_arrow-left.svg";
import { ThreeDots } from "react-loader-spinner";
import { NavLink, useNavigate } from "react-router-dom";
import SnackbarAlert from "../../components/SnackbarAlert";
import { listeCompleteDesNiveauScolaire } from "../../services/parametrage/NiveauxScolaireRequest";
import { listeCompleteDesModePaiements } from "../../services/parametrage/ModePaiementRequest";
import { listeCompleteDesTarifications } from "../../services/parametrage/TarificationRequest";
import { recupereAnneeCatechumiqueEnCours } from "../../services/parametrage/AnneeScolaireRequest";
import { inscrireNouveauCatechumene } from "../../services/catechumene/CatechumeneRequest";

const NouveauCatechumene = () => {
  const navigate = useNavigate();
  registerLocale("fr", fr);
  const steps = [
    "Informations générales",
    "Parcours",
    "Vie chrétienne",
    "Informations parents",
    "Paiement",
  ];

  const [inscriptionLoading, setInscriptionLoading] = useState(false);

  const [anneeScolaireActive, setAnneeScolaireActive] = useState(null);
  const [listeNiveauScolaire, setListeNiveauScolaire] = useState([]);
  const [listeMoyenPaiement, setListeMoyenPaiement] = useState([]);
  const [listeNumeroPaiement, setListeNumeroPaiement] = useState([]);
  const [listeTarificationMotifPaiement, setListeTarificationMotifPaiement] =
    useState([]);

  const fileInputRef = useRef(null);
  const fileInputRefPaiement = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [preview, setPreview] = useState(null);
  const [filePhoto, setFilePhoto] = useState(null);

  const [anciensCatechu, setAnciensCatechu] = useState("NON");
  const [dejaBaptise, setDejaBaptise] = useState("NON");

  const [montantInscription, setMontantInscription] = useState("0");
  const [previewPaiment, setPreviewPaiment] = useState(null);
  const [filePaiement, setFilePaiement] = useState(null);

  const [globalStateInscription, setGlobalStateInscription] = useState({
    nom: "",
    prenoms: "",
    contact: "",
    secondContact: "",
    email: "",
    lieuNaissance: "",
    dateNaissance: "",
    quartierResidence: "",
    ecoleProfession: "",
    classeNiveau: "",
    ceb: "",
    numeroCarnetBapteme: "",
    dateCreationCarnetBapteme: "",
    bapteme: {
      date: "",
      paroisse: "",
      diocese: "",
    },
    premiereCommunion: {
      date: "",
      paroisse: "",
      diocese: "",
    },
    premiereAnnee: {
      date: "",
      paroisse: "",
      mvtGroupePriere: "",
    },
    deuxiemeAnnee: {
      date: "",
      paroisse: "",
      mvtGroupePriere: "",
    },
    troisiemeAnnee: {
      date: "",
      paroisse: "",
      mvtGroupePriere: "",
    },
    quatriemeAnnee: {
      date: "",
      paroisse: "",
      mvtGroupePriere: "",
    },
    cinquiemeAnnee: {
      date: "",
      paroisse: "",
      mvtGroupePriere: "",
    },
    pere: {
      nom: "",
      prenoms: "",
      religion: "",
      profession: "",
      contact: "",
      secondContact: "",
      email: "",
    },
    mere: {
      nom: "",
      prenoms: "",
      religion: "",
      profession: "",
      contact: "",
      secondContact: "",
      email: "",
    },
    situationMatrimoniale: "",
    autreSituationMatrimoniale: "",
    personneAContacter: {
      nomPrenoms: "",
      contact: "",
      secondContact: "",
    },
    numeroPaiement: "",
    referencePaiement: "",
    modePaiement: "",
    motifPaiement: "66a6e1f43d332a3f7d6de357",
    montant: "",
    commentaire: `Nouveau catéchumène a effectué son inscription à la date du ${new Date().toISOString()}.`,
    anneeScolaire: "",
  });

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = snackbarPosition;

  const years = range(1970, getYear(new Date("12/31/2017")) + 1, 1);
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  /**
   * ------------- EMAIL VALIDATION -----------
   */
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  /**
   * ------------- PHONE NUMBER VALIDATION -----------
   */
  const validatePhoneNumber = (number) => {
    const regex = /^\+225\d{10}$/;
    return regex.test(number);
  };

  /**
   * ------------- VALIDE PHONE AND EMAIL CATECHUMENE ------------
   */
  const validateFieldEmailAndPhoneNumber = () => {
    // Check which fields are filled
    const filledEmail = globalStateInscription.email.length > 0;
    const filledContact = globalStateInscription.contact.length > 0;
    const filledSecondContact = globalStateInscription.secondContact.length > 0;

    // Validate only the filled fields, they should have at least 10 characters
    const isValidEmail =
      !filledEmail || validateEmail(globalStateInscription.email);
    const isValidContact =
      !filledContact || validatePhoneNumber(globalStateInscription.contact);
    const isValidSecondContact =
      !filledSecondContact ||
      validatePhoneNumber(globalStateInscription.secondContact);

    // If all filled fields are valid, return true
    return isValidEmail && isValidContact && isValidSecondContact;
  };

  /**
   * ------------- VALIDE PHONE AND EMAIL PERE ------------
   */
  const validateFieldEmailAndPhoneNumberPere = () => {
    // Check which fields are filled
    const filledEmail = globalStateInscription.pere.email.length > 0;
    const filledContact = globalStateInscription.pere.contact.length > 0;
    const filledSecondContact =
      globalStateInscription.pere.secondContact.length > 0;

    // Validate only the filled fields, they should have at least 10 characters
    const isValidEmail =
      !filledEmail || validateEmail(globalStateInscription.pere.email);
    const isValidContact =
      !filledContact ||
      validatePhoneNumber(globalStateInscription.pere.contact);
    const isValidSecondContact =
      !filledSecondContact ||
      validatePhoneNumber(globalStateInscription.pere.secondContact);

    // If all filled fields are valid, return true
    return isValidEmail && isValidContact && isValidSecondContact;
  };

  /**
   * ------------- VALIDE PHONE AND EMAIL MERE ------------
   */
  const validateFieldEmailAndPhoneNumberMere = () => {
    // Check which fields are filled
    const filledEmail = globalStateInscription.mere.email.length > 0;
    const filledContact = globalStateInscription.mere.contact.length > 0;
    const filledSecondContact =
      globalStateInscription.mere.secondContact.length > 0;

    // Validate only the filled fields, they should have at least 10 characters
    const isValidEmail =
      !filledEmail || validateEmail(globalStateInscription.mere.email);
    const isValidContact =
      !filledContact ||
      validatePhoneNumber(globalStateInscription.mere.contact);
    const isValidSecondContact =
      !filledSecondContact ||
      validatePhoneNumber(globalStateInscription.mere.secondContact);

    // If all filled fields are valid, return true
    return isValidEmail && isValidContact && isValidSecondContact;
  };

  /**
   * ------------- VALIDE PHONE AND EMAIL TUTEUR ------------
   */
  const validateFieldEmailAndPhoneNumberTuteur = () => {
    // Check which fields are filled
    const filledContact =
      globalStateInscription.personneAContacter.contact.length > 0;
    const filledSecondContact =
      globalStateInscription.personneAContacter.secondContact.length > 0;

    // Validate only the filled fields, they should have at least 10 characters
    const isValidContact =
      !filledContact ||
      validatePhoneNumber(globalStateInscription.personneAContacter.contact);
    const isValidSecondContact =
      !filledSecondContact ||
      validatePhoneNumber(
        globalStateInscription.personneAContacter.secondContact
      );

    // If all filled fields are valid, return true
    return isValidContact && isValidSecondContact;
  };

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  // === REQUESTES NECESSAIRES ===
  const recupererAnneeScolaireActive = () => {
    recupereAnneeCatechumiqueEnCours()
      .then((res) => {
        setAnneeScolaireActive(res.data[0]);
        updateFieldGlobalState("anneeScolaire", res.data[0]._id);
      })
      .catch((err) => {
        console.log("ERREUR", err);
      });
  };

  const getListeNiveauScolaire = () => {
    listeCompleteDesNiveauScolaire()
      .then((res) => {
        setListeNiveauScolaire(res.data);
      })
      .catch((err) => {
        console.log("ERREUR", err);
      });
  };

  const getListeModeDePaiement = () => {
    listeCompleteDesModePaiements()
      .then((res) => {
        setListeMoyenPaiement(res.data);
        let liste = [];
        res.data.forEach((element) => {
          if (element.numeroPaiement && element.secondNumeroPaiement) {
            liste.push({
              moyen: element.designation,
              numero: element.numeroPaiement,
              secondNumero: element.secondNumeroPaiement,
            });
          } else if (element.numeroPaiement && !element.secondNumeroPaiement) {
            liste.push({
              moyen: element.designation,
              numero: element.numeroPaiement,
              secondNumero: "",
            });
          }
        });
        setListeNumeroPaiement(liste);
      })
      .catch((err) => {
        console.log("ERREUR", err);
      });
  };

  const getListeTarificationMotifPaiement = () => {
    listeCompleteDesTarifications(
      "66a6e1f43d332a3f7d6de357",
      anneeScolaireActive ? anneeScolaireActive._id : ""
    )
      .then((res) => {
        setListeTarificationMotifPaiement(res.data);
        updateFieldGlobalState(
          "montant",
          res.data[0].montant
        )
        setMontantInscription(res.data[0].montant);
      })
      .catch((err) => {
        console.log("ERREUR", err);
      });
  };

  // === CHANGEMENT DE STEP ===
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSkipped(newSkipped);
    }
  };

  // === UPLOAD PHOTO DE PROFIL ===
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFilePhoto(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // === UPLOAD PHOTO RECU PAIEMENT ===
  const handleClickPaiement = () => {
    fileInputRefPaiement.current.click();
  };

  const handleFileChangePaiement = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFilePaiement(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewPaiment(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const changeAncienneteCatechumene = (e) => {
    setAnciensCatechu(e.target.value);
  };

  const changeEtatBaptemeCatechumene = (e) => {
    setDejaBaptise(e.target.value);
  };

  const updateFieldGlobalState = (key, value) => {
    setGlobalStateInscription((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const updateFieldGlobalStateNestedObject = (keyParent, keyChild, value) => {
    setGlobalStateInscription((prev) => ({
      ...prev,
      [keyParent]: {
        ...prev[keyParent],
        [keyChild]: value,
      },
    }));
  };

  /**
   * FUNCTION D'INSCRIPTION CATECHUMENE
   */
  const inscriptionNouveauCatechumene = () => {
    document.getElementById("modal_spinner").showModal();
    setInscriptionLoading(true);

    const dataCatechumene = new FormData();
    dataCatechumene.append("photo", filePhoto);
    dataCatechumene.append("photoRecuInscription", filePaiement);

    Object.keys(globalStateInscription).forEach((key) => {
      if (
        typeof globalStateInscription[key] === "object" &&
        !Array.isArray(globalStateInscription[key])
      ) {
        dataCatechumene.append(
          key,
          JSON.stringify(globalStateInscription[key])
        );
      } else {
        dataCatechumene.append(key, globalStateInscription[key]);
      }
    });

    inscrireNouveauCatechumene(dataCatechumene)
      .then((res) => {
        setInscriptionLoading(false);
        document.getElementById("modal_spinner").close();
        navigate("/felicitation_inscription");
      })
      .catch((err) => {
        setInscriptionLoading(false);
        document.getElementById("modal_spinner").close();
        if (err && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite durant votre inscription");
          setSnackbarOpenError(true);
        }
        console.log("ERREUR", err);
      });
  };

  const validateField = (fields, nestedObject = null) => {
    const stateToValidate = nestedObject
      ? globalStateInscription[nestedObject]
      : globalStateInscription;
    for (let field of fields) {
      if (!stateToValidate[field]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    recupererAnneeScolaireActive();
    getListeNiveauScolaire();
    getListeModeDePaiement();
    getListeTarificationMotifPaiement();
  }, []);

  return (
    <div className="pb-16 min-h-screen">
      <div className="w-full h-44 bg-white border-b drop-shadow-sm">
        <div className="pt-2 w-full lg:w-9/12 mx-auto flex items-center justify-between">
          <NavLink to="/inscription-catechumene">
            <button className="flex items-center text-xs text-primary font-bold">
              <img src={iconeRetour} className="w-8 sm:w-10" alt="" /> Retour
            </button>
          </NavLink>
          <p className="font-bold text-xs text-gray-500 pr-5">
            Année catéchumique:{" "}
            {anneeScolaireActive
              ? `${anneeScolaireActive.anneeDebut} - ${anneeScolaireActive.anneeFin}`
              : "-"}
          </p>
        </div>
        <h1 className="px-4 mt-8 text-black text-center text-xl sm:text-2xl md:text-3xl font-extrabold">
          Formulaire d'inscription catéchumène
        </h1>
      </div>

      <div className="w-11/12 lg:w-9/12 xl:w-8/12 mx-auto flex flex-col md:flex-row gap-x-0 md:gap-x-4 h-full">
        {/* DESKTOP STEPPER */}
        <div className="hidden md:block mt-5 w-72 h-fit rounded-lg p-4 border bg-white">
          <Stepper
            orientation="vertical"
            activeStep={activeStep}
            style={{ height: 300, width: "100%" }}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step
                  key={label}
                  {...stepProps}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "#059669",
                    },
                    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                    {
                      color: "blue",
                    },
                    "& .MuiStepLabel-root .Mui-active": {
                      color: "#A70606",
                    },
                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                      fill: "#ffff",
                    },
                  }}
                >
                  <StepLabel {...labelProps}>
                    <h2 className="text-base font-bold truncate">{label}</h2>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        {/* MOBILE STEPPER */}
        <div className="mt-7 overflow-x-auto md:hidden">
          <Stepper
            orientation="horizontal"
            style={{ height: "100%", width: "100%" }}
            activeStep={activeStep}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step
                  key={label}
                  {...stepProps}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "#059669",
                    },
                    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                    {
                      color: "blue",
                    },
                    "& .MuiStepLabel-root .Mui-active": {
                      color: "#A70606",
                    },
                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                      fill: "#ffff",
                    },
                  }}
                >
                  <StepLabel {...labelProps}></StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>

        <div className="w-full">
          <div className="">
            <div className="mt-10">
              <h2 className="font-extrabold text-primary text-lg uppercase">
                {activeStep === 0
                  ? "Informations générales (page 1)"
                  : activeStep === 1
                    ? "Parcours (page 2)"
                    : activeStep === 2
                      ? "Vie chrétienne (page 3)"
                      : activeStep === 3
                        ? "Informations parents (page 4)"
                        : activeStep === 4
                          ? "Paiement (page 5)"
                          : activeStep === 5}
              </h2>
            </div>

            <div className="mt-5">
              {/* INFORMATION GENERALE */}
              {activeStep === 0 ? (
                <div className="w-full bg-white border px-2 rounded-xl overflow-x-auto">
                  <div className="w-full pb-5">
                    <div className="my-6">
                      <div
                        onClick={handleClick}
                        className="w-24 h-24 mx-auto bg-gray-200 border rounded-full cursor-pointer"
                      >
                        {preview ? (
                          <div className="relative w-24 h-24">
                            <div className="absolute bg-black/20 w-24 h-24 rounded-full flex justify-center">
                              <img
                                src={iconeAppareilPhoto}
                                className="w-8"
                                alt=""
                              />
                            </div>
                            <img
                              src={preview}
                              alt="Preview"
                              className="w-full h-full object-contain rounded-full"
                            />
                          </div>
                        ) : preview === null ? (
                          <div className="relative w-24 w-24">
                            <div className="absolute bg-gray-100 w-24 h-24 rounded-full flex justify-center">
                              <img
                                src={iconeAppareilPhoto}
                                className="w-8"
                                alt=""
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="w-full h-full rounded-full flex justify-center">
                            <img
                              src={iconeAppareilPhoto}
                              className="w-8"
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <p className="mt-2 text-center text-sm text-gray-500 font-bold">
                        Photo d'identité
                      </p>
                    </div>
                    <div className="flex flex-row px-2 gap-4 w-full">
                      <label className="form-control w-full">
                        <div className="label">
                          <span className="label-text font-semibold">Nom</span>
                        </div>
                        <input
                          type="text"
                          value={globalStateInscription.nom}
                          onChange={(e) =>
                            updateFieldGlobalState("nom", e.target.value)
                          }
                          placeholder="Votre nom"
                          className="input border input-bordered w-full"
                        />
                      </label>
                      <label className="form-control w-full ">
                        <div className="label">
                          <span className="label-text font-semibold">
                            Prénoms
                          </span>
                        </div>
                        <input
                          type="text"
                          value={globalStateInscription.prenoms}
                          onChange={(e) =>
                            updateFieldGlobalState("prenoms", e.target.value)
                          }
                          placeholder="Vos prénoms"
                          className="input border input-bordered w-full"
                        />
                      </label>
                    </div>

                    <div className="flex items-end px-2 gap-4 w-full">
                      <label className="form-control w-full">
                        <div className="label">
                          <span className="label-text font-semibold">
                            Date de naissance
                          </span>
                        </div>
                        <DatePicker
                          locale="fr"
                          dateFormat="dd/MM/yyyy"
                          closeOnScroll={true}
                          selected={globalStateInscription.dateNaissance}
                          onChange={(date) =>
                            updateFieldGlobalState("dateNaissance", date)
                          }
                          className="input w-full"
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                className="w-8 h-8 flex items-center justify-center bg-white rounded border"
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                              >
                                {"<"}
                              </button>

                              <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <button
                                className="w-8 h-8 flex items-center justify-center bg-white rounded border"
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                              >
                                {">"}
                              </button>
                            </div>
                          )}
                        />
                      </label>
                      <label className="form-control w-full">
                        <div className="label">
                          <span className="label-text font-semibold">
                            Lieu de naissance
                          </span>
                        </div>
                        <input
                          type="text"
                          value={globalStateInscription.lieuNaissance}
                          onChange={(e) =>
                            updateFieldGlobalState(
                              "lieuNaissance",
                              e.target.value
                            )
                          }
                          placeholder="Votre lieu de naissance"
                          className="input border input-bordered w-full "
                        />
                      </label>
                    </div>

                    <div className="flex px-2 gap-4">
                      <label className="form-control w-full ">
                        <div className="label">
                          <span className="label-text font-semibold">
                            Quartier
                          </span>
                        </div>
                        <input
                          type="text"
                          value={globalStateInscription.quartierResidence}
                          onChange={(e) =>
                            updateFieldGlobalState(
                              "quartierResidence",
                              e.target.value
                            )
                          }
                          placeholder="Votre quartier"
                          className="input border input-bordered w-full "
                        />
                      </label>
                      <label className="form-control w-full ">
                        <div className="label">
                          <span className="label-text font-semibold">CEB</span>
                        </div>
                        <input
                          type="text"
                          value={globalStateInscription.ceb}
                          onChange={(e) =>
                            updateFieldGlobalState("ceb", e.target.value)
                          }
                          placeholder="Nom de votre CEB"
                          className="input border input-bordered w-full "
                        />
                      </label>
                    </div>

                    <div className="flex  px-2 gap-4">
                      <label className="form-control w-full ">
                        <div className="label">
                          <span className="label-text font-semibold">
                            Ecole / Profession
                          </span>
                        </div>
                        <input
                          type="text"
                          value={globalStateInscription.ecoleProfession}
                          onChange={(e) =>
                            updateFieldGlobalState(
                              "ecoleProfession",
                              e.target.value
                            )
                          }
                          placeholder="Nom d'école ou profession"
                          className="input border input-bordered w-full "
                        />
                      </label>
                      <label className="form-control w-full ">
                        <div className="label">
                          <span className="label-text font-semibold">
                            Niveau scolaire
                          </span>
                        </div>
                        <select
                          value={globalStateInscription.classeNiveau}
                          onChange={(e) =>
                            updateFieldGlobalState(
                              "classeNiveau",
                              e.target.value
                            )
                          }
                          className="select border input-bordered w-full "
                        >
                          <option disabled value="">
                            Selectionner un niveau
                          </option>
                          {listeNiveauScolaire &&
                            listeNiveauScolaire.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.designation}
                              </option>
                            ))}
                        </select>
                      </label>
                    </div>

                    <div className="flex px-2 gap-4">
                      <label className="form-control w-full ">
                        <div className="label">
                          <span className="label-text font-semibold">
                            Contact
                          </span>
                        </div>
                        <input
                          type="tel"
                          value={globalStateInscription.contact}
                          onChange={(e) =>
                            updateFieldGlobalState("contact", e.target.value)
                          }
                          placeholder="+2250700000000"
                          className="input border input-bordered w-full "
                        />
                        {globalStateInscription.contact.length > 0 &&
                          !validatePhoneNumber(
                            globalStateInscription.contact
                          ) && (
                            <span className="mt-1 label-text text-xs text-red-600 font-medium">
                              Entrer un numéro de tépéhone correct commençant
                              par +225
                            </span>
                          )}
                      </label>
                      <label className="form-control w-full ">
                        <div className="label">
                          <span className="label-text font-semibold">
                            Second contact
                          </span>
                        </div>
                        <input
                          type="text"
                          value={globalStateInscription.secondContact}
                          onChange={(e) =>
                            updateFieldGlobalState(
                              "secondContact",
                              e.target.value
                            )
                          }
                          placeholder="+2250700000000"
                          className="input border input-bordered w-full "
                        />
                        {globalStateInscription.secondContact.length > 0 &&
                          !validatePhoneNumber(
                            globalStateInscription.secondContact
                          ) && (
                            <span className="mt-1 label-text text-xs text-red-600 font-medium">
                              Entrer un numéro de tépéhone correct commençant
                              par +225
                            </span>
                          )}
                      </label>
                    </div>
                    <label className="form-control w-full mb-6 px-2 ">
                      <div className="label">
                        <span className="label-text font-semibold">Email</span>
                      </div>
                      <input
                        type="email"
                        value={globalStateInscription.email}
                        onChange={(e) =>
                          updateFieldGlobalState("email", e.target.value)
                        }
                        placeholder="exemple@gmail.com"
                        className="input border input-bordered w-full"
                      />
                      {globalStateInscription.email.length > 0 &&
                        !validateEmail(globalStateInscription.email) && (
                          <span className="mt-1 label-text text-xs text-red-600 font-medium">
                            Entrer une adresse email correcte
                          </span>
                        )}
                    </label>
                  </div>
                </div>
              ) : null}

              {/* PARCOURS */}
              {activeStep === 1 ? (
                <div className="w-full h-full bg-white border px-2 rounded-xl overflow-x-auto">
                  <div className="">
                    <div className="flex justify-start items-center px-2 py-4">
                      <h3 className="font-semibold">
                        Aviez-vous déjà fait la catéchèse ?
                      </h3>

                      <div className="form-control ml-2">
                        <label className="label cursor-pointer gap-2">
                          <span className="label-text font-bold">Oui</span>
                          <input
                            type="radio"
                            className="radio checked:bg-[#A70606]"
                            value="OUI"
                            checked={anciensCatechu === "OUI"}
                            onChange={(e) => changeAncienneteCatechumene(e)}
                            name="parcours"
                          />
                        </label>
                      </div>

                      <div className="form-control">
                        <label className="label cursor-pointer gap-2">
                          <span className="label-text font-bold">Non</span>
                          <input
                            type="radio"
                            className="radio checked:bg-[#A70606]"
                            value="NON"
                            checked={anciensCatechu === "NON"}
                            onChange={(e) => changeAncienneteCatechumene(e)}
                            name="parcours"
                          />
                        </label>
                      </div>
                    </div>

                    {anciensCatechu === "OUI" ? (
                      <>
                        <div className="mt-4 flex flex-col w-full px-2">
                          <div className="">
                            <span className="uppercase text-gray-500 label-text font-semibold">
                              Première année
                            </span>
                            <hr className="mt-2 w-full border-gray-400 border-dashed" />
                          </div>

                          <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 items-end gap-3 w-full">
                            <label className="form-control w-full">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Année
                                </span>
                              </div>
                              <input
                                type="text"
                                placeholder="2000-2001"
                                value={
                                  globalStateInscription.premiereAnnee.date
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "premiereAnnee",
                                    "date",
                                    e.target.value
                                  )
                                }
                                className="input border input-bordered w-full "
                              />
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Paroisse
                                </span>
                              </div>
                              <input
                                type="text"
                                value={
                                  globalStateInscription.premiereAnnee.paroisse
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "premiereAnnee",
                                    "paroisse",
                                    e.target.value
                                  )
                                }
                                placeholder="Saint jean cocody"
                                className="input border input-bordered w-full "
                              />
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Mvt. / Groupe de prière
                                </span>
                              </div>
                              <input
                                type="text"
                                value={
                                  globalStateInscription.premiereAnnee
                                    .mvtGroupePriere
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "premiereAnnee",
                                    "mvtGroupePriere",
                                    e.target.value
                                  )
                                }
                                placeholder="Servant de messe"
                                className="input border input-bordered w-full "
                              />
                            </label>
                          </div>
                        </div>

                        <div className="mt-4 flex flex-col w-full px-2">
                          <div className="">
                            <span className="uppercase text-gray-500 label-text font-semibold">
                              Deuxieme année
                            </span>
                            <hr className="mt-2 w-full border-gray-400 border-dashed" />
                          </div>
                          <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 items-end gap-3 w-full">
                            <label className="form-control w-full">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Année
                                </span>
                              </div>
                              <input
                                type="text"
                                placeholder="2000-2001"
                                value={
                                  globalStateInscription.deuxiemeAnnee.date
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "deuxiemeAnnee",
                                    "date",
                                    e.target.value
                                  )
                                }
                                className="input border input-bordered w-full "
                              />
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Paroisse
                                </span>
                              </div>
                              <input
                                type="text"
                                value={
                                  globalStateInscription.deuxiemeAnnee.paroisse
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "deuxiemeAnnee",
                                    "paroisse",
                                    e.target.value
                                  )
                                }
                                placeholder="Saint jean cocody"
                                className="input border input-bordered w-full "
                              />
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Mvt. / Groupe de prière
                                </span>
                              </div>
                              <input
                                type="text"
                                value={
                                  globalStateInscription.deuxiemeAnnee
                                    .mvtGroupePriere
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "deuxiemeAnnee",
                                    "mvtGroupePriere",
                                    e.target.value
                                  )
                                }
                                placeholder="Servant de messe"
                                className="input border input-bordered w-full "
                              />
                            </label>
                          </div>
                        </div>

                        <div className="mt-4 flex flex-col w-full px-2">
                          <div className="">
                            <span className="uppercase text-gray-500 label-text font-semibold">
                              Troisieme année
                            </span>
                            <hr className="mt-2 w-full border-gray-400 border-dashed" />
                          </div>
                          <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 items-end gap-3 w-full">
                            <label className="form-control w-full">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Année
                                </span>
                              </div>
                              <input
                                type="text"
                                placeholder="2000-2001"
                                value={
                                  globalStateInscription.troisiemeAnnee.date
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "troisiemeAnnee",
                                    "date",
                                    e.target.value
                                  )
                                }
                                className="input border input-bordered w-full "
                              />
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Paroisse
                                </span>
                              </div>
                              <input
                                type="text"
                                value={
                                  globalStateInscription.troisiemeAnnee.paroisse
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "troisiemeAnnee",
                                    "paroisse",
                                    e.target.value
                                  )
                                }
                                placeholder="Saint jean cocody"
                                className="input border input-bordered w-full "
                              />
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Mvt. / Groupe de prière
                                </span>
                              </div>
                              <input
                                type="text"
                                value={
                                  globalStateInscription.troisiemeAnnee
                                    .mvtGroupePriere
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "troisiemeAnnee",
                                    "mvtGroupePriere",
                                    e.target.value
                                  )
                                }
                                placeholder="Servant de messe"
                                className="input border input-bordered w-full "
                              />
                            </label>
                          </div>
                        </div>
                        <div className="mt-4 flex flex-col w-full px-2">
                          <div className="">
                            <span className="uppercase text-gray-500 label-text font-semibold">
                              Quartrieme année
                            </span>
                            <hr className="mt-2 w-full border-gray-400 border-dashed" />
                          </div>
                          <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 items-end gap-3 w-full">
                            <label className="form-control w-full">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Année
                                </span>
                              </div>
                              <input
                                type="text"
                                placeholder="2000-2001"
                                value={
                                  globalStateInscription.quatriemeAnnee.date
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "quatriemeAnnee",
                                    "date",
                                    e.target.value
                                  )
                                }
                                className="input border input-bordered w-full "
                              />
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Paroisse
                                </span>
                              </div>
                              <input
                                type="text"
                                value={
                                  globalStateInscription.quatriemeAnnee.paroisse
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "quatriemeAnnee",
                                    "paroisse",
                                    e.target.value
                                  )
                                }
                                placeholder="Saint jean cocody"
                                className="input border input-bordered w-full "
                              />
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Mvt. / Groupe de prière
                                </span>
                              </div>
                              <input
                                type="text"
                                value={
                                  globalStateInscription.quatriemeAnnee
                                    .mvtGroupePriere
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "quatriemeAnnee",
                                    "mvtGroupePriere",
                                    e.target.value
                                  )
                                }
                                placeholder="Servant de messe"
                                className="input border input-bordered w-full "
                              />
                            </label>
                          </div>
                        </div>

                        <div className="mt-4 flex flex-col w-full px-2 pb-6">
                          <div className="">
                            <span className="uppercase text-gray-500 label-text font-semibold">
                              Cinquieme année
                            </span>
                            <hr className="mt-2 w-full border-gray-400 border-dashed" />
                          </div>
                          <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 items-end gap-3 w-full">
                            <label className="form-control w-full">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Année
                                </span>
                              </div>
                              <input
                                type="text"
                                placeholder="2000-2001"
                                value={
                                  globalStateInscription.cinquiemeAnnee.date
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "cinquiemeAnnee",
                                    "date",
                                    e.target.value
                                  )
                                }
                                className="input border input-bordered w-full "
                              />
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Paroisse
                                </span>
                              </div>
                              <input
                                type="text"
                                value={
                                  globalStateInscription.cinquiemeAnnee.paroisse
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "cinquiemeAnnee",
                                    "paroisse",
                                    e.target.value
                                  )
                                }
                                placeholder="Saint jean cocody"
                                className="input border input-bordered w-full "
                              />
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Mvt. / Groupe de prière
                                </span>
                              </div>
                              <input
                                type="text"
                                value={
                                  globalStateInscription.cinquiemeAnnee
                                    .mvtGroupePriere
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "cinquiemeAnnee",
                                    "mvtGroupePriere",
                                    e.target.value
                                  )
                                }
                                placeholder="Servant de messe"
                                className="input border input-bordered w-full "
                              />
                            </label>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {/* VIE CHRETIENNE */}
              {activeStep === 2 ? (
                <div className="w-full h-full bg-white border px-2 rounded-xl overflow-x-auto">
                  <div className="">
                    <div className=" flex justify-start items-center px-2 py-4">
                      <h3 className="font-semibold">Etes vous baptisé(e) ?</h3>
                      <div className="form-control ml-2">
                        <label className="label cursor-pointer gap-2">
                          <span className="label-text font-bold">Oui</span>
                          <input
                            type="radio"
                            value="OUI"
                            checked={dejaBaptise === "OUI"}
                            onChange={(e) => changeEtatBaptemeCatechumene(e)}
                            className="radio checked:bg-[#A70606]"
                            name="bapteme"
                          />
                        </label>
                      </div>
                      <div className="form-control">
                        <label className="label cursor-pointer gap-2">
                          <span className="label-text font-bold">Non</span>
                          <input
                            type="radio"
                            name="bapteme"
                            value="NON"
                            checked={dejaBaptise === "NON"}
                            onChange={(e) => changeEtatBaptemeCatechumene(e)}
                            className="radio checked:bg-[#A70606]"
                          />
                        </label>
                      </div>
                    </div>

                    {dejaBaptise === "OUI" ? (
                      <>
                        <div className="flex flex-col xsm:flex-row items-end px-1 gap-x-4 w-full">
                          <label className="form-control w-full">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Numéro du carnet de baptême
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.numeroCarnetBapteme}
                              onChange={(e) =>
                                updateFieldGlobalState(
                                  "numeroCarnetBapteme",
                                  e.target.value
                                )
                              }
                              placeholder="27653765"
                              className="input border input-bordered w-full"
                            />
                          </label>
                          <label className="form-control w-full">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Date création du carnet
                              </span>
                            </div>
                            <DatePicker
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                              closeOnScroll={true}
                              selected={
                                globalStateInscription.dateCreationCarnetBapteme
                              }
                              onChange={(date) =>
                                updateFieldGlobalState(
                                  "dateCreationCarnetBapteme",
                                  date
                                )
                              }
                              className="input w-full"
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    className="w-8 h-8 flex items-center justify-center bg-white rounded border"
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                  >
                                    {"<"}
                                  </button>

                                  <select
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) =>
                                      changeMonth(months.indexOf(value))
                                    }
                                  >
                                    {months.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  <select
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {years.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  <button
                                    className="w-8 h-8 flex items-center justify-center bg-white rounded border"
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                  >
                                    {">"}
                                  </button>
                                </div>
                              )}
                            />
                          </label>
                        </div>

                        <div className="w-full px-1 pb-6">
                          <div className="mt-5">
                            <span className="uppercase text-gray-500 label-text font-semibold">
                              Baptême
                            </span>
                            <hr className="mt-2 w-full border-gray-400 border-dashed" />
                          </div>
                          <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-2 items-end gap-3 w-full">
                            <label className="form-control w-full">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Date
                                </span>
                              </div>
                              <DatePicker
                                locale="fr"
                                dateFormat="dd/MM/yyyy"
                                closeOnScroll={true}
                                selected={globalStateInscription.bapteme.date}
                                onChange={(date) =>
                                  updateFieldGlobalStateNestedObject(
                                    "bapteme",
                                    "date",
                                    date
                                  )
                                }
                                className="input w-full"
                                renderCustomHeader={({
                                  date,
                                  changeYear,
                                  changeMonth,
                                  decreaseMonth,
                                  increaseMonth,
                                  prevMonthButtonDisabled,
                                  nextMonthButtonDisabled,
                                }) => (
                                  <div
                                    style={{
                                      margin: 10,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <button
                                      className="w-8 h-8 flex items-center justify-center bg-white rounded border"
                                      onClick={decreaseMonth}
                                      disabled={prevMonthButtonDisabled}
                                    >
                                      {"<"}
                                    </button>

                                    <select
                                      value={months[getMonth(date)]}
                                      onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                      }
                                    >
                                      {months.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <select
                                      value={getYear(date)}
                                      onChange={({ target: { value } }) =>
                                        changeYear(value)
                                      }
                                    >
                                      {years.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <button
                                      className="w-8 h-8 flex items-center justify-center bg-white rounded border"
                                      onClick={increaseMonth}
                                      disabled={nextMonthButtonDisabled}
                                    >
                                      {">"}
                                    </button>
                                  </div>
                                )}
                              />
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Paroisse
                                </span>
                              </div>
                              <input
                                type="text"
                                value={globalStateInscription.bapteme.paroisse}
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "bapteme",
                                    "paroisse",
                                    e.target.value
                                  )
                                }
                                placeholder="Saint jean cocody"
                                className="input border input-bordered w-full "
                              />
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Diocèce
                                </span>
                              </div>
                              <input
                                type="text"
                                value={globalStateInscription.bapteme.diocese}
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "bapteme",
                                    "diocese",
                                    e.target.value
                                  )
                                }
                                placeholder="Saint jean cocody"
                                className="input border input-bordered w-full "
                              />
                            </label>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {dejaBaptise === "OUI" &&
                      anciensCatechu === "OUI" &&
                      validateField(
                        ["date", "paroisse", "mvtGroupePriere"],
                        "troisiemeAnnee"
                      ) ? (
                      <div className="px-1 pb-6">
                        <div className="">
                          <span className="uppercase text-gray-500 label-text font-semibold">
                            Première communion
                          </span>
                          <hr className="mt-2 w-full border-gray-400 border-dashed" />
                        </div>
                        <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 items-end gap-3 w-full">
                          <label className="form-control w-full">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Date
                              </span>
                            </div>
                            <DatePicker
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                              closeOnScroll={true}
                              selected={
                                globalStateInscription.premiereCommunion.date
                              }
                              onChange={(date) =>
                                updateFieldGlobalStateNestedObject(
                                  "premiereCommunion",
                                  "date",
                                  date
                                )
                              }
                              className="input w-full"
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    className="w-8 h-8 flex items-center justify-center bg-white rounded border"
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                  >
                                    {"<"}
                                  </button>

                                  <select
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) =>
                                      changeMonth(months.indexOf(value))
                                    }
                                  >
                                    {months.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  <select
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {years.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  <button
                                    className="w-8 h-8 flex items-center justify-center bg-white rounded border"
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                  >
                                    {">"}
                                  </button>
                                </div>
                              )}
                            />
                          </label>
                          <label className="form-control w-full ">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Paroisse
                              </span>
                            </div>
                            <input
                              type="text"
                              value={
                                globalStateInscription.premiereCommunion
                                  .paroisse
                              }
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "premiereCommunion",
                                  "paroisse",
                                  e.target.value
                                )
                              }
                              placeholder="Saint jean cocody"
                              className="input border input-bordered w-full "
                            />
                          </label>
                          <label className="form-control w-full ">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Diocèce
                              </span>
                            </div>
                            <input
                              type="text"
                              value={
                                globalStateInscription.premiereCommunion.diocese
                              }
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "premiereCommunion",
                                  "diocese",
                                  e.target.value
                                )
                              }
                              placeholder="Saint jean cocody"
                              className="input border input-bordered w-full "
                            />
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {/* INFORMATION PARENTS */}
              {activeStep === 3 ? (
                <div className="w-full h-full bg-white border px-2 rounded-xl overflow-x-auto">
                  <div className="">
                    <div className="">
                      <div className="my-3 flex flex-col w-full px-1">
                        <div className="mt-5">
                          <span className="uppercase text-gray-500 label-text font-semibold">
                            Père / Tuteur
                          </span>
                          <hr className="mt-2 w-full border-gray-400 border-dashed" />
                        </div>

                        <div className="mt-3 flex flex-row gap-4 w-full">
                          <label className="form-control w-full">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Nom
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.pere.nom}
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "pere",
                                  "nom",
                                  e.target.value
                                )
                              }
                              placeholder="Votre Nom"
                              className="input border input-bordered w-full "
                            />
                          </label>
                          <label className="form-control w-full ">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Prénoms
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.pere.prenoms}
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "pere",
                                  "prenoms",
                                  e.target.value
                                )
                              }
                              placeholder="Vos prénoms"
                              className="input border input-bordered w-full "
                            />
                          </label>
                        </div>
                        <div className="flex flex-row gap-4 w-full">
                          <label className="form-control w-full">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Profession
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.pere.profession}
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "pere",
                                  "profession",
                                  e.target.value
                                )
                              }
                              placeholder="Votre profession"
                              className="input border input-bordered w-full "
                            />
                          </label>
                          <label className="form-control w-full ">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Réligion
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.pere.religion}
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "pere",
                                  "religion",
                                  e.target.value
                                )
                              }
                              placeholder="Catholique"
                              className="input border input-bordered w-full "
                            />
                          </label>
                        </div>
                        <div className="flex flex-row gap-4 w-full">
                          <label className="form-control w-full">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Contact
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.pere.contact}
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "pere",
                                  "contact",
                                  e.target.value
                                )
                              }
                              placeholder="+225070000000"
                              className="input border input-bordered w-full "
                            />
                            {globalStateInscription.pere.contact.length > 0 &&
                              !validatePhoneNumber(
                                globalStateInscription.pere.contact
                              ) && (
                                <span className="mt-1 label-text text-xs text-red-600 font-medium">
                                  Entrer un numéro de tépéhone correct
                                  commençant par +225
                                </span>
                              )}
                          </label>
                          <label className="form-control w-full">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Second contact
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.pere.secondContact}
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "pere",
                                  "secondContact",
                                  e.target.value
                                )
                              }
                              placeholder="+225********"
                              className="input border input-bordered w-full "
                            />
                            {globalStateInscription.pere.secondContact.length >
                              0 &&
                              !validatePhoneNumber(
                                globalStateInscription.pere.secondContact
                              ) && (
                                <span className="mt-1 label-text text-xs text-red-600 font-medium">
                                  Entrer un numéro de tépéhone correct
                                  commençant par +225
                                </span>
                              )}
                          </label>
                        </div>
                        <label className="form-control w-full">
                          <div className="label">
                            <span className="label-text font-semibold">
                              Email
                            </span>
                          </div>
                          <input
                            type="email"
                            value={globalStateInscription.pere.email}
                            onChange={(e) =>
                              updateFieldGlobalStateNestedObject(
                                "pere",
                                "email",
                                e.target.value
                              )
                            }
                            placeholder="Vos prénoms"
                            className="input border input-bordered w-full "
                          />
                          {globalStateInscription.pere.email.length > 0 &&
                            !validateEmail(
                              globalStateInscription.pere.email
                            ) && (
                              <span className="mt-1 label-text text-xs text-red-600 font-medium">
                                Entrer une adresse email correcte
                              </span>
                            )}
                        </label>
                      </div>

                      <div className="mt-3 flex flex-col w-full px-1">
                        <div className="mt-5">
                          <span className="uppercase text-gray-500 label-text font-semibold">
                            Mère / Tutrice
                          </span>
                          <hr className="mt-2 w-full border-gray-400 border-dashed" />
                        </div>
                        <div className="mt-3 flex flex-row gap-4 w-full">
                          <label className="form-control w-full">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Nom
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.mere.nom}
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "mere",
                                  "nom",
                                  e.target.value
                                )
                              }
                              placeholder="Votre Nom"
                              className="input border input-bordered w-full "
                            />
                          </label>
                          <label className="form-control w-full ">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Prénoms
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.mere.prenoms}
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "mere",
                                  "prenoms",
                                  e.target.value
                                )
                              }
                              placeholder="Vos prénoms"
                              className="input border input-bordered w-full "
                            />
                          </label>
                        </div>
                        <div className="flex flex-row gap-4 w-full">
                          <label className="form-control w-full">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Profession
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.mere.profession}
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "mere",
                                  "profession",
                                  e.target.value
                                )
                              }
                              placeholder="Votre profession"
                              className="input border input-bordered w-full "
                            />
                          </label>
                          <label className="form-control w-full ">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Réligion
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.mere.religion}
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "mere",
                                  "religion",
                                  e.target.value
                                )
                              }
                              placeholder="Catholique"
                              className="input border input-bordered w-full "
                            />
                          </label>
                        </div>
                        <div className="flex flex-row gap-4 w-full">
                          <label className="form-control w-full">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Contact
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.mere.contact}
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "mere",
                                  "contact",
                                  e.target.value
                                )
                              }
                              placeholder="+225********"
                              className="input border input-bordered w-full "
                            />
                            {globalStateInscription.mere.contact.length > 0 &&
                              !validatePhoneNumber(
                                globalStateInscription.mere.contact
                              ) && (
                                <span className="mt-1 label-text text-xs text-red-600 font-medium">
                                  Entrer un numéro de tépéhone correct
                                  commençant par +225
                                </span>
                              )}
                          </label>
                          <label className="form-control w-full">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Second contact
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.mere.secondContact}
                              onChange={(e) =>
                                updateFieldGlobalStateNestedObject(
                                  "mere",
                                  "secondContact",
                                  e.target.value
                                )
                              }
                              placeholder="+2250700000000"
                              className="input border input-bordered w-full "
                            />
                            {globalStateInscription.mere.secondContact.length >
                              0 &&
                              !validatePhoneNumber(
                                globalStateInscription.mere.secondContact
                              ) && (
                                <span className="mt-1 label-text text-xs text-red-600 font-medium">
                                  Entrer un numéro de tépéhone correct
                                  commençant par +225
                                </span>
                              )}
                          </label>
                        </div>
                        <label className="form-control w-full">
                          <div className="label">
                            <span className="label-text font-semibold">
                              Email
                            </span>
                          </div>
                          <input
                            type="email"
                            value={globalStateInscription.mere.email}
                            onChange={(e) =>
                              updateFieldGlobalStateNestedObject(
                                "mere",
                                "email",
                                e.target.value
                              )
                            }
                            placeholder="Vos prénoms"
                            className="input border input-bordered w-full "
                          />
                          {globalStateInscription.mere.email.length > 0 &&
                            !validateEmail(
                              globalStateInscription.mere.email
                            ) && (
                              <span className="mt-1 label-text text-xs text-red-600 font-medium">
                                Entrer une adresse email correcte
                              </span>
                            )}
                        </label>

                        <div className="flex flex-col justify-start mt-[2rem] w-full">
                          <h3 className="font-bold uppercase">
                            Situation matrimoniale des parents ?
                          </h3>
                          <div className="flex items-center">
                            <div className="form-control">
                              <label className="label cursor-pointer gap-2">
                                <span className="label-text text-base font-medium">
                                  Mariée
                                </span>
                                <input
                                  type="radio"
                                  value="MARIE"
                                  className="radio radio-sm checked:bg-[#A70606]"
                                  checked={
                                    globalStateInscription.situationMatrimoniale ===
                                    "MARIE"
                                  }
                                  onChange={(e) =>
                                    updateFieldGlobalState(
                                      "situationMatrimoniale",
                                      e.target.value
                                    )
                                  }
                                  name="parents"
                                />
                              </label>
                            </div>
                            <div className="form-control">
                              <label className="label cursor-pointer gap-2">
                                <span className="label-text text-base font-medium">
                                  Autres
                                </span>
                                <input
                                  type="radio"
                                  value="AUTRES"
                                  className="radio radio-sm checked:bg-[#A70606]"
                                  checked={
                                    globalStateInscription.situationMatrimoniale ===
                                    "AUTRES"
                                  }
                                  onChange={(e) =>
                                    updateFieldGlobalState(
                                      "situationMatrimoniale",
                                      e.target.value
                                    )
                                  }
                                  name="parents"
                                />
                              </label>
                            </div>
                          </div>

                          {globalStateInscription &&
                            globalStateInscription.situationMatrimoniale &&
                            globalStateInscription.situationMatrimoniale ===
                            "AUTRES" ? (
                            <input
                              type="text"
                              value={
                                globalStateInscription.autreSituationMatrimoniale
                              }
                              onChange={(e) =>
                                updateFieldGlobalState(
                                  "autreSituationMatrimoniale",
                                  e.target.value
                                )
                              }
                              placeholder="Entrer autre situation"
                              className="input border input-bordered w-full "
                            />
                          ) : null}

                          <div className="mt-6">
                            <span className="uppercase text-gray-500 label-text font-semibold">
                              Personne à contacter en cas d'urgence
                            </span>
                            <hr className="mt-2 w-full border-gray-400 border-dashed" />
                          </div>
                          <div className="mt-2 pb-5 flex flex-row flex-wrap gap-x-4 w-full">
                            <label className="form-control w-full">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Noms & prénoms
                                </span>
                              </div>
                              <input
                                type="text"
                                value={
                                  globalStateInscription.personneAContacter
                                    .nomPrenoms
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "personneAContacter",
                                    "nomPrenoms",
                                    e.target.value
                                  )
                                }
                                placeholder="Nom et prénoms"
                                className="input border input-bordered w-full "
                              />
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Contact
                                </span>
                              </div>
                              <input
                                type="text"
                                value={
                                  globalStateInscription.personneAContacter
                                    .contact
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "personneAContacter",
                                    "contact",
                                    e.target.value
                                  )
                                }
                                placeholder="+2250700000000"
                                className="input border input-bordered w-full "
                              />
                              {globalStateInscription.personneAContacter.contact
                                .length > 0 &&
                                !validatePhoneNumber(
                                  globalStateInscription.personneAContacter
                                    .contact
                                ) && (
                                  <span className="mt-1 label-text text-xs text-red-600 font-medium">
                                    Entrer un numéro de tépéhone correct
                                    commençant par +225
                                  </span>
                                )}
                            </label>
                            <label className="form-control w-full ">
                              <div className="label">
                                <span className="label-text font-semibold">
                                  Second contact
                                </span>
                              </div>
                              <input
                                type="text"
                                value={
                                  globalStateInscription.personneAContacter
                                    .secondContact
                                }
                                onChange={(e) =>
                                  updateFieldGlobalStateNestedObject(
                                    "personneAContacter",
                                    "secondContact",
                                    e.target.value
                                  )
                                }
                                placeholder="+2250700000000"
                                className="input border input-bordered w-full "
                              />
                              {globalStateInscription.personneAContacter
                                .secondContact.length > 0 &&
                                !validatePhoneNumber(
                                  globalStateInscription.personneAContacter
                                    .secondContact
                                ) && (
                                  <span className="mt-1 label-text text-xs text-red-600 font-medium">
                                    Entrer un numéro de tépéhone correct
                                    commençant par +225
                                  </span>
                                )}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* PAIEMENT */}
              {activeStep === 4 ? (
                <div className="w-full h-full bg-white border px-2 rounded-xl overflow-x-auto">
                  <div className="py-2">
                    <div className="px-1">
                      <div className="w-full h-14 p-3 flex items-center justify-between bg-gray-100 rounded-lg">
                        <p className="text-sm xs:text-base text-primary font-bold">
                          Montant de l'inscription
                        </p>
                        <p className="text-black font-bold">{`${montantInscription} FCFA`}</p>
                      </div>
                      <div className="mt-3 w-full h-fit p-3 flex flex-col xs:flex-row items-start justify-between bg-gray-100 rounded-lg">
                        <p className="text-sm xs:text-base text-primary font-bold">
                          Numéro de paiement
                        </p>
                        <div className="mt-3 xs:mt-0 xs:text-right text-black font-bold space-y-px">
                          {listeNumeroPaiement.map((item, index) => (
                            <p key={index} className="text-sm xs:text-base">
                              {item.numero} ({item.moyen})
                            </p>
                          ))}
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="flex flex-col sm:flex-row items-end gap-2">
                          <label className="form-control w-full">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Numéro utiliser pour payer
                              </span>
                            </div>
                            <input
                              type="text"
                              value={globalStateInscription.numeroPaiement}
                              onChange={(e) =>
                                updateFieldGlobalState(
                                  "numeroPaiement",
                                  e.target.value
                                )
                              }
                              placeholder="Numéro de paiement"
                              className="input border input-bordered w-full "
                            />
                            {globalStateInscription.numeroPaiement.length > 0 &&
                              !validatePhoneNumber(
                                globalStateInscription.numeroPaiement
                              ) && (
                                <span className="mt-1 label-text text-xs text-red-600 font-medium">
                                  Entrer un numéro de tépéhone correct commençant
                                  par +225
                                </span>
                              )}
                          </label>
                          <label className="form-control w-full">
                            <div className="label">
                              <span className="label-text font-semibold">
                                Moyen de paiement
                              </span>
                            </div>
                            <select
                              value={globalStateInscription.modePaiement}
                              onChange={(e) =>
                                updateFieldGlobalState(
                                  "modePaiement",
                                  e.target.value
                                )
                              }
                              className="select border input-bordered w-full "
                            >
                              <option disabled value="">
                                Selectionner un moyen
                              </option>
                              {listeMoyenPaiement &&
                                listeMoyenPaiement.map((item, index) => (
                                  <option key={index} value={item._id}>
                                    {item.designation}
                                  </option>
                                ))}
                            </select>
                          </label>
                        </div>
                        <label className="form-control w-full">
                          <div className="label">
                            <span className="label-text font-semibold">
                              Référence paiement
                            </span>
                          </div>
                          <input
                            type="text"
                            value={globalStateInscription.referencePaiement}
                            onChange={(e) =>
                              updateFieldGlobalState(
                                "referencePaiement",
                                e.target.value
                              )
                            }
                            placeholder="Référence paiement"
                            className="input border input-bordered w-full "
                          />
                        </label>
                        <div className="w-full pb-5">
                          <div className="label">
                            <span className="label-text font-semibold">
                              Photo du reçu de paiement
                            </span>
                          </div>
                          <div
                            onClick={handleClickPaiement}
                            className="flex justify-center items-center w-full h-full rounded-lg cursor-pointer"
                          >
                            {previewPaiment !== null ? (
                              <div className="relative w-full h-64">
                                <div className="absolute bg-black/20 w-full h-full rounded-lg flex  items-center justify-center">
                                  <img
                                    src={iconeAppareilPhoto}
                                    className="w-10 h-10"
                                    alt=""
                                  />
                                </div>
                                <img
                                  src={previewPaiment}
                                  alt="Preview"
                                  className="w-full h-full object-contain rounded-lg "
                                />
                              </div>
                            ) : previewPaiment === null ? (
                              <div className="relative w-full h-64 rounded-full flex justify-center ">
                                <div className="absolute bg-gray-100 top-0 w-full h-full rounded-lg flex justify-center items-center">
                                  <img
                                    src={iconeAppareilPhoto}
                                    className="w-10 h-10"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <input
                            type="file"
                            ref={fileInputRefPaiement}
                            style={{ display: "none" }}
                            onChange={handleFileChangePaiement}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="pt-5">
            {activeStep === 0 ? (
              <div className="flex justify-end items-center">
                {validateFieldEmailAndPhoneNumber() &&
                  validateField([
                    "nom",
                    "prenoms",
                    "dateNaissance",
                    "lieuNaissance",
                    "quartierResidence",
                    "ceb",
                    "ecoleProfession",
                    "contact",
                  ]) ? (
                  <button
                    className="w-28 h-9 bg-primary text-white font-semibold rounded-lg"
                    onClick={() => handleNext()}
                  >
                    Suivant
                  </button>
                ) : (
                  <button
                    disabled
                    className="w-28 h-9 bg-gray-100 text-sm text-black font-semibold border rounded-lg"
                  >
                    Suivant
                  </button>
                )}
              </div>
            ) : null}

            {activeStep === 1 ? (
              <div className="w-full justify-end flex gap-2 items-center">
                <button
                  className="w-28 h-9 bg-black text-sm text-white font-semibold border rounded-lg"
                  onClick={() => {
                    handleBack();
                  }}
                >
                  Précédent
                </button>
                {anciensCatechu === "OUI" &&
                  validateField(
                    ["date", "paroisse", "mvtGroupePriere"],
                    "premiereAnnee"
                  ) ? (
                  <button
                    className="w-28 h-9 bg-primary text-white font-semibold rounded-lg"
                    onClick={() => handleNext()}
                  >
                    Suivant
                  </button>
                ) : null}
                {anciensCatechu === "OUI" &&
                  !validateField(
                    ["date", "paroisse", "mvtGroupePriere"],
                    "premiereAnnee"
                  ) ? (
                  <button
                    disabled
                    className="w-28 h-9 bg-gray-100 text-sm text-black font-semibold border rounded-lg"
                  >
                    Suivant
                  </button>
                ) : null}
                {anciensCatechu === "NON" ? (
                  <button
                    className="w-28 h-9 bg-primary text-white font-semibold rounded-lg"
                    onClick={() => handleNext()}
                  >
                    Suivant
                  </button>
                ) : null}
              </div>
            ) : null}

            {activeStep === 2 ? (
              <div className="flex justify-end items-center">
                {dejaBaptise === "OUI" &&
                  validateField([
                    "numeroCarnetBapteme",
                    "dateCreationCarnetBapteme",
                  ]) &&
                  validateField(["date", "paroisse", "diocese"], "bapteme") ? (
                  <div className="w-full justify-end flex gap-2 items-center">
                    <button
                      className="w-28 h-9 bg-black text-sm text-white font-semibold border rounded-lg"
                      onClick={() => {
                        handleBack();
                      }}
                    >
                      Précédent
                    </button>
                    <button
                      className="w-28 h-9 bg-primary text-white font-semibold rounded-lg"
                      onClick={() => handleNext()}
                    >
                      Suivant
                    </button>
                  </div>
                ) : null}

                {(dejaBaptise === "OUI" &&
                  !validateField([
                    "numeroCarnetBapteme",
                    "dateCreationCarnetBapteme",
                  ])) ||
                  (dejaBaptise === "OUI" &&
                    !validateField(
                      ["date", "paroisse", "diocese"],
                      "bapteme"
                    )) ? (
                  <div className="w-full justify-end flex gap-2 items-center">
                    <button
                      className="w-28 h-9 bg-black text-sm text-white font-semibold border rounded-lg"
                      onClick={() => handleBack()}
                    >
                      Précédent
                    </button>
                    <button
                      disabled
                      className="w-28 h-9 bg-gray-100 text-sm text-black font-semibold border rounded-lg"
                    >
                      Suivant
                    </button>
                  </div>
                ) : null}

                {dejaBaptise === "NON" ? (
                  <div className="w-full justify-end flex gap-2 items-center">
                    <button
                      className="w-28 h-9 bg-black text-sm text-white font-semibold border rounded-lg"
                      onClick={() => {
                        handleBack();
                      }}
                    >
                      Précédent
                    </button>
                    <button
                      className="w-28 h-9 bg-primary text-white font-semibold rounded-lg"
                      onClick={() => handleNext()}
                    >
                      Suivant
                    </button>
                  </div>
                ) : null}
              </div>
            ) : null}

            {activeStep === 3 ? (
              <div className="flex justify-end items-center">
                {validateFieldEmailAndPhoneNumberPere() &&
                  validateField(
                    ["nom", "prenoms", "religion", "profession", "contact"],
                    "pere"
                  ) &&
                  validateFieldEmailAndPhoneNumberPere() &&
                  validateField(
                    ["nom", "prenoms", "religion", "profession", "contact"],
                    "mere"
                  ) &&
                  validateFieldEmailAndPhoneNumberTuteur() &&
                  validateField(
                    ["nomPrenoms", "contact"],
                    "personneAContacter"
                  ) ? (
                  <div className="w-full justify-end flex gap-2 items-center">
                    <button
                      className="w-28 h-9 bg-black text-sm text-white font-semibold border rounded-lg"
                      onClick={() => {
                        handleBack();
                      }}
                    >
                      Précédent
                    </button>
                    <button
                      className="w-28 h-9 bg-primary text-white font-semibold rounded-lg"
                      onClick={() => handleNext()}
                    >
                      Suivant
                    </button>
                  </div>
                ) : (
                  <div className="w-full justify-end flex gap-2 items-center">
                    <button
                      className="w-28 h-9 bg-black text-sm text-white font-semibold border rounded-lg"
                      onClick={() => handleBack()}
                    >
                      Précédent
                    </button>
                    <button
                      disabled
                      className="w-28 h-9 bg-gray-100 text-sm text-black font-semibold border rounded-lg"
                    >
                      Suivant
                    </button>
                  </div>
                )}
              </div>
            ) : null}

            {activeStep === 4 ? (
              <div className="flex justify-end items-center">
                {validatePhoneNumber(globalStateInscription.numeroPaiement) && validateField(["modePaiement", "numeroPaiement", "referencePaiement"]) ? (
                  <div className="w-full justify-end flex gap-2 items-center">
                    <button
                      className="w-28 h-9 bg-black text-sm text-white font-semibold border rounded-lg"
                      onClick={() => {
                        handleBack();
                      }}
                    >
                      Précédent
                    </button>
                    <button
                      disabled={inscriptionLoading}
                      className="w-48 h-9 bg-primary text-white font-semibold rounded-lg flex items-center justify-center"
                      onClick={inscriptionNouveauCatechumene}
                    >
                      {!inscriptionLoading ? (
                        "Terminer inscription"
                      ) : (
                        <ThreeDots
                          height="40"
                          width="40"
                          radius="9"
                          color="#fff"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={inscriptionLoading}
                        />
                      )}
                    </button>
                  </div>
                ) : (
                  <div className="w-full justify-end flex gap-2 items-center">
                    <button
                      className="w-28 h-9 bg-black text-sm text-white font-semibold border rounded-lg"
                      onClick={() => {
                        handleBack();
                      }}
                    >
                      Précédent
                    </button>
                    <button
                      disabled
                      className="w-48 h-9 bg-gray-100 text-sm text-black font-semibold border rounded-lg"
                    >
                      {!inscriptionLoading ? (
                        "Terminer inscription"
                      ) : (
                        <ThreeDots
                          height="40"
                          width="40"
                          radius="9"
                          color="#fff"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={inscriptionLoading}
                        />
                      )}
                    </button>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* CHARGEMENT EN COURS */}
      <dialog id="modal_spinner" className="modal">
        <div className="modal-box bg-transparent shadow-none">
          <div className="animate-spin w-20 h-20 mx-auto border-4 border-gray-100 border-t-red-500 rounded-full"></div>
          <div className="pt-4 text-xl text-white text-center font-extrabold">
            Inscription en cours, merci de patienter...
          </div>
        </div>
      </dialog>

      {/* MODAL SUCCÈS INSCRIPTION */}
      <dialog id="modal_succes_inscription" className="modal">
        <div className="modal-box rounded-lg">
          <h3 className="font-extrabold text-xl text-center text-green-600">
            Inscription effectuée avec succès
          </h3>
          <div className="pt-4 pb-2">
            <img src={imageCongra} alt="" className="w-32 mx-auto" />
            <p className="text-center text-base font-medium text-gray-600">
              Votre inscription a été prise en compte avec succès. Vous serez
              informé par message et/ou par email quand votre inscription sera
              validée par les responsables de la catéchèse.
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <NavLink end to="/inscription-catechumene">
              <button className="w-full sm:w-48 h-11 bg-green-600 text-white text-sm rounded-lg flex items-center justify-center font-medium outline-none border-none drop-shadow-md">
                Fermer et quitter
              </button>
            </NavLink>
          </div>
        </div>
      </dialog>

      <SnackbarAlert
        vertical={vertical}
        horizontal={horizontal}
        snackbarSuccessOpen={snackbarSuccessOpen}
        handleCloseSnackbarSuccess={handleCloseSnackbarSuccess}
        successMessage={successMessage}
        snackbarErrorOpen={snackbarErrorOpen}
        handleCloseSnackbarError={handleCloseSnackbarError}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default NouveauCatechumene;
