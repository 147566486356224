import { BASE_URL, FREE_BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
}, function (error) {
  console.log('AN ERROR REQUEST', error)
});

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const listeDesNiveauScolairePaginee = async (page = 1, size = 10, param = "") => {  
  return await axios.get(
    BASE_URL(`niveau-scolaire/query?page=${page}&size=${size}&param=${param}`),
    {}
  );
};

export const listeCompleteDesNiveauScolaire = async () => {
  return await axios.get(
    FREE_BASE_URL(`niveau-scolaire`),
    {}
  );
};


export const recupereNiveauScolaireParSonId = async (niveauId) => {
  return await axios.get(
    BASE_URL(`niveau-scolaire/${niveauId}`),
    {}
  );
};

export const ajouterUneNiveauScolaire = async (values) => {
  return await axios.post(
    BASE_URL(`niveau-scolaire`),
    values
  );
};

export const modifierUneNiveauScolaire = async (values, niveauId) => {
  return await axios.put(
    BASE_URL(`niveau-scolaire/${niveauId}`),
    values
  );
};

export const supprimerUneNiveauScolaire = async (niveauId) => {
  return await axios.delete(
    BASE_URL(`niveau-scolaire/${niveauId}`),
    {}
  );
};
