import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import IMAGE_ALT from "./../assets/IconeSvg/alt-img.svg";

const ImageComponent = ({ src, alt, classe, height = 'h-64' }) => {
  const [loading, setLoading] = useState(true);
  const [imgSrc, setImgSrc] = useState(src);

  const handleLoad = () => {
    setLoading(false);
    console.log('chargement...');
  };

  const handleError = () => {
    setImgSrc(null); // Set fallback image if original fails
    setLoading(false);
    console.log('error image');
  };

  useEffect(() => {
    setImgSrc(src)
    console.log('====>', src);
  }, [src])
  

  return (
    <>
      {src && loading ? (
        <div className={`w-full ${height} flex items-center justify-center`}>
          <RotatingLines
            visible={loading}
            height="20"
            width="20"
            color="grey"
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : null}
      {imgSrc ? (
        <img
          src={imgSrc}
          alt={alt}
          onLoad={handleLoad}
          onError={handleError}
          style={{ display: loading ? "none" : "block" }}
          className={classe}
        />
      ) : (
        <div className={`w-full ${height} bg-white border rounded-xl flex items-center justify-center p-4`}>
          <img src={IMAGE_ALT} className="w-10" />
        </div>
      )}
    </>
  );
};

export default ImageComponent;
