import React, { useEffect, useRef, useState } from "react";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import iconeAppareilPhoto from "../../assets/IconeSvg/appareil_photo.svg";
import imageCongra from "../../assets/images/congra3.png";
import iconeRetour from "../../assets/IconeSvg/IconeRetour_arrow-left.svg";
import { ThreeDots } from "react-loader-spinner";
import { NavLink, useNavigate } from "react-router-dom";
import SnackbarAlert from "../../components/SnackbarAlert";
import { listeCompleteDesModePaiements } from "../../services/parametrage/ModePaiementRequest";
import { listeCompleteDesTarifications } from "../../services/parametrage/TarificationRequest";
import { recupereAnneeCatechumiqueEnCours } from "../../services/parametrage/AnneeScolaireRequest";
import {
  inscrireAncienCatechumene,
  inscrireNouveauCatechumene,
  recupererInfosCatechumeneParMatricule,
} from "../../services/catechumene/CatechumeneRequest";

const AncienCatechumene = () => {
  const steps = ["Informations catéchumène", "Paiement"];

  const [searchLoading, setSearchLoading] = useState(false);
  const [inscriptionLoading, setInscriptionLoading] = useState(false);

  const [anneeScolaireActive, setAnneeScolaireActive] = useState(null);
  const [listeMoyenPaiement, setListeMoyenPaiement] = useState([]);
  const [listeNumeroPaiement, setListeNumeroPaiement] = useState([]);
  const [listeTarificationMotifPaiement, setListeTarificationMotifPaiement] =
    useState([]);

  const fileInputRefPaiement = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const [montantInscription, setMontantInscription] = useState("0");
  const [previewPaiment, setPreviewPaiment] = useState(null);
  const [filePaiement, setFilePaiement] = useState(null);

  const [matricule, setMatricule] = useState("");
  const [catechumeneDetail, setCatechumeneDetail] = useState(null);
  const [globalStateInscription, setGlobalStateInscription] = useState({
    catechumeneId: "",
    modePaiement: "",
    motifPaiement: "66a6e1f43d332a3f7d6de357",
    montant: "",
    commentaire: `Nouveau catéchumène a effectué son inscription à la date du ${new Date().toISOString()}.`,
    anneeScolaire: "",
  });

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = snackbarPosition;

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const rechercherDetailCatechumene = () => {
    setSearchLoading(true);
    setErrorMessage('')
    recupererInfosCatechumeneParMatricule(matricule)
      .then((res) => {
        setSearchLoading(false);
        setCatechumeneDetail(res.data)
        setGlobalStateInscription(prev => ({
          ...prev,
          catechumeneId: res.data._id
        }))
      })
      .catch((err) => {
        setSearchLoading(false);
        setCatechumeneDetail(null)
        if (err && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite durant votre inscription");
          setSnackbarOpenError(true);
        }
        console.log("error api", err);
      });
  };

  // === REQUESTES NECESSAIRES ===
  const recupererAnneeScolaireActive = () => {
    recupereAnneeCatechumiqueEnCours()
      .then((res) => {
        setAnneeScolaireActive(res.data[0]);
        updateFieldGlobalState("anneeScolaire", res.data[0]._id);
      })
      .catch((err) => {
        console.log("ERREUR", err);
      });
  };

  const getListeModeDePaiement = () => {
    listeCompleteDesModePaiements()
      .then((res) => {
        setListeMoyenPaiement(res.data);
        let liste = [];
        res.data.forEach((element) => {
          if (element.numeroPaiement && element.secondNumeroPaiement) {
            liste.push({
              moyen: element.designation,
              numero: element.numeroPaiement,
              secondNumero: element.secondNumeroPaiement,
            });
          } else if (element.numeroPaiement && !element.secondNumeroPaiement) {
            liste.push({
              moyen: element.designation,
              numero: element.numeroPaiement,
              secondNumero: "",
            });
          }
        });
        setListeNumeroPaiement(liste);
      })
      .catch((err) => {
        console.log("ERREUR", err);
      });
  };

  const getListeTarificationMotifPaiement = () => {
    listeCompleteDesTarifications(
      "66a6e1f43d332a3f7d6de357",
      anneeScolaireActive ? anneeScolaireActive._id : ""
    )
      .then((res) => {
        setListeTarificationMotifPaiement(res.data);
        setMontantInscription(res.data[0].montant);
      })
      .catch((err) => {
        console.log("ERREUR", err);
      });
  };

  // === CHANGEMENT DE STEP ===
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSkipped(newSkipped);
    }
  };

  // === UPLOAD PHOTO RECU PAIEMENT ===
  const handleClickPaiement = () => {
    fileInputRefPaiement.current.click();
  };

  const handleFileChangePaiement = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFilePaiement(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewPaiment(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const updateFieldGlobalState = (key, value) => {
    setGlobalStateInscription((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  /**
   * FUNCTION D'INSCRIPTION CATECHUMENE
   */
  const inscriptionAncienCatechumene = () => {
    document.getElementById("modal_spinner").showModal();
    setInscriptionLoading(true);

    const dataCatechumene = new FormData();
    dataCatechumene.append("photoRecuInscription", filePaiement);

    Object.keys(globalStateInscription).forEach((key) => {
      if (
        typeof globalStateInscription[key] === "object" &&
        !Array.isArray(globalStateInscription[key])
      ) {
        dataCatechumene.append(
          key,
          JSON.stringify(globalStateInscription[key])
        );
      } else {
        dataCatechumene.append(key, globalStateInscription[key]);
      }
    });

    inscrireAncienCatechumene(dataCatechumene)
      .then((res) => {
        setInscriptionLoading(false);
        document.getElementById("modal_spinner").close();
        document.getElementById("modal_succes_inscription").showModal();
      })
      .catch((err) => {
        setInscriptionLoading(false);
        document.getElementById("modal_spinner").close();
        if (err && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite durant votre inscription");
          setSnackbarOpenError(true);
        }
        console.log("ERREUR", err);
      });
  };

  const validateField = (fields, nestedObject = null) => {
    const stateToValidate = nestedObject
      ? globalStateInscription[nestedObject]
      : globalStateInscription;
    for (let field of fields) {
      if (!stateToValidate[field]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    recupererAnneeScolaireActive();
    getListeModeDePaiement();
    getListeTarificationMotifPaiement();
  }, []);

  return (
    <div className="pb-16 min-h-screen">
      <div className="w-full h-44 bg-white border-b drop-shadow-sm">
        <div className="pt-2 w-full lg:w-9/12 mx-auto flex items-center justify-between">
          <NavLink to="/inscription-catechumene">
            <button className="flex items-center text-xs text-primary font-bold">
              <img src={iconeRetour} className="w-8 sm:w-10" alt="" /> Retour
            </button>
          </NavLink>
          <p className="font-bold text-xs text-gray-500 pr-5">
            Année catéchumique:{" "}
            {anneeScolaireActive
              ? `${anneeScolaireActive.anneeDebut} - ${anneeScolaireActive.anneeFin}`
              : "-"}
          </p>
        </div>
        <h1 className="px-4 mt-8 text-black text-center text-xl sm:text-2xl md:text-3xl font-extrabold">
          Formulaire d'inscription
        </h1>
      </div>

      <div className="w-11/12 lg:w-9/12 xl:w-8/12 mx-auto flex flex-col md:flex-row gap-x-0 md:gap-x-4 h-full">
        {/* DESKTOP STEPPER */}
        <div className="hidden md:block mt-5 w-72 h-fit rounded-lg p-4 border bg-white">
          <Stepper
            orientation="vertical"
            activeStep={activeStep}
            style={{ height: 100, width: "100%" }}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step
                  key={label}
                  {...stepProps}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "#059669",
                    },
                    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                    {
                      color: "blue",
                    },
                    "& .MuiStepLabel-root .Mui-active": {
                      color: "#A70606",
                    },
                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                      fill: "#ffff",
                    },
                  }}
                >
                  <StepLabel {...labelProps}>
                    <h2 className="text-base font-bold truncate">{label}</h2>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        {/* MOBILE STEPPER */}
        <div className="mt-7 overflow-x-auto md:hidden">
          <Stepper
            orientation="horizontal"
            style={{ height: "100%", width: "60%", margin: "auto" }}
            activeStep={activeStep}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step
                  key={label}
                  {...stepProps}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "#059669",
                    },
                    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                    {
                      color: "blue",
                    },
                    "& .MuiStepLabel-root .Mui-active": {
                      color: "#A70606",
                    },
                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                      fill: "#ffff",
                    },
                  }}
                >
                  <StepLabel {...labelProps}></StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>

        <div className="w-full">
          <div className="">
            <div className="mt-6">
              <h2 className="font-extrabold text-primary text-lg uppercase">
                {activeStep === 0
                  ? "Informations catéchumène"
                  : activeStep === 1
                    ? "Paiement"
                    : null}
              </h2>
            </div>

            <div className="mt-5">
              {/* INFORMATION GENERALE */}
              {activeStep === 0 ? (
                <div className="w-full bg-white border px-4 rounded-xl overflow-x-auto">
                  <div className="w-full pb-5">
                    <div className="my-6">
                      <p className="font-bold text-gray-500">
                        Entre votre matricule pour vérifier vos informatiosn et
                        vous inscrire.
                      </p>
                      <div className="mt-4 w-full flex items-end gap-x-3">
                        <label className="form-control w-full">
                          <div className="label">
                            <span className="label-text font-semibold">
                              Matricule
                            </span>
                          </div>
                          <input
                            type="text"
                            value={matricule}
                            onChange={(e) => setMatricule(e.target.value)}
                            placeholder="Entrez votre matricule"
                            className="input border input-bordered w-full"
                          />
                        </label>
                        <button
                          disabled={searchLoading}
                          className="w-64 px-5 h-10 flex items-center justify-center text-sm text-white font-semibold bg-black rounded-lg truncate"
                          onClick={() => rechercherDetailCatechumene()}
                        >
                          {!searchLoading ? (
                            "Rechercher"
                          ) : (
                            <ThreeDots
                              height="40"
                              width="40"
                              radius="9"
                              color="#fff"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={searchLoading}
                            />
                          )}
                        </button>
                      </div>
                    </div>

                    {/* CATECHUMENE DETAIL */}
                    {catechumeneDetail ? <div className="mt-4 flex items-start gap-x-3 bg-zinc-100 p-2 rounded-lg border">
                      <div className="w-14 h-14 rounded-full flex items-center justify-center">
                        <img src={catechumeneDetail ? catechumeneDetail.photo : ""} className="w-full h-full rounded-full object-cover" />
                      </div>
                      <div>
                        <p className="text-gray-700 font-medium"><span className="text-gray-500 underline font-medium">Nom & Prénoms:</span> {catechumeneDetail.nom} {catechumeneDetail.prenoms}</p>
                        <p className="text-gray-700 font-medium"><span className="text-gray-500 underline font-medium">Email:</span> {catechumeneDetail.email}</p>
                        <p className="text-gray-700 font-medium"><span className="text-gray-500 underline font-medium">Contact:</span> {catechumeneDetail.contact}</p>
                      </div>
                    </div> : null}

                    {/* AUCUN CATECHUMENE TROUVE */}
                    {errorMessage ? <div className="h-32 flex items-center justify-center">
                      <p className="text-red-600 text-sm font-medium">{errorMessage}</p>
                    </div> : null}
                  </div>
                </div>
              ) : null}

              {/* PAIEMENT */}
              {activeStep === 1 ? (
                <div className="w-full h-full bg-white border px-2 rounded-xl overflow-x-auto">
                  <div className="py-2">
                    <div className="px-1">
                      <div className="w-full h-14 p-3 flex items-center justify-between bg-gray-100 rounded-lg">
                        <p className="text-sm xs:text-base text-primary font-bold">
                          Montant de l'inscription
                        </p>
                        <p className="text-black font-bold">{`${montantInscription} FCFA`}</p>
                      </div>
                      <div className="mt-3 w-full h-fit p-3 flex flex-col xs:flex-row items-start justify-between bg-gray-100 rounded-lg">
                        <p className="text-sm xs:text-base text-primary font-bold">
                          Numéro de paiement
                        </p>
                        <div className="mt-3 xs:mt-0 xs:text-right text-black font-bold space-y-px">
                          {listeNumeroPaiement.map((item, index) => (
                            <p key={index} className="text-sm xs:text-base">
                              {item.numero} ({item.moyen})
                            </p>
                          ))}
                        </div>
                      </div>
                      <div className="mt-2 flex gap-x-4 w-full">
                        <label className="form-control w-full">
                          <div className="label">
                            <span className="label-text font-semibold">
                              Montant versé
                            </span>
                          </div>
                          <input
                            type="number"
                            value={globalStateInscription.montant}
                            onChange={(e) =>
                              updateFieldGlobalState("montant", e.target.value)
                            }
                            placeholder="4000"
                            className="input border input-bordered w-full "
                          />
                        </label>
                        <label className="form-control w-full ">
                          <div className="label">
                            <span className="label-text font-semibold">
                              Reste à Payer
                            </span>
                          </div>
                          <input
                            type="text"
                            disabled
                            placeholder={`${parseInt(montantInscription) -
                              parseInt(globalStateInscription.montant)
                              } FCFA`}
                            className="input border font-extrabold  input-bordered w-full "
                          />
                        </label>
                      </div>
                      <div className="w-full">
                        <label className="form-control w-full">
                          <div className="label">
                            <span className="label-text font-semibold">
                              Moyen de paiement
                            </span>
                          </div>
                          <select
                            value={globalStateInscription.modePaiement}
                            onChange={(e) =>
                              updateFieldGlobalState(
                                "modePaiement",
                                e.target.value
                              )
                            }
                            className="select border input-bordered w-full "
                          >
                            <option disabled value="">
                              Selectionner un moyen
                            </option>
                            {listeMoyenPaiement &&
                              listeMoyenPaiement.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.designation}
                                </option>
                              ))}
                          </select>
                        </label>
                        <div className="w-full pb-5">
                          <div className="label">
                            <span className="label-text font-semibold">
                              Photo du reçu de paiement
                            </span>
                          </div>
                          <div
                            onClick={handleClickPaiement}
                            className="flex justify-center items-center w-full h-full rounded-lg cursor-pointer"
                          >
                            {previewPaiment !== null ? (
                              <div className="relative w-full h-64">
                                <div className="absolute bg-black/20 w-full h-full rounded-lg flex  items-center justify-center">
                                  <img
                                    src={iconeAppareilPhoto}
                                    className="w-10 h-10"
                                    alt=""
                                  />
                                </div>
                                <img
                                  src={previewPaiment}
                                  alt="Preview"
                                  className="w-full h-full object-contain rounded-lg "
                                />
                              </div>
                            ) : previewPaiment === null ? (
                              <div className="relative w-full h-64 rounded-full flex justify-center ">
                                <div className="absolute bg-gray-100 top-0 w-full h-full rounded-lg flex justify-center items-center">
                                  <img
                                    src={iconeAppareilPhoto}
                                    className="w-10 h-10"
                                    alt=""
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <input
                            type="file"
                            ref={fileInputRefPaiement}
                            style={{ display: "none" }}
                            onChange={handleFileChangePaiement}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="pt-5">
            {activeStep === 0 ? (
              <div className="flex justify-end items-center">
                {catechumeneDetail !== null ? (
                  <button
                    className="w-28 h-9 bg-primary text-white font-semibold rounded-lg"
                    onClick={() => handleNext()}
                  >
                    Suivant
                  </button>
                ) : (
                  <button
                    disabled
                    className="w-28 h-9 bg-gray-100 text-sm text-black font-semibold border rounded-lg"
                  >
                    Suivant
                  </button>
                )}
              </div>
            ) : null}

            {activeStep === 1 ? (
              <div className="flex justify-end items-center">
                {validateField(["montant"]) ? (
                  <div className="w-full justify-end flex gap-2 items-center">
                    <button
                      className="w-28 h-9 bg-black text-sm text-white font-semibold border rounded-lg"
                      onClick={() => {
                        handleBack();
                      }}
                    >
                      Précédent
                    </button>
                    <button
                      disabled={inscriptionLoading}
                      className="w-48 h-9 bg-primary text-white font-semibold rounded-lg flex items-center justify-center"
                      onClick={inscriptionAncienCatechumene}
                    >
                      {!inscriptionLoading ? (
                        "Terminer inscription"
                      ) : (
                        <ThreeDots
                          height="40"
                          width="40"
                          radius="9"
                          color="#fff"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={inscriptionLoading}
                        />
                      )}
                    </button>
                  </div>
                ) : (
                  <div className="w-full justify-end flex gap-2 items-center">
                    <button
                      className="w-28 h-9 bg-black text-sm text-white font-semibold border rounded-lg"
                      onClick={() => handleBack()}
                    >
                      Précédent
                    </button>
                    <button
                      disabled
                      className="w-48 h-9 bg-gray-100 text-sm text-black font-semibold border rounded-lg"
                    >
                      Terminer inscription
                    </button>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* CHARGEMENT EN COURS */}
      <dialog id="modal_spinner" className="modal">
        <div className="modal-box bg-transparent shadow-none">
          <div className="animate-spin w-20 h-20 mx-auto border-4 border-gray-100 border-t-red-500 rounded-full"></div>
          <div className="pt-4 text-xl text-white text-center font-extrabold">
            Inscription en cours, merci de patienter...
          </div>
        </div>
      </dialog>

      {/* MODAL SUCCÈS INSCRIPTION */}
      <dialog id="modal_succes_inscription" className="modal">
        <div className="modal-box rounded-lg">
          <h3 className="font-extrabold text-xl text-center text-green-600">
            Inscription effectuée avec succès
          </h3>
          <div className="pt-4 pb-2">
            <img src={imageCongra} alt="" className="w-32 mx-auto" />
            <p className="text-center text-base font-medium text-gray-600">
              Votre inscription a été prise en compte avec succès. Vous serez
              informé par message et/ou par email quand votre inscription sera
              validée par les responsables de la catéchèse.
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <NavLink end to="/inscription-catechumene">
              <button className="w-full sm:w-48 h-11 bg-green-600 text-white text-sm rounded-lg flex items-center justify-center font-medium outline-none border-none drop-shadow-md">
                Fermer et quitter
              </button>
            </NavLink>
          </div>
        </div>
      </dialog>

      <SnackbarAlert
        vertical={vertical}
        horizontal={horizontal}
        snackbarSuccessOpen={snackbarSuccessOpen}
        handleCloseSnackbarSuccess={handleCloseSnackbarSuccess}
        successMessage={successMessage}
        snackbarErrorOpen={snackbarErrorOpen}
        handleCloseSnackbarError={handleCloseSnackbarError}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default AncienCatechumene;
