import { BASE_URL, FREE_BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("cc_pssm_api");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers.Authorization = null;
    }

    return config;
  },
  function (error) {
    console.log("AN ERROR REQUEST", error);
  }
);

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const listeDesCatechumenesParFilterParam = async (
  page,
  size,
  param,
  commission,
  niveau,
  classe,
  statut = "VALIDER"
) => {
  return await axios.get(
    BASE_URL(
      `historique-catechumene/query?param=${param}&commission=${commission}&niveau=${niveau}&classe=${classe}&anneeScolaire=${currentAnnee._id}&page=${page}&size=${size}&statut=${statut}`
    ),
    {}
  );
};

export const listeCompleteDesCatechumenesParClasse = async (classe) => {
  return await axios.get(
    BASE_URL(
      `historique-catechumene/complete/classe?classe=${classe}&annee=${currentAnnee._id}`
    ),
    {}
  );
};

export const transfertCatechumeneVersAutresClasse = async (historiqueId, transfertModel) => {
  return await axios.post(
    BASE_URL(
      `historique-catechumene/${historiqueId}/transferer-catechumene`
    ),
    transfertModel
  );
};
