import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import LOGO_WHITE from "./../../assets/images/logo-preview.png";
import BANNER from "./../../assets/images/catechese.jpg";
import { ThreeDots } from "react-loader-spinner";
import { loginAdmin } from "../../services/authentification/AuthRequest";

const InscriptionOnline = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [messageAlert, setMessageAlert] = useState("");

  const handleSubmit = () => {
    setIsLoading(true);
    const data = {
      password: password,
      email: username,
    };

    try {
      loginAdmin(data)
        .then((res) => {
          setIsLoading(false);
          console.log(res);
          localStorage.setItem("cc_pssm_api", res.data.token);
          // Stocker les données utilisateur dans le sessionStorage
          localStorage.setItem("cc_pssm_admin", JSON.stringify(res.data.user));
          navigate("/classes");
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response) {
            setMessageAlert(err.response.data.message);
          } else {
            setMessageAlert("Une erreur s'est produite, rééssayez!");
          }
          console.log("api error", err);
        });
    } catch (error) {
      setIsLoading(false);
      setMessageAlert("Quelque chose s'est mal passé, rééssayer");
      console.log("catch error");
    }
  };

  useEffect(() => { }, []);

  return (
    <div className="w-full min-h-screen">
      <div className="flex items-start">
        <div className="hidden md:block w-1/2 min-h-screen bg-primary">
          <img src={BANNER} className="w-full min-h-screen object-cover" />
        </div>
        <div className="w-full md:w-1/2 min-h-screen bg-white">
          <div className="w-11/12 max-w-2xl mx-auto pt-24">
            <div className="w-11/12 lg:w-[500px] mx-auto">
              <img src={LOGO_WHITE} className="w-20 border" />
            </div>
            <div className="w-11/12 lg:w-[500px] mx-auto mt-10">
              <h1 className="text-2xl text-left text-black font-extrabold">
                Plateforme d'inscription à la catéchèse
              </h1>
              <p className="mt-6 text-base text-gray-500 text-left font-medium">
                Cliquez sur le bouton en bas pour vous inscrire au cours de
                catéchèse pour l'année 2024-2025
              </p>

              <p className="mt-3 text-base text-gray-500 text-left font-medium">
                Merci de renseigner les bonnes informations à chaque étape de
                l'inscription, au risque de voir votre inscription rejeté.
              </p>

              <div className="mt-10">
                <NavLink to="/inscription-catechumene/nouveau">
                  <button className="bg-primary text-sm font-semibold text-white w-fit px-5 h-10 rounded-lg flex items-center justify-center">
                    Je suis un nouveau catéchumène
                  </button>
                </NavLink>
                {/* <NavLink to="/inscription-catechumene/ancien">
                  <button className="mt-3 bg-black text-sm font-semibold text-white w-fit px-5 h-10 rounded-lg flex items-center justify-center">
                    Je suis un ancien catéchumène
                  </button>
                </NavLink> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InscriptionOnline;
