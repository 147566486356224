import React, { useEffect, useState } from "react";
import SnackbarComponent from "../../../components/SnackbarComponent";
import { Drawer, Pagination } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import iconeCamera from "../../../assets/IconeSvg/camera.svg";
import iconeDefaultUser from "../../../assets/IconeSvg/user-rounded.svg";
import iconethreeDots from "../../../assets/IconeSvg/treeDotsGray.svg";
import iconeDelete from "../../../assets/IconeSvg/delete.svg";
import {
  listeDesAnimateurPaginee,
  ajouterUneAnimateur,
  modifierUneAnimateur,
  supprimerAnimateurDeLaClasse,
} from "../../../services/animateur/AnimateurRequest";
import { useParams } from "react-router-dom";
import { returnPermissionsParMenu, verificationPermissionsExistantes } from "../../../utils/ManageUserProfil";

const connectedUser = localStorage.getItem("cc_pssm_admin")
  ? JSON.parse(localStorage.getItem("cc_pssm_admin"))
  : null;
const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;
const listePermissions = returnPermissionsParMenu("/classes");

const ClasseAnimateurs = ({ classe }) => {
  let { classeId } = useParams();
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 10,
    nombrePage: 1,
    pagePagination: 1,
  });
  const [addLoading, setAddLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalShowDetailAnimateur, setModalShowDetailAnimateur] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [listeModePaiement, setListeModePaiement] = useState([]);
  const [listeMotifPaiement, setListeMotifPaiement] = useState([]);
  const [listeAnimateur, setListeAnimateur] = useState([]);
  const [animateurInfo, setAnimateurInfo] = useState(null);
  const [fichierPhoto, setFichierPhoto] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [animateurData, setAnimateurData] = useState({
    nom: "",
    prenoms: "",
    contact: "",
    secondContact: "",
    email: "",
    nomParent: "",
    prenomsParent: "",
    contactParent: "",
    secondContactParent: "",
    emailParent: "",
    commission: classe ? classe.commission._id : "",
    niveau: classe ? classe.niveau._id : "",
    classe: classe ? classe._id : "",
    anneeScolaire: currentAnnee ? currentAnnee._id : "",
    origine: connectedUser ? connectedUser.id : "",
  });

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = snackbarPosition;

  const clearFormEnregistrement = () => {
    setAnimateurInfo(null);
    setAnimateurData({
      nom: "",
      prenoms: "",
      contact: "",
      secondContact: "",
      email: "",
      nomParent: "",
      prenomsParent: "",
      contactParent: "",
      secondContactParent: "",
      emailParent: "",
      commission: classe ? classe.commission._id : "",
      niveau: classe ? classe.niveau._id : "",
      classe: classe ? classe._id : "",
      anneeScolaire: currentAnnee ? currentAnnee._id : "",
      origine: connectedUser ? connectedUser.id : "",
    });
  };

  const getListeDesAnimateur = () => {
    setLoadingData(true);
    listeDesAnimateurPaginee(
      0,
      pageQuery.size,
      searchParam,
      "",
      "",
      classeId
    )
      .then((res) => {
        setLoadingData(false);
        setListeAnimateur(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const enregistrerAnimateur = () => {
    setAddLoading(true);
    const formData = new FormData();
    formData.append("photo", fichierPhoto);

    // Append each field to the FormData
    for (const key in animateurData) {
      if (animateurData.hasOwnProperty(key)) {
        formData.append(key, animateurData[key]);
      }
    }

    ajouterUneAnimateur(formData)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Animateur enregistré avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesAnimateur();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("ELEMENT", err);
      });
  };

  // === MODIFIER UNE NIVEAU ===
  const modifierAnimateur = () => {
    setAddLoading(true);
    modifierUneAnimateur(animateurData, animateurInfo._id)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Animateur modifié avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesAnimateur();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("ELEMENT", err);
      });
  };

  const supprimerAnimateurFromClasse = () => {
    setDeleteLoading(true);
    let typeAnimateur = null
    if (animateurInfo && animateurInfo.classe._id !== classeId && animateurInfo.classeRemplacement._id === classeId) {
      typeAnimateur = 'SECONDAIRE'
    } else {
      typeAnimateur = 'PRINCIPAL'
    }
    supprimerAnimateurDeLaClasse(animateurInfo._id, typeAnimateur)
      .then((res) => {
        setSuccessMessage("Animateur retiré de cette classe avec succès");
        getListeDesAnimateur();
        setSnackbarOpenSuccess(true);
        setDeleteLoading(false);
        document.getElementById("delete_animateur_clase").close();
        setModalShowDetailAnimateur(false);
        setAnimateurInfo(null);
      })
      .catch((err) => {
        setDeleteLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("api error", err);
      });
  };

  const filterElement = () => {
    setSearchLoading(true);
    listeDesAnimateurPaginee(
      1,
      pageQuery.size,
      searchParam,
      "",
      "",
      classeId
    )
      .then((res) => {
        setSearchLoading(false);
        setListeAnimateur(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setSearchLoading(false);
        console.log("error", error);
      });
  };

  const handlePaginationChange = (event, value) => {
    setLoadingData(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value,
    }));
    listeDesAnimateurPaginee(
      value,
      pageQuery.size,
      searchParam,
      "",
      "",
      classeId
    )
      .then((res) => {
        setLoadingData(false);
        setListeAnimateur(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const handleFileInput = (e) => {
    const fileUploaded = e.target.files[0];

    if (fileUploaded || fileUploaded !== undefined) {
      setFichierPhoto(fileUploaded);

      // Create a preview URL
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result);
      };
      fileReader.readAsDataURL(fileUploaded);
    }
  };

  useEffect(() => {
    setLoadingData(true);

    listeDesAnimateurPaginee(
      1,
      pageQuery.size,
      searchParam,
      "",
      "",
      classeId
    )
      .then((res) => {
        setLoadingData(false);
        setListeAnimateur(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  }, []);

  return (
    <div>
      <div className="flex flex-col sm:flex-row justify-end items-end sm:items-start gap-2">
        <div className="w-full md:w-fit flex items-center gap-x-2 overflow-x-scroll sm:overflow-auto">
          <input
            type="text"
            placeholder="Rechercher un élement..."
            value={searchParam}
            onChange={(e) => setSearchParam(e.target.value)}
            className="input input-bordered w-64 sm:w-56 h-10 text-sm font-medium"
          />
          <button
            disabled={searchLoading}
            className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
            onClick={filterElement}
          >
            {!searchLoading ? (
              "Rechercher"
            ) : (
              <ThreeDots
                height="35"
                width="35"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={searchLoading}
              />
            )}
          </button>
        </div>
        {/* <button
          onClick={() => {
            clearFormEnregistrement();
            setOpenModal(true);
          }}
          className="bg-black w-full sm:w-fit h-10 px-4 flex items-center justify-center gap-x-2 rounded-xl"
        >
          <img src={iconeAdd} alt="" className="w-5 sm:w-auto" />
          <span className="text-white text-sm font-semibold truncate">
            Ajouter un animateur
          </span>
        </button> */}
      </div>

      {!loadingData && listeAnimateur.length !== 0 ? (
        <div className="mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4 w-full">
          {listeAnimateur.map((animateur, index) => (
            <div className="w-fit h-fit relative">
              <div className="absolute right-2 top-2 z-50">
                <div className="dropdown dropdown-left dropdown-end">
                  <div
                    tabIndex={0}
                    className="w-7 h-7 rounded-lg bg-gray-200 flex items-center justify-center cursor-pointer"
                  >
                    <img src={iconethreeDots} alt="" />
                  </div>
                  <ul
                    tabIndex={0}
                    className="dropdown-content z-50 menu p-1 shadow border bg-base-100 rounded-lg w-32 mr-2"
                  >
                    {verificationPermissionsExistantes(listePermissions, "SUPPRIMER") && <button
                      onClick={() => {
                        setAnimateurInfo(animateur);
                        document
                          .getElementById("delete_animateur_clase")
                          .showModal();
                      }}
                      className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                    >
                      <img src={iconeDelete} alt="" className="w-5" />
                      <span className="font-semibold text-sm">Retirer</span>
                    </button>}
                  </ul>
                </div>
              </div>
              <div
                key={index}
                className="cursor-pointer w-full bg-white rounded-xl p-4 border drop-shadow-sm"
                onClick={() => {
                  setModalShowDetailAnimateur(true);
                  setAnimateurInfo(animateur);
                }}
              >
                <div className="h-16 w-16 rounded-full mx-auto bg-gray-100 flex items-center justify-center">
                  {animateur && animateur.photo ? (
                    <img
                      src={animateur.photo}
                      className="w-full h-full rounded-full"
                    />
                  ) : (
                    <img src={iconeDefaultUser} className="w-8" />
                  )}
                </div>
                <div className="mt-4">
                  <p className="text-lg text-primary text-center font-extrabold">
                    {animateur.nom} {animateur.prenoms}
                  </p>
                </div>
                <div className="mt-1">
                  <p className="text-sm text-gray-600 text-center font-semibold">
                    {animateur.contact}
                  </p>
                </div>
                <div className="mt-1">
                  <p className="text-sm text-gray-500 text-center font-medium">
                    Date création:{" "}
                    {new Date(animateur.createdAt).toLocaleDateString()}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}

      {!loadingData && listeAnimateur.length === 0 ? (
        <div className="mt-5 h-56 w-full flex items-center justify-center">
          <p className="text-red-600 font-semibold">Aucun élément trouvé.</p>
        </div>
      ) : null}

      {loadingData && listeAnimateur.length === 0 ? (
        <div className="mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4 w-full">
          {[0, 1, 2, 3, 4].map((item) => (
            <div
              key={item}
              className="w-full bg-white rounded-xl p-4 border drop-shadow-sm"
            >
              <div className="skeleton h-16 w-16 rounded-full mx-auto"></div>
              <div className="skeleton h-5 w-11/12 mx-auto rounded mt-4"></div>
              <div className="skeleton h-5 w-9/12 mx-auto rounded mt-2"></div>
            </div>
          ))}
        </div>
      ) : null}

      {loadingData && listeAnimateur.length !== 0 ? (
        <div className="mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4 w-full">
          {[0, 1, 2, 3, 4, 5, 6].map((item) => (
            <div
              key={item}
              className="w-full bg-white rounded-xl p-4 border drop-shadow-sm"
            >
              <div className="skeleton h-16 w-16 rounded-full mx-auto"></div>
              <div className="skeleton h-5 w-11/12 mx-auto rounded mt-4"></div>
              <div className="skeleton h-5 w-9/12 mx-auto rounded mt-2"></div>
            </div>
          ))}
        </div>
      ) : null}

      <div className="flex justify-end mt-2">
        <Pagination
          count={pageQuery.nombrePage}
          page={pageQuery.pagePagination}
          variant="outlined"
          shape="rounded"
          onChange={handlePaginationChange}
        />
      </div>

      {/* CREATION - MODIFICATION */}
      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => {
          clearFormEnregistrement();
          setOpenModal(false);
        }}
      >
        <div className="w-screen sm:w-[430px] p-4 bg-white">
          <button
            onClick={() => {
              clearFormEnregistrement();
              setOpenModal(false);
            }}
          >
            <img src={iconeCloseWindow} alt="" />
          </button>

          <h2 className="mt-3 text-xl font-extrabold">
            {animateurInfo === null
              ? " Ajouter un catéchumène"
              : "Modifier ce catéchumène"}
          </h2>

          <div className="mt-6 pb-16">
            <div
              className="w-20 h-20 mx-auto rounded-full bg-gray-100 border-2 border-gray-400 flex items-center justify-center"
              onClick={() => document.getElementById("file-selector-5").click()}
            >
              {!previewUrl ? <img src={iconeCamera} className="w-8" /> : null}
              {previewUrl && (
                <img
                  src={previewUrl}
                  alt="Preview"
                  className="w-full h-full rounded-full"
                />
              )}
            </div>

            <input
              id="file-selector-5"
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleFileInput}
              style={{ display: "none" }}
            />

            <div className="flex items-center gap-x-3 mt-5">
              <div className="">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Nom
                    </span>
                  </div>
                  <input
                    type="text"
                    value={animateurData.nom}
                    onChange={(e) =>
                      setAnimateurData((prev) => ({
                        ...prev,
                        nom: e.target.value,
                      }))
                    }
                    disabled={addLoading}
                    placeholder="Entrer un nom"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
              <div className="">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Prénoms
                    </span>
                  </div>
                  <input
                    type="text"
                    value={animateurData.prenoms}
                    onChange={(e) =>
                      setAnimateurData((prev) => ({
                        ...prev,
                        prenoms: e.target.value,
                      }))
                    }
                    disabled={addLoading}
                    placeholder="Entrer un prénom"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Contact
                    </span>
                  </div>
                  <input
                    type="text"
                    value={animateurData.contact}
                    onChange={(e) =>
                      setAnimateurData((prev) => ({
                        ...prev,
                        contact: e.target.value,
                      }))
                    }
                    disabled={addLoading}
                    placeholder="Entrer un contact"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
              <div className="">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Second contact
                    </span>
                  </div>
                  <input
                    type="text"
                    value={animateurData.secondContact}
                    onChange={(e) =>
                      setAnimateurData((prev) => ({
                        ...prev,
                        secondContact: e.target.value,
                      }))
                    }
                    disabled={addLoading}
                    placeholder="Entrer un second contact"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
            </div>
            <div className="mt-1">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Email
                  </span>
                </div>
                <input
                  type="text"
                  value={animateurData.email}
                  onChange={(e) =>
                    setAnimateurData((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                  disabled={addLoading}
                  placeholder="Entrer un email"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>

            <div className="flex items-center gap-x-3 mt-4 pt-1 border-t">
              <div className="">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Nom parent
                    </span>
                  </div>
                  <input
                    type="text"
                    value={animateurData.nomParent}
                    onChange={(e) =>
                      setAnimateurData((prev) => ({
                        ...prev,
                        nomParent: e.target.value,
                      }))
                    }
                    disabled={addLoading}
                    placeholder="Entrer un nom"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
              <div className="">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Prénoms parent
                    </span>
                  </div>
                  <input
                    type="text"
                    value={animateurData.prenomsParent}
                    onChange={(e) =>
                      setAnimateurData((prev) => ({
                        ...prev,
                        prenomsParent: e.target.value,
                      }))
                    }
                    disabled={addLoading}
                    placeholder="Entrer un prénom"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Contact parent
                    </span>
                  </div>
                  <input
                    type="text"
                    value={animateurData.contactParent}
                    onChange={(e) =>
                      setAnimateurData((prev) => ({
                        ...prev,
                        contactParent: e.target.value,
                      }))
                    }
                    disabled={addLoading}
                    placeholder="Entrer un contact"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
              <div className="">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Second contact parent
                    </span>
                  </div>
                  <input
                    type="text"
                    value={animateurData.secondContactParent}
                    onChange={(e) =>
                      setAnimateurData((prev) => ({
                        ...prev,
                        secondContactParent: e.target.value,
                      }))
                    }
                    disabled={addLoading}
                    placeholder="Entrer un second contact"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
            </div>
            <div className="mt-1">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Email parent
                  </span>
                </div>
                <input
                  type="text"
                  value={animateurData.emailParent}
                  onChange={(e) =>
                    setAnimateurData((prev) => ({
                      ...prev,
                      emailParent: e.target.value,
                    }))
                  }
                  disabled={addLoading}
                  placeholder="Entrer un email"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>

            {animateurInfo === null ? (
              <div>
                <h2 className="mt-4 text-lg text-primary font-extrabold">
                  Paiement
                </h2>
                <div className="mt-2 flex items-center gap-x-3">
                  <div className="">
                    <label className="form-control w-full">
                      <div className="label">
                        <span className="label-text font-bold text-gray-500 text-sm">
                          Motif paiement
                        </span>
                      </div>
                      <select
                        value={animateurData.motifPaiement}
                        onChange={(e) =>
                          setAnimateurData((prev) => ({
                            ...prev,
                            motifPaiement: e.target.value,
                          }))
                        }
                        className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                      >
                        <option disabled selected value="">
                          Choisir un élément
                        </option>
                        {listeMotifPaiement.map((item, index) => (
                          <option
                            key={index}
                            value={item._id}
                            className="font-medium"
                          >
                            {item.designation}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                  <div className="w-full">
                    <label className="form-control w-full">
                      <div className="label">
                        <span className="label-text font-bold text-gray-500 text-sm">
                          Mode paiement
                        </span>
                      </div>
                      <select
                        value={animateurData.modePaiement}
                        onChange={(e) =>
                          setAnimateurData((prev) => ({
                            ...prev,
                            modePaiement: e.target.value,
                          }))
                        }
                        className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                      >
                        <option disabled selected value="">
                          Choisir un élément
                        </option>
                        {listeModePaiement.map((item, index) => (
                          <option
                            key={index}
                            value={item._id}
                            className="font-medium"
                          >
                            {item.designation}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </div>
                <div className="w-full">
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-bold text-gray-500 text-sm">
                        Montant
                      </span>
                    </div>
                    <input
                      type="text"
                      value={animateurData.montant}
                      onChange={(e) =>
                        setAnimateurData((prev) => ({
                          ...prev,
                          montant: e.target.value,
                        }))
                      }
                      disabled={addLoading}
                      placeholder="Entrer un montant"
                      className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                    />
                  </label>
                </div>
                <div className="">
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-bold text-gray-500 text-sm">
                        Commentaire
                      </span>
                    </div>
                    <input
                      type="text"
                      value={animateurData.commentaire}
                      onChange={(e) =>
                        setAnimateurData((prev) => ({
                          ...prev,
                          commentaire: e.target.value,
                        }))
                      }
                      disabled={addLoading}
                      placeholder="Entrer un commentaire"
                      className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                    />
                  </label>
                </div>
              </div>
            ) : null}

            <button
              onClick={
                animateurInfo === null
                  ? () => enregistrerAnimateur()
                  : () => modifierAnimateur()
              }
              disabled={addLoading}
              className="mt-8 bg-primary flex items-center justify-center w-full h-11 font-bold text-white rounded-lg hover:drop-shadow-md"
            >
              {!addLoading ? (
                <span>
                  {animateurInfo === null ? "Enregistrer" : "Modifier"}
                </span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={addLoading}
                />
              )}
            </button>
          </div>
        </div>
      </Drawer>

      {/* DETAIL */}
      <Drawer
        anchor={"right"}
        open={modalShowDetailAnimateur}
        onClose={() => {
          setAnimateurInfo(null);
          setModalShowDetailAnimateur(false);
        }}
      >
        <div className="w-screen sm:w-[450px] min-h-screen p-4 bg-white">
          <button
            onClick={() => {
              setAnimateurInfo(null);
              setModalShowDetailAnimateur(false);
            }}
          >
            <img src={iconeCloseWindow} alt="" />
          </button>

          <h2 className="mt-3 text-xl font-extrabold">
            A propos du catéchumène
          </h2>

          {animateurInfo ? (
            <div className="mt-6 pb-16">
              <div className="h-24 w-24 rounded-xl mx-auto bg-gray-100 flex items-center justify-center border-4 border-gray-200 shadow p-1">
                {animateurInfo.photo ? (
                  <img
                    src={animateurInfo.photo}
                    className="w-full h-full rounded-lg"
                  />
                ) : (
                  <img src={iconeDefaultUser} className="w-8" />
                )}
              </div>
              <div className="w-44 mx-auto mt-4">
                <button className="w-full h-10 rounded-lg bg-black text-white text-sm font-semibold">
                  Générer le badge
                </button>
              </div>
              <div className="mt-5">
                <p className="text-base text-primary font-extrabold">
                  Informations
                </p>
                <div className="mt-2 w-full h-fit bg-gray-100 rounded-xl shadow-sm p-3">
                  <div className="text-black text-base font-bold">
                    <span className="text-gray-500">Nom prénoms: </span>
                    {animateurInfo.nom} {animateurInfo.prenoms}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Contact: </span>
                    {animateurInfo.contact}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Second contact: </span>
                    {animateurInfo.secondContact}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Email: </span>
                    {animateurInfo.email}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Nom prénoms parent: </span>
                    {animateurInfo.nomParent} {animateurInfo.prenomsParent}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Contact parent: </span>
                    {animateurInfo.contactParent}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">
                      Second contact parent:{" "}
                    </span>
                    {animateurInfo.secondContactParent}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Email parent: </span>
                    {animateurInfo.emailParent}
                  </div>
                  <div className="text-black text-base font-bold mt-2">
                    <span className="text-gray-500">Date d'inscription: </span>
                    {new Date(animateurInfo.createdAt).toLocaleDateString()}
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <button
                  className="w-full h-11 rounded-lg bg-primary text-white text-sm font-semibold flex items-center justify-center"
                  onClick={() => {
                    document
                      .getElementById("delete_animateur_clase")
                      .showModal();
                  }}
                >
                  Supprimer de la classe
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </Drawer>

      {/* MODAL DELETE */}
      <dialog id="delete_animateur_clase" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-center text-primary">
            Supprimer de cette classe
          </h3>
          <div className="pt-4 pb-2">
            <p className="text-center text-base font-medium text-gray-600">
              Attention action est irrversible et entrainera la suppression du
              catéchumène de cette classe.
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? (
                <div
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={supprimerAnimateurFromClasse}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      <SnackbarComponent
        vertical={vertical}
        horizontal={horizontal}
        snackbarSuccessOpen={snackbarSuccessOpen}
        handleCloseSnackbarSuccess={handleCloseSnackbarSuccess}
        successMessage={successMessage}
        snackbarErrorOpen={snackbarErrorOpen}
        handleCloseSnackbarError={handleCloseSnackbarError}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default ClasseAnimateurs;
