import React from "react";
import BaseLayout from "../../layout/BaseLayout";

const Dashboard = () => {
  return (
    <BaseLayout>
      <div className="w-full h-full flex items-center justify-center pt-32">
        <p className="text-2xl sm:text-3xl text-primary text-center font-extrabold">En cours de réalisation...</p>
      </div>
    </BaseLayout>
  );
};

export default Dashboard;
