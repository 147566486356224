export const formatDate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1; // Month is zero-based, so we add 1
  const year = date.getFullYear();

  const jour = new Date()

  const hours = date.getHours()
  const minutes = date.getMinutes()

  // Pad day and month with leading zeros if needed
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Construct the formatted date string
  const formattedDate = `${formattedDay}-${formattedMonth}-${year}, ${formattedHours}:${formattedMinutes}`;
  return formattedDate;
}