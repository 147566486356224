import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import LOGO_WHITE from "./../../assets/images/logo-preview.png";
import { ThreeDots } from "react-loader-spinner";
import ArrowBack from "./../../assets/icons/arrowRetour.png";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [messageAlert, setMessageAlert] = useState("");

  useEffect(() => {
    function isEventExpired(currentDate, endDateInMilliseconds) {
      // Convert the milliseconds to a Date object
      const endDate = new Date(endDateInMilliseconds);

      // Compare the two dates
      if (currentDate > endDate) {
        // Event has expired
        return true;
      } else {
        // Event is still within time
        return false;
      }
    }

    if (localStorage.getItem("cc_pssm_api")) {
      const currentDate = new Date();
      const decodedToken = jwtDecode(localStorage.getItem("cc_pssm_api"));
      const expirationDate = new Date(decodedToken.exp * 1000);

      if (decodedToken.exp && !isEventExpired(currentDate, expirationDate)) {
        navigate("/dashboard");
      }
    }
  }, []);

  return (
    <div className="w-full min-h-screen flex items-center">
      <div className="flex flex-col pattern h-full w-full gap-8 px-4 md:w-1/2 min-h-screen items-center justify-center">
        <div className="block lg:hidden md:hidden lg:w-1/2 lg:h-full md:w-2/4 md:h-full bg-white px-4 py-8 rounded-lg">
          <div className="w-full h-fit rounded-lg">
            <div
              onClick={() => navigate("/login")}
              className="flex flex-row justify-start items-center gap-2  cursor-pointer"
            >
              <img onClick={() => navigate("/login")} src={ArrowBack} alt="" />
              <h2 className="text-primary ">Retour</h2>
            </div>
            <img src={LOGO_WHITE} className="w-20" />

            <p className="mt-3 text-zinc-600 font-medium">
              Entrez votre adresse email et nous vous enverrons un email pour
              modifier votre mot de passe
            </p>
            <div className="mt-5">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text font-medium text-gray-500">Email</span>
                </label>
                <input
                  type="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="exemple@gmail.com"
                  className="input input-bordered w-full font-medium text-black"
                />
              </div>

              {messageAlert ? (
                <div className="mt-4">
                  <p className="text-red-500 text-sm text-center font-medium">
                    {messageAlert}
                  </p>
                </div>
              ) : null}
              <button
                // onClick={handleSubmit}
                className="mt-5 bg-primary flex items-center justify-center w-full h-10 font-medium text-white rounded-lg hover:drop-shadow-md"
              >
                {!isLoading ? (
                  <span>Recevoir le mail</span>
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#000"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={isLoading}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden xl:flex justify-center items-center lg:flex md:flex md:w-1/2 xl:w-1/2 lg:w-1/2 lg:min-h-screen  md:min-h-screen bg-white">
        <div className="w-[80%] md:[70%] lg:w-[70%] xl:w-[70%] mx-auto h-fit">
          <div
            onClick={() => navigate("/login")}
            className="flex flex-row justify-start items-center mb-[2rem] gap-2  cursor-pointer"
          >
            <img onClick={() => navigate("/login")} src={ArrowBack} alt="" />
            <h2 className="text-primary ">Retour</h2>
          </div>
          <img src={LOGO_WHITE} alt="" className="w-20" />
          <h1 className="text- text-2xl lg:text-3xl text-black font-extrabold mt-2">
            Mot de passe oublié
          </h1>
          <p className="mt-3 text-zinc-600 font-medium">
            Entrez vos informations pour vous connecter et accéder à
            l’application
          </p>
          <div className="mt-5">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text font-medium text-gray-500">Email</span>
              </label>
              <input
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="exemple@gmail.com"
                className="input input-bordered w-full bg-[#F9F9F9] font-medium text-black"
              />
            </div>

            {messageAlert ? (
              <div className="mt-4">
                <p className="text-red-500 text-sm text-center font-medium">
                  {messageAlert}
                </p>
              </div>
            ) : null}
            <button
              // onClick={handleSubmit}
              className="mt-5 bg-primary flex items-center justify-center w-full h-10 font-medium text-white rounded-lg hover:drop-shadow-md"
            >
              {!isLoading ? (
                <span>Se connecter</span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#000"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={isLoading}
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
