import BaseLayout from "../../layout/BaseLayout";
import iconethreeDots from "../../assets/IconeSvg/treeDotsGray.svg";
import iconeTestTube from "../../assets/IconeSvg/IconeTubeTest.svg";
import iconeAnalyse from "../../assets/IconeSvg/iconeAnalyse.svg";
import iconeCloseWindow from "../../assets/IconeSvg/close_window.svg";
import iconeAppareilPhoto from "../../assets/IconeSvg/appareil_photo.svg";
import { Drawer, Pagination, Snackbar } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  creatCatechumene,
  exportListeCatechumene,
  ListeCompleteCatechumene,
  listeHistoriqueCatechumeneAnalyse,
  rejeterInscription,
  RevaliderInscription,
  updateCatechumene,
  validerInscription
} from "../../services/catechumene/CatechumeneRequest";
import { listeCompleteDesCommissionsParAnnee } from "../../services/parametrage/CommissionRequest";
import { listeCompleteDesNiveauParCommission } from "../../services/parametrage/NiveauxRequest";
import {
  listeCompleteDesClasses,
  listeDesClassePaginee
} from "../../services/parametrage/ClasseRequest";

import { supprimerUneCatechumene } from "../../services/catechumene/CatechumeneRequest";
import { listeCompleteDesMotifRejetInscriptions } from "../../services/parametrage/MotifRejetInscriptionRequest";
import { listeCompleteDesTarifications } from "../../services/parametrage/TarificationRequest";
import {
  returnPermissionsParMenu,
  verificationPermissionsExistantes
} from "../../utils/ManageUserProfil";

const listePermissions = returnPermissionsParMenu("/catechumenes");

const Catechumenes = () => {
  const currentAnnee = localStorage.getItem("currentAnnee")
    ? JSON.parse(localStorage.getItem("currentAnnee"))
    : null;

  const userInfo = localStorage.getItem("cc_pssm_admin")
    ? JSON.parse(localStorage.getItem("cc_pssm_admin"))
    : null;
  const navigate = useNavigate();
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 30,
    nombrePage: 1,
    pagePagination: 1
  });

  const fileInputRef = useRef(null);
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [nomCatechu, setNomCatechu] = useState("");
  const [prenomsCatechu, setPrenomsCatechu] = useState("");
  const [telCatechu, setTelCatechu] = useState("");
  const [MontantPaye, setMontantPaye] = useState("");
  const [EmailCatechu, setEmailCatechu] = useState("");
  const [secondTelCatechu, setSecondTelCatechu] = useState("");
  const [EmailPereCatechu, setEmailPereCatechu] = useState("");
  const [SecondContactPereCatechu, setSecondContactPereCatechu] = useState("");
  const [ContactPereCatechu, setContactPereCatechu] = useState("");
  const [PrenomsPereCatechu, setPrenomsPereCatechu] = useState("");
  const [NomPereCatechu, setNomPereCatechu] = useState("");

  const [EmailMereCatechu, setEmailMereCatechu] = useState("");
  const [SecondContactMereCatechu, setSecondContactMereCatechu] = useState("");
  const [ContactMereCatechu, setContactMereCatechu] = useState("");
  const [PrenomsMereCatechu, setPrenomsMereCatechu] = useState("");
  const [NomMereCatechu, setNomMereCatechu] = useState("");

  const [nomPrenomsTuteurCatechu, setNomPrenomsTuteurCatechu] = useState("");
  const [SecondContactTuteurCatechu, setSecondContactTuteurCatechu] =
    useState("");
  const [ContactTuteurCatechu, setContactTuteurCatechu] = useState("");

  const [selectClasseCatechu, setSelectClasseCatechu] = useState(null);
  const [
    selectClasseCatechuForAffectation,
    setSelectClasseCatechuForAffectation
  ] = useState(null);
  const [selectNiveau, setSelectNiveau] = useState(null);
  const [selectNiveauAffectation, setSelectNiveauAffectation] = useState(null);
  const [selectCommission, setSelectCommission] = useState(null);
  const [idAnneeScolaire, setIdAnneeScolaire] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectModePaiement, setSelectModePaiement] = useState("");
  const [ListeCatechume, setListeCatechume] = useState([]);
  const [ListeCommission, setListeCommission] = useState([]);
  const [ListeNiveau, setListeNiveau] = useState([]);
  const [ListeClasse, setListeClasse] = useState([]);
  const [listeClasseForAffectation, setListeClasseForAffectation] = useState(
    []
  );
  const [montantInscription, setMontantInscription] = useState("");
  const [listeMotifRejetInscription, setListeMotifRejetInscription] = useState(
    []
  );
  const [listeanneeScolaire, setListeanneeScolaire] = useState([]);
  const [selectMotifRejet, setSelectMotifRejet] = useState("");
  const [commentaireRejet, setCommentaireRejet] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [commissionSelected, setCommissionSelected] = useState("");
  const [niveauSelected, setNiveauSelected] = useState("");
  const [classeSelected, setClasseSelected] = useState("");
  const [infosCatechumene, setInfosCatechumene] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validLording, setValidLording] = useState(false);
  const [rejetLording, setRejetLording] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [typesStatus, setTypesStatus] = useState("ALL");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;
  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };
  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const returnBadgeStatut = (statutRejet, statutValide) => {
    if (statutRejet) {
      return (
        <span className="truncate w-fit bg-red-100 px-2 py-1 font-semibold text-xs text-red-700 tracking-wider rounded-sm">
          Réfusé
        </span>
      );
    } else if (statutValide) {
      return (
        <span className="truncate w-fit bg-green-100 px-2 py-1 font-semibold text-xs text-green-600 tracking-wider rounded-sm">
          Validé
        </span>
      );
    } else {
      return (
        <span className="truncate w-fit bg-gray-200 px-2 py-1 font-semibold text-xs text-gray-600 tracking-wider rounded-sm">
          En vérification
        </span>
      );
    }
  };

  const clearInputEnregistrement = () => {
    setFile("");
    setPreview("");
    setNomCatechu("");
    setPrenomsCatechu("");
    setTelCatechu("");
    setSecondTelCatechu("");
    setSelectCommission("");
    setSelectNiveau("");
    setSelectClasseCatechu("");
    setNomPereCatechu("");
    setPrenomsPereCatechu("");
    setContactPereCatechu("");
    setSecondContactPereCatechu("");
    setEmailPereCatechu("");
    setNomMereCatechu("");
    setPrenomsMereCatechu("");
    setContactMereCatechu("");
    setSecondContactMereCatechu("");
    setEmailMereCatechu("");
    setNomPrenomsTuteurCatechu("");
    setContactTuteurCatechu("");
    setSecondContactTuteurCatechu("");
    setMontantPaye("");
    setSelectCommission("");
    setSelectNiveau("");
    setSelectClasseCatechu("");
  };

  const deleteCatechu = () => {
    setDeleteLoading(true);
    console.log(infosCatechumene);
    supprimerUneCatechumene(infosCatechumene._id)
      .then((res) => {
        setDeleteLoading(false);
        console.log(res);
        setSuccessMessage("Catéchumène supprimé avec succès");
        setSnackbarOpenSuccess(true);
        ListeDesCatechumene();
        document.getElementById("updateStatut_catechumene").close();
      })
      .catch((err) => {
        setDeleteLoading(false);
        console.log("api error", err);
        setErrorMessage("Erreur de suppression de l utilisateur, réessayez!");
        setSnackbarOpenError(true);
      });
  };

  // === RECUPERE LISTE DES NIVEAUX FOR MODIFS INFOS CATECHU===
  const getListeDesNiveauxParCommission = (selectCommission) => {
    listeCompleteDesNiveauParCommission(selectCommission)
      .then((res) => {
        setListeNiveau(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES NIVEAUX FOR AFFECTATION CATECHU ===
  const getListeDesNiveauxParCommissionAffectation = () => {
    listeCompleteDesNiveauParCommission(infosCatechumene.commission._id)
      .then((res) => {
        setListeNiveau(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES CLASSES FOR AFFECTATION CATECHU ===
  const getListeDesClasseAffectationCatechu = () => {
    setLoadingData(true);
    listeCompleteDesClasses(
      infosCatechumene.commission._id,
      selectNiveauAffectation
    )
      .then((res) => {
        setLoadingData(false);
        setListeClasseForAffectation(res.data);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesCommission = () => {
    listeCompleteDesCommissionsParAnnee(currentAnnee._id)
      .then((res) => {
        setListeCommission(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const ListeDesCatechumene = () => {
    setIsLoading(true);

    listeHistoriqueCatechumeneAnalyse(
      "",
      "",
      "",
      "",
      0,
      pageQuery.size,
      typesStatus
    )
      .then((res) => {
        setIsLoading(false);
        setListeCatechume(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          page: 0,
          pagePagination: 1,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorMessage("Erreur lors de la récupération des administrateurs");
      });
  };

  const handlePaginationChange = (event, value) => {
    setIsLoading(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value
    }));
    if (searchTerm.length !== 0) {
      listeHistoriqueCatechumeneAnalyse(
        searchTerm,
        commissionSelected,
        niveauSelected,
        classeSelected,
        value,
        pageQuery.size,
        typesStatus
      )
        .then((res) => {
          setListeCatechume(res.data.results);
          setPageQuery((prev) => ({
            ...prev,
            nombrePage: res.data.totalPages,
            totalElements: res.data.totalElements
          }));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("api error", err);
        });
    } else {
      listeHistoriqueCatechumeneAnalyse(
        searchTerm,
        commissionSelected,
        niveauSelected,
        classeSelected,
        value,
        pageQuery.size,
        typesStatus
      )
        .then((res) => {
          setIsLoading(false);
          setListeCatechume(res.data.results);
          setPageQuery((prev) => ({
            ...prev,
            nombrePage: res.data.totalPages
          }));
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("api error", err);
        });
    }
  };

  const parcoursCatechumene = infosCatechumene
    ? [
        {
          niveau: "Première Année",
          data: infosCatechumene.catechumene.premireAnnee
        },
        {
          niveau: "Deuxième Année",
          data: infosCatechumene.catechumene.deuxiemeAnnee
        },
        {
          niveau: "Troisième Année",
          data: infosCatechumene.catechumene.troisiemeAnnee
        },
        {
          niveau: "Quatrième Année",
          data: infosCatechumene.catechumene.quatriemeAnnee
        },
        {
          niveau: "Cinquième Année",
          data: infosCatechumene.catechumene.cinquiemeAnnee
        }
      ].filter(
        (annee) =>
          annee.data &&
          annee.data.date &&
          annee.data.paroisse &&
          annee.data.mvtGroupePriere
      )
    : [];

  const searchCatechu = () => {
    setSearchLoading(true);
    listeHistoriqueCatechumeneAnalyse(
      searchTerm,
      commissionSelected,
      niveauSelected,
      classeSelected,
      1,
      pageQuery.size,
      typesStatus
    )
      .then((res) => {
        setSearchLoading(false);
        setListeCatechume(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((err) => {
        setSearchLoading(false);
        console.log("api error", err);
      });
  };

  // EXPORT LISTE CATECHUMENE PAR CLASSE
  const exporterListeCatechumeneInscrit = () => {
    setLoadingExport(true);
    exportListeCatechumene(
      commissionSelected,
      niveauSelected,
      classeSelected,
      typesStatus
    )
      .then((res) => {
        setLoadingExport(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Recap-liste-catechumene-${typesStatus}.xlsx`
        );
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        setLoadingExport(false);
        console.log("api error", err);
      });
  };

  const formatDate = (date) => {
    // Vérifier si la date est valide
    if (!date) {
      return ""; // Retourner une chaîne vide ou un message par défaut si la date est null/undefined
    }

    // Supprimer les guillemets échappés
    date = date.replace(/\"/g, "");

    // Convertir en objet Date
    date = new Date(date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const handleValideInsciprion = () => {
    setValidLording(true);
    const data = {
      niveau: selectNiveauAffectation,
      classe: selectClasseCatechuForAffectation
    };

    if (infosCatechumene && !infosCatechumene.inscriptionRejete) {
      validerInscription(infosCatechumene._id, data)
        .then((res) => {
          console.log(res);
          setValidLording(false);
          setSuccessMessage("Inscription valider avec succès");
          setSnackbarOpenSuccess(true);
          setSelectClasseCatechuForAffectation("");
          setSelectNiveauAffectation("")
          document.getElementById("accept_catechumene").close();
          setOpenModal(false);
          ListeDesCatechumene();
        })
        .catch((err) => {
          console.log(err);
          setValidLording(false);
          setErrorMessage(
            "Erreur pendant la validation, veuillez reesayer svp"
          );
          setSnackbarOpenError(true);
        });
    } else {
      RevaliderInscription(infosCatechumene._id, data)
        .then((res) => {
          console.log(res);
          setValidLording(false);
          setSuccessMessage("Inscription valider avec succès");
          setSnackbarOpenSuccess(true);
          document.getElementById("accept_catechumene").close();
          setOpenModal(false);
          ListeDesCatechumene();
        })
        .catch((err) => {
          console.log(err);
          setValidLording(false);
          setErrorMessage(
            "Erreur pendant la validation, veuillez reesayer svp"
          );
          setSnackbarOpenError(true);
        });
    }
  };

  const handleRejetInsciprion = () => {
    setRejetLording(true);

    const dataRejet = {
      motif: selectMotifRejet,
      commentaire: commentaireRejet
    };
    rejeterInscription(infosCatechumene._id, dataRejet)
      .then((res) => {
        setRejetLording(false);
        setSuccessMessage("Inscription rejeter avec succès");
        setSnackbarOpenSuccess(true);
        setCommentaireRejet("");
        setSelectMotifRejet("");
        document.getElementById("refuser_catechumene").close();
        setOpenModal(false);
        ListeDesCatechumene();
      })
      .catch((err) => {
        setRejetLording(false);
        setErrorMessage(
          "Erreur pendant le rejet de l'inscription, veuillez reesayer svp"
        );
        setSnackbarOpenError(true);
      });
  };

  const ListeMotifRejetInscription = () => {
    listeCompleteDesMotifRejetInscriptions()
      .then((res) => {
        setLoadingData(false);
        setListeMotifRejetInscription(res.data);
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const getListeTarificationMotifPaiement = () => {
    listeCompleteDesTarifications(
      "66a6e1f43d332a3f7d6de357",
      idAnneeScolaire ? idAnneeScolaire : ""
    )
      .then((res) => {
        // setListeTarificationMotifPaiement(res.data);
        setMontantInscription(res.data[0].montant);
      })
      .catch((err) => {
        console.log("ERREUR", err);
      });
  };

  const returnStatutBarNavig = (statut) => {
    return (
      <div
        className={`truncate px-1.5 lg:px-3 md:px-3 xl:px-3 lg:h-9 xl:h-9 md:h-9 h-7 flex items-center justify-center gap-x-1 rounded-md text-[13px] font-semibold cursor-pointer ${
          typesStatus === statut.value
            ? "text-white bg-primary"
            : "bg-gray-100 text-gray-600"
        }`}
        onClick={() => {
          if (!isLoading) {
            setTypesStatus(statut.value);
          }
        }}
      >
        {statut.libelle}
      </div>
    );
  };

  // === RECUPERE LISTE DES CLASSES PAR NIVEAU ===
  const getListeDesClassesParNiveau = (commission = "", niveau = "") => {
    setListeClasse([]);

    listeCompleteDesClasses(commission, niveau)
      .then((res) => {
        setListeClasse(res.data);
        setClasseSelected("");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const recupereDataDeLaTab = () => {
    ListeDesCatechumene();
  };

  useEffect(() => {
    getListeDesCommission();
    ListeMotifRejetInscription();
    getListeTarificationMotifPaiement();
  }, []);

  useEffect(() => {
    ListeDesCatechumene();
  }, [typesStatus]);

  useEffect(() => {
    if (selectCommission) {
      getListeDesNiveauxParCommission(selectCommission);
    }
  }, [selectCommission]);

  useEffect(() => {
    if (infosCatechumene) {
      getListeDesNiveauxParCommissionAffectation();
    }
  }, [infosCatechumene]);

  useEffect(() => {
    if (selectNiveauAffectation) {
      getListeDesClasseAffectationCatechu();
    }
  }, [selectNiveauAffectation]);

  return (
    <BaseLayout>
      {verificationPermissionsExistantes(listePermissions, "VOIR_LISTE") ? (
        <div className="w-full ">
          <div className="">
            <div>
              <div className="w-full ">
                <h1 className="text-2xl md:text-3xl font-extrabold">
                  Catéchumènes
                </h1>
                <p className="text-gray-500 font-medium text-[16px]">
                  Ajoutez, modifiez ou supprimez des catéchumènes
                </p>
              </div>

              {/* TAB BAR MENU */}
              <div className="flex xl:flex-row pt-5 lg:flex-row md:flex-row flex-col gap-2 justify-between w-full">
                <div className="w-fit lg:h-11 md:h-11 xl:h-11 h-9 rounded-lg drop-shadow gap-1 px-1 bg-white flex items-center">
                  {[
                    { libelle: "Tout", value: "ALL" },
                    { libelle: "Validé", value: "VALIDER" },
                    { libelle: "Rejeté", value: "REJETER" },
                    { libelle: "En attente", value: "EN_ATTENTE" }
                  ].map((item) => returnStatutBarNavig(item))}
                </div>
              </div>

              <div className="mt-8 flex flex-col lg:flex-row justify-end items-end lg:items-start gap-2">
                {/* FILTRE DES ANIMATEURS */}
                <div className="w-full md:w-fit flex items-center gap-x-2 overflow-x-scroll sm:overflow-auto">
                  <input
                    type="text"
                    placeholder="Rechercher un élement..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="input input-bordered   h-10 text-sm font-medium"
                  />
                  <select
                    value={commissionSelected}
                    onChange={(e) => {
                      setCommissionSelected(e.target.value);
                      getListeDesNiveauxParCommission(e.target.value);
                    }}
                    className="select select-bordered custom-select w-32 h-10 font-medium"
                  >
                    <option disabled selected value="">
                      Commission
                    </option>
                    <option value="" className="font-medium">
                      Tout
                    </option>
                    {ListeCommission.map((item, index) => (
                      <option
                        key={index}
                        value={item._id}
                        className="font-medium"
                      >
                        {item.designation}
                      </option>
                    ))}
                  </select>
                  <select
                    value={niveauSelected}
                    onChange={(e) => {
                      setNiveauSelected(e.target.value);
                      getListeDesClassesParNiveau(
                        commissionSelected,
                        e.target.value
                      );
                    }}
                    className="select select-bordered custom-select w-36 h-10 font-medium"
                  >
                    <option disabled selected value="">
                      Niveau
                    </option>
                    <option value="" className="font-medium">
                      Tout
                    </option>
                    {ListeNiveau.map((item, index) => (
                      <option
                        key={index}
                        value={item._id}
                        className="font-medium"
                      >
                        {item.designation}
                      </option>
                    ))}
                  </select>
                  <select
                    value={classeSelected}
                    onChange={(e) => {
                      setClasseSelected(e.target.value);
                    }}
                    className="select select-bordered custom-select w-40 h-10 font-medium"
                  >
                    <option disabled selected value="">
                      Classe
                    </option>
                    <option value="" className="font-medium">
                      Tout
                    </option>
                    {ListeClasse.map((item, index) => (
                      <option
                        key={index}
                        value={item._id}
                        className="font-medium"
                      >
                        {item.designation}
                      </option>
                    ))}
                  </select>

                  <button
                    disabled={searchLoading}
                    className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
                    onClick={searchCatechu}
                  >
                    {!searchLoading ? (
                      "Rechercher"
                    ) : (
                      <ThreeDots
                        height="35"
                        width="35"
                        radius="9"
                        color="#fff"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={searchLoading}
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>

            {/* Liste des catéchumènes   */}
            <div className="mt-[2rem] grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 xsm:grid-cols-2 grid-cols-1 gap-3">
              {!isLoading && ListeCatechume.length > 0 ? (
                ListeCatechume.map((item, index) => {
                  const commission = ListeCommission.find(
                    (commissionItem) =>
                      commissionItem._id === item.commission._id
                  );
                  return (
                    <div
                      key={index}
                      onClick={() =>
                        verificationPermissionsExistantes(
                          listePermissions,
                          "VOIR_DETAIL"
                        )
                          ? navigate(`/catechumenes/${item.catechumene._id}`)
                          : ""
                      }
                      className="relative w-full h-full p-2 bg-[#fdfdfd] hover:shadow-md rounded-lg border cursor-pointer "
                    >
                      {!item.inscriptionValide ? (
                        <div className="absolute right-2 flex items-center justify-end w-full gap-x-2">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              setInfosCatechumene(item);
                            }}
                            className="dropdown dropdown-bottom dropdown-end"
                          >
                            <button
                              onClick={() => setInfosCatechumene(item)}
                              className="w-7 h-7 rounded-lg bg-gray-200 flex items-center justify-center"
                            >
                              <img src={iconethreeDots} alt="" />
                            </button>
                            <ul
                              tabIndex={0}
                              className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-md border w-32"
                            >
                              {item.inscriptionValide ||
                              (item.inscriptionRejete &&
                                verificationPermissionsExistantes(
                                  listePermissions,
                                  "REEVALUER"
                                )) ? (
                                <button
                                  onClick={() => {
                                    setOpenModal(true);
                                  }}
                                  className="rounded-lg flex gap-x-1 items-center"
                                >
                                  <img
                                    src={iconeTestTube}
                                    className="w-6"
                                    alt=""
                                  />
                                  <span className="font-semibold text-black text-sm">
                                    Réévaluer
                                  </span>
                                </button>
                              ) : (
                                verificationPermissionsExistantes(
                                  listePermissions,
                                  "ANALYSER"
                                ) && (
                                  <button
                                    onClick={() => {
                                      setOpenModal(true);
                                    }}
                                    className="rounded-lg flex gap-x-1 items-center"
                                  >
                                    <img
                                      className="w-6"
                                      src={iconeAnalyse}
                                      alt=""
                                    />
                                    <span className="font-semibold text-black text-sm">
                                      Analyser
                                    </span>
                                  </button>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      ) : null}

                      <img
                        src={item.catechumene && item.catechumene.photo}
                        alt=""
                        className="rounded-lg w-16 h-16 border"
                      />

                      <div className="w-11/12 mt-2">
                        <h2 className="text-black text-base font-extrabold truncate">
                          {item.catechumene && item.catechumene.nom}{" "}
                          {item.catechumene && item.catechumene.prenoms}
                        </h2>
                        <p className="mt-1 text-sm text-left font-semibold text-gray-500">
                          <span className="text-primary font-semibold">
                            Contacts:{" "}
                          </span>
                          {item.catechumene && item.catechumene.contact} /{" "}
                          {item.catechumene && item.catechumene.secondContact}
                        </p>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-left font-semibold text-gray-500">
                          <span className="text-primary font-semibold">
                            Commission:{" "}
                          </span>
                          {commission ? commission.designation : "N/A"}
                        </p>

                        <p className="mt-1.5 text-sm text-left font-semibold text-gray-500 pb-2">
                          <span className="text-primary font-semibold">
                            Statut inscription:{" "}
                          </span>
                          {returnBadgeStatut(
                            item.inscriptionRejete,
                            item.inscriptionValide
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : !isLoading && ListeCatechume.length === 0 ? (
                <div className="flex col-span-full w-full items-center justify-center h-[55vh]">
                  <div className="flex items-center justify-center text-red-700">
                    <p className="font-extrabold">Aucun catéchumène trouvé</p>
                  </div>
                </div>
              ) : (
                <div className="flex col-span-full w-full items-center justify-center h-[55vh]">
                  <div className="w-full h-32 rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-700 items-center justify-center">
                    <TailSpin
                      height="50"
                      width="50"
                      color="#000"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end mt-6 pb-16">
            <Pagination
              count={pageQuery.nombrePage}
              page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginationChange}
            />
          </div>
        </div>
      ) : (
        <div className="h-56 flex items-center justify-center">
          <p className="text-base text-primary font-extrabold">
            Vous n'avez pas accès à ce menu.
          </p>
        </div>
      )}

      {/* MODAL ANALYSE INSCRIPTION CATECHUMENE */}
      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          clearInputEnregistrement();
        }}
      >
        <div className="md:w-[500px] w-[360px] px-1 pt-2 pl-2 flex flex-col bg-white ">
          <div className="flex justify-start ">
            <img
              onClick={() => setOpenModal(false)}
              src={iconeCloseWindow}
              className="cursor-pointer"
              alt=""
            />
          </div>

          <h2 className="mt-3 ml-2 text-xl text-black font-extrabold">
            {infosCatechumene &&
            infosCatechumene.inscriptionRejete &&
            !infosCatechumene.inscriptionValide
              ? "Ré-analyse inscription du catéchumène"
              : "Analyse inscription catéchumène"}
          </h2>

          <div className="flex justify-center items-center  mt-6 h-24 w-full ">
            <div className="flex justify-center items-center w-20 h-20 bg-[#F9F9F9] rounded-full cursor-pointer">
              <div className="relative">
                <img
                  src={infosCatechumene && infosCatechumene.catechumene.photo}
                  alt=""
                  className="w-20 h-20 rounded-full"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full py-4">
            {/* INFORMATIONS GENERALES */}
            <div className="py-2">
              <h2 className="font-extrabold text-primary text-md ">
                Informations générales
              </h2>
              <div className="flex flex-col justify-start  bg-[#F9F9F9] border gap-1 rounded-lg py-2 px-2">
                <div className=" flex justify-start flex-wrap items-center gap-2">
                  <span className="label-text font-bold text-md">
                    Nom & prénoms :
                  </span>
                  <span className="label-text font-medium text-md">
                    {` ${
                      infosCatechumene && infosCatechumene.catechumene.nom
                    }  ${
                      infosCatechumene && infosCatechumene.catechumene.prenoms
                    }`}
                  </span>
                </div>

                <div className="flex justify-start flex-wrap items-center gap-2">
                  <span className="label-text font-bold text-md">
                    Date & lieu naissance :
                  </span>
                  <span className="label-text font-medium text-md truncate">
                    {` ${
                      infosCatechumene &&
                      formatDate(infosCatechumene.catechumene.dateNaissance)
                    } à ${
                      infosCatechumene &&
                      infosCatechumene.catechumene.lieuNaissance
                    }`}
                  </span>
                </div>

                <div className="flex justify-start items-center gap-2">
                  <span className="label-text font-bold text-md">
                    Ecole / profession :
                  </span>
                  <span className="label-text font-medium text-md truncate">
                    {` ${
                      infosCatechumene &&
                      infosCatechumene.catechumene.ecoleProfession
                    }`}
                  </span>
                </div>

                <div className="flex justify-start items-center gap-2">
                  <span className="label-text font-bold text-md">
                    Niveau scolaire :
                  </span>
                  <span className="label-text font-medium text-md truncate">
                    {` ${
                      infosCatechumene &&
                      infosCatechumene.catechumene.classeNiveau.designation
                    }`}
                  </span>
                </div>

                <div className="flex justify-start items-center gap-2">
                  <span className="label-text font-bold text-md">
                    Quartier :
                  </span>
                  <span className="label-text font-medium text-md truncate">
                    {` ${
                      infosCatechumene &&
                      infosCatechumene.catechumene.quartierResidence
                    }`}
                  </span>
                </div>

                <div className="flex justify-start items-center gap-2">
                  <span className="label-text font-bold text-md">CEB :</span>
                  <span className="label-text font-medium text-md truncate">
                    {` ${infosCatechumene && infosCatechumene.catechumene.ceb}`}
                  </span>
                </div>

                <div className="flex justify-start items-center gap-2">
                  <span className="label-text font-bold text-md">
                    Contacts :
                  </span>
                  <span className="label-text font-medium text-md truncate">
                    {` ${
                      infosCatechumene && infosCatechumene.catechumene.contact
                    } ; ${
                      infosCatechumene &&
                      infosCatechumene.catechumene.secondContact
                    }`}
                  </span>
                </div>

                <div className="flex justify-start items-center gap-2">
                  <span className="label-text font-bold text-md">Email :</span>
                  <span className="label-text font-medium text-md truncate">
                    {` ${
                      infosCatechumene && infosCatechumene.catechumene.email
                    } `}
                  </span>
                </div>
              </div>
            </div>

            {infosCatechumene && infosCatechumene.inscriptionRejete ? (
              <>
                <h2 className="font-extrabold text-primary text-md ">
                  Motifs et commentaires du rejet
                </h2>
                <div className="flex flex-col my-2 justify-start  bg-[#F9F9F9] border gap-1 rounded-lg py-2 px-2">
                  <div className=" flex flex-col justify-start items-start gap-2">
                    <span className="label-text font-bold text-md">
                      Motif :
                    </span>
                    <span className="label-text font-medium text-md text-red-700">
                      {infosCatechumene &&
                      infosCatechumene.motifRejetInscription
                        ? listeMotifRejetInscription.find(
                            (rejetItem) =>
                              rejetItem._id ===
                              infosCatechumene.motifRejetInscription
                          )?.designation || "--"
                        : "--"}
                    </span>
                  </div>

                  <div className="flex flex-col justify-start items-start gap-2">
                    <span className="label-text font-bold text-md">
                      Commentaire :
                    </span>
                    {/* <hr className="w-full" /> */}
                    <div className=" flex justify-start items-center gap-2">
                      <p className="label-text font-medium text-md text-wrap">
                        {` ${
                          infosCatechumene &&
                          infosCatechumene.commentaireRejetInscription
                            ? infosCatechumene.commentaireRejetInscription
                            : "Information indisponible"
                        }  `}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* PARCOURS */}
                <div className="py-2">
                  <h2 className="font-extrabold text-primary text-md">
                    Parcours
                  </h2>
                  <div className="overflow-x-auto bg-white w-full mt-2">
                    <table className="custom-table table table-zebra w-full">
                      <thead>
                        <tr className="bg-black h-12">
                          <th className="text-sm text-white">Niveau</th>
                          <th className="text-sm text-white">Date</th>
                          <th className="text-sm text-white">Paroisse</th>
                          <th className="text-sm text-white">
                            Mvt/Grpe prière
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-[#F9F9F9]">
                        {parcoursCatechumene.map((annee, index) => (
                          <tr key={index} className="">
                            <td className="px-4 text-sm text-black font-medium">
                              {annee.niveau}
                            </td>
                            <td className="px-4 text-sm text-black font-medium">
                              {annee.data.date}
                            </td>
                            <td className="px-4 text-sm text-black font-medium">
                              {annee.data.paroisse}
                            </td>
                            <td className="px-4 text-sm text-black font-medium">
                              {annee.data.mvtGroupePriere}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* VIE CHRETIENNE  */}
                <div className="py-2">
                  <h2 className="font-extrabold text-primary text-md ">
                    Vie chrétienne
                  </h2>
                  <div className="flex flex-col justify-start  bg-[#F9F9F9] border gap-1 rounded-lg py-2 px-2">
                    <div className=" flex justify-start items-center gap-2">
                      <span className="label-text font-bold text-md">
                        Numéro carnet baptême :
                      </span>
                      <span className="label-text font-medium text-md">
                        {` ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.numeroCarnetBapteme
                        }  `}
                      </span>
                    </div>

                    <div className="flex justify-start items-center gap-2">
                      <span className="label-text font-bold text-md">
                        Date création du carnet :
                      </span>
                      <span className="label-text font-medium text-md truncate">
                        {` ${
                          infosCatechumene &&
                          formatDate(
                            infosCatechumene.catechumene
                              .dateCreationCarnetBapteme
                          )
                        } `}
                      </span>
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2">
                      <span className="label-text font-bold text-md">
                        Baptême :
                      </span>
                      <hr className="w-full" />
                      <h2 className="label-text font-medium text-md text-wrap truncate">
                        Baptisé(e) le
                        {` ${
                          infosCatechumene &&
                          infosCatechumene.catechumene &&
                          infosCatechumene.catechumene.bapteme &&
                          formatDate(
                            infosCatechumene.catechumene.bapteme.date || ""
                          )
                        } à ${
                          (infosCatechumene &&
                            infosCatechumene.catechumene &&
                            infosCatechumene.catechumene.bapteme &&
                            infosCatechumene.catechumene.bapteme.paroisse) ||
                          "N/A" // Ajout d'une valeur par défaut "N/A"
                        }, diocèse ${
                          (infosCatechumene &&
                            infosCatechumene.catechumene &&
                            infosCatechumene.catechumene.bapteme &&
                            infosCatechumene.catechumene.bapteme.diocese) ||
                          "N/A" // Ajout d'une valeur par défaut "N/A"
                        } `}
                      </h2>
                    </div>
                    <div className="flex flex-col justify-start items-start gap-2">
                      <span className="label-text font-bold text-md">
                        1ère communion :
                      </span>
                      <hr className="w-full" />
                      <h2 className="label-text font-medium text-md text-wrap truncate">
                        {infosCatechumene &&
                        infosCatechumene.catechumene &&
                        infosCatechumene.catechumene.premiereCommunion &&
                        infosCatechumene.catechumene.premiereCommunion.date &&
                        infosCatechumene.catechumene.numeroCarnetBapteme !==
                          "" ? (
                          `Le ${formatDate(
                            infosCatechumene.catechumene.premiereCommunion.date
                          )} à ${
                            infosCatechumene.catechumene.premiereCommunion
                              .paroisse || "N/A"
                          }, diocèse ${
                            (infosCatechumene.catechumene.bapteme &&
                              infosCatechumene.catechumene.bapteme.diocese) ||
                            "N/A"
                          } `
                        ) : (
                          <span className="text-red-700 text-md">
                            Pas baptisé, pas encore pris la communion
                          </span>
                        )}
                      </h2>
                    </div>
                  </div>
                </div>

                {/* INFORMATIONS PARENTS */}
                <div className="py-2 gap-2">
                  <h2 className="font-extrabold text-primary text-md ">
                    Informations des parents
                  </h2>
                  <div className="flex flex-col my-2 justify-start  bg-[#F9F9F9] border gap-1 rounded-lg py-2 px-2">
                    <div className=" flex justify-start flex-wrap items-center gap-2">
                      <span className="label-text font-bold text-md">
                        Nom & prénoms du père :
                      </span>
                      <span className="label-text font-medium text-md">
                        {` ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.pere.nom
                        }  ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.pere.prenoms
                        }`}
                      </span>
                    </div>

                    <div className="flex justify-start items-center gap-2">
                      <span className="label-text font-bold text-md">
                        Profession :
                      </span>
                      <span className="label-text font-medium text-md truncate">
                        {` ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.pere.profession
                        }`}
                      </span>
                    </div>

                    <div className="flex justify-start items-center gap-2">
                      <span className="label-text font-bold text-md">
                        Réligion :
                      </span>
                      <span className="label-text font-medium text-md truncate">
                        {` ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.pere.religion
                        }`}
                      </span>
                    </div>

                    <div className="flex justify-start items-center gap-2">
                      <span className="label-text font-bold text-md">
                        Contacts :
                      </span>
                      <span className="label-text font-medium text-md truncate">
                        {` ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.pere.contact
                        } ; ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.pere.secondContact
                        }`}
                      </span>
                    </div>

                    <div className="flex justify-start items-center gap-2">
                      <span className="label-text font-bold text-md">
                        Email :
                      </span>
                      <span className="label-text font-medium text-md truncate">
                        {` ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.pere.email
                        } `}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col my-2 justify-start  bg-[#F9F9F9] border gap-1 rounded-lg py-2 px-2">
                    <div className=" flex justify-start flex-wrap items-center gap-2">
                      <span className="label-text font-bold text-md">
                        Nom & prénoms de la mère :
                      </span>
                      <span className="label-text font-medium text-md">
                        {` ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.mere.nom
                        }  ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.mere.prenoms
                        }`}
                      </span>
                    </div>

                    <div className="flex justify-start items-center gap-2">
                      <span className="label-text font-bold text-md">
                        Profession :
                      </span>
                      <span className="label-text font-medium text-md truncate">
                        {` ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.mere.profession
                        }`}
                      </span>
                    </div>

                    <div className="flex justify-start items-center gap-2">
                      <span className="label-text font-bold text-md">
                        Réligion :
                      </span>
                      <span className="label-text font-medium text-md truncate">
                        {` ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.mere.religion
                        }`}
                      </span>
                    </div>

                    <div className="flex justify-start items-center gap-2">
                      <span className="label-text font-bold text-md">
                        Contacts :
                      </span>
                      <span className="label-text font-medium text-md truncate">
                        {` ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.mere.contact
                        } ; ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.mere.secondContact
                        }`}
                      </span>
                    </div>

                    <div className="flex justify-start items-center gap-2">
                      <span className="label-text font-bold text-md">
                        Email :
                      </span>
                      <span className="label-text font-medium text-md truncate">
                        {` ${
                          infosCatechumene &&
                          infosCatechumene.catechumene.mere.email
                        } `}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col my-2 justify-start  bg-[#F9F9F9] border gap-1 rounded-lg py-2 px-2">
                    <div className=" flex flex-col justify-start items-start gap-2">
                      <span className="label-text font-bold text-md">
                        Situation matrimoniale des parents ? :
                      </span>
                      <span className="label-text font-medium text-md text-red-700">
                        {infosCatechumene && infosCatechumene.catechumene
                          ? infosCatechumene.catechumene
                              .situationMatrimoniale === "AUTRES"
                            ? infosCatechumene.catechumene
                                .autreSituationMatrimoniale
                            : infosCatechumene.catechumene.situationMatrimoniale
                          : "Information indisponible"}
                      </span>
                    </div>

                    <div className="flex flex-col justify-start items-start gap-2">
                      <hr className="w-full" />
                      <span className="label-text font-bold text-md">
                        Personne à contacter en cas d'urgence :
                      </span>

                      <div className=" flex justify-start flex-wrap items-center gap-2">
                        <span className="label-text font-bold text-md">
                          Nom & prénoms :
                        </span>
                        <span className="label-text font-medium text-md">
                          {` ${
                            infosCatechumene &&
                            infosCatechumene.catechumene.personneAContacter
                              .nomPrenoms
                          }  `}
                        </span>
                      </div>

                      <div className="flex justify-start items-center gap-2">
                        <span className="label-text font-bold text-md">
                          Contacts :
                        </span>
                        <span className="label-text font-medium text-md truncate">
                          {` ${
                            infosCatechumene &&
                            infosCatechumene.catechumene.personneAContacter
                              .contact
                          } ; ${
                            infosCatechumene &&
                            infosCatechumene.catechumene.personneAContacter
                              .secondContact
                          }`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* PAIEMENT  */}
            <div className="py-2">
              <h2 className="font-extrabold text-primary text-md ">
                Paiement de l'inscription
              </h2>
              <div className="flex flex-col justify-start  bg-[#F9F9F9] border gap-1 rounded-lg py-2 px-2">
                <div className=" flex justify-start items-center gap-2">
                  <span className="label-text font-bold text-md">
                    Montant versé : 5000
                  </span>
                  <span className="label-text font-medium text-md">
                    {infosCatechumene && infosCatechumene.paiementInscription
                      ? infosCatechumene.paiementInscription.montant
                      : "--"}
                    FCFA le{" "}
                    {infosCatechumene &&
                      formatDate(
                        infosCatechumene.paiementInscription.datePaiement
                      )}{" "}
                    sur le
                  </span>
                </div>
                <span>
                  {infosCatechumene &&
                  infosCatechumene.paiementInscription.modePaiement
                    ? ` ${infosCatechumene.paiementInscription.modePaiement.numeroPaiement} , ${infosCatechumene.paiementInscription.modePaiement.designation}`
                    : "--"}
                </span>

                <span>
                  {infosCatechumene &&
                  infosCatechumene.paiementInscription.motifPaiement
                    ? `${infosCatechumene.paiementInscription.motifPaiement.description}`
                    : "--"}
                </span>

                <div className=" flex justify-start items-center gap-2">
                  <span className="label-text font-bold text-md">
                    Référence du paiement :
                  </span>
                  <span className="label-text font-medium text-md">
                    {infosCatechumene && infosCatechumene.paiementInscription
                      ? infosCatechumene.paiementInscription.referencePaiement
                      : "--"}
                  </span>
                </div>

                <div className="flex flex-col justify-start items-start gap-2">
                  <span className="label-text font-bold text-md">
                    Photo du reçu de paiement :
                  </span>
                  <div className="flex justify-center items-center w-full h-full rounded-lg cursor-pointer">
                    <div className="relative w-full h-64">
                      <img
                        src={
                          infosCatechumene &&
                          infosCatechumene.paiementInscription.photoRecuPaiement
                        }
                        alt="Preview"
                        className="w-full h-full object-contain rounded-lg "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-center items-center gap-2">
              {infosCatechumene && !infosCatechumene.inscriptionRejete ? (
                <button
                  onClick={() => {
                    window.refuser_catechumene.showModal();
                  }}
                  className="mt-5 bg-primary flex items-center justify-center w-full h-10 font-medium text-white rounded-lg hover:drop-shadow-md"
                >
                  {!isLoading ? (
                    <span>Refuser</span>
                  ) : (
                    <ThreeDots
                      height="40"
                      width="40"
                      radius="9"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={isLoading}
                    />
                  )}
                </button>
              ) : null}

              <button
                onClick={() => {
                  window.accept_catechumene.showModal();
                }}
                className="mt-5 bg-green-600 flex items-center justify-center w-full h-10 font-medium text-white rounded-lg hover:drop-shadow-md"
              >
                {!isLoading ? (
                  <span>Valider</span>
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={isLoading}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </Drawer>

      {/* MODAL REFUS INSCRIPTION CATECHUMENE */}
      <dialog id="refuser_catechumene" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog" className="flex flex-row justify-end">
            <button className="w-7 h-7 flex mb-[-2rem] items-center  rounded-lg hover:border-red-700 hover:border-2  justify-center text-red-700 font-semibold">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-7 h-7"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-bold text-xl text-center text-red-700">
            Refus d'inscription du catéchumène
          </h3>

          <label className="form-control w-full ">
            <div className="label">
              <span className="label-text font-semibold">Motif du rejet</span>
            </div>
            <select
              value={selectMotifRejet}
              onChange={(e) => setSelectMotifRejet(e.target.value)}
              className="select select-bordered bg-[#F9F9F9]"
            >
              <option value="" disabled selected>
                selectionner un motif
              </option>

              {listeMotifRejetInscription.map((motifRejetItem) => (
                <option
                  className="bg-[#F9F9F9] text-black"
                  key={motifRejetItem._id}
                  value={motifRejetItem._id}
                >
                  {motifRejetItem.designation}
                </option>
              ))}
            </select>
          </label>

          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Votre commentaire du rejet</span>
            </div>
            <textarea
              value={commentaireRejet}
              onChange={(e) => setCommentaireRejet(e.target.value)}
              className="textarea textarea-bordered h-24"
              placeholder="Bio"
            ></textarea>
          </label>

          <div className="py-4">
            <p className="text-center text-md text-red-700 italic">
              Attention cet action est irrversible, cette personne va devoir
              refaire son inscription en ligne.
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form method="dialog" className="flex gap-x-3">
              {/* if there is a button in form, it will close the modal */}
              <button className="w-32 h-10 bg-gray-300 text-gray-700 rounded-lg">
                Annuler
              </button>
              <div
                onClick={handleRejetInsciprion}
                className="w-32 h-10 bg-primary text-white rounded-lg flex items-center justify-center cursor-pointer"
              >
                {!rejetLording ? (
                  <span>Rejeter</span>
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={rejetLording}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      {/* MODAL ACCEPT INSCRIPTION CATECHUMENE */}
      <dialog id="accept_catechumene" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog" className="flex flex-row justify-end">
            <button className="w-7 h-7 flex mb-[-2rem] items-center  rounded-lg hover:border-red-700 hover:border-2  justify-center text-red-700 font-semibold">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-7 h-7"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-bold text-xl text-center text-green-600">
            Validation inscription du catéchumène
          </h3>

          <h5 className="font-bold text-md text-start text-gray-600">
            Commission :{" "}
            {infosCatechumene && infosCatechumene.commission
              ? infosCatechumene.commission.designation
              : null}
          </h5>

          <div className="flex flex-row flex-wrap justify-center items-center gap-2">
            <label className="form-control w-full ">
              <div className="label">
                <span className="label-text font-semibold">Niveau</span>
              </div>
              <select
                value={selectNiveauAffectation}
                onChange={(e) => setSelectNiveauAffectation(e.target.value)}
                className="select select-bordered bg-[#F9F9F9]"
              >
                <option disabled selected>
                  selectionner un niveau
                </option>
                {ListeNiveau.map((niveauItem) => (
                  <option
                    className="bg-[#F9F9F9] text-black"
                    key={niveauItem.id}
                    value={niveauItem._id}
                  >
                    {niveauItem.designation}
                  </option>
                ))}
              </select>
            </label>

            <label className="form-control w-full ">
              <div className="label">
                <span className="label-text font-semibold">Classe</span>
              </div>
              <select
                value={selectClasseCatechuForAffectation}
                onChange={(e) =>
                  setSelectClasseCatechuForAffectation(e.target.value)
                }
                className="select select-bordered bg-[#F9F9F9]"
              >
                <option disabled selected>
                  selectionner une classe
                </option>
                <option disabled selected>
                  {" "}
                </option>
                {listeClasseForAffectation &&
                  listeClasseForAffectation.map((classeItem) => (
                    <option
                      className="bg-[#F9F9F9] text-black"
                      key={classeItem._id}
                      value={classeItem._id}
                    >
                      {classeItem.designation}
                    </option>
                  ))}
              </select>
            </label>
          </div>

          <div className="py-4">
            <p className="text-center text-lg text-red-700 italic">
              Attention cette action est irrversible, cette personne fera
              desormais partie de vos effectifs de catéchumène pour cette année.
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form method="dialog" className="flex gap-x-3">
              {/* if there is a button in form, it will close the modal */}
              <button className="w-32 h-10 bg-gray-300 text-gray-700 rounded-lg">
                Annuler
              </button>
              <div
                onClick={handleValideInsciprion}
                className="w-32 h-10 bg-green-600 text-white rounded-lg flex items-center justify-center cursor-pointer"
              >
                {!validLording ? (
                  <span>Accepter</span>
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={validLording}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      {/* MODAL DELETE CATECHUMENE */}
      <dialog id="updateStatut_catechumene" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-bold text-xl text-center text-red-700"></h3>
          <div className="py-4">
            <p className="text-center text-lg text-black">
              Attention action irrversible et entrainera son ajout immédiat dans
              la liste des catéchumènes pour l'année en cours.
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {deleteLoading ? (
                <div
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={deleteCatechu}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="bg-green-500 text-white px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="bg-red-700 text-white px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </BaseLayout>
  );
};

export default Catechumenes;
