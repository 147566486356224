import { BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
}, function (error) {
  console.log('AN ERROR REQUEST', error)
});

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const listeDesNiveauPaginee = async (page = 1, size = 10, param = "", commission = "") => {  
  return await axios.get(
    BASE_URL(`niveaux/query?page=${page}&size=${size}&param=${param}&commission=${commission}&annee=${currentAnnee ? currentAnnee._id : ""}`),
    {}
  );
};

export const listeCompleteDesNiveau = async (commission = "", annee = "") => {
  return await axios.get(
    BASE_URL(`niveaux/complete?commission=${commission}&annee=${annee}`),
    {}
  );
};

export const listeCompleteDesNiveauParCommission = async (commission = "") => {
  return await axios.get(
    BASE_URL(`niveaux/commission/${commission}`),
    {}
  );
};

export const listeCompleteDesNiveauxParAnnee = async (anneeId) => {
  return await axios.get(
    BASE_URL(`niveaux`),
    {}
  );
};

export const recupereNiveauParSonId = async (niveauId) => {
  return await axios.get(
    BASE_URL(`niveaux/${niveauId}`),
    {}
  );
};

export const ajouterUneNiveau = async (values) => {
  return await axios.post(
    BASE_URL(`niveaux`),
    values
  );
};

export const modifierUneNiveau = async (values, niveauId) => {
  return await axios.put(
    BASE_URL(`niveaux/${niveauId}`),
    values
  );
};

export const supprimerUneNiveau = async (niveauId) => {
  return await axios.delete(
    BASE_URL(`niveaux/${niveauId}`),
    {}
  );
};
