// const ROOT_URL = 'http://192.168.1.27:7871';
// const ROOT_URL = `http://172.20.10.6:7871`;
const ROOT_URL = `https://api.catechese-pssm.com`;

export const BASE_URL = (url) => {
  let URL = `${ROOT_URL}/api/v1/${url}`;
  return URL;
};

export const FREE_BASE_URL = (url) => {
  let URL = `${ROOT_URL}/${url}`;
  return URL;
};


// Voici nos accès pour l’application
// Email: ad.aimedesire@gmail.com
// Mot de passe: S$@rRjqO


// email: example@gmail.com
// mot de passe: QdMib&3q
