const PROFIL = localStorage.getItem("user_profil")
  ? JSON.parse(localStorage.getItem("user_profil"))
  : null;

export const returnProfilDesignation = () => {
  return PROFIL ? PROFIL.designation : null;
};

export const returnMenuVisible = () => {
  return PROFIL ? PROFIL.menusPermissions : null;
};

export const returnPermissionsParMenu = (route) => {
  let listePermissions = [];
  if (PROFIL) {
    PROFIL.menusPermissions.forEach((element) => {
      if (element.menu.route === route) {
        listePermissions = element.permissions;
      }
    });
  }
  return listePermissions;
};

export const verificationPermissionsExistantes = (
  listePermissions,
  permission
) => {
  let hasPermission = false;
  listePermissions.map((item) => {
    if (item.designation === permission) {
      hasPermission = true;
    }
  });
  return hasPermission;
};
