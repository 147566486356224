import React, { useEffect, useRef, useState } from "react";
import BaseLayout from "../../../layout/BaseLayout";
import iconeArrow from "../../../assets/IconeSvg/square-arrow-right.svg";
import { NavLink, useParams } from "react-router-dom";
import { recupereClasseParSonId } from "../../../services/parametrage/ClasseRequest";
import ClasseAnimateurs from "./../animateur/ClasseAnimateurs";
import ClasseCatechumene from "./../catechumene/ClasseCatechumene";
import ClasseListePresence from "./../presence/ClasseListePresence";
import { Tab, Tabs } from "@mui/material";
import { recupereListeCompleteAnimateurParClasse } from "../../../services/animateur/AnimateurRequest";
import { jwtDecode } from "jwt-decode";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const connectedUser = localStorage.getItem("cc_pssm_api")
  ? jwtDecode(localStorage.getItem("cc_pssm_api"))
  : null;

const ClasseDetails = () => {
  let { classeId } = useParams();
  const [classeSelected, setClasseSelected] = useState(null);
  const [valueTab, setValueTab] = useState(0);
  const [animateurClasse, setAnimateurClasse] = useState([]);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  // === RECUPERE LISTE DES ANIMATEURS ===
  const getAnimateurDeLaClasse = () => {
    recupereListeCompleteAnimateurParClasse(classeId)
      .then((res) => {
        setAnimateurClasse(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const checkAnimateurInClasse = (utilisateur) => {
    let isValid = false;
    if (
      animateurClasse.length === 0 &&
      (utilisateur.connected.profil == "SUPER_ADMIN" ||
        utilisateur.connected.profil == "ROLE_PRESIDENT" ||
        utilisateur.connected.profil == "ROLE_DAF")
    ) {
      isValid = true;
    }
    animateurClasse.forEach((element) => {
      if (
        utilisateur.connected.profil == "SUPER_ADMIN" ||
        utilisateur.connected.profil == "ROLE_PRESIDENT" ||
        utilisateur.connected.profil == "ROLE_DAF" ||
        element._id == utilisateur.id
      ) {
        isValid = true;
      }
    });
    return isValid;
  };

  useEffect(() => {
    getAnimateurDeLaClasse();

    recupereClasseParSonId(classeId)
      .then((res) => {
        setClasseSelected(res.data);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }, []);

  return (
    <BaseLayout>
      {checkAnimateurInClasse(connectedUser ? connectedUser : null) ? (
        <div className="w-full h-full">
          <div className="flex items-center gap-x-2">
            <NavLink to="/classes">
              <p className="cursor-pointer text-lg sm:text-2xl text-black font-extrabold">
                Classes
              </p>
            </NavLink>
            <div>
              <img src={iconeArrow} className="w-8" />
            </div>
            <p className="cursor-pointer text-lg sm:text-2xl text-primary font-extrabold">
              {classeSelected && classeSelected.classe
                ? classeSelected.classe.designation
                : null}
            </p>
          </div>
          <div className="mt-4 flex items-center gap-x-2">
            <div className="px-4 py-2 w-fit rounded-xl bg-gray-200 flex items-center justify-center">
              <p className="text-xs sm:text-sm text-black font-medium">
                Commission:{" "}
                {classeSelected &&
                classeSelected.classe &&
                classeSelected.classe.commission
                  ? classeSelected.classe.commission.designation
                  : null}
              </p>
            </div>
            <div className="px-4 py-2 w-fit rounded-xl bg-gray-200 flex items-center justify-center">
              <p className="text-xs sm:text-sm text-black font-medium">
                Niveau:{" "}
                {classeSelected &&
                classeSelected.classe &&
                classeSelected.classe.niveau
                  ? classeSelected.classe.niveau.designation
                  : null}
              </p>
            </div>
          </div>

          <div className="mt-5 border-b">
            <Tabs
              value={valueTab}
              onChange={handleChangeTab}
              variant="scrollable"
              textColor="black"
              scrollButtons="auto"
              aria-label="basic tabs example"
            >
              <Tab
                label={`Catéchumènes (${
                  classeSelected ? classeSelected.totalCatechumene : 0
                })`}
                {...a11yProps(0)}
                sx={{
                  textTransform: "capitalize",
                  fontSize: 14,
                  fontWeight: 700,
                }}
              />
              <Tab
                label="Animateurs"
                {...a11yProps(1)}
                sx={{
                  textTransform: "capitalize",
                  fontSize: 14,
                  fontWeight: 700,
                }}
              />
              <Tab
                label="Liste présence"
                {...a11yProps(2)}
                sx={{
                  textTransform: "capitalize",
                  fontSize: 14,
                  fontWeight: 700,
                }}
              />
            </Tabs>
          </div>

          <div className="mt-8">
            <CustomTabPanel value={valueTab} index={0}>
              <ClasseCatechumene classe={classeSelected} />
            </CustomTabPanel>
            <CustomTabPanel value={valueTab} index={1}>
              <ClasseAnimateurs />
            </CustomTabPanel>
            <CustomTabPanel value={valueTab} index={2}>
              <ClasseListePresence />
            </CustomTabPanel>
          </div>
        </div>
      ) : (
        <div className="h-56 flex items-center justify-center">
          <p className="text-base text-primary font-extrabold">
            Vous n'êtes pas l'animateur de cette classe.
          </p>
        </div>
      )}
    </BaseLayout>
  );
};

export default ClasseDetails;
