import React, { useEffect, useRef, useState } from "react";
import BaseLayout from "../../layout/BaseLayout";
import iconeClasse from "../../assets/IconeSvg/Classe.svg";
import iconeCloseWindow from "../../assets/IconeSvg/close_window.svg";
import iconeDownload from "../../assets/IconeSvg/download-minimalistic.svg";
import { Drawer, Pagination } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { listeCompleteDesCommissions } from "../../services/parametrage/CommissionRequest";
import { listeCompleteDesNiveauParCommission } from "../../services/parametrage/NiveauxRequest";
import {
  listeDesClassePaginee,
  supprimerUneClasse,
} from "../../services/parametrage/ClasseRequest";
import SnackbarAlert from "../../components/SnackbarAlert";
import {
  returnPermissionsParMenu,
  verificationPermissionsExistantes,
} from "../../utils/ManageUserProfil";
import { exportListeCatechumene } from "../../services/catechumene/CatechumeneRequest";
import { ArrowDownToLine } from "lucide-react";

const listePermissions = returnPermissionsParMenu("/classes");

const Classes = () => {
  const navigate = useNavigate();
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 20,
    nombrePage: 1,
    pagePagination: 1,
  });
  const [loadingData, setLoadingData] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [niveauSelected, setNiveauSelected] = useState("");
  const [commissionSelected, setCommissionSelected] = useState("");
  const [searchParam, setSearchParam] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [listeCommission, setListeCommission] = useState([]);
  const [listeNiveaux, setListeNiveaux] = useState([]);
  const [listeClasse, setListeClasse] = useState([]);
  const [classeInfo, setClasseInfo] = useState(null);

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = snackbarPosition;

  const ClasseElement = ({ element }) => {
    return (
      <div className="relative w-full h-full p-4 bg-white hover:shadow-md rounded-xl border cursor-pointer ">
        {verificationPermissionsExistantes(listePermissions, "VOIR_RECAP") && (
          <button
            disabled={loadingExport}
            className="absolute top-2 right-2 bg-gray-100 text-xs text-gray-600 h-7 max-w-32 px-2 font-semibold rounded-md flex items-center justify-center gap-1"
            onClick={() => exporterListeCatechumeneInscrit(element.classe)}
          >
            {!loadingExport ? (
              <>
                <ArrowDownToLine size={16} />
                <p className="truncate">Fichier recap</p>
              </>
            ) : (
              <ThreeDots
                height="35"
                width="35"
                radius="9"
                color="#000"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={loadingExport}
              />
            )}
          </button>
        )}
        <div
          onClick={() =>
            verificationPermissionsExistantes(listePermissions, "VOIR_DETAIL")
              ? navigate(`/classes/${element.classe._id}`)
              : ""
          }
        >
          <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center">
            <img src={iconeClasse} alt="" className="w-8 h-8" />
          </div>
          <div className="w-full mt-4">
            <h2 className="text-black text-lg font-extrabold truncate mb-2">
              {element.classe.designation}
            </h2>
            <p className="mt-1 text-sm sm:text-base text-left font-semibold text-gray-500">
              <span className="text-primary font-semibold">Commission: </span>
              {element.classe.commission.designation}
            </p>
            <p className="mt-1 text-sm sm:text-base text-left font-semibold text-gray-500">
              <span className="text-primary font-semibold">Niveau: </span>
              {element.classe.niveau.designation}
            </p>
            <p className="mt-1 text-sm sm:text-base text-left font-semibold text-gray-500">
              <span className="text-primary font-semibold">
                Nbre catéchumènes:{" "}
              </span>
              {element.totalCatechumene}
            </p>
          </div>
        </div>
      </div>
    );
  };

  // EXPORT LISTE CATECHUMENE PAR CLASSE
  const exporterListeCatechumeneInscrit = (classe) => {
    setLoadingExport(true);
    exportListeCatechumene(
      classe.commission ? classe.commission._id : "",
      classe.niveau ? classe.niveau._id : "",
      classe._id,
      "VALIDER"
    )
      .then((res) => {
        setLoadingExport(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Recap-liste-catechumene-${
            classe ? classe.designation : "classe"
          }.xlsx`
        );
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        setLoadingExport(false);
        console.log("api error", err);
      });
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesCommission = () => {
    listeCompleteDesCommissions()
      .then((res) => {
        setListeCommission(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES NIVEAUX ===
  const getListeDesNiveauxParCommission = (commission = "") => {
    setListeNiveaux([]);
    setNiveauSelected("");
    listeCompleteDesNiveauParCommission(commission)
      .then((res) => {
        setListeNiveaux(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getListeDesClasse = () => {
    setLoadingData(true);
    listeDesClassePaginee(pageQuery.page, pageQuery.size, searchParam, "", "")
      .then((res) => {
        setLoadingData(false);
        setListeClasse(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const enregistrementClasse = () => {
    setIsLoading(true);
  };

  const supprimerClasse = () => {
    setDeleteLoading(true);

    supprimerUneClasse(classeInfo._id)
      .then((res) => {
        setSuccessMessage("Classe supprimé avec succès");
        getListeDesClasse();
        setSnackbarOpenSuccess(true);
        setDeleteLoading(false);
        document.getElementById("delete_classe").close();
      })
      .catch((err) => {
        setDeleteLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("api error", err);
      });
  };

  // === FILTRER LES ELEMENTS ===
  const filterElement = () => {
    setSearchLoading(true);
    listeDesClassePaginee(
      pageQuery.page,
      pageQuery.size,
      searchParam,
      commissionSelected,
      niveauSelected
    )
      .then((res) => {
        setSearchLoading(false);
        setListeClasse(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setSearchLoading(false);
        console.log("error", error);
      });
  };

  // === PAGINATION DES ELEMENTS ===
  const handlePaginationChange = (event, value) => {
    setLoadingData(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value,
    }));
    listeDesClassePaginee(
      value,
      pageQuery.size,
      searchParam,
      commissionSelected,
      niveauSelected
    )
      .then((res) => {
        setLoadingData(false);
        setListeClasse(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  useEffect(() => {
    setLoadingData(true);
    getListeDesCommission();

    listeDesClassePaginee(pageQuery.page, pageQuery.size, searchParam, "", "")
      .then((res) => {
        setLoadingData(false);
        setListeClasse(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  }, []);

  return (
    <BaseLayout>
      {verificationPermissionsExistantes(listePermissions, "VOIR_LISTE") ? (
        <div className="w-full h-full pb-16">
          <div className="w-full">
            <h1 className="text-2xl md:text-3xl font-extrabold">Classes</h1>
            <p className="mt-2 text-gray-500 font-medium text-sm sm:text-base md:text-lg">
              Listes des classes pour cette année
            </p>
          </div>

          <div className="mt-5 w-full md:w-fit flex items-center gap-x-2 overflow-x-scroll sm:overflow-auto">
            <input
              type="text"
              placeholder="Rechercher un élement..."
              value={searchParam}
              onChange={(e) => setSearchParam(e.target.value)}
              className="input input-bordered w-64 sm:w-56 h-10 text-sm font-medium"
            />
            <select
              value={commissionSelected}
              onChange={(e) => {
                setCommissionSelected(e.target.value);
                getListeDesNiveauxParCommission(e.target.value);
              }}
              className="select select-bordered custom-select w-36 h-10 font-medium"
            >
              <option disabled selected value="">
                Choisir une commission
              </option>
              <option value="" className="font-medium">
                Tout
              </option>
              {listeCommission.map((item, index) => (
                <option key={index} value={item._id} className="font-medium">
                  {item.designation}
                </option>
              ))}
            </select>
            <select
              value={niveauSelected}
              onChange={(e) => setNiveauSelected(e.target.value)}
              className="select select-bordered custom-select w-44 h-10 font-medium"
            >
              <option disabled selected value="">
                Choisir un niveau
              </option>
              <option value="" className="font-medium">
                Tout
              </option>
              {listeNiveaux.map((item, index) => (
                <option key={index} value={item._id} className="font-medium">
                  {item.designation}
                </option>
              ))}
            </select>
            <button
              disabled={searchLoading}
              className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-lg flex items-center justify-center"
              onClick={filterElement}
            >
              {!searchLoading ? (
                "Rechercher"
              ) : (
                <ThreeDots
                  height="35"
                  width="35"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={searchLoading}
                />
              )}
            </button>
          </div>

          {/* Liste des catéchumènes   */}
          <div className="mt-10 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
            {!loadingData && listeClasse.length > 0 ? (
              listeClasse.map((item, index) => (
                <ClasseElement key={index} element={item} />
              ))
            ) : !loadingData && listeClasse.length === 0 ? (
              <div className="w-full h-56 flex items-center justify-center">
                <p className="font-semibold text-red-600">
                  Aucune classe trouvée.
                </p>
              </div>
            ) : (
              <div className="flex col-span-full w-full items-center justify-center  h-[55vh]">
                <div className="w-full  h-32 rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                  <TailSpin
                    height="50"
                    width="50"
                    color="#000"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={loadingData}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-end mt-5">
            <Pagination
              count={pageQuery.nombrePage}
              page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginationChange}
            />
          </div>
        </div>
      ) : (
        <div className="h-56 flex items-center justify-center">
          <p className="text-base text-primary font-extrabold">
            Vous n'avez pas accès à ce menu.
          </p>
        </div>
      )}

      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="w-[600px]  pt-2 px-1 flex flex-col bg-white ">
          <div className="flex justify-start ">
            <img
              onClick={() => setOpenModal(false)}
              src={iconeCloseWindow}
              className="cursor-pointer"
              alt=""
            />
          </div>

          <h2 className="mt-3 ml-2 text-lg font-extrabold">
            Modifier la liste des catéchumènes de cette classe
          </h2>

          <div className="mt-10 px-2 pb-4">
            <button
              onClick={enregistrementClasse}
              className="mt-5 bg-primary flex items-center justify-center w-full h-11 font-semibold text-white rounded-lg hover:drop-shadow-md"
            >
              {!isLoading ? (
                <span>Enregistrer</span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={isLoading}
                />
              )}
            </button>
          </div>
        </div>
      </Drawer>

      {/* MODAL DELETE CLASSE */}
      <dialog id="delete_classe" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-center text-primary">
            Supprimer cette classe
          </h3>
          <div className="py-4">
            <p className="text-center text-base font-medium text-gray-600">
              Attention action est irrversible et entrainera la suppression de
              cette classe
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? (
                <div
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={supprimerClasse}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      <SnackbarAlert
        vertical={vertical}
        horizontal={horizontal}
        snackbarSuccessOpen={snackbarSuccessOpen}
        handleCloseSnackbarSuccess={handleCloseSnackbarSuccess}
        successMessage={successMessage}
        snackbarErrorOpen={snackbarErrorOpen}
        handleCloseSnackbarError={handleCloseSnackbarError}
        errorMessage={errorMessage}
      />
    </BaseLayout>
  );
};

export default Classes;
