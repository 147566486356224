/* eslint-disable no-lone-blocks */
import React, { useEffect, useRef, useState } from "react";
import iconeLien from "../../../assets/IconeSvg/ArrowRight.svg";

import iconeAppareilPhoto from "../../../assets/IconeSvg/appareil_photo.svg";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";

import iconeDelete from "../../../assets/IconeSvg/delete.svg";
import iconePencil from "../../../assets/IconeSvg/pencil.svg";
import { useNavigate, useParams } from "react-router-dom";
import BaseLayout from "../../../layout/BaseLayout";
import AppelsCatechu from "./AppelsCatechu";
import PaiementCatechu from "./PaiementCatechu";
import {
  InfosCatechumene,

  supprimerUneCatechumene,
  updateCatechumene
} from "../../../services/catechumene/CatechumeneRequest";
import { listeCompleteDesCommissionsParAnnee } from "../../../services/parametrage/CommissionRequest";
import {
  listeCompleteDesNiveau,
  listeCompleteDesNiveauParCommission,
  listeDesNiveauPaginee
} from "../../../services/parametrage/NiveauxRequest";
import { listeDesClassePaginee } from "../../../services/parametrage/ClasseRequest";
import { Drawer, Snackbar, Tab, Tabs } from "@mui/material";
import NotesCatechu from "./NotesCatechu";
import { ThreeDots } from "react-loader-spinner";
import {
  returnPermissionsParMenu,
  verificationPermissionsExistantes
} from "../../../utils/ManageUserProfil";

const listePermissions = returnPermissionsParMenu("/catechumenes");
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const CatechumeneDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const currentAnnee = localStorage.getItem("currentAnnee")
    ? JSON.parse(localStorage.getItem("currentAnnee"))
    : null;

  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 1,
    nombrePage: 1,
    pagePagination: 1
  });

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;
  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };
  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const fileInputRef = useRef(null);
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const [nomCatechu, setNomCatechu] = useState("");
  const [prenomsCatechu, setPrenomsCatechu] = useState("");
  const [telCatechu, setTelCatechu] = useState("");
  const [MontantPaye, setMontantPaye] = useState("");
  const [EmailCatechu, setEmailCatechu] = useState("");
  const [secondTelCatechu, setSecondTelCatechu] = useState("");
  const [EmailPereCatechu, setEmailPereCatechu] = useState("");
  const [SecondContactPereCatechu, setSecondContactPereCatechu] = useState("");
  const [ContactPereCatechu, setContactPereCatechu] = useState("");
  const [PrenomsPereCatechu, setPrenomsPereCatechu] = useState("");
  const [NomPereCatechu, setNomPereCatechu] = useState("");
  const [EmailMereCatechu, setEmailMereCatechu] = useState("");
  const [SecondContactMereCatechu, setSecondContactMereCatechu] = useState("");
  const [ContactMereCatechu, setContactMereCatechu] = useState("");
  const [PrenomsMereCatechu, setPrenomsMereCatechu] = useState("");
  const [NomMereCatechu, setNomMereCatechu] = useState("");
  const [nomPrenomsTuteurCatechu, setNomPrenomsTuteurCatechu] = useState("");
  const [SecondContactTuteurCatechu, setSecondContactTuteurCatechu] =
    useState("");
  const [ContactTuteurCatechu, setContactTuteurCatechu] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalModifCatechu, setOpenModalModifCatechu] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [selectClasseCatechu, setSelectClasseCatechu] = useState(null);

  const [selectNiveau, setSelectNiveau] = useState(null);
  const [selectCommission, setSelectCommission] = useState(null);
  const [idAnneeScolaire, setIdAnneeScolaire] = useState(null);
  const [selectModePaiement, setSelectModePaiement] = useState("");
  const [selectMotifPaiement, setSelectMotifPaiement] = useState("");

  const [menuParaDisplayed, setMenuParaDisplayed] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [infoCatechu, setInfoCatechu] = useState(null);
  const [infosCatechumene, setInfosCatechumene] = useState(null);

  const [ListeCommission, setListeCommission] = useState([]);
  const [ListeNiveau, setListeNiveau] = useState([]);
  const [ListeClasse, setListeClasse] = useState([]);
 
  const handleOpenMenu = () => {
    setMenuParaDisplayed(!menuParaDisplayed);
  };

  const [valueTab, setValueTab] = useState(0);

  const openModalModif = () => {
    setOpenModalModifCatechu(true);

    setNomCatechu(infoCatechu.catechumene.nom);
    setPrenomsCatechu(infoCatechu.catechumene.prenoms);
    setEmailCatechu(infoCatechu.catechumene.email);
    setTelCatechu(infoCatechu.catechumene.contact);
    setSecondTelCatechu(infoCatechu.catechumene.secondContact);
    setSelectCommission(infoCatechu.commission._id);
    setSelectNiveau(infoCatechu.niveau._id);
    setSelectClasseCatechu(infoCatechu.classe._id);
    setNomPereCatechu(infoCatechu.catechumene.pere.nom);
    setPrenomsPereCatechu(infoCatechu.catechumene.pere.prenoms);
    setContactPereCatechu(infoCatechu.catechumene.pere.contact);
    setSecondContactPereCatechu(infoCatechu.catechumene.pere.secondContact);
    setEmailPereCatechu(infoCatechu.catechumene.pere.email);
    setNomMereCatechu(infoCatechu.catechumene.mere.nom);
    setPrenomsMereCatechu(infoCatechu.catechumene.mere.prenoms);
    setContactMereCatechu(infoCatechu.catechumene.mere.contact);
    setSecondContactMereCatechu(infoCatechu.catechumene.mere.secondContact);
    setEmailMereCatechu(infoCatechu.catechumene.pere.email);
    setNomPrenomsTuteurCatechu(
      infoCatechu.catechumene.personneAContacter.nomPrenoms
    );
    setContactTuteurCatechu(infoCatechu.catechumene.personneAContacter.contact);
    setSecondContactTuteurCatechu(
      infoCatechu.catechumene.personneAContacter.secondContact
    );
    setSelectMotifPaiement(infoCatechu.motifPaiement);
    setMontantPaye(infoCatechu.montant);
    setSelectModePaiement(infoCatechu.modePaiement);
    setFile(infoCatechu.catechumene.photo);
  };

  // Upload photo de profil
  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const clearInputEnregistrement = () => {
    setFile("");
    setPreview("");
    setNomCatechu("");
    setPrenomsCatechu("");
    setTelCatechu("");
    setSecondTelCatechu("");
    setSelectCommission("");
    setSelectNiveau("");
    setSelectClasseCatechu("");
    setNomPereCatechu("");
    setPrenomsPereCatechu("");
    setContactPereCatechu("");
    setSecondContactPereCatechu("");
    setEmailPereCatechu("");
    setNomMereCatechu("");
    setPrenomsMereCatechu("");
    setContactMereCatechu("");
    setSecondContactMereCatechu("");
    setEmailMereCatechu("");
    setNomPrenomsTuteurCatechu("");
    setContactTuteurCatechu("");
    setSecondContactTuteurCatechu("");
    setSelectMotifPaiement("");
    setMontantPaye("");
    setSelectModePaiement("");
    setSelectCommission("");
    setSelectNiveau("");
    setSelectClasseCatechu("");
  };

  const detailsCatechumene = () => {
    setLoadingData(true);
    InfosCatechumene(id)
      .then((res) => {
        setLoadingData(false);
        setInfoCatechu(res.data);
      })
      .catch((err) => {
        setLoadingData(false);
        console.log(err);
      });
  };

  const modifCatechu = () => {
    setIsLoading(true);
    const dataCatechumene = new FormData();

    dataCatechumene.append("photo", file);
    dataCatechumene.append("nom", nomCatechu);
    dataCatechumene.append("prenoms", prenomsCatechu);
    dataCatechumene.append("email", EmailCatechu);
    dataCatechumene.append("contact", telCatechu);
    dataCatechumene.append("secondContact", secondTelCatechu);
    dataCatechumene.append("commission", selectCommission);
    dataCatechumene.append("niveau", selectNiveau);
    dataCatechumene.append("classe", selectClasseCatechu);
    dataCatechumene.append(
      "pere",
      JSON.stringify({
        nom: NomPereCatechu,
        prenoms: PrenomsPereCatechu,
        contact: ContactPereCatechu,
        secondContact: SecondContactPereCatechu,
        email: EmailPereCatechu
      })
    );
    dataCatechumene.append(
      "mere",
      JSON.stringify({
        nom: NomMereCatechu,
        prenoms: PrenomsMereCatechu,
        contact: ContactMereCatechu,
        secondContact: SecondContactMereCatechu,
        email: EmailMereCatechu
      })
    );
    dataCatechumene.append(
      "personneAContacter",
      JSON.stringify({
        nomPrenoms: nomPrenomsTuteurCatechu,
        contact: ContactTuteurCatechu,
        secondContact: SecondContactTuteurCatechu
      })
    );
    updateCatechumene(dataCatechumene, infoCatechu.catechumene._id)
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        setSuccessMessage("Catéchumène modifié avec succès");
        setSnackbarOpenSuccess(true);
        clearInputEnregistrement();
        setOpenModalModifCatechu(false);
        window.location.reload();
        // ListeDesCatechumene();
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage("Erreur lors de la modification du Catéchumène");
        setSnackbarOpenError(true);
      });
  };

  const deleteCatechu = () => {
    setDeleteLoading(true);
    console.log(infosCatechumene);
    supprimerUneCatechumene(id)
      .then((res) => {
        setDeleteLoading(false);
        console.log(res);
        setSuccessMessage("Catéchumène supprimé avec succès");
        setSnackbarOpenSuccess(true);
        // ListeDesCatechumene();
        document.getElementById("delete_catechumene").click();
        navigate("/catechumenes");
      })
      .catch((err) => {
        setDeleteLoading(false);
        console.log("api error", err);
        setErrorMessage("Erreur de suppression de l utilisateur, réessayez!");
        setSnackbarOpenError(true);
      });
  };
  // === RECUPERE LISTE DES NIVEAUX ===
  const getListeDesNiveaux = () => {
    setLoadingData(true);

    listeDesNiveauPaginee(pageQuery.page, 50, "", "")
      .then((res) => {
        setLoadingData(false);
        setListeNiveau(res.data.results);
        // setPageQuery((prev) => ({
        //   ...prev,
        //   nombrePage: res.data.totalPages,
        //   totalElements: res.data.totalElements
        // }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES CLASSES ===
  const getListeDesClasse = () => {
    setLoadingData(true);
    listeDesClassePaginee(pageQuery.page, 50, "", "", "")
      .then((res) => {
        setLoadingData(false);
        setListeClasse(res.data.results);
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesCommission = () => {
    listeCompleteDesCommissionsParAnnee(currentAnnee._id)
      .then((res) => {
        setListeCommission(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

 
  useEffect(() => {}, [idAnneeScolaire]);

  useEffect(() => {
    detailsCatechumene();
  }, [id]);

  useEffect(() => {
    handleOpenMenu();
    getListeDesCommission();
    getListeDesClasse();
    getListeDesNiveaux();
  }, []);

  return (
    <BaseLayout>
      <div className="w-full h-full flex flex-col">
        <div className="flex flex-col w-full h-full p-4">
          <div className="flex items-center justify-start w-full gap-x-2 mb-[1rem]">
            {verificationPermissionsExistantes(
              listePermissions,
              "MODIFIER"
            ) && (
              <button
                onClick={() => {
                  openModalModif();
                }}
                className="rounded-lg flex flex-row gap-x-2 items-center justify-center bg-gray-200 p-2"
              >
                <img src={iconePencil} alt="" />
                <span className="font-medium text-[16px]">Modifier</span>
              </button>
            )}

            {verificationPermissionsExistantes(
              listePermissions,
              "SUPPRIMER"
            ) && (
              <button
                onClick={() => {
                  window.delete_catechumene.showModal();
                  setInfosCatechumene();
                }}
                className="rounded-lg flex flex-row gap-x-2 items-center justify-center bg-gray-200 p-2"
              >
                <img src={iconeDelete} alt="" />
                <span className="font-medium text-[16px]">Supprimer</span>
              </button>
            )}
          </div>
          <div className="w-full flex flex-row items-center gap-2 ">
            <h1
              onClick={() => {
                navigate("/catechumenes");
              }}
              className="xl:text-[28px] lg:text-[28px] md:text-[28px] text-[16px] font-bold cursor-pointer"
            >
              Catéchumène
            </h1>
            <img src={iconeLien} className="w-4 xl:w-6 lg:w-6 md:w-6" alt="" />
            <h2 className="xl:text-[28px] truncate lg:text-[28px] md:text-[28px] text-[16px] text-primary font-bold">
              {`${
                infoCatechu && infoCatechu.catechumene
                  ? infoCatechu.catechumene.nom
                  : "--"
              }  ${
                infoCatechu && infoCatechu.catechumene
                  ? infoCatechu.catechumene.prenoms
                  : "--"
              }`}
            </h2>
          </div>

          <div className="flex md:flex-row xl:flex-row lg:flex-row flex-col mt-4  justify-start w-full gap-2 ">
            <div className="xl:w-[30%] lg:w-[30%] md:w-[30%] w-full  ">
              <h2 className="text-[#9D9D9D]">Informations</h2>
              <div className="bg-[#fffff] border-[1.5px] w-full rounded-lg flex flex-row gap-3 py-1 px-3">
                <img
                  src={
                    infoCatechu && infoCatechu.catechumene
                      ? infoCatechu.catechumene.photo
                      : null
                  }
                  className="rounded-lg mt-1 h-10 w-10"
                  alt=""
                />

                <div className="flex flex-col  w-[90%]  truncate">
                  <h3 className="text-primary font-semibold  truncate">
                    {`${
                      infoCatechu && infoCatechu.catechumene
                        ? infoCatechu.catechumene.nom
                        : "--"
                    }  ${
                      infoCatechu && infoCatechu.catechumene
                        ? infoCatechu.catechumene.prenoms
                        : "--"
                    }`}
                  </h3>
                  <h4 className="font-semibold text-sm">
                    Téléphone:{" "}
                    {infoCatechu && infoCatechu.catechumene
                      ? infoCatechu.catechumene.contact
                      : "--"}
                  </h4>
                  <h4 className="font-semibold text-sm truncate">
                    Second contact:{" "}
                    {infoCatechu && infoCatechu.catechumene
                      ? infoCatechu.catechumene.secondContact
                      : "--"}
                  </h4>
                  <h4 className="font-semibold text-sm truncate">
                    Email:{" "}
                    {infoCatechu && infoCatechu.catechumene
                      ? infoCatechu.catechumene.email
                      : "--"}
                  </h4>
                </div>
              </div>
            </div>
            <div className="xl:w-[20%] lg:w-[20%] md:w-[20%] w-full  ">
              <h2 className="text-[#9D9D9D]">Commission ...</h2>
              <div className="bg-[#fffff] border-[1.5px] py-4 w-full rounded-lg flex flex-row gap-3  px-3">
                <div className="flex flex-col  w-full">
                  <h4 className="font-semibold text-sm">
                    Commission:{" "}
                    {infoCatechu && infoCatechu.commission
                      ? ListeCommission.find(
                          (commissionItem) =>
                            commissionItem._id === infoCatechu.commission._id
                        )?.designation || "--"
                      : "--"}
                  </h4>
                  <h4 className="font-semibold text-sm truncate">
                    Niveau:{" "}
                    {infoCatechu && infoCatechu.niveau
                      ? ListeNiveau.find(
                          (niveauItem) =>
                            niveauItem._id === infoCatechu.niveau._id
                        )?.designation || "--"
                      : "--"}
                  </h4>
                  <h4 className="font-semibold text-sm truncate">
                    Classe:{" "}
                    {infoCatechu && infoCatechu.classe
                      ? ListeClasse.find(
                          (classeItem) =>
                            classeItem._id === infoCatechu.classe._id
                        )?.designation || "--"
                      : "--"}
                  </h4>
                </div>
              </div>
            </div>
            <div className="xl:w-[18%]  lg:w-[18%] md:w-[18%] w-full">
              <h2 className="text-[#9D9D9D]">Père</h2>
              <div className="bg-[#fffff] border-[1.5px] rounded-lg flex flex-row gap-3 py-1 px-3">
                <div className="flex flex-col w-[250px] truncate">
                  <h3 className="text-primary font-semibold truncate">
                    {`${
                      infoCatechu &&
                      infoCatechu.catechumene &&
                      infoCatechu.catechumene.pere
                        ? infoCatechu.catechumene.pere.nom +
                          " " +
                          infoCatechu.catechumene.pere.prenoms
                        : "--"
                    } 
                        
                        `}
                  </h3>
                  <h4 className="font-semibold text-sm">
                    Téléphone:{" "}
                    {infoCatechu &&
                    infoCatechu.catechumene &&
                    infoCatechu.catechumene.pere
                      ? infoCatechu.catechumene.pere.contact
                      : "--"}
                  </h4>

                  <h4 className="font-semibold text-sm ">
                    Second contact:{" "}
                    {infoCatechu &&
                    infoCatechu.catechumene &&
                    infoCatechu.catechumene.pere
                      ? infoCatechu.catechumene.pere.secondContact
                      : "--"}
                  </h4>
                  <h4 className="font-semibold text-sm truncate">
                    Email:{" "}
                    {infoCatechu &&
                    infoCatechu.catechumene &&
                    infoCatechu.catechumene.pere !== ""
                      ? infoCatechu.catechumene.pere.email
                      : "--"}
                  </h4>
                </div>
              </div>
            </div>
            <div className="xl:w-[18%]  lg:w-[18%] md:w-[18%] w-full">
              <h2 className="text-[#9D9D9D]">Mère</h2>
              <div className="bg-[#fffff] border-[1.5px]  w-full  rounded-lg flex flex-row gap-3 py-1 px-3">
                <div className="flex flex-col w-[250px]  truncate">
                  <h3 className="text-primary font-semibold  truncate">
                    {`${
                      infoCatechu &&
                      infoCatechu.catechumene &&
                      infoCatechu.catechumene.mere
                        ? infoCatechu.catechumene.mere.nom +
                          " " +
                          infoCatechu.catechumene.mere.prenoms
                        : "--"
                    } 
                        
                        `}
                  </h3>
                  <h4 className="font-semibold text-sm">
                    Téléphone:{" "}
                    {infoCatechu &&
                    infoCatechu.catechumene &&
                    infoCatechu.catechumene.mere
                      ? infoCatechu.catechumene.mere.contact
                      : "--"}
                  </h4>

                  <h4 className="font-semibold text-sm ">
                    Second contact:{" "}
                    {infoCatechu &&
                    infoCatechu.catechumene &&
                    infoCatechu.catechumene.mere
                      ? infoCatechu.catechumene.mere.secondContact
                      : "--"}
                  </h4>
                  <h4 className="font-semibold text-sm truncate">
                    Email:{" "}
                    {infoCatechu &&
                    infoCatechu.catechumene &&
                    infoCatechu.catechumene.mere !== ""
                      ? infoCatechu.catechumene.mere.email
                      : "--"}
                  </h4>
                </div>
              </div>
            </div>
            <div className="xl:w-[18%]  lg:w-[18%] md:w-[18%] w-full">
              <h2 className="text-[#9D9D9D]">Tuteur/Tutrice</h2>
              <div className="bg-[#fffff] border-[1.5px]  w-full  rounded-lg flex flex-row gap-3 py-1 px-3">
                <div className="flex flex-col w-[250px]  truncate">
                  <h3 className="text-primary font-semibold  truncate">
                    {`${
                      infoCatechu &&
                      infoCatechu.catechumene &&
                      infoCatechu.catechumene.personneAContacter
                        ? infoCatechu.catechumene.personneAContacter.nomPrenoms
                        : "--"
                    } 
                        
                        `}
                  </h3>
                  <h4 className="font-semibold text-sm">
                    Téléphone:{" "}
                    {infoCatechu &&
                    infoCatechu.catechumene &&
                    infoCatechu.catechumene.personneAContacter
                      ? infoCatechu.catechumene.mere.contact
                      : "--"}
                  </h4>

                  <h4 className="font-semibold text-sm ">
                    Second contact:{" "}
                    {infoCatechu &&
                    infoCatechu.catechumene &&
                    infoCatechu.catechumene.personneAContacter
                      ? infoCatechu.catechumene.mere.secondContact
                      : "--"}
                  </h4>
                  <h4 className="font-semibold text-sm truncate h-5">
                    {/* Email:{" "}
                    {infoCatechu && infoCatechu.catechumene.mere.email !==""
                      ? infoCatechu.catechumene.mere.email
                      : "--"} */}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 border-b">
            <Tabs
              value={valueTab}
              onChange={handleChangeTab}
              variant="scrollable"
              textColor="black"
              scrollButtons="auto"
              aria-label="basic tabs example"
            >
              <Tab
                label="Paiements"
                {...a11yProps(1)}
                sx={{
                  textTransform: "capitalize",
                  fontSize: 14,
                  fontWeight: 700
                }}
              />
              <Tab
                label="Présences / Absences"
                {...a11yProps(0)}
                sx={{
                  textTransform: "capitalize",
                  fontSize: 14,
                  fontWeight: 700
                }}
              />
              <Tab
                label="Notes"
                {...a11yProps(0)}
                sx={{
                  textTransform: "capitalize",
                  fontSize: 14,
                  fontWeight: 700
                }}
              />
            </Tabs>
          </div>

          <div className="mt-8">
            <CustomTabPanel value={valueTab} index={0}>
              <PaiementCatechu idCatechu={id} />
            </CustomTabPanel>
            <CustomTabPanel value={valueTab} index={1}>
              <AppelsCatechu idCatechu={id} />
            </CustomTabPanel>
            <CustomTabPanel value={valueTab} index={2}>
              <NotesCatechu idCatechu={id} />
            </CustomTabPanel>
          </div>
        </div>
      </div>

      {/* MODAL MODIFICATIONS INFOS CATECHUMENE */}
      <Drawer
        anchor={"right"}
        open={openModalModifCatechu}
        onClose={() => {
          setOpenModalModifCatechu(false);
          clearInputEnregistrement();
        }}
        className="update-catechumene"
      >
        <div className="w-full md:w-[500px] px-3 pt-2 bg-white">
          <div className="flex justify-start ">
            <img
              onClick={() => setOpenModalModifCatechu(false)}
              src={iconeCloseWindow}
              className="cursor-pointer"
              alt=""
            />
          </div>

          <h2 className="mt-3 text-2xl font-extrabold">
            Modifier cet catéchumène
          </h2>

          <div className="flex justify-center items-center  mt-6 h-24">
            <div
              onClick={handleClick}
              className="flex justify-center items-center w-20 h-20 bg-[#F9F9F9] rounded-full cursor-pointer"
            >
              {preview ? (
                <div className="relative ">
                  <div className="absolute bg-[#0000009a]  w-20 h-20 rounded-full flex justify-center">
                    <img src={iconeAppareilPhoto} className="w-8" alt="" />
                  </div>
                  <img
                    src={preview}
                    alt="Preview"
                    className="w-20 h-20 rounded-full"
                  />
                </div>
              ) : preview === null && infoCatechu !== null ? (
                <div className="relative">
                  <div className="absolute bg-[#00000079]  w-20 h-20 rounded-full flex justify-center">
                    <img src={iconeAppareilPhoto} className="w-8" alt="" />
                  </div>
                  <img
                    src={
                      infoCatechu &&
                      infoCatechu.catechumene &&
                      infoCatechu.catechumene.photo
                        ? infoCatechu.catechumene.photo
                        : ""
                    }
                    alt=""
                    className="w-20 h-20 rounded-full"
                  />
                </div>
              ) : (
                <div className="w-20 h-20 rounded-full flex justify-center">
                  <img src={iconeAppareilPhoto} className="w-8" alt="" />
                </div>
              )}
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>

          <div className="px-3 pb-10">
            <div>
              <label className="form-control">
                <div className="label">
                  <span className="label-text font-semibold text-md">Nom</span>
                </div>
                <input
                  type="text"
                  value={nomCatechu}
                  onChange={(e) => setNomCatechu(e.target.value)}
                  placeholder="Entrer le nom"
                  className="input input-bordered bg-[#F9F9F9]"
                />
              </label>

              <label className="form-control">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Prénoms
                  </span>
                </div>
                <input
                  type="text"
                  value={prenomsCatechu}
                  onChange={(e) => setPrenomsCatechu(e.target.value)}
                  placeholder="Entrer les prénoms"
                  className="input input-bordered bg-[#F9F9F9]"
                />
              </label>

              <label className="form-control">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Email
                  </span>
                </div>
                <input
                  type="email"
                  value={EmailCatechu}
                  onChange={(e) => setEmailCatechu(e.target.value)}
                  placeholder="Entrer l'adresse email"
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>
            </div>
            <div className="flex justify-center items-center gap-x-3">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Téléphone
                  </span>
                </div>
                <input
                  type="text"
                  value={telCatechu}
                  onChange={(e) => setTelCatechu(e.target.value)}
                  placeholder="Entrer un numéro"
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>

              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    second contact
                  </span>
                </div>
                <input
                  type="text"
                  value={secondTelCatechu}
                  onChange={(e) => setSecondTelCatechu(e.target.value)}
                  placeholder="Entrer le second contact"
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>
            </div>
            <div className="flex justify-center items-center w-full gap-2">
              <label className="form-control w-1/2">
                <div className="label">
                  <span className="label-text font-semibold">Commission</span>
                </div>
                <select
                  value={selectCommission}
                  onChange={(e) => setSelectCommission(e.target.value)}
                  className="select select-bordered bg-[#F9F9F9]"
                >
                  <option disabled selected>
                    selectionner une commission
                  </option>

                  {ListeCommission.map((commissionItem) => (
                    <option
                      className="bg-[#F9F9F9] text-black"
                      key={commissionItem._id}
                      value={commissionItem._id}
                    >
                      {commissionItem.designation}
                    </option>
                  ))}
                </select>
              </label>
              <label className="form-control w-1/2">
                <div className="label">
                  <span className="label-text font-semibold">Niveau</span>
                </div>
                <select
                  value={selectNiveau}
                  onChange={(e) => setSelectNiveau(e.target.value)}
                  className="select select-bordered bg-[#F9F9F9]"
                >
                  <option disabled selected>
                    selectionner un niveau
                  </option>
                  {ListeNiveau.map((niveauItem) => (
                    <option
                      className="bg-[#F9F9F9] text-black"
                      key={niveauItem.id}
                      value={niveauItem._id}
                    >
                      {niveauItem.designation}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <label className="form-control w-full ">
              <div className="label">
                <span className="label-text font-semibold">Classe</span>
              </div>
              <select
                value={selectClasseCatechu}
                onChange={(e) => setSelectClasseCatechu(e.target.value)}
                className="select select-bordered bg-[#F9F9F9]"
              >
                <option disabled selected>
                  selectionner une classe
                </option>
                <option disabled selected>
                  {" "}
                </option>
                {ListeClasse.map((classeItem) => (
                  <option
                    className="bg-[#F9F9F9] text-black"
                    key={classeItem.id}
                    value={classeItem._id}
                  >
                    {classeItem.designation}
                  </option>
                ))}
              </select>
            </label>

            <div>
              <h2 className="text-primary mt-2"> Information du père</h2>
              <hr />
              <div className="flex flex-col justify-center items-center gap-2">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-semibold text-md">
                      Nom du père
                    </span>
                  </div>
                  <input
                    type="text"
                    value={NomPereCatechu}
                    onChange={(e) => setNomPereCatechu(e.target.value)}
                    placeholder="Nom du parent"
                    className="input input-bordered w-full bg-[#F9F9F9]"
                  />
                </label>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-semibold text-md">
                      Prénoms du parent
                    </span>
                  </div>
                  <input
                    type="text"
                    value={PrenomsPereCatechu}
                    onChange={(e) => setPrenomsPereCatechu(e.target.value)}
                    placeholder="Prénoms du parent"
                    className="input input-bordered w-full bg-[#F9F9F9]"
                  />
                </label>
                <div className="w-full flex justify-center items-center gap-2">
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-semibold text-md">
                        Tel du parent
                      </span>
                    </div>
                    <input
                      type="text"
                      value={ContactPereCatechu}
                      onChange={(e) => setContactPereCatechu(e.target.value)}
                      placeholder="Nom du parent"
                      className="input input-bordered w-full bg-[#F9F9F9]"
                    />
                  </label>

                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-semibold text-md">
                        Second tel parent
                      </span>
                    </div>
                    <input
                      type="text"
                      value={SecondContactPereCatechu}
                      onChange={(e) =>
                        setSecondContactPereCatechu(e.target.value)
                      }
                      placeholder="contact du parent"
                      className="input input-bordered w-full bg-[#F9F9F9]"
                    />
                  </label>
                </div>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-semibold text-md">
                      Email du parent
                    </span>
                  </div>
                  <input
                    type="email"
                    placeholder="Email du parent"
                    value={EmailPereCatechu}
                    onChange={(e) => setEmailPereCatechu(e.target.value)}
                    className="input input-bordered w-full bg-[#F9F9F9]"
                  />
                </label>
              </div>
            </div>

            <div>
              <h2 className="text-primary mt-2"> Information de la mère</h2>
              <hr />
              <div className="flex flex-col justify-center items-center gap-2">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-semibold text-md">
                      Nom de la mère
                    </span>
                  </div>
                  <input
                    type="text"
                    value={NomMereCatechu}
                    onChange={(e) => setNomMereCatechu(e.target.value)}
                    placeholder="Nom du parent"
                    className="input input-bordered w-full bg-[#F9F9F9]"
                  />
                </label>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-semibold text-md">
                      Prénoms de la mère
                    </span>
                  </div>
                  <input
                    type="text"
                    value={PrenomsMereCatechu}
                    onChange={(e) => setPrenomsMereCatechu(e.target.value)}
                    placeholder="Prénoms du parent"
                    className="input input-bordered w-full bg-[#F9F9F9]"
                  />
                </label>
                <div className="w-full flex justify-center items-center gap-2">
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-semibold text-md">
                        Tel de la mère
                      </span>
                    </div>
                    <input
                      type="text"
                      value={ContactMereCatechu}
                      onChange={(e) => setContactMereCatechu(e.target.value)}
                      placeholder="Nom du parent"
                      className="input input-bordered w-full bg-[#F9F9F9]"
                    />
                  </label>

                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-semibold text-md">
                        Second tel de la mère
                      </span>
                    </div>
                    <input
                      type="text"
                      value={SecondContactMereCatechu}
                      onChange={(e) =>
                        setSecondContactMereCatechu(e.target.value)
                      }
                      placeholder="contact du parent"
                      className="input input-bordered w-full bg-[#F9F9F9]"
                    />
                  </label>
                </div>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-semibold text-md">
                      Email de la mère
                    </span>
                  </div>
                  <input
                    type="email"
                    placeholder="Email du parent"
                    value={EmailMereCatechu}
                    onChange={(e) => setEmailMereCatechu(e.target.value)}
                    className="input input-bordered w-full bg-[#F9F9F9]"
                  />
                </label>
              </div>
            </div>

            <div>
              <h2 className="text-primary mt-4"> Information tuteur/tutrice</h2>
              <hr />
              <div className="flex flex-col justify-center items-center gap-2">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-semibold text-md">
                      Nom & prénoms tuteur/tutrice
                    </span>
                  </div>
                  <input
                    type="text"
                    value={nomPrenomsTuteurCatechu}
                    onChange={(e) => setNomPrenomsTuteurCatechu(e.target.value)}
                    placeholder="Nom du parent"
                    className="input input-bordered w-full bg-[#F9F9F9]"
                  />
                </label>

                <div className="w-full flex justify-center items-center gap-2">
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-semibold text-md">
                        Tel tuteur/tutrice
                      </span>
                    </div>
                    <input
                      type="text"
                      value={ContactTuteurCatechu}
                      onChange={(e) => setContactTuteurCatechu(e.target.value)}
                      placeholder="Nom du parent"
                      className="input input-bordered w-full bg-[#F9F9F9]"
                    />
                  </label>

                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-semibold text-md">
                        Second tel tuteur/tutrice
                      </span>
                    </div>
                    <input
                      type="text"
                      value={SecondContactTuteurCatechu}
                      onChange={(e) =>
                        setSecondContactMereCatechu(e.target.value)
                      }
                      placeholder="contact du parent"
                      className="input input-bordered w-full bg-[#F9F9F9]"
                    />
                  </label>
                </div>
              </div>
            </div>
            {/* {infosCatechumene !== null ? null : (
              <>
                <div className="flex flex-row justify-center items-center w-full gap-2">
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-semibold">
                        Motif du paiement
                      </span>
                    </div>
                    <select
                      value={selectMotifPaiement}
                      onChange={(e) => setSelectMotifPaiement(e.target.value)}
                      className="select select-bordered bg-[#F9F9F9]"
                    >
                      <option disabled selected>
                        choisir un motif
                      </option>

                      {ListeDesEvenements.map((evenement) => (
                        <option
                          className="bg-[#F9F9F9] text-black"
                          key={evenement.id}
                          value={evenement._id}
                        >
                          {evenement.designation}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-semibold">
                        Mode de paiement
                      </span>
                    </div>
                    <select
                      value={selectModePaiement}
                      onChange={(e) => setSelectModePaiement(e.target.value)}
                      className="select select-bordered bg-[#F9F9F9]"
                    >
                      <option disabled selected>
                        selectionner un mode
                      </option>

                      {ListeModePaiement.map((mode) => (
                        <option
                          className="bg-[#F9F9F9] text-black"
                          key={mode.id}
                          value={mode._id}
                        >
                          {mode.designation}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>

                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-semibold text-md">
                      Montant a payé
                    </span>
                  </div>
                  <input
                    type="text"
                    value={MontantPaye}
                    onChange={(e) => setMontantPaye(e.target.value)}
                    placeholder="Entrer le montant"
                    className="input input-bordered w-full bg-[#F9F9F9]"
                  />
                </label>
              </>
            )} */}

            <button
              onClick={modifCatechu}
              className="mt-5 bg-primary flex items-center justify-center w-full h-10 font-medium text-white rounded-lg hover:drop-shadow-md"
            >
              {!isLoading ? (
                <span>Modifier cet catéchumène</span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={isLoading}
                />
              )}
            </button>
          </div>
        </div>
      </Drawer>

      {/* MODAL DELETE CATECHUMENE */}
      <dialog id="delete_catechumene" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-bold text-xl text-center text-red-600">
            Supprimer ce catéchumène
          </h3>
          <div className="py-4">
            <p className="text-center text-lg text-black">
              Attention action est irrversible et entrainera la suppression du
              catéchumène
            </p>
          </div>
          <div className="modal-action w-full flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? (
                <div
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={deleteCatechu}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="bg-green-500 text-white px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="bg-red-500 text-white px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </BaseLayout>
  );
};

export default CatechumeneDetails;
