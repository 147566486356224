import { BASE_URL, FREE_BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
}, function (error) {
  console.log('AN ERROR REQUEST', error)
});

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const listeDesMenuPaginee = async (page = 1, size = 10, param = "") => {  
  return await axios.get(
    BASE_URL(`menuVisible/query?page=${page}&size=${size}&param=${param}`),
    {}
  );
};

export const listeCompleteDesMenu = async () => {
  return await axios.get(
    BASE_URL(`menuVisible`),
    {}
  );
};


export const recupereMenuParSonId = async (niveauId) => {
  return await axios.get(
    BASE_URL(`menuVisible/${niveauId}`),
    {}
  );
};

export const ajouterUneMenu = async (values) => {
  return await axios.post(
    BASE_URL(`menuVisible`),
    values
  );
};

export const modifierUneMenu = async (values, niveauId) => {
  return await axios.put(
    BASE_URL(`menuVisible/${niveauId}`),
    values
  );
};

export const supprimerUneMenu = async (niveauId) => {
  return await axios.delete(
    BASE_URL(`menuVisible/${niveauId}`),
    {}
  );
};
