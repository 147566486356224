import React, { useEffect, useState } from "react";
import BaseLayout from "../../../layout/BaseLayout";
import iconeArrow from "../../../assets/IconeSvg/square-arrow-right.svg";
import { NavLink, useParams } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { recupereAnimateurParSonId } from "../../../services/animateur/AnimateurRequest";
import ListePresenceAnimateur from "../presence/ListePresenceAnimateur";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const AnimateurDetails = () => {
  let { animateurId } = useParams();
  const [animateurSelected, setAnimateurSelected] = useState(null);
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    recupereAnimateurParSonId(animateurId)
      .then((res) => {
        setAnimateurSelected(res.data);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }, []);

  return (
    <BaseLayout>
      <div className="w-full h-full">
        <div className="flex items-center gap-x-2">
          <NavLink to="/animateurs">
            <p className="cursor-pointer text-lg sm:text-2xl text-black font-extrabold">
              Animateurs
            </p>
          </NavLink>
          <div>
            <img src={iconeArrow} className="w-8" />
          </div>
          <p className="cursor-pointer text-lg sm:text-2xl text-primary font-extrabold">
            {animateurSelected
              ? `${animateurSelected.nom} ${animateurSelected.prenoms}`
              : null}
          </p>
        </div>
        <div className="mt-6 flex items-start flex-wrap gap-4">
          <div>
            <h2 className="font-bold text-black text-xs">Détails animateur</h2>
            <div className="mt-1 w-fit flex  items-center gap-x-4 bg-white border drop-shadow-sm rounded-lg p-3">
              <div>
                <img
                  src={animateurSelected ? animateurSelected.photo : ""}
                  className="w-20 h-20 rounded-lg"
                />
              </div>
              <div>
                <p className="text-sm font-medium">
                  Nom & prénoms:{" "}
                  {animateurSelected
                    ? `${animateurSelected.nom} ${animateurSelected.prenoms}`
                    : null}
                </p>
                <p className="text-sm font-medium">
                  Email: {animateurSelected ? animateurSelected.email : null}
                </p>
                <p className="text-sm font-medium">
                  Contact: {animateurSelected ? animateurSelected.contact : null}
                </p>
                <p className="text-sm font-medium">
                  Second contact:{" "}
                  {animateurSelected ? animateurSelected.secondContact : null}
                </p>
              </div>
            </div>
          </div>
          <div>
            <h2 className="font-bold text-black text-xs">Classe principale</h2>
            <div className="mt-1 w-fit flex items-center gap-x-2 bg-white border drop-shadow-sm rounded-lg p-3">
              <div>
                <p className="text-sm font-medium">
                  Commission:{" "}
                  {animateurSelected && animateurSelected.classe
                    ? animateurSelected.classe.commission.designation
                    : null}
                </p>
                <p className="text-sm font-medium">
                  Niveau:{" "}
                  {animateurSelected && animateurSelected.classe
                    ? animateurSelected.classe.niveau.designation
                    : null}
                </p>
                <p className="text-sm font-medium">
                  Classe:{" "}
                  {animateurSelected && animateurSelected.classe
                    ? animateurSelected.classe.designation
                    : null}
                </p>
              </div>
            </div>
          </div>
          <div>
            <h2 className="font-bold text-black text-xs">Classe secondaire</h2>
            <div className="mt-1 w-fit flex items-center gap-x-2 bg-white border drop-shadow-sm rounded-lg p-3">
              <div>
                <p className="text-sm font-medium">
                  Commission:{" "}
                  {animateurSelected && animateurSelected.classeRemplacement
                    ? animateurSelected.classeRemplacement.commission.designation
                    : "-"}
                </p>
                <p className="text-sm font-medium">
                  Niveau:{" "}
                  {animateurSelected && animateurSelected.classeRemplacement
                    ? animateurSelected.classeRemplacement.niveau.designation
                    : "-"}
                </p>
                <p className="text-sm font-medium">
                  Classe:{" "}
                  {animateurSelected && animateurSelected.classeRemplacement
                    ? animateurSelected.classeRemplacement.designation
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 border-b">
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            variant="scrollable"
            textColor="black"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            <Tab
              label="Liste présence"
              {...a11yProps(0)}
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                fontWeight: 700,
              }}
            />
          </Tabs>
        </div>

        <div className="mt-8">
          <CustomTabPanel value={valueTab} index={0}>
            <ListePresenceAnimateur classe={animateurSelected} />
          </CustomTabPanel>
        </div>
      </div>
    </BaseLayout>
  );
};

export default AnimateurDetails;
