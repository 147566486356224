import React from "react";
import Snackbar from "@mui/material/Snackbar";

const SnackbarComponent = ({
  vertical,
  horizontal,
  snackbarSuccessOpen,
  handleCloseSnackbarSuccess,
  successMessage,
  snackbarErrorOpen,
  handleCloseSnackbarError,
  errorMessage,
}) => {
  return (
    <div>
      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={5000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="zoom bg-green-600 text-white font-semibold px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={5000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="zoom bg-red-600 text-white font-semibold px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default SnackbarComponent;
