import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import LOGO_WHITE from "./../../assets/images/logo-preview.png";
import { ThreeDots } from "react-loader-spinner";
import { loginAdmin } from "../../services/authentification/AuthRequest";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [messageAlert, setMessageAlert] = useState("");

  const handleSubmit = () => {
    setIsLoading(true);
    const data = {
      password: password,
      email: username,
    };
    try {
      loginAdmin(data)
        .then((res) => {
          setIsLoading(false);
          localStorage.setItem("cc_pssm_api", res.data.token);
          navigate("/dashboard");
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response) {
            setMessageAlert(err.response.data.message);
          } else {
            setMessageAlert("Une erreur s'est produite, rééssayez!");
          }
          console.log("api error", err);
        });
    } catch (error) {
      setIsLoading(false);
      setMessageAlert("Quelque chose s'est mal passé, rééssayer");
    }
  };

  useEffect(() => {
    function isEventExpired(currentDate, endDateInMilliseconds) {
      // Convert the milliseconds to a Date object
      const endDate = new Date(endDateInMilliseconds);
      // Compare the two dates
      if (currentDate > endDate) {
        // Event has expired
        return true;
      } else {
        // Event is still within time
        return false;
      }
    }

    if (localStorage.getItem("cc_pssm_api")) {
      const currentDate = new Date();
      const decodedToken = jwtDecode(localStorage.getItem("cc_pssm_api"));
      const expirationDate = new Date(decodedToken.exp * 1000);

      if (decodedToken.exp && !isEventExpired(currentDate, expirationDate)) {
        navigate("/dashboard");
      }
    }
  }, []);

  return (
    <div className="w-full min-h-screen flex items-center">
      <div className="flex flex-col pattern h-full w-full gap-8 md:w-1/2 min-h-screen items-center justify-center">
        <div className="block md:hidden mx-2 bg-white p-4 rounded-lg">
          <div className="w-fit mx-auto h-fit">
            <img src={LOGO_WHITE} className="w-20" />
            <h1 className="text-2xl lg:text-3xl text-black font-extrabold mt-2">
              Bon retour parmi nous
            </h1>
            <p className="mt-3 text-zinc-600 font-medium">
              Entrez vos informations pour vous connecter et accéder à
              l’application
            </p>
            <div className="mt-5">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text font-medium text-gray-500">
                    Email ou numéro de téléphone
                  </span>
                </label>
                <input
                  type="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="exemple@gmail.com ou 070000000"
                  className="input input-bordered w-full font-medium text-black"
                />
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text font-medium text-gray-500">
                    Mot de passe
                  </span>
                </label>
                <input
                  type="password"
                  placeholder="********"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input input-bordered w-full font-medium text-black"
                />
                {/* <div className="flex justify-end mt-2">
                  <Link
                    to="/forget-password"
                    className="text-sm font-medium underline"
                  >
                    Mot de passe oublié
                  </Link>
                </div> */}
              </div>
              {messageAlert ? (
                <div className="mt-4">
                  <p className="text-red-500 text-sm text-center font-medium">
                    {messageAlert}
                  </p>
                </div>
              ) : null}
              <button
                onClick={handleSubmit}
                className="mt-5 bg-primary flex items-center justify-center w-full h-10 font-medium text-white rounded-lg hover:drop-shadow-md"
              >
                {!isLoading ? (
                  <span>Se connecter</span>
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#000"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={isLoading}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden xl:flex justify-center items-center md:flex md:w-1/2  min-h-screen bg-white">
        <div className="w-[80%] lg:w-3/5 mx-auto h-fit rounded-lg">
          <img src={LOGO_WHITE} alt="" className="w-20" />
          <h1 className="text-2xl lg:text-3xl text-black font-extrabold mt-2">
            Bon retour parmi nous
          </h1>
          <p className="mt-3 text-zinc-600 font-medium">
            Entrez vos informations pour vous connecter et accéder à
            l’application
          </p>
          <div className="mt-5">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text font-medium text-gray-500">
                  Email ou numéro de téléphone
                </span>
              </label>
              <input
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="exemple@gmail.com ou 070000000"
                className="input input-bordered w-full bg-[#F9F9F9] font-medium text-black"
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text font-medium text-gray-500">
                  Mot de passe
                </span>
              </label>
              <input
                type="password"
                placeholder="********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input input-bordered w-full bg-[#F9F9F9] font-medium text-black"
              />
              {/* <div className="flex justify-end mt-2">
                <Link
                  to="/forget-password"
                  className="text-sm font-medium underline"
                >
                  Mot de passe oublié
                </Link>
              </div> */}
            </div>
            {messageAlert ? (
              <div className="mt-4">
                <p className="text-red-500 text-sm text-center font-medium">
                  {messageAlert}
                </p>
              </div>
            ) : null}
            <button
              onClick={handleSubmit}
              className="mt-5 bg-primary flex items-center justify-center w-full h-10 font-medium text-white rounded-lg hover:drop-shadow-md"
            >
              {!isLoading ? (
                <span>Se connecter</span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#000"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={isLoading}
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
