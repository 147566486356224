import React, { useEffect, useRef, useState } from "react";
import BaseLayout from "../../layout/BaseLayout";
import DatePicker from "react-datepicker";
import iconeCalendrier from "../../assets/IconeSvg/calendrier3.svg";
import IconeCalendierTransparent from "../../assets/IconeSvg/calendrieTransparent.svg";
import iconeAdd from "../../assets/IconeSvg/boutonAdd.svg";
import iconethreeDots from "../../assets/IconeSvg/treeDotsGray.svg";
import iconeDelete from "../../assets/IconeSvg/delete.svg";
import iconeDesactiver from "../../assets/IconeSvg/desactiver.svg";
import iconePencil from "../../assets/IconeSvg/pencil.svg";
import iconeCloseWindow from "../../assets/IconeSvg/close_window.svg";
import iconeAppareilPhoto from "../../assets/IconeSvg/appareil_photo.svg";
import iconeLocalisation from "../../assets/IconeSvg/IconeLocalisation.svg";
import { Drawer, Pagination, Snackbar } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import {
  enregistrerEvenement,
  enregistrerEvenementAnimateur,
  ListDesEvenements,
  ListeCompleteEvent,
  ListeCompleteParticipantEvent,
  supprminerEvent,
  udpdateEvent
} from "../../services/evenements/EvenementRequests";
import {
  returnPermissionsParMenu,
  verificationPermissionsExistantes
} from "../../utils/ManageUserProfil";

const listePermissions = returnPermissionsParMenu("/evenements");
const Evenement = () => {
  const navigate = useNavigate();
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 1,
    nombrePage: 1
  });

  const currentAnnee = localStorage.getItem("currentAnnee")
    ? JSON.parse(localStorage.getItem("currentAnnee"))
    : null;

  const [EventName, setEventName] = useState("");
  const [EventLieu, setEventLieu] = useState("");
  const [EventDateDebut, setEventDateDebut] = useState("");
  const [EventDateFin, setEventDateFin] = useState("");
  const [eventBudget, setEventBudget] = useState("");
  const [InfosEvenements, setInfosEvenements] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [ListeEvenement, setListeEvenement] = useState([1, 2, 3]);

  const [searchLoading, setSearchLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingData, setloadingData] = useState(false);

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;
  const currentYear = new Date().getFullYear();
  const [startDate, setStartDate] = useState(new Date(currentYear, 0, 1)); // 1er janvier de l'année en cours
  const [endDate, setEndDate] = useState(new Date(currentYear + 1, 0, 1)); // 1er janvier de l'année suivante

  const [profilAdminPicture, setProfilAdminPicture] = useState("");

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const openModalModif = (eventItem) => {
    setOpenModal(true);
    setInfosEvenements(eventItem);
    setEventName(eventItem.designation);
    setEventLieu(eventItem.lieu);
    setEventDateDebut(eventItem.dateDebut);
    setEventDateFin(eventItem.dateFin);
    setEventBudget(eventItem.montant_participation);
  };

  const clearInputEnregistrement = () => {
    setEventName("");
    setEventLieu("");
    setEventDateDebut("");
    setEventDateFin("");
    setEventBudget("");
  };

  const enregristrementEvent = () => {
    setIsLoading(true);

    const dataEvent = {
      designation: EventName,
      lieu: EventLieu,
      dateDebut: EventDateDebut,
      dateFin: EventDateFin,
      budget: eventBudget,
      participants: [],
      administrateurs: [],
      anneeScolaire: currentAnnee._id
    };

    enregistrerEvenementAnimateur(dataEvent)
      .then((res) => {
        setIsLoading(false);
        setSuccessMessage("Evènement enregistré avec succès");
        setSnackbarOpenSuccess(true);
        setOpenModal(false);
        clearInputEnregistrement();
        ListeDesEvenements();
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage("Erreur lors de l'enregistrement de l'évènement");
        setSnackbarOpenError(true);
      });
  };

  const deleteEvent = () => {
    setDeleteLoading(true);
    supprminerEvent(InfosEvenements._id)
      .then((res) => {
        setDeleteLoading(false);
        console.log(res);
        setSuccessMessage("Evènement supprimé");
        setSnackbarOpenSuccess(true);
        setOpenModal(false);
        // document.getElementById("delete_evenement").click();
        ListeDesEvenements();
       
      })
      .catch((err) => {
        setDeleteLoading(false);
        console.log("api error", err);
        setErrorMessage("Erreur de suppression de l'évènement, réessayez!");
        setSnackbarOpenError(true);
      });
  };

  const ListeDesEvenements = () => {
    setloadingData(true);

    ListeCompleteEvent(pageQuery.page, pageQuery.size, searchTerm)
      .then((res) => {
        setloadingData(false);
        console.log(res);
        setListeEvenement(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages
        }));
      })
      .catch((err) => {
        setloadingData(false);
        setListeEvenement([]);
        console.log(err);
      });
  };

  // const modifEvent = () => {
  //   setIsLoading(true);
  //   const dataEvent = {
  //     designation: EventName,
  //     lieu: EventLieu,
  //     dateDebut: EventDateDebut,
  //     dateFin: EventDateFin,
  //     montant_participation: eventBudget,
  //   };
  //   udpdateEvent(InfosEvenements._id, dataEvent)
  //     .then((res) => {
  //       setIsLoading(false);
  //       setSuccessMessage("Evènement modifié avec succès");
  //       setSnackbarOpenSuccess(true);
  //       setOpenModal(false);
  //       clearInputEnregistrement();
  //       ListeDesEvenements();
  //     })
  //     .catch(() => {
  //       setIsLoading(false);
  //       setErrorMessage("Erreur lors de la modification de évènement");
  //       setSnackbarOpenError(true);
  //     });
  // };

  const handlePaginationChange = (event, value) => {
    setIsLoading(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value
    }));
    if (searchTerm.length !== 0) {
      ListeCompleteParticipantEvent(value, pageQuery.size, "")
        .then((res) => {
          setListeEvenement(res.data.results);
          setPageQuery((prev) => ({
            ...prev,
            nombrePage: res.data.totalPages
          }));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("api error", err);
        });
    } else {
      ListeCompleteParticipantEvent(value, pageQuery.size, searchTerm)
        .then((res) => {
          setIsLoading(false);
          setListeEvenement(res.data.results);
          setPageQuery((prev) => ({
            ...prev,
            nombrePage: res.data.totalPages
          }));
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("api error", err);
        });
    }
  };

  const searchEvent = () => {
    setSearchLoading(true);

    ListeCompleteParticipantEvent(pageQuery.page, pageQuery.size, searchTerm)
      .then((res) => {
        setSearchLoading(false);
        setListeEvenement(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((err) => {
        setSearchLoading(false);
        console.log("api error", err);
      });
  };

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date); // Convertir en objet Date
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    // const hours = String(date.getHours()).padStart(2, "0");
    // const minutes = String(date.getMinutes()).padStart(2, "0");
    // const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    ListeDesEvenements();
  }, []);

  return (
    <div>
      <div className="w-full h-full">
        <div className="flex flex-col sm:flex-row justify-end mt-4 items-end sm:items-center gap-2">
          <div className="w-full sm:w-fit flex items-center gap-x-2">
            <input
              type="text"
              placeholder="Rechercher un élement..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="input input-bordered w-11/12 sm:w-56 h-10 text-sm font-medium"
            />
            <button
              disabled={searchLoading}
              className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
              onClick={searchEvent}
            >
              {!searchLoading ? (
                "Rechercher"
              ) : (
                <ThreeDots
                  height="35"
                  width="35"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={searchLoading}
                />
              )}
            </button>
          </div>

          {verificationPermissionsExistantes(listePermissions, "AJOUTER") && (
            <button
              onClick={() => {
                clearInputEnregistrement();
                setOpenModal(true);
              }}
              className="bg-black w-full sm:w-fit h-10 px-4 flex items-center justify-center gap-x-2 rounded-xl"
            >
              <img src={iconeAdd} alt="" className="w-5 sm:w-auto" />
              <span className="text-white text-sm font-semibold truncate">
                Ajouter un évènement
              </span>
            </button>
          )}
        </div>

        {/* LISTE DES EVENEMENTS */}
        <div className="mt-10 grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5">
          {!loadingData && ListeEvenement.length > 0 ? (
            ListeEvenement.map((item, index) => (
              <div
                key={index}
                onClick={() => 
                  verificationPermissionsExistantes(
                    listePermissions,
                    "VOIR_DETAIL"
                  ) ? navigate(`/evenements/${item._id}`)
                    : null
                }
                className="relative w-full cursor-pointer h-fit p-2 flex flex-col items-center bg-[#fdfdfd] hover:shadow-md rounded-lg border-[1px]  "
              >
                <div className="flex flex-row justify-end items-center mb-[-2rem]  w-full">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setInfosEvenements(item);
                    }}
                    className="dropdown dropdown-bottom dropdown-end"
                  >
                    <button className="w-7 h-7 rounded-lg bg-gray-200 flex items-center justify-center">
                      <img src={iconethreeDots} alt="" />
                    </button>
                    <ul
                      tabIndex={0}
                      className="dropdown-content flex flex-col gap-2 z-[1]  menu p-1 px-2 rounded-lg  shadow bg-base-100 w-32"
                    >
                      {verificationPermissionsExistantes(
                        listePermissions,
                        "MODIFIER"
                      ) && (
                        <button
                          onClick={() => {
                            openModalModif(item);
                          }}
                          className="rounded-lg  flex flex-row gap-x-2 items-center justify-center pr-4"
                        >
                          <img src={iconePencil} alt="" />
                          <span className="font-medium text-[16px]">
                            Modifier
                          </span>
                        </button>
                      )}

                      {verificationPermissionsExistantes(
                        listePermissions,
                        "SUPPRIMER"
                      ) && (
                        <button
                          onClick={() => {
                            window.delete_evenement.showModal();
                          }}
                          className="  rounded-lg  flex flex-row gap-x-2 items-center justify-center pr-1"
                        >
                          <img src={iconeDelete} alt="" />
                          <span className="font-medium text-[16px]">
                            Supprimer
                          </span>
                        </button>
                      )}
                    </ul>
                  </div>
                </div>
                <h3 className="text-[#A70606] font-bold text-lg">
                  {item.designation}
                </h3>
                <div className="flex flex-row w-full justify-between mt-2 gap-2">
                  <div className="flex flex-row gap-2">
                    <img
                      src={iconeCalendrier}
                      className="xl:w-6 lg:w-6 md:w-6 w-5"
                      alt=""
                    />
                    <div>
                      <h2 className="text-[#979797]  xl:text-[15px] md:text-[15px] text-[12px] lg:text-[12px]">
                        Date de début
                      </h2>
                      <span className="lg:text-[15px]  xl:text-[15px] md:text-[15px] text-[10px] font-semibold">
                        {formatDate(item.dateDebut)}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row gap-2">
                    <img
                      src={iconeCalendrier}
                      className="xl:w-6 lg:w-6 md:w-6 w-5"
                      alt=""
                    />
                    <div className="">
                      <h2 className="text-[#979797]  xl:text-[15px] md:text-[15px] text-[12px] lg:text-[12px]">
                        Date de fin
                      </h2>
                      <h2 className="lg:text-[15px]  xl:text-[15px] md:text-[15px] text-[10px] font-semibold">
                        {formatDate(item.dateDebut)}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-between gap-2 w-full py-2">
                  <div className="flex flex-row w-full ">
                    <img src={iconeLocalisation} className="w-6" alt="" />
                    <div className="w-full  gap-1 pl-2">
                      <h2 className="text-[#979797]  xl:text-[15px] md:text-[15px] text-[12px] lg:text-[12px]">
                        Lieu
                      </h2>
                      <h3 className="xl:text-[15px] md:text-[15px] text-[12px] lg:text-[15px] font-semibold truncate ">
                        {item.lieu}
                      </h3>
                    </div>
                  </div>
                  {/* <div className="flex flex-row pr-2 ">
                    <img src={iconeLocalisation} className="w-6" alt="" />
                    <div className="w-[70%] gap-1 pl-2">
                      <h2 className="text-[#979797]  xl:text-[15px] md:text-[15px] text-[12px] lg:text-[12px]">
                        Participation
                      </h2>
                      <h3 className="xl:text-[15px] md:text-[15px] text-[12px] lg:text-[15px] font-semibold truncate ">
                        {item.montant_participation
                          ? item.montant_participation
                          : "--"}
                      </h3>
                    </div>
                  </div> */}
                </div>

                <hr className="w-full h-2" />

                <div className="flex flex-row w-full justify-around items-center gap-2">
                  <div className="flex flex-row gap-2">
                    <div className="flex flex-col items-center justify-center">
                      <h2 className="text-[#979797] xl:text-[14px] md:text-[14px] text-[12px] lg:text-[14px]">
                        Nombre de participant
                      </h2>
                      <span className="text-[20px] font-black">
                        {item.participants && item.participants.length > 0
                          ? item.participants.length
                          : "--"}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row gap-2">
                    <div className="flex flex-col items-center justify-center">
                      <h2 className="text-[#979797] xl:text-[14px] md:text-[14px] text-[12px] lg:text-[14px]">
                        Budget
                      </h2>
                      <span className="text-[20px] font-black">
                        {item.budget ? item.budget : "--"} FCFA
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : !loadingData && ListeEvenement.length === 0 ? (
            <div className="flex col-span-full w-full items-center justify-center  h-[55vh]">
              <div className="flex items-center justify-center text-red-500">
                <p className="font-medium">Aucun Evènements trouvé</p>
              </div>
            </div>
          ) : (
            <div className="flex col-span-full w-full items-center justify-center  h-[55vh]">
              <div className="w-full  h-32 rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                <TailSpin
                  height="50"
                  width="50"
                  color="#000"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loadingData}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-end mt-4">
          <Pagination
            count={pageQuery.nombrePage}
            // page={pageQuery.pagePagination}
            variant="outlined"
            shape="rounded"
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {/* Drawer DELETE EVENEMENT */}
      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="w-screen sm:w-[430px] h-full px-4 pt-2 pl-2 flex flex-col bg-white ">
          <div className="flex justify-start ">
            <img
              onClick={() => setOpenModal(false)}
              src={iconeCloseWindow}
              className="cursor-pointer"
              alt=""
            />
          </div>

          <h2 className="mt-3 ml-2 text-lg font-semibold">
            {InfosEvenements === null
              ? " Créer un nouvel évènement"
              : "Modifier cet nouvel évènement"}
          </h2>

          <div className="flex flex-col w-full pb-4">
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Nom événement
                  </span>
                </div>
                <input
                  type="text"
                  value={EventName}
                  onChange={(e) => setEventName(e.target.value)}
                  placeholder="Entrer le nom de l'événement"
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>

              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Lieu de l'événement
                  </span>
                </div>
                <input
                  type="text"
                  value={EventLieu}
                  onChange={(e) => setEventLieu(e.target.value)}
                  placeholder="Entrer les prénoms"
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>
            </div>

            <div className="flex flex-row justify-center w-full items-center gap-x-1">
              <label className="form-control w-[50%]">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Date de début
                  </span>
                </div>
                <input
                  type="date"
                  placeholder="Entrer une date"
                  value={EventDateDebut}
                  onChange={(e) => setEventDateDebut(e.target.value)}
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>

              <label className="form-control w-[50%]">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Date de fin
                  </span>
                </div>
                <input
                  type="date"
                  placeholder="Entrer une date"
                  value={EventDateFin}
                  onChange={(e) => setEventDateFin(e.target.value)}
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>
            </div>

            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Budget d'écaisser
                  </span>
                </div>
                <input
                  type="email"
                  value={eventBudget}
                  onChange={(e) => setEventBudget(e.target.value)}
                  placeholder="Entrer le montant ici"
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>
            </div>

            <button
              onClick={
                // InfosEvenements === null ? enregristrementEvent : modifEvent
                InfosEvenements === null ? enregristrementEvent : null
              }
              className="mt-5 bg-primary flex items-center justify-center w-full h-10 font-medium text-white rounded-lg hover:drop-shadow-md"
            >
              {!isLoading ? (
                <span>
                  {InfosEvenements === null
                    ? " Enregistrer cet admin"
                    : "Modifier cet  administrateur"}
                </span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={isLoading}
                />
              )}
            </button>
          </div>
        </div>
      </Drawer>

      {/* MODAL DELETE EVENEMENT */}
      <dialog id="delete_evenement" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-center text-primary">
            Supprimer cet événement
          </h3>
          <div className="py-4">
            <p className="text-center text-base font-medium text-gray-600">
              Attention action est irrversible et entrainera la suppression de
              cet événement
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? (
                <div
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={deleteEvent}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="bg-green-500 text-white px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="bg-red-500 text-white px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default Evenement;
