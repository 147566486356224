import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import iconeAdd from "../../../assets/IconeSvg/boutonAdd.svg";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import iconethreeDots from "../../../assets/IconeSvg/treeDotsGray.svg";
import iconeDelete from "../../../assets/IconeSvg/delete.svg";
import { listeCompleteDesNiveauParCommission } from "../../../services/parametrage/NiveauxRequest";
import { Drawer, Pagination, Snackbar } from "@mui/material";
import { MultiSelect } from "react-multi-select-component";
import { listeCompleteDesMotifPaiements } from "../../../services/parametrage/MotifPaiementRequest";
import { listeCompleteDesTarifications } from "../../../services/parametrage/TarificationRequest";
import { listeCompleteDesCommissionsParAnnee } from "../../../services/parametrage/CommissionRequest";
import {
  enregistrerCeremonie,
  recupererListeCeremoniesPaginee,
  supprimerCeremonie
} from "../../../services/ceremonie/CeremonieRequests";
import { useNavigate } from "react-router-dom";
import {
  returnPermissionsParMenu,
  verificationPermissionsExistantes
} from "../../../utils/ManageUserProfil";

const listePermissions = returnPermissionsParMenu("/evenements");

const languageOption = {
  allItemsAreSelected: "Tous les items sont selectionnées.",
  clearSearch: "Vider la recherche",
  clearSelected: "Vider la selection",
  noOptions: "Pas d'options",
  search: "Rechercher",
  selectAll: "Tout choisir",
  selectAllFiltered: "Tout sélectionner (Filtrés)",
  selectSomeItems: "Selectionner...",
  create: "Créer"
};

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const Ceremonies = () => {
  const navigate = useNavigate();
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 20,
    nombrePage: 1,
    pagePagination: 1
  });
  const [ceremonieData, setCeremonieData] = useState({
    designation: "",
    commission: "",
    niveaux: "",
    motifPaiement: ""
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [commissionSelected, setCommissionSelected] = useState("");
  const [niveauSelected, setNiveauSelected] = useState("");
  const [ceremonieInfo, setCeremonieInfo] = useState("");
  const [listeCommission, setListeCommission] = useState([]);
  const [listeNiveau, setListeNiveau] = useState([]);
  const [listeMotifPaiement, setListeMotifPaiement] = useState([]);
  const [listeTarifPaiement, setListeTarifPaiement] = useState([]);
  const [listeCeremonies, setListeCeremonies] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;
  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };
  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const CeremonieElement = ({ ceremonie }) => {
    return (
      <div className="w-fit h-fit relative">
        <div className="absolute right-2 top-2 z-[1]">
          <div className="dropdown dropdown-left dropdown-end">
            <div
              tabIndex={0}
              className="w-7 h-7 rounded-lg bg-gray-200 flex items-center justify-center cursor-pointer"
            >
              <img src={iconethreeDots} alt="" />
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-1 shadow border bg-base-100 rounded-lg w-44 mr-2"
            >
              {verificationPermissionsExistantes(
                listePermissions,
                "SUPPRIMER"
              ) && (
                <button
                  onClick={() => {
                    setCeremonieInfo(ceremonie);
                    document.getElementById("delete_ceremonie").showModal();
                  }}
                  className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                >
                  <img src={iconeDelete} alt="" className="w-5" />
                  <span className="font-semibold text-sm">Supprimer</span>
                </button>
              )}
            </ul>
          </div>
        </div>
        <div
          className="cursor-pointer w-full bg-white rounded-xl p-4 border drop-shadow-sm"
          onClick={() =>
            verificationPermissionsExistantes(listePermissions, "VOIR_DETAIL")
              ? navigate(`/inscription-ceremonie/${ceremonie._id}`)
              : null
          }
        >
          <div className="mt-2">
            <p className="text-lg text-black text-left font-extrabold">
              {ceremonie.designation}
            </p>
          </div>
          <div className="mt-3">
            <p className="text-base text-gray-600 text-left font-semibold">
              <span className="text-primary">Motif:</span>{" "}
              {ceremonie.motifPaiement
                ? ceremonie.motifPaiement.designation
                : "-"}
            </p>
          </div>
          <div className="mt-1">
            <p className="text-base text-gray-600 text-left font-semibold">
              <span className="text-primary">Prix:</span>{" "}
              {ceremonie.tarif ? `${ceremonie.tarif.montant} FCFA` : "-"}
            </p>
          </div>
          <div className="mt-1">
            <p className="text-base text-gray-600 text-left font-semibold">
              <span className="text-primary">Commission</span>{" "}
              {ceremonie.commission ? ceremonie.commission.designation : "-"}
            </p>
          </div>
          <div className="mt-1">
            <p className="text-base text-gray-600 text-left font-semibold">
              <span className="text-primary">Niveau:</span>{" "}
              {ceremonie.niveau ? (
                <div className="flex flex-wrap gap-2">
                  {ceremonie.niveau.map((item, index) => (
                    <span
                      key={index}
                      className="w-fit px-3 h-6 rounded-md border bg-gray-100 flex items-center justify-center text-xs"
                    >
                      {item.designation}
                    </span>
                  ))}
                </div>
              ) : (
                "-"
              )}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const clearFormEnregistrement = () => {
    setCeremonieData({
      designation: "",
      commission: "",
      niveaux: "",
      motifPaiement: ""
    });
  };

  const handlePaginationChange = (event, value) => {
    setLoadingData(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value
    }));
    recupererListeCeremoniesPaginee(
      value,
      pageQuery.size,
      searchTerm,
      commissionSelected,
      niveauSelected
    )
      .then((res) => {
        setLoadingData(false);
        setListeCeremonies(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // RECUPERER LES CEREMONIES
  const rechercherListeCeremoniesPaginee = () => {
    setSearchLoading(true);
    recupererListeCeremoniesPaginee(
      0,
      pageQuery.size,
      searchTerm,
      commissionSelected,
      niveauSelected
    )
      .then((res) => {
        setSearchLoading(false);
        setListeCeremonies(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setSearchLoading(false);
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES NIVEAUX FOR MODIFS INFOS CATECHU===
  const getListeDesNiveauxParCommission = (selectCommission) => {
    listeCompleteDesNiveauParCommission(selectCommission)
      .then((res) => {
        let liste = [];
        res.data.map((item) => {
          liste.push({ label: item.designation, value: item._id });
        });
        setListeNiveau(liste);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesCommission = () => {
    listeCompleteDesCommissionsParAnnee()
      .then((res) => {
        setListeCommission(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // LISTE MOTIF PAIEMENT
  const getListeDesMotifsDePaiement = () => {
    listeCompleteDesMotifPaiements()
      .then((res) => {
        setListeMotifPaiement(res.data);
      })
      .catch((error) => {
        console.log("Impossible de récupérer les motifs", error);
      });
  };

  // LISTE TARIFS DES MOTIF
  const getListeTarifDesMotifsDePaiement = (motifId) => {
    listeCompleteDesTarifications(motifId)
      .then((res) => {
        setListeTarifPaiement(res.data);
      })
      .catch((error) => {
        console.log("Impossible de récupérer les tarifs", error);
      });
  };

  const rechercheDesEvenements = () => {};

  // === ENREGISTRE UNE CEREMONIE ===
  const enregistrerNouvelleCeremonie = () => {
    setAddLoading(true);
    let liste = [];
    ceremonieData.niveaux.map((item) => liste.push(item.value));
    const values = {
      ...ceremonieData,
      niveau: liste,
      tarif: listeTarifPaiement.length > 0 ? listeTarifPaiement[0]._id : "",
      anneeScolaire: currentAnnee ? currentAnnee._id : null
    };
    enregistrerCeremonie(values)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Cérémonie enregistréee avec succès.");
        setSnackbarOpenSuccess(true);
        rechercherListeCeremoniesPaginee();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("ELEMENT", err);
      });
  };

  const deleteCeremonie = () => {
    setDeleteLoading(true);

    supprimerCeremonie(ceremonieInfo._id)
      .then((res) => {
        setSuccessMessage("Cérémonie supprimé avec succès");
        rechercherListeCeremoniesPaginee();
        setSnackbarOpenSuccess(true);
        setDeleteLoading(false);
        document.getElementById("delete_ceremonie").close();
      })
      .catch((err) => {
        setDeleteLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
      });
  };

  useEffect(() => {
    getListeDesCommission();
    getListeDesMotifsDePaiement();

    recupererListeCeremoniesPaginee(0, pageQuery.size, "", "", "")
      .then((res) => {
        setLoadingData(false);
        setListeCeremonies(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  }, []);

  return (
    <div>
      <div className="mt-8">
        {/* FILTRE DES ANIMATEURS */}
        <div className="mt-8 flex flex-col lg:flex-row justify-end items-end lg:items-start gap-2">
          <div className="w-full md:w-fit flex items-center gap-x-2 overflow-x-scroll sm:overflow-auto">
            <input
              type="text"
              placeholder="Rechercher un élement..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="input input-bordered   h-10 text-sm font-medium"
            />
            <select
              value={commissionSelected}
              onChange={(e) => {
                setCommissionSelected(e.target.value);
                getListeDesNiveauxParCommission(e.target.value);
              }}
              className="select select-bordered custom-select w-32 h-10 font-medium"
            >
              <option disabled selected value="">
                Commission
              </option>
              <option value="" className="font-medium">
                Tout
              </option>
              {listeCommission.map((item, index) => (
                <option key={index} value={item._id} className="font-medium">
                  {item.designation}
                </option>
              ))}
            </select>
            <select
              value={niveauSelected}
              onChange={(e) => {
                setNiveauSelected(e.target.value);
              }}
              className="select select-bordered custom-select w-40 h-10 font-medium"
            >
              <option disabled selected value="">
                Niveau
              </option>
              <option value="" className="font-medium">
                Tout
              </option>
              {listeNiveau.map((item, index) => (
                <option key={index} value={item.value} className="font-medium">
                  {item.label}
                </option>
              ))}
            </select>
            <button
              disabled={searchLoading}
              className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
              onClick={rechercherListeCeremoniesPaginee}
            >
              {!searchLoading ? (
                "Rechercher"
              ) : (
                <ThreeDots
                  height="35"
                  width="35"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={searchLoading}
                />
              )}
            </button>
          </div>

          {verificationPermissionsExistantes(listePermissions, "AJOUTER") && (
            <button
              onClick={() => {
                clearFormEnregistrement();
                setOpenModal(true);
              }}
              className="bg-black w-full sm:w-fit h-10 px-4 flex items-center justify-center gap-x-2 rounded-xl"
            >
              <img src={iconeAdd} alt="" className="w-5 sm:w-auto" />
              <span className="text-white text-sm font-semibold truncate">
                Ajouter une cérémonie
              </span>
            </button>
          )}
        </div>

        <div>
          {!loadingData && listeCeremonies.length > 0 && (
            <div className="mt-8 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-4 w-full">
              {listeCeremonies.map((ceremonie, index) => (
                <CeremonieElement ceremonie={ceremonie} key={index} />
              ))}
            </div>
          )}
        </div>

        {!loadingData && listeCeremonies.length === 0 ? (
          <div className="mt-5 h-56 w-full flex items-center justify-center">
            <p className="text-red-700 font-bold text-center">
              Aucun élément trouvé.
            </p>
          </div>
        ) : null}

        {loadingData && listeCeremonies.length === 0 ? (
          <div className="mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-4 w-full">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
              <div
                key={item}
                className="w-full bg-white rounded-xl p-4 border drop-shadow-sm"
              >
                <div className="skeleton h-16 w-16 rounded-full mx-auto"></div>
                <div className="skeleton h-5 w-11/12 mx-auto rounded mt-4"></div>
                <div className="skeleton h-5 w-9/12 mx-auto rounded mt-2"></div>
              </div>
            ))}
          </div>
        ) : null}

        {loadingData && listeCeremonies.length !== 0 ? (
          <div className="mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-4 w-full">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
              <div
                key={item}
                className="w-full bg-white rounded-xl p-4 border drop-shadow-sm"
              >
                <div className="skeleton h-16 w-16 rounded-full mx-auto"></div>
                <div className="skeleton h-5 w-11/12 mx-auto rounded mt-4"></div>
                <div className="skeleton h-5 w-9/12 mx-auto rounded mt-2"></div>
              </div>
            ))}
          </div>
        ) : null}

        {!loadingData && listeCeremonies.length > 0 && (
          <div className="flex justify-end mt-5">
            <Pagination
              count={pageQuery.nombrePage}
              page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginationChange}
            />
          </div>
        )}
      </div>

      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="w-screen sm:w-[430px] pt-2 px-4 flex flex-col bg-white ">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <img src={iconeCloseWindow} alt="" />
          </button>

          <h2 className="mt-3 text-xl font-extrabold">
            Ajouter une nouvelle cérémonie
          </h2>

          <div className="mt-6">
            <div className="w-full">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Nom de la cérémonie
                  </span>
                </div>
                <input
                  type="text"
                  disabled={addLoading}
                  value={ceremonieData.designation}
                  onChange={(e) => {
                    setCeremonieData((prev) => ({
                      ...prev,
                      designation: e.target.value
                    }));
                  }}
                  placeholder="Entrer une designation"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text font-bold text-gray-500 text-sm">
                  Commission
                </span>
              </div>
              <select
                disabled={addLoading}
                value={ceremonieData.commission}
                onChange={(e) => {
                  setCeremonieData((prev) => ({
                    ...prev,
                    commission: e.target.value
                  }));
                  setCommissionSelected(e.target.value);
                  getListeDesNiveauxParCommission(e.target.value);
                }}
                className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
              >
                <option disabled selected value="">
                  Choisir un élément
                </option>
                {listeCommission.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
            </label>
            <div className="form-control w-full">
              <div className="label">
                <span className="label-text font-bold text-gray-500 text-sm">
                  Niveaux
                </span>
              </div>
              <MultiSelect
                overrideStrings={languageOption}
                options={listeNiveau}
                value={ceremonieData.niveaux || []}
                onChange={(selected) => {
                  setCeremonieData((prev) => ({
                    ...prev,
                    niveaux: selected
                  }));
                }}
                labelledBy="Select"
              />
            </div>
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text font-bold text-gray-500 text-sm">
                  Motif de paiement
                </span>
              </div>
              <select
                disabled={addLoading}
                value={ceremonieData.motifPaiement}
                onChange={(e) => {
                  setCeremonieData((prev) => ({
                    ...prev,
                    motifPaiement: e.target.value
                  }));
                  getListeTarifDesMotifsDePaiement(e.target.value);
                }}
                className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
              >
                <option disabled selected value="">
                  Choisir un élément
                </option>
                {listeMotifPaiement.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
            </label>
            {ceremonieData.motifPaiement && listeTarifPaiement.length > 0 && (
              <div className="mt-4 ml-1">
                <p className="text-sm font-semibold">
                  Le tarif pour ce motif de paiement est de{" "}
                  <span className="font-extrabold text-primary">
                    {listeTarifPaiement[0].montant} F CFA
                  </span>
                </p>
              </div>
            )}
            {ceremonieData.motifPaiement && listeTarifPaiement.length == 0 && (
              <div className="mt-4 ml-1">
                <p className="text-sm font-semibold">
                  Aucun tarif n'a été défini pour ce motif de paiement
                </p>
              </div>
            )}

            <button
              disabled={addLoading}
              className="mt-10 bg-primary flex items-center justify-center w-full h-11 font-medium text-sm text-white rounded-lg hover:drop-shadow-md"
              onClick={enregistrerNouvelleCeremonie}
            >
              {!addLoading ? (
                <span>Enregistrer</span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={addLoading}
                />
              )}
            </button>
          </div>
        </div>
      </Drawer>

      {/* MODAL DELETE ENTREPRISE */}
      <dialog id="delete_ceremonie" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-center text-primary">
            Supprimer cette cérémonie
          </h3>
          <div className="py-4">
            <p className="text-center text-base font-medium text-gray-600">
              Attention action est irrversible et empêchera quiconque ayant de
              lien d'inscription de pouvoir s'inscrire
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? (
                <div
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={deleteCeremonie}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
          className="z-[999999]"
        >
          <div className="bg-green-500 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
          className="z-[999999]"
        >
          <div className="bg-red-500 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default Ceremonies;
