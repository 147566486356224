import React, { useState } from "react";
import BaseLayout from "../../layout/BaseLayout";
import { Tab, Tabs } from "@mui/material";
import Ceremonies from "./ceremonies";
import Evenement from "../evenements/Evenement";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const CeremonieEvenement = () => {
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <BaseLayout>
      <div className="w-full h-full">
        <div className="w-full">
          <h1 className="text-2xl md:text-3xl font-extrabold">Cérémonies et Evénements</h1>
          <p className="mt-2 text-gray-500 font-medium text-sm sm:text-base md:text-lg">
            Listes des cérémonies et événments pour cette année
          </p>
        </div>

        <div className="mt-5 border-b">
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            variant="scrollable"
            textColor="black"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            <Tab
              label="Evénements"
              {...a11yProps(0)}
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                fontWeight: 700
              }}
            />
            <Tab
              label="Cérémonies"
              {...a11yProps(1)}
              sx={{
                textTransform: "capitalize",
                fontSize: 14,
                fontWeight: 700
              }}
            />
          </Tabs>
        </div>

        <div className="mt-8">
          <CustomTabPanel value={valueTab} index={0}>
            <Evenement />
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={1}>
            <Ceremonies />
          </CustomTabPanel>
        </div>
      </div>
    </BaseLayout>
  );
};

export default CeremonieEvenement;
