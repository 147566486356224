import { BASE_URL, FREE_BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
}, function (error) {
  console.log('AN ERROR REQUEST', error)
});

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const listeDesTarificationPaginee = async (page = 1, size = 10, param = "", motif = "") => {  
  return await axios.get(
    BASE_URL(`tarification/query?page=${page}&size=${size}&param=${param}&motif=${motif}&annee=${currentAnnee ? currentAnnee._id : ""}`),
    {}
  );
};

export const recupererTarificationParMotifPaiement = async (motif = "") => {
  return await axios.get(
    BASE_URL(`tarification/motif/${motif}?annee=${currentAnnee ? currentAnnee._id : ""}`),
    {}
  );
};

export const listeCompleteDesTarifications = async (motif = "", anneeScolaire = "") => {
  return await axios.get(
    FREE_BASE_URL(`tarification/complete?motif=${motif}&annee=${currentAnnee ? currentAnnee._id : ""}`),
    {}
  );
};

export const listeCompleteDesTarificationsParAnnee = async (anneeId) => {
  return await axios.get(
    BASE_URL(`tarification`),
    {}
  );
};

export const recupereTarificationParSonId = async (tarificationId) => {
  return await axios.get(
    BASE_URL(`tarification/${tarificationId}`),
    {}
  );
};

export const ajouterUneTarification = async (values) => {
  return await axios.post(
    BASE_URL(`tarification`),
    values
  );
};

export const modifierUneTarification = async (values, tarificationId) => {
  return await axios.put(
    BASE_URL(`tarification/${tarificationId}`),
    values
  );
};

export const supprimerUneTarification = async (tarificationId) => {
  return await axios.delete(
    BASE_URL(`tarification/${tarificationId}`),
    {}
  );
};
