import React, { useEffect, useRef, useState } from "react";
import BaseLayout from "../../layout/BaseLayout";
import DatePicker from "react-datepicker";
import iconeCalendrier from "../../assets/IconeSvg/calendrieTransparent.svg";
import iconeAdd from "../../assets/IconeSvg/boutonAdd.svg";
import iconethreeDots from "../../assets/IconeSvg/treeDotsGray.svg";
import iconeDelete from "../../assets/IconeSvg/delete.svg";
import iconeDesactiver from "../../assets/IconeSvg/desactiver.svg";
import iconePencil from "../../assets/IconeSvg/pencil.svg";
import iconeCloseWindow from "../../assets/IconeSvg/close_window.svg";
import iconeAppareilPhoto from "../../assets/IconeSvg/appareil_photo.svg";
import { Drawer, Pagination, Snackbar } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import {
  desactivUserById,
  enregistrerAdmin,
  ListDesAdmims,
  supprminerAdmin,
  udpdateAdnim
} from "../../services/administrateurs/AdminRequest";
import {
  returnPermissionsParMenu,
  verificationPermissionsExistantes
} from "../../utils/ManageUserProfil";

const listePermissions = returnPermissionsParMenu("/administrateurs");

const Administrateurs = () => {
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 10,
    nombrePage: 1,
    pagePagination: 1
  });

  const [nomAdmin, setNomAdmin] = useState("");
  const [prenomAdmin, setPrenomAdmin] = useState("");
  const [telAdmin, setTelAdmin] = useState("");
  const [secondTelAdmin, setSecondTelAdmin] = useState("");
  const [passwordAdmin, setPasswordAdmin] = useState("");
  const [emailAdmin, setEmailAdmin] = useState("");
  const [profilAdmin, setProfilAdmin] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [desactiverAdmin, setDesactiverAdmin] = useState(false);

  const fileInputRef = useRef(null);
  const [preview, setPreview] = useState(null);
  const [filePhoto, setFilePhoto] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [listeAdministrateur, setListeAdministrateur] = useState([]);

  const [infosAdministrateur, setInfosAdministrateur] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingChauffeurNonAssocier, setLoadingChauffeurNonAssocier] =
    useState(false);

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  // Upload photo de profil
  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFilePhoto(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };

      console.log(preview);
      reader.readAsDataURL(file);
    }
  };

  const openModalModif = (adminItem) => {
    setOpenModal(true);
    setInfosAdministrateur(adminItem);
    setNomAdmin(adminItem.nom);
    setPrenomAdmin(adminItem.prenoms);
    setTelAdmin(adminItem.contact);
    setSecondTelAdmin(adminItem.secondContact);
    setEmailAdmin(adminItem.email);
    setFilePhoto(adminItem.photo);
    setProfilAdmin(adminItem.role);
  };

  const clearInputEnregistrement = () => {
    setNomAdmin("");
    setPrenomAdmin("");
    setTelAdmin("");
    setSecondTelAdmin("");
    setEmailAdmin("");
    setProfilAdmin("");
    setPasswordAdmin("");
    setFilePhoto(null);
    setPreview(null);
  };

  const enregistrementAdmin = () => {
    setIsLoading(true);
    const dataUser = new FormData();
    dataUser.append("email", emailAdmin);
    dataUser.append("nom", nomAdmin);
    dataUser.append("prenoms", prenomAdmin);
    dataUser.append("contact", telAdmin);

    dataUser.append("secondContact", secondTelAdmin);
    dataUser.append("password", passwordAdmin);
    // dataUser.append("fonction", profilAdmin);
    dataUser.append("photo", filePhoto);
    dataUser.append("role", profilAdmin);

    enregistrerAdmin(dataUser)
      .then((res) => {
        setIsLoading(false);
        setSuccessMessage("Administrateur enregistré avec succès");
        setSnackbarOpenSuccess(true);
        setOpenModal(false);
        clearInputEnregistrement();
        listeDesUsers();
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage("Erreur lors de l'enregistrement de l'administrateur");
        setSnackbarOpenError(true);
      });
  };

  const modifAdmin = () => {
    setIsLoading(true);
    const dataUser = new FormData();
    dataUser.append("email", emailAdmin);
    dataUser.append("nom", nomAdmin);
    dataUser.append("prenoms", prenomAdmin);
    dataUser.append("contact", telAdmin);
    dataUser.append("secondContact", secondTelAdmin);
    // dataUser.append("password", passwordAdmin);
    // dataUser.append("fonction", profilAdmin);
    dataUser.append("photo", filePhoto);
    dataUser.append("role", profilAdmin);
    udpdateAdnim(infosAdministrateur._id, dataUser)
      .then((res) => {
        setIsLoading(false);
        setSuccessMessage("Administrateur modifié avec succès");
        setSnackbarOpenSuccess(true);
        setOpenModal(false);
        clearInputEnregistrement();
        listeDesUsers();
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage("Erreur lors de la modification de l'administrateur");
        setSnackbarOpenError(true);
      });
  };

  const deleteAdmin = () => {
    setDeleteLoading(true);
    supprminerAdmin(infosAdministrateur._id)
      .then((res) => {
        setDeleteLoading(false);
        console.log(res);
        setSuccessMessage("Administrateur supprimé");
        setSnackbarOpenSuccess(true);
        listeDesUsers();
        document.getElementById("delete_administrateur").click();
      })
      .catch((err) => {
        setDeleteLoading(false);
        console.log("api error", err);
        setErrorMessage("Erreur de suppression de l utilisateur, réessayez!");
        setSnackbarOpenError(true);
      });
  };

  const desactivAdmin = () => {
    setDesactiverAdmin(true);
    desactivUserById(infosAdministrateur._id)
      .then((res) => {
        setDesactiverAdmin(false);
        console.log(res);
        setSuccessMessage("Administrateur desactiver");
        setSnackbarOpenSuccess(true);
        listeDesUsers();
        document.getElementById("desactiver_administrateur").click();
      })
      .catch((err) => {
        setDesactiverAdmin(false);
        console.log("api error", err);
        setErrorMessage("Erreur de désactivation de l utilisateur, réessayez!");
        setSnackbarOpenError(true);
      });
  };

  const listeDesUsers = () => {
    setIsLoading(true);

    ListDesAdmims(pageQuery.page, pageQuery.size, "")
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        setListeAdministrateur(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorMessage("Erreur lors de la récupération des administrateurs");
      });
  };

  const handlePaginationChange = (event, value) => {
    setIsLoading(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value
    }));
    if (searchTerm.length !== 0) {
      ListDesAdmims(value, pageQuery.size, "")
        .then((res) => {
          setListeAdministrateur(res.data.results);
          setPageQuery((prev) => ({
            ...prev,
            nombrePage: res.data.totalPages,
            totalElements: res.data.totalElements
          }));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("api error", err);
        });
    } else {
      ListDesAdmims(value, pageQuery.size, searchTerm)
        .then((res) => {
          setIsLoading(false);
          setListeAdministrateur(res.data.results);
          setPageQuery((prev) => ({
            ...prev,
            nombrePage: res.data.totalPages
          }));
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("api error", err);
        });
    }
  };

  const searchAdmin = () => {
    setSearchLoading(true);

    ListDesAdmims(pageQuery.page, pageQuery.size, searchTerm)
      .then((res) => {
        setSearchLoading(false);
        setListeAdministrateur(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((err) => {
        setSearchLoading(false);
        console.log("api error", err);
      });
  };

  useEffect(() => {
    // setTimeout(() => {
    listeDesUsers();
    // }, 3000);
  }, []);

  return (
    <BaseLayout>
      {verificationPermissionsExistantes(listePermissions, "VOIR_LISTE") ? (
        <div className="w-full h-full">
          <div className="w-full ">
            <h1 className="text-2xl md:text-3xl font-extrabold">
              Administrateurs
            </h1>
            <p className="mt-2 text-gray-500 font-medium text-sm sm:text-base md:text-lg">
              Ajouter, modifier, supprimer les personnes abilitées à administrer
              l’application.
            </p>
          </div>

          <div className="mt-5 flex flex-col lg:flex-row justify-end items-end lg:items-start gap-2">
            <div className="w-full md:w-fit flex items-center gap-x-2 overflow-x-scroll sm:overflow-auto">
              <input
                type="text"
                placeholder="Rechercher un élement..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="input input-bordered w-11/12 sm:w-56 h-10 text-sm font-medium"
              />
              <button
                disabled={searchLoading}
                className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
                onClick={searchAdmin}
              >
                {!searchLoading ? (
                  "Rechercher"
                ) : (
                  <ThreeDots
                    height="35"
                    width="35"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={searchLoading}
                  />
                )}
              </button>
            </div>

            {verificationPermissionsExistantes(listePermissions, "AJOUTER") && (
              <button
                onClick={() => {
                  clearInputEnregistrement();
                  setOpenModal(true);
                }}
                className="bg-black w-full sm:w-fit h-10 px-4 flex items-center justify-center gap-x-2 rounded-xl"
              >
                <img src={iconeAdd} alt="" className="w-5 sm:w-auto" />
                <span className="text-white text-sm font-semibold truncate">
                  Ajouter une administrateur
                </span>
              </button>
            )}
          </div>

          <div className="mt-8 w-full h-full">
            {/* Liste des Administrateurs */}
            <div className="flex flex-col justify-between h-full w-full">
              <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5">
                {listeAdministrateur && listeAdministrateur.length > 0 ? (
                  listeAdministrateur.map(
                    (item, index) =>
                      item.role !== "SUPER ADMIN" && (
                        <div
                          key={index}
                          className="relative w-full h-fit p-2 flex items-start bg-white hover:shadow-sm cursor-pointer rounded-lg border"
                        >
                          <div className="flex h-12 w-12 rounded-lg bg-slate-200 items-center justify-center">
                            <img
                              src={item.photo}
                              alt=""
                              className="h-full w-full rounded-lg"
                            />
                          </div>

                          <div className="w-8/12 gap-1 px-4">
                            <h2 className="text-base font-bold truncate">
                              {item.nom} {item.prenoms}
                            </h2>
                            <span className="text-sm text-[#7A7A7A] font-semibold">
                              {item.contact}
                            </span>
                            {item.enabled ? (
                              <div
                                className="w-fit h-fit bg-green-100 border border-green-200 mt-2 text-xs text-green-600
                          font-semibold rounded px-2 py-px"
                              >
                                Actif
                              </div>
                            ) : (
                              <div
                                className="w-fit h-fit bg-red-100 border border-red-200 mt-2 text-xs text-red-600
                          font-semibold rounded px-2 py-px"
                              >
                                Inactif
                              </div>
                            )}
                          </div>

                          <div className="absolute right-3 dropdown dropdown-bottom dropdown-end">
                            <button className="w-7 h-7 rounded-lg bg-gray-200 flex items-center justify-center">
                              <img src={iconethreeDots} alt="" />
                            </button>

                            <ul
                              tabIndex={0}
                              className="dropdown-content z-50 menu p-1 shadow border bg-base-100 rounded-lg w-32 mr-2"
                            >
                              {verificationPermissionsExistantes(
                                listePermissions,
                                "MODIFIER"
                              ) && (
                                <button
                                  onClick={() => openModalModif(item)}
                                  className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                                >
                                  <img
                                    src={iconePencil}
                                    alt=""
                                    className="w-5"
                                  />
                                  <span className="font-semibold text-sm">
                                    Modifier
                                  </span>
                                </button>
                              )}

                              {verificationPermissionsExistantes(
                                listePermissions,
                                "ETAT_ADMIN"
                              ) && (
                                <button
                                  onClick={() => {
                                    window.desactiver_administrateur.showModal();
                                    setInfosAdministrateur(item);
                                  }}
                                  className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                                >
                                  <img
                                    src={iconeDesactiver}
                                    alt=""
                                    className="w-5"
                                  />
                                  <span className="font-semibold text-sm">
                                    {item.enabled ? "Désactiver" : "Activer"}
                                  </span>
                                </button>
                              )}

                              {verificationPermissionsExistantes(
                                listePermissions,
                                "SUPPRIMER"
                              ) && (
                                <button
                                  onClick={() => {
                                    window.delete_administrateur.showModal();
                                    setInfosAdministrateur(item);
                                  }}
                                  className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                                >
                                  <img
                                    src={iconeDelete}
                                    alt=""
                                    className="w-5"
                                  />
                                  <span className="font-semibold text-sm">
                                    Supprimer
                                  </span>
                                </button>
                              )}
                            </ul>
                          </div>
                        </div>
                      )
                  )
                ) : isLoading && listeAdministrateur.length === 0 ? (
                  <SkeletonAdministrateurs />
                ) : (
                  <div className="flex col-span-full w-full items-center justify-center h-[55vh]">
                    <div className="flex items-center justify-center text-red-500">
                      <p className="font-medium">Aucun administrateur trouvé</p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-end mt-4">
              <Pagination
                count={pageQuery.nombrePage}
                variant="outlined"
                shape="rounded"
                onChange={handlePaginationChange}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="h-56 flex items-center justify-center">
          <p className="text-base text-primary font-extrabold">
            Vous n'avez pas accès à ce menu.
          </p>
        </div>
      )}

      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          clearInputEnregistrement();
          setInfosAdministrateur(null);
        }}
      >
        <div className=" xl:w-[350px] lg:w-[350px] md:w-[350px] px-1 pt-2 pl-2 flex flex-col bg-white ">
          <div className="flex justify-start ">
            <img
              onClick={() => {
                setOpenModal(false);
                clearInputEnregistrement();
                setInfosAdministrateur(null);
              }}
              src={iconeCloseWindow}
              className="cursor-pointer"
              alt=""
            />
          </div>

          <h2 className="mt-3 ml-2 text-lg font-semibold">
            {infosAdministrateur === null
              ? " Ajouter un  administrateur"
              : "Modifier cet  administrateur"}
          </h2>

          <div className="flex justify-center items-center  mt-6 h-24 w-full ">
            <div
              onClick={handleClick}
              className="flex justify-center items-center w-20 h-20 bg-[#F9F9F9] rounded-full cursor-pointer"
            >
              {preview &&
              (infosAdministrateur !== null || infosAdministrateur === null) ? (
                <div className="relative ">
                  <div className="absolute bg-[#0000009a]  w-20 h-20 rounded-full flex justify-center">
                    <img src={iconeAppareilPhoto} className="w-8" alt="" />
                  </div>
                  <img
                    src={preview}
                    alt="Preview"
                    className="w-20 h-20 rounded-full"
                  />
                </div>
              ) : preview === null && infosAdministrateur !== null ? (
                <div className="relative">
                  <div className="absolute bg-[#00000079]  w-20 h-20 rounded-full flex justify-center">
                    <img src={iconeAppareilPhoto} className="w-8" alt="" />
                  </div>
                  <img
                    src={infosAdministrateur && infosAdministrateur.photo}
                    alt=""
                    className="w-20 h-20 rounded-full"
                  />
                </div>
              ) : (
                <div className="w-20 h-20 rounded-full flex justify-center">
                  <img src={iconeAppareilPhoto} className="w-8" alt="" />
                </div>
              )}
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>

          <div className="flex flex-col w-full pb-4">
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold text-md">Nom</span>
                </div>
                <input
                  type="text"
                  value={nomAdmin}
                  onChange={(e) => setNomAdmin(e.target.value)}
                  placeholder="Entrer le nom"
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>

              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Prénoms
                  </span>
                </div>
                <input
                  type="text"
                  value={prenomAdmin}
                  onChange={(e) => setPrenomAdmin(e.target.value)}
                  placeholder="Entrer les prénoms"
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>
            </div>

            <div className="flex flex-row justify-center items-center gap-x-3">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Téléphone
                  </span>
                </div>
                <input
                  type="text"
                  value={telAdmin}
                  onChange={(e) => setTelAdmin(e.target.value)}
                  placeholder="Entrer un numéro"
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>

              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Second contact
                  </span>
                </div>
                <input
                  type="text"
                  value={secondTelAdmin}
                  onChange={(e) => setSecondTelAdmin(e.target.value)}
                  placeholder="Entrer le second contact"
                  className="input input-bordered w-full bg-[#F9F9F9]"
                />
              </label>
            </div>

            <div>
              {infosAdministrateur === null ? (
                <>
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-semibold text-md">
                        Email
                      </span>
                    </div>
                    <input
                      type="email"
                      value={emailAdmin}
                      onChange={(e) => setEmailAdmin(e.target.value)}
                      placeholder="Entrer une adresse email"
                      className="input input-bordered w-full bg-[#F9F9F9]"
                    />
                  </label>
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-semibold text-md">
                        Mot de passe
                      </span>
                    </div>
                    <input
                      type="password"
                      value={passwordAdmin}
                      onChange={(e) => setPasswordAdmin(e.target.value)}
                      placeholder="Votre mot de passe"
                      className="input input-bordered w-full bg-[#F9F9F9]"
                    />
                  </label>
                </>
              ) : null}

              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-semibold text-md">
                    Profil
                  </span>
                </div>
                {/* ANIMATEUR CURE PRESIDENT DAF ADMIN */}

                <select
                  value={profilAdmin}
                  onChange={(e) => {
                    setProfilAdmin(e.target.value);
                  }}
                  className="select select-bordered custom-select w-full h-10 font-medium"
                >
                  <option disabled selected value="">
                    Choisir un profil
                  </option>
                  <option value="CURE" className="font-medium">
                    CURE
                  </option>
                  <option value="ADMIN" className="font-medium">
                    ADMIN
                  </option>
                  <option value="DAF" className="font-medium">
                    DAF
                  </option>
                  <option value="PRESIDENT" className="font-medium">
                    PRESIDENT
                  </option>
                  {/* <option value="ANIMATEUR" className="font-medium">ANIMATEUR</option> */}
                </select>
              </label>
            </div>

            <button
              onClick={
                infosAdministrateur === null ? enregistrementAdmin : modifAdmin
              }
              className="mt-5 bg-primary flex items-center justify-center w-full h-10 font-medium text-white rounded-lg hover:drop-shadow-md"
            >
              {!isLoading ? (
                <span>
                  {infosAdministrateur === null
                    ? " Enregistrer cet admin"
                    : "Modifier cet  administrateur"}
                </span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={isLoading}
                />
              )}
            </button>
          </div>
        </div>
      </Drawer>

      {/* MODAL DELETE ADMINSTRATEUR */}
      <dialog id="delete_administrateur" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog" className="flex flex-row justify-end">
            <button className="w-7 h-7 flex mb-[-2rem] items-center  rounded-lg hover:border-red-500 hover:border-2  justify-center text-red-600 font-semibold">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-7 h-7"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-bold text-xl text-center text-red-600">
            Supprimer cet Administrateur
          </h3>
          <div className="py-4">
            <p className="text-center text-lg text-black">
              Attention action est irrversible et entrainera la suppression de
              l'administrateur
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form method="dialog" className="flex gap-x-3">
              {/* if there is a button in form, it will close the modal */}
              <button className="w-32 h-10 bg-gray-300 text-gray-700 rounded-lg">
                Annuler
              </button>
              <button
                onClick={deleteAdmin}
                className="w-32 h-10 bg-red-600 text-white rounded-lg flex items-center justify-center cursor-pointer"
              >
                Supprimer
              </button>
            </form>
          </div>
        </div>
      </dialog>

      {/* MODAL DESACTIVER ADMINSTRATEUR */}
      <dialog id="desactiver_administrateur" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog" className="flex flex-row justify-end">
            <button className="w-7 h-7 flex mb-[-2rem] items-center  rounded-lg hover:border-red-500 hover:border-2  justify-center text-red-600 font-semibold">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-7 h-7"
                alt=""
              />
            </button>
          </form>
          {infosAdministrateur && infosAdministrateur.enabled ? (
            <h3 className="font-bold text-xl text-center text-red-600">
              Désactiver cet Administrateur
            </h3>
          ) : (
            <h3 className="font-bold text-xl text-center text-greeb-600">
              Activer cet Administrateur
            </h3>
          )}

          <div className="py-4">
            <p className="text-center text-lg text-black">
              Attention action est irrversible et entrainera la désactivation de
              l'administrateur
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form method="dialog" className="flex gap-x-3">
              {/* if there is a button in form, it will close the modal */}
              <button className="w-32 h-10 bg-gray-300 text-gray-700 rounded-lg">
                Annuler
              </button>
              <button
                onClick={desactivAdmin}
                className={`w-32 h-10 ${
                  infosAdministrateur && infosAdministrateur.enabled
                    ? " bg-red-600"
                    : "bg-green-600"
                } text-white rounded-lg flex items-center justify-center cursor-pointer`}
              >
                {infosAdministrateur && infosAdministrateur.enabled ? (
                  <h2
                    className="text-[12px] text-white
                        font-semibold"
                  >
                    Désactiver
                  </h2>
                ) : (
                  <h2
                    className="text-[12px] text-white
                        font-semibold"
                  >
                    Activer
                  </h2>
                )}
              </button>
            </form>
          </div>
        </div>
      </dialog>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="bg-green-500 text-white px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="bg-red-500 text-white px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </BaseLayout>
  );
};

export default Administrateurs;

const SkeletonAdministrateurs = () => {
  const skeletonItems = Array.from({ length: 6 }); // Nombre d'éléments skeleton à afficher

  return (
    <>
      {skeletonItems.map((_, index) => (
        <div
          key={index}
          className="relative w-full h-fit p-2 bg-white rounded-lg border"
        >
          <div className="flex flex-row items-start">
            <div className="skeleton h-12 w-12 shrink-0 rounded-lg bg-gray-200 animate-pulse"></div>
            <div className="flex flex-col gap-1 w-[80%] px-4">
              <div className="skeleton h-2 xl:w-84 lg:w-84 md:w-84 w-40 bg-gray-200 animate-pulse"></div>
              <div className="skeleton h-2 w-28 bg-gray-200 animate-pulse"></div>
            </div>
            <div className="skeleton flex items-center gap-x-2">
              <button className="w-7 h-7 rounded-lg bg-gray-200 flex items-center justify-center">
                <img src={iconethreeDots} alt="" />
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
