import React, { useEffect, useRef, useState } from "react";
import BaseLayout from "../../../layout/BaseLayout";
import DatePicker from "react-datepicker";
import iconeCalendrier from "../../../assets/IconeSvg/calendrier3.svg";
import IconeCalendierTransparent from "../../../assets/IconeSvg/calendrieTransparent.svg";
import iconeAdd from "../../../assets/IconeSvg/boutonAdd.svg";
import iconethreeDots from "../../../assets/IconeSvg/treeDotsGray.svg";
import iconeDelete from "../../../assets/IconeSvg/delete.svg";
import iconeDesactiver from "../../../assets/IconeSvg/desactiver.svg";
import iconePencil from "../../../assets/IconeSvg/pencil.svg";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import iconeAppareilPhoto from "../../../assets/IconeSvg/appareil_photo.svg";
import iconeLocalisation from "../../../assets/IconeSvg/IconeLocalisation.svg";
import { Drawer, Pagination, Snackbar } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { ListPresenseCatechu } from "../../../services/catechumene/CatechumeneRequest";
import { listeDesAnimateurPaginee } from "../../../services/animateur/AnimateurRequest";
import { listeDesClassePaginee } from "../../../services/parametrage/ClasseRequest";

const AppelsCatechu = (idCatechu) => {
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 10,
    nombrePage: 1
  });

  const [searchParam, setSearchParam] = useState("");
  const [listeAnimateurs, setListeAnimateurs] = useState([]);
  const [listeClasse, setListeClasse] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [TableauPontialite, setTableauPontialite] = useState([]);

  const [infosAdministrateur, setInfosAdministrateur] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loadingChauffeurNonAssocier, setLoadingChauffeurNonAssocier] =
    useState(false);

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };
  const formatDate = (date) => {
    // Vérifier si la date est valide
    if (!date) {
      return ""; // Retourner une chaîne vide ou un message par défaut si la date est null/undefined
    }

    // Supprimer les guillemets échappés
    date = date.replace(/\"/g, "");

    // Convertir en objet Date
    date = new Date(date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const TableElement = ({ ponctualiterItem }) => {
    return (
      <tr className="">
        <td className="px-4 text-sm text-black font-semibold ">
          {ponctualiterItem.dateAppel
            ? formatDate(ponctualiterItem.dateAppel)
            : "--"}
        </td>
        <td className="px-4 text-sm text-black font-semibold ">
          {ponctualiterItem.absent ? "Absent": "Présent"}
        </td>

        <td className="px-4 text-sm text-black font-semibold ">
          {ponctualiterItem.enRetard && !ponctualiterItem.absent ? "En retard": "--"}
        </td>

        <td className="px-4 text-sm text-black font-semibold ">
        {(() => {
            const animateur = listeAnimateurs.find(
              (itemAnimateur) =>
                itemAnimateur._id === "66c3d4c88c4a9eafe6f0ee6a"
            );
            return animateur ? `${animateur.prenoms} ${animateur.nom}` : "--";
          })()}
          {/* {(() => {
            const animateur = listeAnimateurs.find(
              (itemAnimateur) =>
                itemAnimateur._id === ponctualiterItem.animateur
            );
            return animateur ? `${animateur.prenoms} ${animateur.nom}` : "--";
          })()} */}
        </td>
      </tr>
    );
  };

  const getListeDesPresencesDesCatechumene = () => {
    setLoadingData(true);

    ListPresenseCatechu(
      idCatechu.idCatechu,
      1,
      pageQuery.size
    )
      .then((res) => {
        setLoadingData(false);
        setTableauPontialite(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  console.log(TableauPontialite);

  // === RECUPERE LISTE DES ANIMATEURS ===
  const getListeDesAnimateurs = () => {
    setLoadingData(true);
    listeDesAnimateurPaginee(1, pageQuery.size, searchParam, "", "", "")
      .then((res) => {
        setLoadingData(false);
        setListeAnimateurs(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES CLASSES ===
  const getListeDesClasse = () => {
    setLoadingData(true);
    listeDesClassePaginee(pageQuery.page, 100, searchParam, "", "")
      .then((res) => {
        setLoadingData(false);
        setListeClasse(res.data.results);
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    getListeDesPresencesDesCatechumene();
    getListeDesAnimateurs();
    getListeDesClasse();
  }, []);

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <div className="flex flex-col w-full h-full">
          <div className="flex xl:flex-row  sm:flex-row md:flex-row  lg:flex-row flex-col gap-y-2  justify-end items-center gap-x-4 mt-3">
            <label className="form-control lg:w-fit xl:w-fit md:w-fit w-full">
              <div className="label">
                <span className="label-text font-semibold text-md">
                  Date du cours
                </span>
              </div>
              <input
                type="date"
                className="input input-bordered lg:w-fit xl:w-fit md:w-fit w-full bg-[#F9F9F9]"
              />
            </label>
          </div>

          {/* Liste des evenements   */}

          <div className="flex w-full mt-2 h-full">
            <div className="overflow-x-auto bg-white w-full mt-2 ">
              <table className="custom-table table  table-zebra w-full">
                <thead>
                  <tr className="bg-black h-12">
                    <th className="text-sm text-white">Date du cours</th>
                    <th className="text-sm text-white">Statut</th>
                    <th className="text-sm text-white">Arrivée</th>
                    <th className="text-sm text-white">Animateur</th>
                  </tr>
                </thead>
                <tbody>
                  {!loadingData &&
                  TableauPontialite &&
                  TableauPontialite.length > 0 ? (
                    TableauPontialite.map((item) => (
                      <TableElement key={item.id} ponctualiterItem={item} />
                    ))
                  ) : !loadingData && TableauPontialite.length === 0 ? (
                    <tr>
                      <td colSpan="6">
                        <div className="w-full h-32 bg-white flex gap-x-2 text-red-500 items-center justify-center">
                          {/* <AlertCircle size={18} /> */}
                          <p className="font-medium text-red-500">
                            Aucun élément trouvé
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : loadingData && TableauPontialite.length === 0 ? (
                    <tr>
                      <td colSpan="6">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                          <TailSpin
                            height="30"
                            width="30"
                            color="#000"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loadingData}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <Pagination
              count={pageQuery.nombrePage}
              // page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
              // onChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="bg-green-500 text-white px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="bg-red-500 text-white px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </>
  );
};

export default AppelsCatechu;
